<template>
  <div>
    <DxDataGrid
        :key-expr="id"            
        :data-source="CustomStoreMain"
        :columns="columns"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :focused-row-enabled="true"
        @exporting="onExporting"
        @init-new-row="initNewRow"
    >
        <DxPaging 
            :enabled="true"
            :page-size="5"
        />

        <DxPager
            :visible="true"
            :allowed-page-sizes="pageSizes"
            :display-mode="displayMode"
            :show-info="true"
            :show-navigation-buttons="true"
        />

        <DxGroupPanel :visible="false"/>

        <DxSorting mode="multiple"/>

        <DxEditing
            :allow-updating="true"
            :allow-adding="true"
            :allow-deleting="true"
            :texts= "{
                    addRow: 'Προσθήκη',
                    deleteRow: 'Διαγραφή',
                    editRow:'Επεξεργασία',
                    saveAllChanges:'Αποθήκευση Όλων',
                    cancelAllChanges:'Ακύρωση Όλων'
                }"
            mode="row"
        >
            <DxTexts
                save-row-changes="Αποθηκευση"
                cancel-row-changes="Κλεισιμο"
                add-row="Προσθήκη"
                delete-row="Διαγραφή"
                edit-row="Επεξεργασία"
            />
        </DxEditing>

        <DxColumn
            data-field="network_id"
            caption=""
            v-model:visible="isColumnVisible"
        >
          <DxLookup
            :data-source="LookupCustomDS_sims_networks" 
            value-expr="id"
            display-expr="name"
            />
        </DxColumn>

        <DxColumn
            data-field="ip"
            caption="Διεύθυνση IP"
        >
            <DxRequiredRule 
                message="Υποχρεωτικό πεδίο"
            />

            <DxPatternRule
                message="Η διεύθυνση IP πρέπει να έχει σωστή μορφή."
                :pattern="IPPattern"
            />
        </DxColumn>

        <DxColumn
            data-field="name"
            caption="Επιπλέον Πληροφορίες για την IP"
        >
        </DxColumn>

    </DxDataGrid>
  </div>
</template>

<script>
import DxDataGrid, {
    DxColumn,
    DxEditing, 
    DxPaging,
    DxPager,
    DxGroupPanel,
    DxSorting,
    DxRequiredRule,
    //DxEmailRule,    
    DxLookup,
    DxTexts,
    DxPatternRule
} from 'devextreme-vue/data-grid';
import 'whatwg-fetch';
import CustomStore from 'devextreme/data/custom_store';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

import OParameters from '../sims-globals';

const URL_SERVICE = OParameters.Host.URI + 'sims_network_ips';
const URL_SERVICE_LOOKUP_sims_networks = OParameters.Host.URI + 'sims_networks';

export default {
        components: {
            DxDataGrid,
            DxColumn,
            DxEditing, 
            DxPaging,
            DxPager,
            DxGroupPanel,
            DxSorting,
            DxRequiredRule,
            //DxEmailRule,
            DxLookup,
            DxTexts,
            DxPatternRule
    },
    props: {
        templateData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            IPPattern: "^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$",
            CustomStoreMain: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE + '?filter=network_id,eq,' + this.templateData.key),
                insert: (values) => this.sendRequest(URL_SERVICE, 'POST', values),
                update: (key, values) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
            }),

            LookupCustomDS_sims_networks:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_networks+ '?order=name'),
            }),
            title: 'Διευθύνσεις IP',
            isColumnVisible: false,
            pageSizes: [5]
        };
    }, 
    
    methods: {
        // 
        //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
        //
        sendRequest(url, method = 'GET', values = {}) {
            // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then((response) => response.json()
                .then((json) => {
                    if (response.ok) return json.records;
                    throw json.Message;
                }));
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {                    
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
        },
        onExporting(e) {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet(this.title);
            exportDataGrid({
                component: e.component,
                worksheet,
                autoFilterEnabled: true,
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), this.title + '.xlsx');
                });
            });
            e.cancel = true;
        },
        initNewRow(row) {            
            row.data.network_id = this.templateData.key;
        },
    },
};
</script>

<style lang="scss">
</style>
