<template>
  <div>
    <h3 class="content-block openware-form-title">{{ title }}</h3>
    <DxPopover
            :shading="true"
            width="70%"
            target="#help-button"
            position="top"
            shading-color="rgba(0, 0, 0, 0.5)"
            v-model:visible="isPopoverVisible"
            :show-title="true"
            title="Βοήθεια: Φόρμα «Διαχείριση Εφαρμογών»"
            :toolbar-items="[{
                                widget: 'dxButton',
                                location: 'after',
                                options: {
                                    icon: 'close',
                                    onClick: () => {
                                        this.isPopoverVisible = false;
                                    }
                                }
                            }
                            ]"
         
        >
            <DxScrollView>
                <div class="application-help">
                    <div class="application-help-intro">
                        Στην Ενότητα Εφαρμογές και άδειες Χρήσης / Διαχείριση Εφαρμογών, καταχωρούνται όλες οι εφαρμογές που βρίσκονται στην κατοχή της υπηρεσίας καθώς και οι διαθέσιμες άδειες χρήσης
                    </div>
                    <br />
                    <div class="application-help-content">
                    </div>
                </div>
            </DxScrollView>
        </DxPopover>

    <DxDataGrid
        :key-expr="id"            
        :data-source="CustomStoreMain"
        :columns="columns"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :focused-row-enabled="true"          
        :word-wrap-enabled="true"
        
        @exporting="onExporting"
        ref="dataGridRef"
    >
        <DxPaging 
            :enabled="true"
            :page-size="5"
        />
        
        <DxFilterRow :visible="true"/>

        <DxHeaderFilter :visible="true"/>

        <DxPager
            :visible="true"
            :allowed-page-sizes="pageSizes"
            :display-mode="displayMode"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
        />

        <DxGroupPanel :visible="false"/>

        <DxSorting mode="multiple"/>
        
        <DxEditing
            :allow-updating="true"
            :allow-adding="true"
            :allow-deleting="true"
            :texts= "{
                    addRow: 'Προσθήκη',
                }"
            
            mode="popup"
        >
            <DxTexts
                save-row-changes="Αποθηκευση"
                cancel-row-changes="Κλεισιμο"
                add-row="Προσθήκη"
                delete-row="Διαγραφή"
                edit-row="Επεξεργασία"
            />
            <DxPopup
                :show-title="true" 

                title="Διαχείριση Εφαρμογών"
                        
                :width="'90%'" 
                :height="'90%'"
                :show-close-button="true"
                > 
                    <DxToolbarItem
                        widget="dxButton"
                        location="after"
                        toolbar="bottom"
                        :options="{ 
                            icon: 'save',
                            text: 'Αποθήκευση', 
                            type: 'success',
                            onClick: () => {
                                this.$refs['dataGridRef'].instance.saveEditData();
                            }
                        }"
                    />
                    <DxToolbarItem 
                        widget="dxButton"
                        location="after"
                        toolbar="bottom"
                        :options="{ 
                            icon: 'close',
                            text: 'Κλείσιμο',
                            type: 'danger',
                            onClick: () => {
                                this.$refs['dataGridRef'].instance.cancelEditData();
                            }
                        }"
                    />
                    <DxToolbarItem 
                        widget="dxButton"
                        location="after"
                        toolbar="top"
                        :options="{ 
                            icon: 'help',
                            onClick: () => {
                                this.isPopoverVisible = !this.isPopoverVisible;
                            },
                            elementAttr: {
                                id: 'help-button'
                            }
                        }"
                    />

            </DxPopup>

            <DxForm>

                <DxItem 
                    itemType="group" 
                    :col-count="2" 
                    :col-span="2" 
                    caption="Κατηγορία"
                >
                    <!--
                    <DxItem data-field="name"/>
                    -->

                    <DxItem data-field="sw_application_id"/>
                    <DxItem data-field="serial_no"/>
                    <DxItem data-field="licenses_quantity"/>
                    <DxItem data-field="download_url"/>
                    
                    <DxItem data-field="username"/>
                    <DxItem data-field="password"/>

                </DxItem>

                <DxItem
                    itemType="group" 
                    :col-count="2" 
                    :col-span="2" 
                    caption="Επιπλέον Πληροφορίες"
                >

                    <DxItem
                            data-field="description"
                            :label="{ visible: false }"
                            :editor-options="{ height: 60, }"
                            :col-span="2"
                            editor-type="dxTextArea"
                    />
                </DxItem>
            </DxForm>

        </DxEditing>

        <DxColumn
            data-field="sw_application_id"
            caption="Εφαρμογή"
                sort-order="asc"
        >
            <DxLookup
                :data-source="LookupCustomDataSourcesims_sw_applications" 
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>

        <!--
        <DxColumn
            data-field="name"
            caption="Όνομα"
        >
            <DxRequiredRule/>
        </DxColumn>
        -->

        <DxColumn
            data-field="serial_no"
            caption="Σειριακός Αριθμός"
            :hiding-priority="110"
        >
            <DxRequiredRule/>
        </DxColumn>

        <DxColumn
            data-field="licenses_quantity"
            caption="Πλήθος Αδειών Χρήσης"
            :hiding-priority="100"
        >
            <DxRequiredRule/>
        </DxColumn>

        <DxColumn
            data-field="download_url"
            caption="Download Link"
            :hiding-priority="90"
        >
        </DxColumn>

        <DxColumn 
            data-field="username"
            caption="Όνομα Χρήστη Εγκατάστασης"
            :hiding-priority="80"
        >
            
        </DxColumn>

        <DxColumn 
            data-field="password"
            caption="Κωδικός Χρήστη Εγκατάστασης"
            :hiding-priority="70"
        >
            
        </DxColumn>

        <DxColumn
            data-field="description"
            caption="Επιπλέον Πληροφορίες"
            :hiding-priority="60"
        >
        </DxColumn>

        <DxExport
            :enabled="true"
            :formats="['pdf', 'xlsx']"
            :texts= "{
                    exportAll: 'Εξαγωγή σε {0}',
                }"
        />

    </DxDataGrid>
  </div>
</template>

<script>
import DxDataGrid, {
    DxColumn,
    DxEditing, 
    DxPaging,
    DxPager,
    DxGroupPanel,
    DxSorting,
    DxFilterRow,
    DxHeaderFilter,
    DxExport,
    DxRequiredRule,
    DxLookup,
    DxForm, 
    DxItem,
    DxTexts,
    DxPopup
} from 'devextreme-vue/data-grid';

import { DxToolbarItem } from 'devextreme-vue/popup'

import 'whatwg-fetch';

import { DxPopover } from 'devextreme-vue/popover';
import DxScrollView from 'devextreme-vue/scroll-view';

import CustomStore from 'devextreme/data/custom_store';

import OParameters from '../sims-globals';

const URL_SERVICE = OParameters.Host.URI + 'sims_sw_software';
const URL_SERVICE_LOOKUP_sims_sw_applications = OParameters.Host.URI + 'sims_sw_applications';

export default {
        components: {
            DxDataGrid,
            DxColumn,
            DxEditing, 
            DxPaging,
            DxPager,
            DxGroupPanel,
            DxSorting,
            DxFilterRow, 
            DxHeaderFilter,
            DxExport,
            DxRequiredRule,
            DxLookup,
            DxForm, 
            DxItem,
            DxTexts,
            DxPopup,
            DxPopover,
            DxScrollView,
            DxToolbarItem
    },
    data() {
        return {
            CustomStoreMain: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE),
                insert: (values) => this.sendRequest(URL_SERVICE, 'POST', values),
                update: (key, values) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
            }),

            LookupCustomDataSourcesims_sw_applications:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_sw_applications+ '?order=name'),
            }),
            
            isPopoverVisible: false,
            title: 'Διαχείριση Εφαρμογών',
            pageSizes: [5, 10, 20]
        };
    }, 
    
    methods: {
        // 
        //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
        //
        sendRequest(url, method = 'GET', values = {}) {
            // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then((response) => response.json()
                .then((json) => {
                    if (response.ok) return json.records;
                    throw json.Message;
                }));
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
        },
        onExporting (e){
            OParameters.methods.exportDataGridToPdfExcel(e, this.title)
        }
    },
};
</script>

<style lang="scss">
</style>
