<template>
  <div>    
    <div class="widget-container">
     
      <DxForm
            id="form"
            :form-data="state.formData"
            label-mode='outside'
            :read-only='false'
            :show-colon-after-label='true'
            label-location='top'
            :min-col-width='300'
            :col-count='2'
            onInitialized="onInitialized"
            ref="formMaster"
      >

        <DxColCountByScreen :xs="1" :sm="1" :md="1" :lg="1"/>

        <DxGroupItem caption="Βασικά Στοιχεία" :col-span="3" :col-count="3">

            <DxSimpleItem
                data-field="entry_date"
                editor-type="dxDateBox"
                :editor-options="dateBoxEditorOptions"
            >
                <DxLabel text="Ημερομηνία Εισαγωγής"/>
            </DxSimpleItem>

            <DxSimpleItem 
                data-field="code"
                editor-type="dxNumberBox"
                :is-required="true"
                :editor-options="{
                    onValueChanged: this.onCodeValueChanged
                }"
                :help-text="helpText"
            >
                <DxLabel text="Κωδικός Αναγνώρισης Συσκευής"/>
                <DxRequiredRule message="O Κωδικός Αναγνώρισης Συσκευής είναι υποχρεωτικός"/>
                <DxAsyncRule
                    message="O Κωδικός Αναγνώρισης Συσκευής υπάρχει ήδη" 
                    :validation-callback="validateIsUniqueCode"
                />
            </DxSimpleItem>

            <DxSimpleItem
                data-field="hw_pc_parts_id"
                editor-type="dxSelectBox"
                searchEnabled = true
                :is-required="true"
                :editor-options="{
                    dataSource: state.LookupCustomDS_sims_hw_pc_parts,
                    searchEnabled: true,
                    displayExpr: 'name',
                    valueExpr:'id',
                    onValueChanged: this.onPcPartsChange
                }"
            >
                <DxLabel text="Μοντέλο Εξαρτήματος"/>
            </DxSimpleItem>

            <DxSimpleItem 
                data-field="serial_no"
            >
                <DxLabel text="Σειριακός Αριθμός"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="hw_pc_parts_category_id"
                editor-type="dxSelectBox"
                searchEnabled = true
                :is-required="true"
                :editor-options="{
                    dataSource: state.LookupCustomDS_sims_hw_pc_parts_categories,
                    searchEnabled: true,
                    displayExpr: 'name',
                    valueExpr:'id',
                    readOnly: true
                }"
            >
                <DxLabel text="Κατηγορία Εξαρτήματος"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="hw_pc_parts_type_id"
                editor-type="dxSelectBox"
                searchEnabled = true
                :is-required="true"
                :editor-options="{
                    dataSource: state.LookupCustomDS_sims_hw_pc_parts_types,
                    searchEnabled: true,
                    displayExpr: 'name',
                    valueExpr:'id',
                    readOnly: true
                }"
            >
                <DxLabel text="Είδος Εξαρτήματος"/>
            </DxSimpleItem>

        </DxGroupItem>

        <DxGroupItem caption="Χρήση Εξαρτήματος" :col-span="3" :col-count="3">
            <DxSimpleItem
                data-field="assigned_employment_staff_id"
                editor-type="dxSelectBox"
                :editor-options="{
                    dataSource: state.LookupCustomDS_sims_employment_staff,
                    searchEnabled: true,
                    displayExpr: 'name',
                    valueExpr:'id'
                }"
            >
                <DxLabel text="Υπάλληλος"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="assigned_date"
                editor-type="dxDateBox"
                :editor-options="dateBoxEditorOptions"
            >
                <DxLabel text="Έναρξη Χρήσης από"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="assigned_for"
            >
                <DxLabel text="Χρήση Σε"/>
            </DxSimpleItem>

        </DxGroupItem>


        <DxGroupItem caption="Απόσυρση Εξαρτήματος" :col-span="3" :col-count="3">
            <DxSimpleItem
                data-field="device_disposal_ind"
                editor-type="dxCheckBox"
                :editor-options="{
                    onValueChanged: this.onDeviceDisposalIndValueChanged,
                }"
            >
                <DxLabel text="Απόσυρση Εξαρτήματος"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="device_disposal_reason"
                :is-required="true"
            >
                <DxLabel text="Λόγος Απόσυρσης"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="device_disposal_date"
                editor-type="dxDateBox"
                :is-required="true"
                :editor-options="dateBoxEditorOptions"
            >
                <DxLabel text="Ημερομηνία Απόσυρσης"/>
            </DxSimpleItem>
        </DxGroupItem>

        <DxGroupItem caption="Επιπλέον Πληροφορίες">

            <DxSimpleItem
                data-field="description"
                editor-type="dxTextArea"
                :editor-options="{placeholder: 'Προσθήκη σχόλιου...', height: 75, rows: 10}"
            >
                <DxLabel :visible="false" text="Επιπλέον Πληροφορίες"/>
            </DxSimpleItem>
            
        </DxGroupItem>


      </DxForm>

      <div id="buttons">
          <DxToolbar>
              <DxItem 
                  location="after"
                  widget="dxButton"
                  @click="onClickSave"
                  :options="{
                      icon: 'save',
                      text: 'Αποθηκευση'
                  }"
              >
              </DxItem>
              <DxItem 
                  location="after" 
                  widget="dxButton"
                  @click="onClickClose"
                  :options="{
                      icon: 'close',
                      text: 'Κλεισιμο'
                  }"
                  >
              </DxItem>
          </DxToolbar>
    </div>
    </div>
</div>
</template>

<script>

  //Form
  const URL_SERVICE = OParameters.Host.URI + 'sims_hw_pc_parts_devices';
  
  const URL_SERVICE_LOOKUP_sims_hw_pc_parts_categories = OParameters.Host.URI + 'sims_hw_pc_parts_categories';
  const URL_SERVICE_LOOKUP_sims_hw_pc_parts_types = OParameters.Host.URI + 'sims_hw_pc_parts_types';
  const URL_SERVICE_LOOKUP_sims_hw_pc_parts = OParameters.Host.URI + 'sims_hw_pc_parts';
  
  const URL_SERVICE_LOOKUP_sims_hw_connection_types = OParameters.Host.URI + 'sims_hw_connection_types';
  const URL_SERVICE_LOOKUP_sims_employment_staff = OParameters.Host.URI + 'sims_employment_staff_VIEW';

  const URL_SERVICE_LOOKUP_sims_hw_device_codes_VIEW = OParameters.Host.URI + 'sims_hw_device_codes_VIEW';
  
  //SEQUENCE
  const URL_SERVICE_seq_hw_nextval_VIEW = OParameters.Host.URI + 'seq_hw_nextval_VIEW';

  import CustomStore from 'devextreme/data/custom_store';

  import { reactive, onMounted, computed} from 'vue';  

  import 'whatwg-fetch';

  import OParameters from '../sims-globals';

  import {
      DxForm, 
      DxLabel,
      DxSimpleItem, 
      DxGroupItem,
      DxColCountByScreen,
  } from 'devextreme-vue/form'; 

  import {
        DxRequiredRule,
        DxAsyncRule,
  } from 'devextreme-vue/validator';

  import {DxItem} from 'devextreme-vue/tab-panel';

  import DxToolbar from 'devextreme-vue/toolbar';

  export default {
      name: 'HwPcPartsDevicesFormPage',
      components: {
          DxForm,
          DxLabel,
          DxSimpleItem,
          DxToolbar,
          DxItem,
          DxColCountByScreen,
          DxGroupItem,
          DxRequiredRule,
          DxAsyncRule,
          
      },
    data() {
        return {
            helpText: "",
            title: "Διαχείριση Εξαρτήματος Υπολογιστή",
            calendarOptions: { value: new Date() },
            textAreaOptions: { placeholder: 'Προσθήκη σχολίου..'},
            pageSizes: [5, 10, 20],
            //dataGridRefKey: DATAGRIDREFKEY,
            dateBoxEditorOptions: {
                //pickerType: 'rollers',
                type: 'date',
                displayFormat: 'dd/MM/yyyy',
                dateSerializationFormat: 'yyyy-MM-dd',
                invalidDateMessage: 'Λάθος Ημερομηνία',
                showClearButton: true,
                min: new Date(),
                max: new Date(2030, 11, 31)
            }
        }
    },
    props: {
        hw_pc_parts_device_id: {
            type: Number,
            required: true
        }
    },
    watch: {
        hw_pc_parts_device_id(newValue, oldValue) {
            this.$emit('hw_pc_parts_device_id', newValue, oldValue);
            this.loadFormData(newValue);
        }
    },
    setup(props) {
        const gridWidth = computed(() => '100%'); // set the width to 100%
        const maxWidth = computed(() => '800px'); // set the max-width to 800px
        const state = reactive({
          formData: {},


          LookupCustomDS_sims_hw_pc_parts_categories:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE_LOOKUP_sims_hw_pc_parts_categories + '?order=name'),
          }),

          LookupCustomDS_sims_hw_pc_parts_types: new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE_LOOKUP_sims_hw_pc_parts_types + '?order=name'),
          }),

          LookupCustomDS_sims_hw_pc_parts: new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE_LOOKUP_sims_hw_pc_parts + '?order=name'),
              byKey: (key) => sendRequestByKey(URL_SERVICE_LOOKUP_sims_hw_pc_parts + "/" + encodeURIComponent(key)),
          }),          

          LookupCustomDS_sims_hw_connection_types: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_hw_connection_types + '?order=name'),
          }),

          LookupCustomDS_sims_employment_staff:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE_LOOKUP_sims_employment_staff + '?order=name'),
          }),

          CustomStore_seq_hw_nextval_VIEW:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                cacheRawData: 'false',
                load: () => sendRequest(URL_SERVICE_seq_hw_nextval_VIEW),
          }),

          LookupValidateCustomDS_sims_hw_device_codes_VIEW:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE_LOOKUP_sims_hw_device_codes_VIEW + '?order=name'),
              byKey: (key) => sendRequestGetRow(URL_SERVICE_LOOKUP_sims_hw_device_codes_VIEW + "/" + encodeURIComponent(key)),
          }),

          CustomStoreMain: new CustomStore({
              key: 'id',
              //loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE),
              insert: (values) => sendRequest(URL_SERVICE, 'POST', values),
              update: (key, values) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
              remove: (key) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
              byKey: (key) => sendRequestByKey(URL_SERVICE + "/" + encodeURIComponent(key)),
          })
      });

      // 
      //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
      //
      const sendRequest = (url, method = 'GET', values = {}) => {
            //console.log('sendRequest START LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then(response => response.json())
                .then(json => {
                    return json.records;
                })
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
      };

      const sendRequestByKey = (url, method = 'GET') => {
          //console.log(' sendRequestByKey LOG: ' + url + ' METHOD: ' + method);
          return fetch(url, {
                    method,
                    headers: {
                        'Accept': 'application/json',
                    },
                    credentials: 'include',
                })
                .then(response => response.json())
                .then(json => {
                    // convert string to array in order to prepare the field for dxTagBox

                    return json;
                })
      };

      const sendRequestGetRow = (url, method = 'GET') => {
      //console.log(' sendRequestByKey LOG: ' + url + ' METHOD: ' + method);
      return fetch(url, {
        method, headers: {'Accept': 'application/json',}, credentials: 'include',
      })
        .then(response => response.json())
        .then(json => json)
      };

      const saveData = async () => {
            await state.CustomStoreMain.update(state.formData.id, state.formData);
      };

      const insertData = async () => {
            await state.CustomStoreMain.insert(state.formData);
      };

      const clearForm = () => {
          state.formData = {};
      };

      var isGridValidated = true;
      var initialCodeValue
      var isInsertMode = false
  
      onMounted(async () => {
          if (props.hw_pc_parts_device_id != null) state.formData = await state.CustomStoreMain.byKey(props.hw_pc_parts_device_id);
      });

      return {
          saveData,
          insertData,
          clearForm,
          gridWidth,
          maxWidth,
          state,
          isGridValidated,
          initialCodeValue,
          isInsertMode
      };
    },
    methods: {
        
        async loadFormData(id) {
            //
            //  nf 28/6/2023 
            //  init disposal value in order to invoke onchange event which disables dispose field
            //
            this.$refs.formMaster.instance.getEditor('device_disposal_ind').option('value', true)

            if (id != null) {
                this.isInsertMode = false
                this.updateLastCode(this.isInsertMode)
                
                this.state.formData = await this.state.CustomStoreMain.byKey(id)
            } else {
                this.getNextVal().then(value => {
                    this.isInsertMode = true
                    this.updateLastCode(this.isInsertMode)
                    this.state.formData = {entry_date: new Date().toISOString().split('T')[0]}
                    this.state.formData.id = value
                });
            }

            this.initialCodeValue = this.state.formData.code
        },
              
        async onPcPartsChange (e) {
            
            if (e.event && (e.event.type === 'dxclick' || e.event.type === 'keydown')) {
                // Handle the value change
                const modelrow = await this.state.LookupCustomDS_sims_hw_pc_parts.byKey(e.value)

                this.$refs.formMaster.instance.updateData('hw_pc_parts_type_id', modelrow.hw_pc_parts_type_id)
                this.$refs.formMaster.instance.updateData('hw_pc_parts_category_id', modelrow.hw_pc_parts_category_id)
                //this.$refs.formMaster.instance.repaint()
                /*
                */
            }
            
        },

        onDeviceDisposalIndValueChanged (e) {
            this.$refs.formMaster.instance.getEditor('device_disposal_reason').option('disabled', (!e.value == true))
            this.$refs.formMaster.instance.getEditor('device_disposal_date').option('disabled', (!e.value == true))

            if (e.value == true) {
                this.$refs.formMaster.instance.updateData('assigned_employment_staff_id', null)
                this.$refs.formMaster.instance.updateData('assigned_date', null)
                this.$refs.formMaster.instance.updateData('network_ip_id', null)
            }
        },

        async validateIsUniqueCode(params) {
            this.state.LookupValidateCustomDS_sims_hw_device_codes_VIEW.clearRawDataCache()
            const row = await this.state.LookupValidateCustomDS_sims_hw_device_codes_VIEW.byKey(params.value)
            return new Promise((resolve) => {
                if (this.initialCodeValue != params.value) {
                    resolve(row.id === undefined);
                } else {
                    resolve(true)
                }
            });
        },

        
        async updateLastCode(isInsertMode) {
            if (isInsertMode) {
                let lc = await OParameters.methods.getLastInsertedCode()
                this.helpText = "Τελευταίος καταχωρημένος Κωδικός Αναγνώρισης Συσκευής: " + lc[0].code
            } else {
                this.helpText = ""
            }
        },

        async getNextVal() {
            //console.log('getNextVal')
            this.state.CustomStore_seq_hw_nextval_VIEW.clearRawDataCache()
            let row = await this.state.CustomStore_seq_hw_nextval_VIEW.load()
            return row[0].nextval
        },

        onClickSave: async function() {

            const formValidator = this.dataForm.validate();
            this.isGridValidated = true
            let formSaved = false

            if (formValidator.isValid) {
                if (!this.isInsertMode) {
                    await this.saveData()
                    formSaved = true
                } else {
                    await this.insertData()
                    formSaved = true
                }
            }

            if (this.isGridValidated && formSaved) this.$emit('closePopupEvent', true)

        },
        onClickClose: function() {
            this.$emit('closePopupEvent', true);
        },        
        onInitialized(e) {
            e.component.option('selectedItemKeys', [])
        },
        onRowValidating(e) {
            this.isGridValidated = this.isGridValidated && e.isValid;
        },
    },
    computed: {
        dataForm: function() {
            return this.$refs.formMaster.instance;
        },
    }
};
</script>

<style lang="scss">

</style>

<style scoped>
    #form {
    margin-top: 25px;
    }
    .caption {
        font-size: 18px;
        font-weight: 500;
    }
    .option {
        margin-top: 10px;
    }
    .dx-fieldset-header {
        font-size: 16px;
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 10px;
        padding-top: 10px;
        margin-top: 4px;
    }
</style>
