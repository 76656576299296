<template>
  <div>
    <h3 class="content-block openware-form-title">{{ title }}</h3>
    <DxDataGrid
        :key-expr="id"            
        :data-source="CustomStoreMain"
        :columns="columns"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :focused-row-enabled="true"
        :word-wrap-enabled="true"
        @exporting="onExporting"
        @init-new-row="initNewRow"
        ref="dataGridRef"
    >

        <DxPaging 
            :enabled="true"
            :page-size="5"
        />

        <DxFilterRow :visible="true"/>
        <DxHeaderFilter :visible="true"/>
        
        <DxPager
            :visible="true"
            :allowed-page-sizes="pageSizes"
            :display-mode="displayMode"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
        />

        <DxGroupPanel :visible="false"/>

        <DxSorting mode="multiple"/>

        <DxEditing
            :allow-updating="true"
            :allow-adding="true"
            :allow-deleting="true"
            :texts= "{
                    addRow: 'Προσθήκη',
                }"
            
            mode="popup"
        >
            <DxTexts
                save-row-changes="Αποθηκευση"
                cancel-row-changes="Κλεισιμο"
                add-row="Προσθήκη"
                delete-row="Διαγραφή"
                edit-row="Επεξεργασία"
            />
          <DxPopup
            :show-title="true" 
            title="Στοιχεία Μοντέλоυ Σαρωτή"
                        
            :width="'90%'" 
            :height="'90%'"
            :show-close-button="true"
            > 
                <DxToolbarItem
                    widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    :options="{ 
                        icon: 'save',
                        text: 'Αποθήκευση', 
                        type: 'success',
                        onClick: () => {
                            this.$refs['dataGridRef'].instance.saveEditData();
                        }
                    }"
                />
                <DxToolbarItem 
                    widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    :options="{ 
                        icon: 'close',
                        text: 'Κλείσιμο',
                        type: 'danger',
                        onClick: () => {
                            this.$refs['dataGridRef'].instance.cancelEditData();
                        }
                    }"
                />
        </DxPopup>

            <DxForm>
                <DxItem data-field="name" :col-span="2"/>

                <DxItem data-field="hw_scanner_type_id"/>
                <DxItem data-field="hw_scanner_scan_resolution_id"/>
                <DxItem data-field="hw_scanner_color_depth_id"/>
                <DxItem data-field="maximum_scanning_size"/>
                
                <DxItem 
                    itemType="group" 
                    :col-count="3" 
                    :col-span="2" 
                    caption="Δυνατότητες Σαρωτή"
                >
                    <DxItem data-field="doubleside_scanning_ind"/>
                    <DxItem data-field="adf_ind"/>
                    <DxItem data-field="ocr_ind"/>
                </DxItem>

                <DxItem data-field="json_2_connection_types" :col-span="2"/>

                <DxItem
                    data-field="description"
                    :editor-options="{ height: 120 }"
                    :col-span="2"
                    editor-type="dxTextArea"
                />

            </DxForm>

        </DxEditing>
        
        <DxColumn
            data-field="name"
            caption="Όνομα"
            sort-order="asc"
        >
            <DxRequiredRule/>
        </DxColumn>

        <DxColumn
            data-field="hw_scanner_type_id"
            caption="Τύπος σαρωτή"
            :width="150"
            :hiding-priority="150"
        >
            <DxLookup
                :data-source="LookupCustomDS_sims_hw_scanner_types"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>

        <DxColumn
            data-field="hw_scanner_scan_resolution_id"
            caption="Ανάλυση Σαρωτή"
            :width="150"
            :hiding-priority="140"
        >
            <DxLookup
                :data-source="LookupCustomDS_sims_hw_scanner_scan_resolutions"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>

        <DxColumn
            data-field="hw_scanner_color_depth_id"
            caption="Βάθος Χρώματος"
            :width="150"
            :hiding-priority="130"
        >
            <DxLookup
                :data-source="LookupCustomDS_sims_hw_scanner_color_depths"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>

        <DxColumn
            data-field="maximum_scanning_size"
            caption="Μέγιστο Μέγεθος Σάρωσης (Διάσταση σελίδας)"
            :width="150"
            :hiding-priority="120"
        >
        </DxColumn>

        <DxColumn
            data-field="doubleside_scanning_ind"
            caption="Σάρωση Διπλής όψης"
            :width="150"
            data-type="boolean"
            :hiding-priority="110"
        >
        </DxColumn>

        <DxColumn
            data-field="adf_ind"
            caption="ADF"
            :width="150"
            data-type="boolean"
            :hiding-priority="100"
        >
        </DxColumn>

        <DxColumn
            data-field="ocr_ind"
            caption="OCR"
            :width="150"
            data-type="boolean"
            :hiding-priority="90"
        >
        </DxColumn>

        <DxColumn
          :width="200"
          :allow-sorting="false"
          :calculate-filter-expression="calculateFilterExpression"
          data-field="json_2_connection_types"
          caption="Δυνατότητες Σύνδεσης"
          :cell-template="cellTemplate"
          edit-cell-template="tagBoxEditor"
          :hiding-priority="80"
        >   
            <DxLookup
                :data-source="LookupCustomDS_sims_hw_connection_types"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>

        <DxColumn
            data-field="description"
            caption="Επιπλέον Χαρακτηριστικά"
            :hiding-priority="70"
        />

        <DxExport
            :enabled="true"
            :formats="['pdf', 'xlsx']"
            :texts= "{
                    exportAll: 'Εξαγωγή σε {0}',
                }"
        />

        <template #tagBoxEditor="{ data: cellInfo }">
            <ConnectionsTagBoxComponent
                :value="cellInfo.value"
                :on-value-changed="(value) => onValueChanged(value, cellInfo)"
                :data-source="LookupCustomDS_sims_hw_connection_types"
                :data-grid-component="cellInfo.component"
            />
        </template>

    </DxDataGrid>
  </div>
</template>

<script>
import DxDataGrid, {
    DxColumn,
    DxEditing, 
    DxPaging,
    DxPager,
    DxGroupPanel,
    DxSorting,
    DxFilterRow,
    DxHeaderFilter,
    DxExport,
    DxRequiredRule,
    //DxEmailRule,
    DxLookup,
    DxPopup,
    DxForm,
    DxItem,
    DxTexts
} from 'devextreme-vue/data-grid';
import { DxToolbarItem } from 'devextreme-vue/popup'

import 'whatwg-fetch';
import CustomStore from 'devextreme/data/custom_store';

import ConnectionsTagBoxComponent from './sims_ConnectionsTagBoxComponent.vue';

import OParameters from '../sims-globals';

const URL_SERVICE = OParameters.Host.URI + 'sims_hw_scanner_models';
//const URL_SERVICE_LOOKUP_sims_hw_graphic_card_types = OParameters.Host.URI + 'sims_hw_graphic_card_types';
const URL_SERVICE_LOOKUP_sims_hw_scanner_types = OParameters.Host.URI + 'sims_hw_scanner_types';
const URL_SERVICE_LOOKUP_sims_hw_scanner_scan_resolutions = OParameters.Host.URI + 'sims_hw_scanner_scan_resolutions';
const URL_SERVICE_LOOKUP_sims_hw_scanner_color_depths = OParameters.Host.URI + 'sims_hw_scanner_color_depths';
const URL_SERVICE_LOOKUP_sims_hw_connection_types = OParameters.Host.URI + 'sims_hw_connection_types';

export default {
        components: {
            DxDataGrid,
            DxColumn,
            DxEditing, 
            DxPaging,
            DxPager,
            DxGroupPanel,
            DxSorting,
            DxFilterRow, 
            DxHeaderFilter,
            DxExport,
            DxRequiredRule,
            //DxEmailRule,
            DxLookup,
            DxPopup,
            DxForm,
            DxItem,
            DxTexts,
            ConnectionsTagBoxComponent,
            DxToolbarItem
    },
    data() {
        return {
            CustomStoreMain: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE),
                insert: (values) => this.sendRequest(URL_SERVICE, 'POST', values),
                update: (key, values) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
            }),

            LookupCustomDS_sims_hw_scanner_types: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_scanner_types+ '?order=name'),
            }),

            LookupCustomDS_sims_hw_scanner_scan_resolutions: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_scanner_scan_resolutions+ '?order=name'),
            }),

            LookupCustomDS_sims_hw_scanner_color_depths:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_scanner_color_depths+ '?order=name'),
            }),

            LookupCustomDS_sims_hw_connection_types: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_connection_types+ '?order=name'),
            }),

            title: 'Μοντέλα Σαρωτή',
            pageSizes: [5, 10, 20],
        };
    },
    methods: {
        // 
        //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
        //
        sendRequest(url, method = 'GET', values = {}) {
            // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then((response) => response.json()
                .then((json) => {
                    if (response.ok) return json.records;
                    throw json.Message;
                }));
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
        },
        cellTemplate(container, options) {
           /*
            if (options !== undefined) {
                console.log('Grid cell template');
                console.log(options);
            }
            */
           const noBreakSpace = '\u00A0';

            var text;
            try {
                text = (JSON.parse(options.value) || []).map((element) => options.column.lookup.calculateCellValue(element)).join(', ');
            } catch {
                text = '[]';//(options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(', ');
            }
            //const text = (options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(', ');
            container.textContent = (text || noBreakSpace);
            container.title = text;
        },
        onValueChanged(value, cellInfo) {
            /*
            console.log('Grid value changed');
            console.log(value);
            */

            cellInfo.setValue(JSON.stringify(value));
            //cellInfo.setValue(value);
            cellInfo.component.updateDimensions();
        },
        initNewRow(row) {
          row.data.json_2_connection_types = '[]';
          row.data.doubleside_scanning_ind = 0;
          row.data.adf_ind = 0;
          row.data.ocr_ind = 0;
/*
          hw_scanner_type_id
          hw_scanner_scan_resolution_id
          hw_scanner_color_depth_id
          maximum_scanning_size
*/
        },
        onExporting (e){
            OParameters.methods.exportDataGridToPdfExcel(e, this.title, [40, 40, 20])
        },

    },
};
</script>

<style lang="scss">
</style>
