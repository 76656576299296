<template>
    <div id="container">
        <h3 class="content-block openware-form-title">{{ title }}</h3>
        <DxPopover
            :shading="true"
            width="70%"
            target="#help-button"
            position="top"
            shading-color="rgba(0, 0, 0, 0.5)"
            v-model:visible="isPopoverVisible"
            :show-title="true"
            title="Βοήθεια: Φόρμα «Add/Remove Εφαρμογής σε Υπολογιστή»"
            :toolbar-items="[{
                                widget: 'dxButton',
                                location: 'after',
                                options: {
                                    icon: 'close',
                                    onClick: () => {
                                        this.isPopoverVisible = false;
                                    }
                                }
                            }
                            ]"
         
        >
            <DxScrollView>
                <div class="application-help">
                    <div class="application-help-intro">
                        Στην Ενότητα Εφαρμογές και άδειες Χρήσης / Add-remove, ο χρήστης προσθέτει ή αφαιρεί άδεια χρήσης εφαρμογής στις συσκευές, η εφαρμογή ενημερώνεται αυτόματα και μας δίνει την δυνατότητα, ανά πάσα στιγμή να αντλούμε πληροφορίες σχετικά με την διαθεσιμότητα αδειών χρήσης της κάθε εφαρμογής ξεχωριστά
                    </div>
                    <br />
                    <div class="application-help-content">
                        • Ο χρήστης δηλώνει ποια εφαρμογή και σε ποια συσκευή εγκαταστάθηκε. Στην λίστα με τις εφαρμογές εμφανίζονται μόνο αυτές για τις οποίες υπάρχει διαθέσιμη-ελεύθερη άδεια χρήσης. {Με το που αποθηκεύσουμε μία εγγραφή, η εφαρμογή α) ενημερώνει σχετικά τα αποθέματα της εφαρμογής στην «Προβολή Εφαρμογών» και β) ενημερώνει την καρτέλα του αντίστοιχου εξοπλισμού για την εγκατάσταση και ύπαρξη της εφαρμογής. Αν μεταγενέστερα διαγράψουμε την συγκεκριμένη εγγραφή οι ανωτέρω λειτουργίες-ενημερώσεις αναιρούνται}.<br />
                        • Η εφαρμογή ενημερώνει στο Dashboard για τις εφαρμογές που δεν υπάρχει διαθεσιμότητα.<br />
                    </div>
                </div>
            </DxScrollView>
        </DxPopover>

        <DxPopup
            :drag-enabled="false"
            :hide-on-outside-click="true"
            :show-close-button="true"
            :show-title="true"
  
            :columns="columns"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :show-borders="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :row-alternation-enabled="true"
            :focused-row-enabled="true"
            title="Add/Remove Εφαρμογής σε Υπολογιστή"
                :width="'90%'" 
                :height="'90%'"
                :toolbar-items="[
                            {
                                widget: 'dxButton',
                                location: 'after',
                                options: {
                                    icon: 'help', 
                                    onClick: () => {
                                        this.isPopoverVisible = !this.isPopoverVisible;
                                    },
                                    elementAttr: {
                                        id: 'help-button'
                                    }
                                }
                            }
                        ]"
            v-model:visible="isPopupVisible"
        >
            <DxScrollView height="100%" width="100%">
                <SwSoftware2HwPcFormPage
                    ref="swSoftware2HwPcFormPage"
                    :sw_software_2_hw_pc_id="selectedSwSoftware2HwPcID" 
                    @closePopupEvent="onClosePopupEmit">
                </SwSoftware2HwPcFormPage>
            </DxScrollView>
  
        </DxPopup>
            
        <DxDataGrid
            :key-expr="id"
            :data-source="CustomStoreMain"
            :columns="columns"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :show-borders="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :row-alternation-enabled="true"
            :focused-row-enabled="true"
            :selection-mode="'single'"
            :ref="dataGridRefKey"          
            :word-wrap-enabled="true"
  
            @exporting="onExporting"
            @selectionChanged="onSelectionChanged"
            @init-new-row="onCustomInitNewRow"
        >
            <DxSelection mode="single" />
            <DxEditing
                :allow-deleting="true"
                mode="row"
            />
              <DxToolbar>
                  <DxItem location="after" 
                    widget="dxButton" 
                    :options="{
                        icon: 'add',
                        text: '',
                        hint: 'Προσθήκη',
                        onClick: () => {
                            this.onCustomInitNewRow()
                        }
                    }"
                  />
                  <DxItem name="exportButton" 
                    :options="{
                            hint: 'Εξαγωγή δεδομένων'

                        }"
                  />
              </DxToolbar>
  
            <DxPaging 
                :enabled="true"
                :page-size="5"
            />
  
            <DxFilterRow :visible="true"/>
            <DxHeaderFilter :visible="true" :word-wrap-enabled="true"/>
            
            <DxPager
                :visible="true"
                :allowed-page-sizes="pageSizes"
                :display-mode="displayMode"
                :show-page-size-selector="true"
                :show-info="true"
                :show-navigation-buttons="true"
            />
  
            <DxGroupPanel :visible="false"/>
  
            <DxSorting mode="multiple"/>
  
            <DxColumn
                data-field="sw_software_id"
                caption="Εφαρμογή"
                :hiding-priority="110"
                sort-order="asc"
            >
                <DxLookup
                    :data-source="LookupCustomDS_sims_sw_software_VIEW" 
                    value-expr="id"
                    display-expr="application_name_serial_no"
                />
            </DxColumn>
  
            <DxColumn
                data-field="hw_pc_id"
                caption="Κωδικός Υπολογιστή"
                :hiding-priority="110"
            >
                <DxLookup
                    :data-source="LookupCustomDS_sims_hw_pc_VIEW" 
                    value-expr="id"
                    display-expr="code_model_name"
                />
            </DxColumn>

            <DxExport
                :enabled="true"
                :formats="['pdf', 'xlsx']"
                :texts= "{
                            exportAll: 'Εξαγωγή σε {0}',
                        }"
            />

        </DxDataGrid>
    </div>
  </template>
  
  <script>
  
  const DATAGRIDREFKEY = 'my-data-grid';
  
  const URL_SERVICE = OParameters.Host.URI + 'sims_sw_software_2_hw_pc';
  const URL_SERVICE_LOOKUP_sims_sw_software_VIEW = OParameters.Host.URI + 'sims_sw_software_VIEW';
  const URL_SERVICE_LOOKUP_sims_hw_pc_VIEW = OParameters.Host.URI + 'sims_hw_pc_VIEW';
  
  import DxDataGrid, {
              DxColumn,
              //DxButton,
              DxEditing, 
              DxPaging,
              DxPager,
              DxGroupPanel,
              DxSorting,
              DxFilterRow,
              DxHeaderFilter,
              DxExport,
              DxSelection,
              DxLookup,
        DxToolbar,
        DxItem
  } from 'devextreme-vue/data-grid';
  
  import DxScrollView from 'devextreme-vue/scroll-view';
  import DxPopup from 'devextreme-vue/popup';
  import { DxPopover } from 'devextreme-vue/popover';
  
  import CustomStore from 'devextreme/data/custom_store';
  import 'whatwg-fetch';
  
  import SwSoftware2HwPcFormPage from './sims-sw-software-2-hw-pc-form-page.vue';
  
  import OParameters from '../sims-globals';
  
  export default {
        components: {
            DxDataGrid,
            DxColumn,
            //DxButton,
            DxEditing, 
            DxPaging,
            DxPager,
            DxGroupPanel,
            DxSorting,
            DxFilterRow, 
            DxHeaderFilter,
            DxExport,
            DxPopup,
            DxLookup,
            SwSoftware2HwPcFormPage,
            DxScrollView,
            DxSelection,
            DxToolbar, 
            DxItem,
            DxPopover
  
    },
    data() {
        return {
            IPPattern: "^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$",
            CustomStoreMain: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE),
                insert: (values) => this.sendRequest(URL_SERVICE, 'POST', values),
                update: (key, values) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
            }),
            
            LookupCustomDS_sims_sw_software_VIEW: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_sw_software_VIEW+ '?order=name'),
            }),
            
            LookupCustomDS_sims_hw_pc_VIEW:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_pc_VIEW+ '?order=name'),
            }),
            
            isPopupVisible: false,
            isPopoverVisible: false,
            title: 'Add/Remove Εφαρμογής σε Υπολογιστή',
            pageSizes: [5, 10, 20],
            dataGridRefKey: DATAGRIDREFKEY
        };
    }, 
    
    methods: {
        // 
        //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
        //
        sendRequest(url, method = 'GET', values = {}) {
            //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then((response) => response.json()
                .then((json) => {
                    if (response.ok) return json.records;
                    throw json.Message;
                }));
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
        },
        onSelectionChanged(e) {
              this.selectedSwSoftware2HwPcID = e.currentSelectedRowKeys[0];            
        },
        onClosePopupEmit() {
            this.isPopupVisible = false;
            this.dataGrid.refresh();
        },
        onCustomInitNewRow() {            
              this.selectedSwSoftware2HwPcID = null;
              this.isPopupVisible = true;
        },
        onCustomRowEdit(e) {
            this.selectedSwSoftware2HwPcID = e.row.key
            this.isPopupVisible = true
        },
        onExporting (e){
            OParameters.methods.exportDataGridToPdfExcel(e, this.title, [])
        },
    },
    computed: {
        dataGrid: function() {
            return this.$refs[DATAGRIDREFKEY].instance;
        }
    },
  };
  </script>
  
  <style lang="scss">
  </style>
  