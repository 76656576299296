import 'whatwg-fetch';

//import CustomStore from 'devextreme/data/custom_store';
import OParameters from './sims-globals';

const URL_SERVICE = OParameters.Host.APIRoot;
//const _user = {}
/*
const defaultUser = //OParameters.loggedUser 
{  
//    email: 'nikos@openware.gr',
//    avatarUrl: 'https://127.0.0.1:8080/openware/nikos.png'
  email: 'g93020@gmail.com',
  avatarUrl: 'https://127.0.0.1:8080/openware/user_profile.png'

};
*/
/*
function handleErrors(response) {
  if (!response.ok) {
      throw Error(response.statusText + ' ' + response.status);
  }
  return response;
}
*/

export default {   
  //_user: defaultUser,

  loggedIn() {
      console.log(' ... loggedIn ... ')
      return !!this._user;
  },

  async logIn(username, password) {
    try {
        // Send request
        // POST request using fetch with async/await

      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify( { username: username, password: password } ),
          credentials: 'include',
      };

      await fetch(URL_SERVICE + "login", requestOptions)
          .then(async response => {
              const data = await response.json();
              // check for error response
              if (!response.ok) {
                throw 'There was an error!' + (data && data.message) || response.status;
              }
              this._user = data;
              OParameters.loggedUser = this._user
              
          })
          .catch(error => {
              throw 'There was an error!' + error
          });

      return {
          isOk: true,
          data: this._user
      };
    }
    catch (e) {
        return {
            isOk: false,
            message: "Authentication failed"
        };
    }
  },

  async logOut() {
      console.log('... logout ...')
      
      try {
        this._user = null;
        const response = await fetch(URL_SERVICE + "logout", {method: 'POST', credentials: 'include',});

        // As response body can only be read once, read it here and reuse
        const data = await response.json(); 

        // Check if response status is not ok
        if (!response.ok) {
            throw new Error('There was an error! ' + (data && data.message) || response.status);
        }

        // If response status is ok, process the data
        //this._user = data;
        
        return {
            isOk: true,
            data: this._user
        };
    } catch (error) {
        console.error('Error in logOut:', error);
        
        return {
            isOk: false,
            message: "Authentication failed"
        };
    }

  },

  async getUser() {
    try {
      // Send request

      return {
          isOk: true,
          data: this._user
      };
    }
    catch {
        return {
            isOk: false
        };
    }
  },

  async resetPassword(email) {
    try {
        // Send request
        console.log('... resetPassword ...')

        console.log(email);

        return {
            isOk: true
        };
    }
    catch {
        return {
            isOk: false,
            message: "Failed to reset password"
        };
    }
  },

  async changePassword(email, recoveryCode) {
    try {
        // Send request

        console.log('... changePassword ...')
        console.log(email, recoveryCode);

        return {
            isOk: true
        };
    }
    catch {
        return {
            isOk: false,
            message: "Failed to change password"
        }
    }
  },

  async createAccount(email, password) {
    try {
        // Send request
        console.log('... createAccount ...')
        console.log(email, password);

        return {
            isOk: true
        };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to create account"
      };
    }
  },
  
  async getMe() {
    try {
        const response = await fetch(URL_SERVICE + "me", {method: 'GET', credentials: 'include',});

        // As response body can only be read once, read it here and reuse
        const data = await response.json(); 

        // Check if response status is not ok
        if (!response.ok) {
            throw new Error('There was an error! ' + (data && data.message) || response.status);
        }

        // If response status is ok, process the data
        this._user = data;
        OParameters.loggedUser = this._user
        
        return {
            isOk: true,
            data: this._user
        };
    } catch (error) {
        //console.error('Error in getMe:', error);
        
        return {
            isOk: false,
            message: "Authentication failed"
        };
    }
  },

};
