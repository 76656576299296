<template>
  <div id="container">
      <h3 class="content-block openware-form-title">{{ title }}</h3>

      <DxDataGrid
          :key-expr="id"
          :data-source="CustomStoreMain"
          :columns="columns"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          :selection-mode="'single'"
          :ref="dataGridRefKey"
          :word-wrap-enabled="true"

          @exporting="onExporting"
      >
          <DxSelection mode="single" />
            <DxToolbar>
                <DxItem name="exportButton" 
                    :options="{
                            hint: 'Εξαγωγή δεδομένων'

                        }"
                  />
            </DxToolbar>

          <DxPaging 
              :enabled="true"
              :page-size="5"
          />

          <DxFilterRow :visible="true"/>
          <DxHeaderFilter :visible="true" :word-wrap-enabled="true"/>
          
          <DxPager
              :visible="true"
              :allowed-page-sizes="pageSizes"
              :display-mode="displayMode"
              :show-page-size-selector="true"
              :show-info="true"
              :show-navigation-buttons="true"
          />

          <DxGroupPanel :visible="false"/>

          <DxSorting mode="multiple"/>

          <DxColumn
              data-field="device_leasing"
              caption="Είδος"
              :hiding-priority="110"
          >
          </DxColumn>

          <DxColumn
              data-field="leasing_code"
              caption="Κωδικός Μίσθωσης"
              sort-order="asc"
          >
          </DxColumn>

          <DxColumn
              data-field="model_name"
              caption="Μοντέλο Συσκευής"
              :hiding-priority="110"
          >
          </DxColumn>

          <DxColumn
              data-field="building_id"
              caption="Τοποθεσία Εγκατάστασης"
              :hiding-priority="100"
          >
              <DxLookup
                  :data-source="LookupCustomDS_sims_buildings" 
                  value-expr="id"
                  display-expr="name"
              />
          </DxColumn>

          <DxColumn
              data-field="directorate_services_id"
              caption="Υπηρεσία"
              :hiding-priority="90"
          >
              <DxLookup
                  :data-source="LookupCustomDataSourcesims_directorate_services" 
                  value-expr="id"
                  display-expr="name"
              />
          </DxColumn>

          <DxExport
              :enabled="true"
              :formats="['pdf', 'xlsx']"
              :texts= "{
                      exportAll: 'Εξαγωγή σε {0}',
                  }"
          />

      </DxDataGrid>
  </div>
</template>

<script>

const DATAGRIDREFKEY = 'my-data-grid';

const URL_SERVICE = OParameters.Host.URI + 'sims_leasing_hw_VIEW';
const URL_SERVICE_LOOKUP_sims_buildings = OParameters.Host.URI + 'sims_buildings';
const URL_SERVICE_LOOKUP_sims_directorate_services = OParameters.Host.URI + 'sims_directorate_service_departments';

import DxDataGrid, {
  DxColumn,
  DxPaging,
  DxPager,
  DxGroupPanel,
  DxSorting,
  DxFilterRow,
  DxHeaderFilter,
  DxExport,
  DxSelection,
  DxLookup,
  DxToolbar, 
  DxItem
} from 'devextreme-vue/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';

import OParameters from '../sims-globals';

export default {
      components: {
          DxDataGrid,
          DxColumn,
          DxPaging,
          DxPager,
          DxGroupPanel,
          DxSorting,
          DxFilterRow, 
          DxHeaderFilter,
          DxExport,
          DxLookup,
          DxSelection,
          DxToolbar, 
          DxItem,
  },
  data() {
      return {
          IPPattern: "^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$",
          CustomStoreMain: new CustomStore({
              key: 'id',
              //loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE),
              byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
          }),

          LookupCustomDS_sims_buildings:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_buildings+ '?order=name'),
          }),

          LookupCustomDataSourcesims_directorate_services:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_directorate_services+ '?order=name'),
            }),

          isPopupVisible: false,
          isPopoverVisible: false,
          //selectedLeasingHwPrinterDeviceID: -1,
          title: 'Προβολή Ενεργών Συσκευών Μίσθωσης',
          pageSizes: [5, 10, 20],
          dataGridRefKey: DATAGRIDREFKEY,
      };
  }, 
  
  methods: {
        // 
        //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
        //
        sendRequest(url, method = 'GET', values = {}) {
            //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then((response) => response.json()
                .then((json) => {
                    if (response.ok) return json.records;
                    throw json.Message;
                }));
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
        },
        onExporting (e){
            OParameters.methods.exportDataGridToPdfExcel(e, this.title)
        },
  },
  computed: {
      dataGrid: function() {
          return this.$refs[DATAGRIDREFKEY].instance;
      }
  },
};
</script>

<style lang="scss">
</style>
