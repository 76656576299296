<template>
  <header class="header-component">
    <dx-toolbar class="header-toolbar">
      <dx-item
        :visible="menuToggleEnabled"
        location="before"
        css-class="menu-button"
      >
        <template #default>
          <dx-button
            icon="menu"
            styling-mode="text"
            @click="toggleMenuFunc"
          />
        </template>
      </dx-item>

      <dx-item
          v-if="title"
          location="before"
          css-class="header-title dx-toolbar-label"
      >
          <div>
              <div class="logo">
                  <img :src="imageSrc" />
              </div>
              <span></span>
          </div>
      </dx-item>

      <dx-item
        location="after"
        locate-in-menu="auto"
        menu-item-template="menuUserItem"
      >
      <template #default>
          <div>
            <dx-button
              class="user-button authorization"
              :width="210"
              height="100%"
              styling-mode="text"
            >
              <user-panel :email="email" :menu-items="userMenuItems" menu-mode="context" />
            </dx-button>
          </div>
        </template>
      </dx-item>
      
      <template #menuUserItem>
        <user-panel
          :email="email"
          :menu-items="userMenuItems"
          menu-mode="list"
        />
      </template>
    </dx-toolbar>
  </header>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import auth from "../auth";
import { useRouter, useRoute } from 'vue-router';
import { ref } from 'vue';

import UserPanel from "./user-panel";

import OParameters from '../sims-globals';

export default {
  props: {
    menuToggleEnabled: Boolean,
    title: String,
    toggleMenuFunc: Function,
    logOutFunc: Function
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const email = ref("");

    auth.getUser().then((e) => email.value = e.data.email);
    
    const userMenuItems = [
      {
        text: "Προφίλ Χρήστη",
        icon: "user",
        onClick: onProfileClick
      },
      {
        text: "Αλλαγή Κωδικού",
        icon: "key",
        onClick: onPasswordChangeClick
      },
      {
        text: "Αποσύνδεση",
        icon: "runner",
        onClick: onLogoutClick
      }
      ];
      
    async function onLogoutClick() {
      await auth.logOut();
      console.log("onLogoutClick")
      router.push({
        path: "/login-form",
        query: { redirect: route.path }
      });
    }

    function onProfileClick() {
      router.push({
        path: "/sims-system-users-profile-form-page",
        query: { redirect: route.path }
      });
    }

    function onPasswordChangeClick() {
      router.push({
        path: "/sims-system-users-password-form-page",
        query: { redirect: route.path }
      });
    }

    const picture = OParameters.logoMainImageSource; //"openware/logo-main.png";    
    const imageSrc = `${picture}`;

    return {
      email,
      userMenuItems,
      imageSrc
    };
  },
  components: {
    DxButton,
    DxToolbar,
    DxItem,
    UserPanel,
  }
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
@import "../dx-styles.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
  width:35px;
  height: 35px;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button > .dx-button-content {
    padding: 3px;
  }

  .dx-button {
    height:30px
  }
}

.logo {
    float: left;
    height: 36px;
    /*width: 135px;*/
    margin-right: 20px;
    //border: 1px solid rgba(0, 0, 0, 0.1);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    overflow: hidden;

    img {
        height: 43px;
        display: block;
        margin: 0 auto;
    }
}
</style>
