<template>
  <div id="container">
      <h3 class="content-block openware-form-title">{{ title }}</h3>
          
      <DxDataGrid
          :key-expr="id"
          :data-source="CustomStoreMain"
          :columns="columns"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          :selection-mode="'single'"
          :ref="dataGridRefKey"
          :word-wrap-enabled="true"

          @exporting="onExporting"
          @selectionChanged="onSelectionChanged"
          @init-new-row="onCustomInitNewRow"
      >
          <DxSelection mode="single" />
            <DxToolbar>
                <DxItem name="exportButton" 
                    :options="{
                            hint: 'Εξαγωγή δεδομένων'

                        }"
                  />
            </DxToolbar>

          <DxPaging 
              :enabled="true"
              :page-size="5"
          />

          <DxFilterRow :visible="true"/>

          <DxHeaderFilter :visible="true" :word-wrap-enabled="true"/>

          <DxPager
              :visible="true"
              :allowed-page-sizes="pageSizes"
              :display-mode="displayMode"
              :show-page-size-selector="true"
              :show-info="true"
              :show-navigation-buttons="true"
          />

          <DxSorting mode="multiple"/>

          <DxColumn
              data-field="hardware_type"
              caption="Είδος Υλικού"
              :hiding-priority="140"
              sort-order="asc"
          >
          </DxColumn>

          <DxColumn
              data-field="code"
              caption="Κωδικός Αναγνώρισης Συσκευής"
              alignment="left" 
              sort-order="asc"
          >
          </DxColumn>

          <DxColumn
              data-field="model_name"
              caption="Μοντέλο"
          >
          </DxColumn>

          <DxColumn
              data-field="serial_no"
              caption="Σειριακός αριθμός"
              :hiding-priority="120"
          >
          </DxColumn>
        
          <DxColumn
              data-field="assigned_employment_staff_id"
              caption="Χρήση από Υπάλληλο"
              :hiding-priority="100"
          >
            <DxLookup
                :data-source="LookupCustomDS_sims_employment_staff" 
                value-expr="id"
                display-expr="name"
              />
          </DxColumn>

          <DxColumn
              data-field="device_disposal_ind"
              caption="Απόσυρση Συσκευής"
              :hiding-priority="90"
          >
          </DxColumn>


        <DxExport
            :enabled="true"
            :formats="['pdf', 'xlsx']"
            :texts= "{
                    exportAll: 'Εξαγωγή σε {0}',
                }"
        />

      </DxDataGrid>
  </div>
</template>

<script>

const DATAGRIDREFKEY = 'my-data-grid';

const URL_SERVICE = OParameters.Host.URI + 'sims_hw_VIEW';
const URL_SERVICE_LOOKUP_sims_employment_staff = OParameters.Host.URI + 'sims_employment_staff_VIEW';

import DxDataGrid, {
        DxColumn,
        DxPaging,
        DxPager,
        DxSorting,
        DxFilterRow,
        DxHeaderFilter,
        DxExport,
        DxSelection,
        DxLookup,
        DxToolbar, 
        DxItem,
} from 'devextreme-vue/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';

import OParameters from '../sims-globals';

export default {
      components: {
          DxDataGrid,
          DxColumn,
          DxPaging,
          DxPager,
          DxSorting,
          DxFilterRow, 
          DxHeaderFilter,
          DxExport,
          DxLookup,
          DxSelection,
          DxToolbar, 
          DxItem,
  },
  data() {
      return {
          IPPattern: "^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$",
          CustomStoreMain: new CustomStore({
              key: 'id',
              //loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE),
              insert: (values) => this.sendRequest(URL_SERVICE, 'POST', values),
              update: (key, values) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
              remove: (key) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
              byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
          }),

          LookupCustomDS_sims_employment_staff:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_employment_staff+ '?order=name'),
          }),


          isPopupVisible: false,
          isPopoverVisible: false,
          title: 'Προβολή Εξοπλισμού',
          pageSizes: [5, 10, 20],
          dataGridRefKey: DATAGRIDREFKEY
      };
  }, 
  
  methods: {
        // 
        //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
        //
        sendRequest(url, method = 'GET', values = {}) {
            //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then((response) => response.json()
                .then((json) => {
                    if (response.ok) return json.records;
                    throw json.Message;
                }));
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
        },

        onValueChanged(value, cellInfo) {
                /*
                console.log('Grid value changed');
                console.log(value);
                */

                cellInfo.setValue(JSON.stringify(value));
                //cellInfo.setValue(value);
                cellInfo.component.updateDimensions();
        },
        onExporting (e){
            OParameters.methods.exportDataGridToPdfExcel(e, this.title)
        }
  },
  computed: {
      dataGrid: function() {
          return this.$refs[DATAGRIDREFKEY].instance;
      }
  },
};
</script>

<style lang="scss">
</style>
