<template>
  <div>    
    <div class="widget-container">
     
      <DxForm
        id="form"
        :form-data="state.formData"
        label-mode='outside'
        :read-only='false'
        :show-colon-after-label='true'
        label-location='top'
        :min-col-width='300'
        :col-count='2'
        ref="formMaster"
      >
        <DxSimpleItem data-field="name" :is-required="true">
            <DxLabel text="Όνομα"/>
        </DxSimpleItem>

        <DxSimpleItem data-field="device_type">
          <DxLabel text="Μάρκα/Τύπος"/>
        </DxSimpleItem>

        <DxSimpleItem data-field="device_category">
            <DxLabel text="Είδος Συσκευής"/>
        </DxSimpleItem>

        <DxSimpleItem data-field="ip">
            <DxLabel text="IP Address"/>
        </DxSimpleItem>

      </DxForm>

      <DxDataGrid
          :key-expr="id"
          :data-source="state.CustomStoreDetail"
          :columns="columns"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          :width="gridWidth"
          :style="{ 'max-width': maxWidth }"
          ref="gridDetail"
          @exporting="onExporting"
          @rowClick="openForm"
          @init-new-row="onInitNewRow"
          @row-validating="onRowValidating">          

      >
          <DxPaging 
              :enabled="true"
              :page-size="5"
          />
          <DxFilterRow :visible="false"/>
          <DxHeaderFilter :visible="true"/>
          
          <DxPager
              :visible="true"
              :allowed-page-sizes="pageSizes"
              :display-mode="displayMode"
              :show-page-size-selector="true"
              :show-info="true"
              :show-navigation-buttons="true"
          />

          <DxGroupPanel :visible="false"/>
          <DxSorting mode="multiple"/>

          <DxEditing
              :allow-updating="true"
              :allow-adding="true"
              :allow-deleting="true"
              mode="batch"
          />

          <DxColumn data-field="name" caption="Περιγραφή/Αναγνωριστικό Λογαριασμού"/>

          <DxColumn data-field="username" caption="Όνομα Χρήστη">
              <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
          </DxColumn>

          <DxColumn data-field="password" caption="Κωδικός Πρόσβασης" :cell-template="(cellElement) => cellElement.textContent = '••••••••'">
              <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
          </DxColumn>
          
      </DxDataGrid>
      <div id="buttons">
          <DxToolbar>
              <dxItem 
                  location="after"
                  widget="dxButton"
                  @click="onClickSave"
                  :options="{
                      icon: 'save',
                      text: 'Αποθηκευση'
                  }"
              >
              </dxItem>
              <dxItem 
                  location="after" 
                  widget="dxButton"
                  @click="onClickClose"
                  :options="{
                      icon: 'close',
                      text: 'Κλεισιμο'
                  }"
                  >
              </dxItem>
          </DxToolbar>
      </div>
    </div>
</div>
</template>

<script>

  const URL_SERVICE = OParameters.Host.URI + 'sims_credentials_devices';
  const URL_SERVICE_sims_credentials_devices_logins = OParameters.Host.URI + 'sims_credentials_devices_logins';
   
  //SEQUENCE
  const URL_SERVICE_seq_hw_nextval_VIEW = OParameters.Host.URI + 'seq_hw_nextval_VIEW';

  import CustomStore from 'devextreme/data/custom_store';

  import { reactive, onMounted, computed } from 'vue';  
  import 'whatwg-fetch';

  import OParameters from '../sims-globals';

  import {
      DxForm, 
      DxLabel,
      DxSimpleItem, 
      DxRequiredRule,
  } from 'devextreme-vue/form';

  import DxDataGrid, {
      DxColumn,
      DxEditing, 
      DxPaging,
      DxPager,
      DxGroupPanel,
      DxSorting,
      DxFilterRow,
      DxHeaderFilter,    
  } from 'devextreme-vue/data-grid';

  import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';

  export default {
      name: 'CredentialsDevicesFormPage',
      components: {
      DxForm,
      DxLabel,
      DxSimpleItem,
      DxRequiredRule,
      DxDataGrid,
      DxColumn,
      DxEditing,
      DxPaging,
      DxPager,
      DxGroupPanel,
      DxSorting,
      DxFilterRow,
      DxHeaderFilter,
      DxToolbar,
      DxItem
    },
    data() {
        return {
            title: "Διαχείριση Τηλεφωνίας",
            calendarOptions: { value: new Date() },
            textAreaOptions: { placeholder: 'Προσθήκη σχολίου..'},
            pageSizes: [5, 10, 20],
            //dataGridRefKey: DATAGRIDREFKEY,
            dateBoxEditorOptions: {
              //pickerType: 'rollers',
              type: 'date',
              displayFormat: 'dd/MM/yyyy',
              dateSerializationFormat: 'yyyy-MM-dd',
              invalidDateMessage: 'Λάθος Ημερομηνία',
              showClearButton: true,
              min: new Date(),
              max: new Date(2030, 11, 31)
            },            
        }
    },
    props: {
      credentials_device_id: {
          type: Number,
          required: true
      }
    },
    watch: {
      credentials_device_id(newValue, oldValue) {          
          this.$emit('credentials_device_id-value-changed', newValue, oldValue);
          this.loadFormData(newValue);
      }
    },
    setup(props) {
      const gridWidth = computed(() => '100%'); // set the width to 100%
      const maxWidth = computed(() => '800px'); // set the max-width to 800px
      const state = reactive({
          formData: {},
          
          CustomStoreDetail: new CustomStore({
              key: 'id',
              //loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE_sims_credentials_devices_logins + '?filter=credentials_device_id,eq,' + props.credentials_device_id),
              insert: (values) => sendRequest(URL_SERVICE_sims_credentials_devices_logins, 'POST', values),
              update: (key, values) => sendRequest(URL_SERVICE_sims_credentials_devices_logins + '/' + encodeURIComponent(key), 'PUT', values),
              remove: (key) => sendRequest(URL_SERVICE_sims_credentials_devices_logins + '/' + encodeURIComponent(key), 'DELETE'),
              byKey: (key) => sendRequest(URL_SERVICE_sims_credentials_devices_logins + "/" + encodeURIComponent(key)),
          }),

          CustomStore_seq_hw_nextval_VIEW:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              cacheRawData: 'false',
              load: () => sendRequest(URL_SERVICE_seq_hw_nextval_VIEW),
          }),

          CustomStoreMain: new CustomStore({
              key: 'id',
              //loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE),
              insert: (values) => sendRequest(URL_SERVICE, 'POST', values),
              update: (key, values) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
              remove: (key) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
              byKey: (key) => sendRequestByKey(URL_SERVICE + "/" + encodeURIComponent(key)),
          }),
      });

      // 
      //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
      //
      const sendRequest = (url, method = 'GET', values = {}) => {
          //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
          if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then(response => response.json())
                .then(json => {
                  return json.records;
                })
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
      };

      const sendRequestByKey = (url, method = 'GET') => {
          //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
          return fetch(url, {
                    method,
                    headers: {
                        'Accept': 'application/json',
                    },
                    credentials: 'include',
                })
                .then(response => response.json())
      };

      const saveData = async () => {
          // console.log('Save data');
          await state.CustomStoreMain.update(state.formData.id, state.formData);
      };

      const insertData = async () => {
          // console.log('Insert data');
          await state.CustomStoreMain.insert(state.formData);
      };

      const clearForm = () => {
          state.formData = {};
      };

      var isGridValidated = true
      var isInsertMode = false
  
      onMounted(async () => {
          if (props.credentials_device_id != null) state.formData = await state.CustomStoreMain.byKey(props.credentials_device_id);
      });

      return {
          saveData,
          insertData,
          clearForm,
          gridWidth,
          maxWidth,
          state,
          isGridValidated,
          isInsertMode
      };
    },
    methods: {
        async loadFormData(id) {

            if (id != null) {
                this.isInsertMode = false
                this.state.formData = await this.state.CustomStoreMain.byKey(id)
                
                // cascade ips support
                /*
                const cascaderows = await this.state.LookupCascadeCustomDS_sims_network_ips_VIEW.load()
                if (cascaderows) {
                    this.$refs.formMaster.instance.getEditor('network_ip_id').option('dataSource', cascaderows)
                }
                */

            } else {
                this.getNextVal().then(value => {
                    this.isInsertMode = true
                    this.state.formData = {entry_date: new Date().toISOString().split('T')[0]}
                    this.state.formData.id = value
                });
            }

            await this.$refs.gridDetail.instance.refresh();

            /*
            if (id != undefined) {
                this.state.formData = await this.state.CustomStoreMain.byKey(id);
                await this.state.CustomStoreDetail.load();
            } else {
                this.state.formData = {};
            }
            this.dataGrid.refresh();
            */

        },

        async getNextVal() {
            //console.log('getNextVal')
            this.state.CustomStore_seq_hw_nextval_VIEW.clearRawDataCache()
            let row = await this.state.CustomStore_seq_hw_nextval_VIEW.load()
            return row[0].nextval
        },

        onClickSave: async function() {
            const formValidator = this.dataForm.validate();
            this.isGridValidated = true
            let formSaved = false

            if (formValidator.isValid) {
                if (!this.isInsertMode) {
                    await this.saveData()
                    formSaved = true
                } else {
                    await this.insertData()
                    formSaved = true
                }
            }

            await this.$refs.gridDetail.instance.saveEditData()

            if (this.isGridValidated && formSaved) this.$emit('closePopupEvent', true)
        },

        onClickClose: function() {
            this.$emit('closePopupEvent', true);
        },
        onInitNewRow(row) {
            row.data.credentials_device_id = this.state.formData.id;
        },        
        onRowValidating(e) {
          this.isGridValidated = this.isGridValidated && e.isValid;
        }
    },
    computed: {
        dataGrid: function() {
            return this.$refs.gridDetail.instance;
        },
        dataForm: function() {
            return this.$refs.formMaster.instance;
        },
    }
};
</script>

<style lang="scss">

</style>

<style scoped>

#form {
   margin-top: 25px;
}
.caption {
    font-size: 18px;
    font-weight: 500;
}
.option {
    margin-top: 10px;
}
</style>
