<template>
  <div>    
    <div class="widget-container">
   
      <DxForm
            :form-data="state.formData"
            label-mode='outside'
            :read-only='false'
            :show-colon-after-label='true'
            label-location='top'
            :min-col-width='300'
            :col-count='2'
            onInitialized="onInitialized"
            ref="formMaster"
      >

        <DxColCountByScreen :xs="1" :sm="1" :md="1" :lg="1"/>

        <DxGroupItem caption="Βασικά Στοιχεία" :col-span="3" :col-count="3">

            <DxSimpleItem 
                data-field="surname"
                :is-required="true"
                :editor-options="{
                    disabled: !this.allowEditing,
                }"
            >
                <DxLabel text="Επίθετο"/>
            </DxSimpleItem>

            <DxSimpleItem 
                data-field="name"
                :is-required="true"
                :editor-options="{
                    disabled: !this.allowEditing ,
                }"
            >
                <DxLabel text="Όνομα"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="system_user_group_id"
                editor-type="dxSelectBox"
                :is-required="true"
                :editor-options="{
                    dataSource: state.LookupCustomDS_sims_system_user_groups,
                    searchEnabled: true,
                    displayExpr: 'name',
                    disabled: !this.allowEditing,
                    showClearButton: true,
                    valueExpr:'id'
                }"
            >
                <DxLabel text="Ομάδα Χρηστών"/>

            </DxSimpleItem>

            <DxSimpleItem
                data-field="active_ind"
                editor-type="dxCheckBox"
                :editor-options="{
                    disabled: !this.allowEditing,
                }"
            >
                <DxLabel text="Ενεργός"/>
            </DxSimpleItem>
            
        </DxGroupItem>

        <DxGroupItem caption="Σύνδεση με τον Υπάλληλο" :col-span="3" :col-count="3">
            <DxSimpleItem
                data-field="assigned_employment_staff_id"
                editor-type="dxSelectBox"
                :editor-options="{
                    dataSource: state.LookupCustomDS_sims_employment_staff,
                    searchEnabled: true,
                    displayExpr: 'name',
                    disabled: !this.allowAdministratorEditing,
                    showClearButton: true,
                    valueExpr:'id'
                }"
            >
                <DxLabel text="Υπάλληλος"/>
            </DxSimpleItem>

        </DxGroupItem>

        <DxGroupItem caption="Επιπλέον Πληροφορίες">

            <DxSimpleItem
                data-field="description"
                editor-type="dxTextArea"
                :editor-options="{
                                    placeholder: 'Προσθήκη σχόλιου...', 
                                    disabled: !this.allowEditing,
                                    height: 75, rows: 10
                                }"
            >
                <DxLabel :visible="false" text="Επιπλέον Πληροφορίες"/>
            </DxSimpleItem>
            
        </DxGroupItem>

      </DxForm>
   
      <DxForm
            :form-data="state.formData_users"
            label-mode='outside'
            :read-only='false'
            :show-colon-after-label='true'
            label-location='top'
            :min-col-width='300'
            :col-count='2'
            onInitialized="onInitialized"
            ref="formSubmaster_users"
      >

        <DxColCountByScreen :xs="1" :sm="1" :md="1" :lg="1"/>

        <DxGroupItem caption="Σύνδεση" :col-span="3" :col-count="3">

            <DxSimpleItem 
                data-field="username"
                :is-required="true"
                :editor-options="{
                    disabled: !this.allowEditing,
                }"
            >
                <DxLabel text="Email (Χρήστης)"/>
            </DxSimpleItem>

            <DxSimpleItem 
                data-field="password"
                :is-required="this.isInsertMode"
                :v-model="password"
                editor-type="dxTextBox"
                :editor-options="{
                    showClearButton: true,
                }"
            >
                <DxLabel text="Νέος Κωδικός"/>
                <DxCustomRule
                    message="O Νέος Κωδικός πρέπει να έχει μήκος από 6 εώς 50 χαρακτήρες" 
                    :validation-callback="validateStringLength"
                />
            </DxSimpleItem>
        </DxGroupItem>

      </DxForm>

      <div id="buttons">
          <DxToolbar>
              <DxItem 
                  location="after"
                  widget="dxButton"
                  @click="onClickSave"
                  :options="{
                      icon: 'save',
                      text: 'Αποθηκευση'
                  }"
              >
              </DxItem>
              <DxItem 
                  location="after" 
                  widget="dxButton"
                  @click="onClickClose"
                  :options="{
                      icon: 'close',
                      text: 'Κλεισιμο'
                  }"
                  >
              </DxItem>
          </DxToolbar>
    </div>
    </div>
</div>
</template>

<script>

    //Form
    const URL_SERVICE = OParameters.Host.URI + 'sims_system_users';
    const URL_SERVICE_sims_system_user_login_account = OParameters.Host.URI + 'sims_system_user_login_account';
    const URL_SERVICE_sims_system_user_login_account_VIEW = OParameters.Host.URI + 'sims_system_user_login_account_VIEW';

    const URL_SERVICE_USERS_REGISTER = OParameters.Host.APIRoot + 'register';
    //const URL_SERVICE_USERS_ΜΕ = OParameters.Host.APIRoot + 'me';

    const URL_SERVICE_LOOKUP_sims_system_user_groups = OParameters.Host.URI + 'sims_system_user_groups';
    const URL_SERVICE_LOOKUP_sims_employment_staff = OParameters.Host.URI + 'sims_employment_staff_VIEW';

  //SEQUENCE
  const URL_SERVICE_seq_general_VIEW = OParameters.Host.URI + 'seq_general_VIEW';

  import CustomStore from 'devextreme/data/custom_store';

  import { reactive, onMounted, computed} from 'vue';  

  import 'whatwg-fetch';

  import OParameters from '../sims-globals';

  import {
      DxForm, 
      DxLabel,
      DxSimpleItem, 
      DxGroupItem,
      DxColCountByScreen,
  } from 'devextreme-vue/form'; 

  import {
        //DxRequiredRule,
        //DxAsyncRule,
        DxCustomRule
} from 'devextreme-vue/validator';

  import {DxItem} from 'devextreme-vue/tab-panel';
  import DxToolbar from 'devextreme-vue/toolbar';

  export default {
      name: 'SystemUsersFormPage',
      components: {
          DxForm,
          DxLabel,
          DxSimpleItem,
          DxToolbar,
          DxItem,
          DxColCountByScreen,
          DxGroupItem,
          //DxRequiredRule, 
          //DxAsyncRule,
          DxCustomRule
      },
    data() {
        return {
            title: "Διαχείριση Ποντικιού",
            calendarOptions: { value: new Date() },
            textAreaOptions: { placeholder: 'Προσθήκη σχολίου..'},
            pageSizes: [5, 10, 20],
            //dataGridRefKey: DATAGRIDREFKEY,
            dateBoxEditorOptions: {
                //pickerType: 'rollers',
                type: 'date',
                displayFormat: 'dd/MM/yyyy',
                dateSerializationFormat: 'yyyy-MM-dd',
                invalidDateMessage: 'Λάθος Ημερομηνία',
                showClearButton: true,
                //min: new Date(),
                max: new Date(2030, 11, 31)
            }
        }
    },
    props: {
        system_user_id: {
            type: Number,
            required: true
        }
    },
    watch: {
        system_user_id(newValue, oldValue) {
            this.$emit('system_user_id', newValue, oldValue);
            this.loadFormData(newValue);
        }
    },
    setup() {
        const gridWidth = computed(() => '100%'); // set the width to 100%
        const maxWidth = computed(() => '800px'); // set the max-width to 800px
        const state = reactive({
          formData: {},
          formData_users: {},

          LookupCustomDS_sims_system_user_groups: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_system_user_groups + '?order=name'),
          }),

          LookupCustomDS_sims_employment_staff:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE_LOOKUP_sims_employment_staff + '?order=name'),
          }),

          CustomStore_users: new CustomStore({
              key: 'id',
              //loadMode: 'raw',
              //load: () => sendRequest(URL_SERVICE_sims_system_user_login_account),
              insert: (values) => sendRequest(URL_SERVICE_USERS_REGISTER, 'POST', values),
              update: (key, values) => sendRequest(URL_SERVICE_sims_system_user_login_account + '/' + encodeURIComponent(key), 'PUT', values),
              remove: (key) => sendRequest(URL_SERVICE_sims_system_user_login_account + '/' + encodeURIComponent(key), 'DELETE'),
              byKey: (key) => sendRequestGetRow(URL_SERVICE_sims_system_user_login_account_VIEW + "/" + encodeURIComponent(key)),
          }),

          CustomStoreMain: new CustomStore({
              key: 'id',
              //loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE),
              insert: (values) => sendRequest(URL_SERVICE, 'POST', values),
              update: (key, values) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
              remove: (key) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
              byKey: (key) => sendRequestByKey(URL_SERVICE + "/" + encodeURIComponent(key)),
          }),

          CustomStore_seq_general_VIEW:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                cacheRawData: 'false',
                load: () => sendRequest(URL_SERVICE_seq_general_VIEW),
          }),

      });

      // 
      //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
      //
      const sendRequest = (url, method = 'GET', values = {}) => {
            //console.log('sendRequest START LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then(response => response.json())
                .then(json => {
                    return json.records;
                })
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {'Content-Type': 'application/json',},
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
      };
      
      const sendRequestByKey = (url, method = 'GET') => {
          //console.log(' sendRequestByKey LOG: ' + url + ' METHOD: ' + method);
          return fetch(url, {
                    method,
                    headers: {'Accept': 'application/json',},
                    credentials: 'include',
                })
                .then(response => response.json())
                .then(json => json)
      };

      const sendRequestGetRow = (url, method = 'GET') => {
        //console.log(' sendRequestByKey LOG: ' + url + ' METHOD: ' + method);
        return fetch(url, {
                method, headers: {'Accept': 'application/json',}, credentials: 'include',
        })
                .then(response => response.json())
                .then(json => json)
      };
    
      const saveData = async () => {
            let data2store = {...state.formData}
            await state.CustomStoreMain.update(state.formData.id, data2store);
      };

      const insertData = async () => {
            let data2store = {...state.formData}
            await state.CustomStoreMain.insert(data2store)
      };

      const saveLogin = async () => {
            
            if (state.formData_users.system_user_login_account_id !== null) {
                await state.CustomStore_users.remove(state.formData_users.system_user_login_account_id)
            }

            let data2store = await state.CustomStore_users.insert({
                                username: state.formData_users.username,
                                password: state.formData_users.password
                             })
            data2store.system_user_id = state.formData.id
            await state.CustomStore_users.update(data2store.id, data2store);
      }

      const clearForm = () => {
            state.formData = {};
      };

      onMounted(async () => {
            /*
            console.log('mounted')
            
            if (props.system_user_id != null) state.formData = await state.CustomStoreMain.byKey(props.system_user_id);
            if (props.system_user_id != null) state.formData_users = await state.CustomStore_users.byKey(props.system_user_id);
            */
      });

      return {
          saveData,
          insertData,
          saveLogin,
          clearForm,
          gridWidth,
          maxWidth,
          state,
          sendRequest
      };
    },
    methods: {
        async loadFormData(id) {
            this.state.formData = {}
            this.state.formData_users = {}
            if (id != null) {
                this.isInsertMode = false
                
                this.state.formData = await this.state.CustomStoreMain.byKey(id)
                this.state.formData_users = await this.state.CustomStore_users.byKey(id)
                this.state.formData_users.password = ""
            } else {
                this.isInsertMode = true
                this.getNextVal().then(value => {
                    this.state.formData.id = value
                    this.state.formData_users.id = value
                });
            }
        },

        async getNextVal() {
            this.state.CustomStore_seq_general_VIEW.clearRawDataCache()
            let row = await this.state.CustomStore_seq_general_VIEW.load()
            return row[0].nextval
        },

        validateStringLength(params) {
            if ((params.value.length > 0 && !this.isInsertMode)||(this.isInsertMode)) {
                return params.value.length >= 6 && params.value.length <= 50
            } else {
                return true
            }
        },

        onClickSave: async function() {
            const formValidator = this.$refs.formMaster.instance.validate();
            const formValidatorUsers = this.$refs.formSubmaster_users.instance.validate();

            let formSaved = false
            if (formValidator.isValid && formValidatorUsers.isValid) {
                if (!this.isInsertMode) {
                    await this.saveData()
                    formSaved = true
                } else {
                    await this.insertData()
                    formSaved = true
                }
                
                if (this.state.formData_users.password.length > 0) {
                    await this.saveLogin()
                }
                formSaved = formSaved && true
            }

            if (formSaved) this.$emit('closePopupEvent', true)
        },

        onClickClose: function() {
            this.$emit('closePopupEvent', true);
        },
    },
    computed: {
        /*
        dataGrid: function() {
            return this.$refs[DATAGRIDREFKEY].instance;
        },
        */
        allowDeleting() {
            return OParameters.loggedUser.system_user_group_id === 1;
        },
        allowAdding() {
            return OParameters.loggedUser.system_user_group_id === 1;
        },
        allowEditing() {
            //console.log("OParameters.loggedUser.system_user_group_id")
            //console.log(OParameters.loggedUser.system_user_group_id)
            return OParameters.loggedUser.system_user_group_id === 1;
        },
        allowAdministratorEditing() {
            //console.log("OParameters.loggedUser.system_user_group_id")
            //console.log(OParameters.loggedUser.system_user_group_id)
            return OParameters.loggedUser.system_user_group_id < 3;
        },
    },
};
</script>


<style lang="scss">

</style>

<style scoped>

#form {
   margin-top: 25px;
}
.caption {
    font-size: 18px;
    font-weight: 500;
}
.option {
    margin-top: 10px;
}
.dx-fieldset-header {
    font-size: 16px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-top: 4px;
}
</style>
