<template>
  <div>
    <h3 class="content-block openware-form-title">{{ title }}</h3>
    <DxDataGrid
        :key-expr="id"            
        :data-source="CustomStoreMain"
        :columns="columns"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :focused-row-enabled="true"
        @exporting="onExporting"
        @init-new-row="initNewRow"
    >

        <DxPaging 
            :enabled="true"
            :page-size="5"
        />

        <DxFilterRow :visible="true"/>
        <DxHeaderFilter :visible="true"/>
        
        <DxPager
            :visible="true"
            :allowed-page-sizes="pageSizes"
            :display-mode="displayMode"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
        />

        <DxGroupPanel :visible="false"/>

        <DxSorting mode="multiple"/>

        <DxEditing
            :allow-updating="true"
            :allow-adding="true"
            :allow-deleting="true"
            :texts= "{
                    addRow: 'Προσθήκη',
                    deleteRow: 'Διαγραφή',
                    editRow:'Επεξεργασία',
                    saveAllChanges:'Αποθήκευση Όλων',
                    cancelAllChanges:'Ακύρωση Όλων'
                }"
            mode="row"
        >

        </DxEditing>

        <DxColumn
            data-field="hw_multimedia_device_model_id"
            caption="Μοντέλο Multimedia"
            sort-order="asc"
        >
            <DxLookup
                :data-source="LookupCustomDS_sims_hw_multimedia_device_models" 
                value-expr="id"
                display-expr="name"
            />
            <DxRequiredRule/>
        </DxColumn>

        <DxColumn
            data-field="hw_multimedia_device_type_id"
            caption="Είδος Multimedia"
        >
            <DxLookup
                :data-source="LookupCustomDS_sims_hw_multimedia_device_types" 
                value-expr="id"
                display-expr="name"
            />
            <DxRequiredRule/>
        </DxColumn>

        <DxColumn
          :width="200"
          :allow-sorting="false"
          :calculate-filter-expression="calculateFilterExpression"
          data-field="json_2_connection_types"
          caption="Δυνατότητες Σύνδεσης"
          :cell-template="cellTemplate"
          edit-cell-template="tagBoxEditor"
          :hiding-priority="1"
        >   
            <DxLookup
                :data-source="LookupCustomDS_sims_hw_connection_types"
                value-expr="id"
                display-expr="name"
            />

            <DxRequiredRule/>
        </DxColumn>

        <!--
        <DxColumn
            data-field="name"
            caption="Όνομα"
        >
            <DxRequiredRule/>
        </DxColumn>

        <DxColumn
            data-field="description"
            caption="Επιπλέον Πληροφορίες"
        >
        </DxColumn>
        -->

        <DxExport
            :enabled="true"
            :formats="['pdf', 'xlsx']"
            :texts= "{
                    exportAll: 'Εξαγωγή σε {0}',
                }"
        />

        <template #tagBoxEditor="{ data: cellInfo }">
            <ConnectionsTagBoxComponent
                :value="cellInfo.value"
                :on-value-changed="(value) => onValueChanged(value, cellInfo)"
                :data-source="LookupCustomDS_sims_hw_connection_types"
                :data-grid-component="cellInfo.component"
            />
        </template>

    </DxDataGrid>
  </div>
</template>

<script>
import DxDataGrid, {
    DxColumn,
    DxEditing, 
    DxPaging,
    DxPager,
    DxGroupPanel,
    DxSorting,
    DxFilterRow,
    DxHeaderFilter,
    DxExport,
    DxRequiredRule,
    //DxEmailRule,
    DxLookup,
} from 'devextreme-vue/data-grid';
import 'whatwg-fetch';
import CustomStore from 'devextreme/data/custom_store';
import ConnectionsTagBoxComponent from './sims_ConnectionsTagBoxComponent.vue';

import OParameters from '../sims-globals';

const URL_SERVICE = OParameters.Host.URI + 'sims_hw_multimedia_device_categories';
const URL_SERVICE_LOOKUP_sims_hw_multimedia_device_types = OParameters.Host.URI + 'sims_hw_multimedia_device_types';
const URL_SERVICE_LOOKUP_sims_hw_multimedia_device_models = OParameters.Host.URI + 'sims_hw_multimedia_device_models';
const URL_SERVICE_LOOKUP_sims_hw_connection_types = OParameters.Host.URI + 'sims_hw_connection_types';

export default {
        components: {
            DxDataGrid,
            DxColumn,
            DxEditing, 
            DxPaging,
            DxPager,
            DxGroupPanel,
            DxSorting,
            DxFilterRow, 
            DxHeaderFilter,
            DxExport,
            DxRequiredRule,
            //DxEmailRule,
            DxLookup,
            ConnectionsTagBoxComponent,
    },
    data() {
        return {
            CustomStoreMain: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE),
                insert: (values) => this.sendRequest(URL_SERVICE, 'POST', values),
                update: (key, values) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
            }),

            LookupCustomDS_sims_hw_multimedia_device_types:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_multimedia_device_types+ '?order=name'),
            }),

            LookupCustomDS_sims_hw_multimedia_device_models:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_multimedia_device_models+ '?order=name'),
            }),

            LookupCustomDS_sims_hw_connection_types: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_connection_types+ '?order=name'),
            }),

            title: 'Χαρακτηριστικά Multimedia Συσκευής',
            pageSizes: [5, 10, 20]
        };
    }, 
    
    methods: {
        // 
        //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
        //
        sendRequest(url, method = 'GET', values = {}) {
            // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then((response) => response.json()
                .then((json) => {
                    if (response.ok) return json.records;
                    throw json.Message;
                }));
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {                    
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
        },
        cellTemplate(container, options) {
           /*
            if (options !== undefined) {
                console.log('Grid cell template');
                console.log(options);
            }
            */
           const noBreakSpace = '\u00A0';

            var text;
            try {
                text = (JSON.parse(options.value) || []).map((element) => options.column.lookup.calculateCellValue(element)).join(', ');
            } catch {
                text = '[]';//(options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(', ');
            }
            //const text = (options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(', ');
            container.textContent = (text || noBreakSpace);
            container.title = text;
        },
        onValueChanged(value, cellInfo) {
            /*
            console.log('Grid value changed');
            console.log(value);
            */

            cellInfo.setValue(JSON.stringify(value));
            //cellInfo.setValue(value);
            cellInfo.component.updateDimensions();
        },
        initNewRow(row) {
            row.data.json_2_connection_types = '[]';
        },
        onExporting (e){
            OParameters.methods.exportDataGridToPdfExcel(e, this.title, [60])
        },

    },
};
</script>

<style lang="scss">
</style>
