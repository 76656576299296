import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';


import { createApp }  from "vue";
import router from "./router";
//import auth from "./auth";

import App from "./App";
import appInfo from "./app-info";

const app = createApp(App);

// Now any template can access `answer` and `capitalizeFirstLetter()`

app.use(router);
app.config.globalProperties.$appInfo = appInfo;

app.mount('#app');

// Example of demo application from
//https://js.devexpress.com/Documentation/Guide/Vue_Components/Application_Template/
