<template>
  <div>
    <h3 class="content-block openware-form-title">{{ title }}</h3>
    <DxDataGrid
        :key-expr="id"            
        :data-source="CustomStoreMain"
        :columns="columns"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :focused-row-enabled="true"        
        :word-wrap-enabled="true"
        @exporting="onExporting"
        ref="dataGridRef"
    >

        <DxPaging 
            :enabled="true"
            :page-size="5"
        />

        <DxFilterRow :visible="true"/>
        <DxHeaderFilter :visible="true"/>
        
        <DxPager
            :visible="true"
            :allowed-page-sizes="pageSizes"
            :display-mode="displayMode"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
        />

        <DxGroupPanel :visible="false"/>

        <DxSorting mode="multiple"/>

        <DxEditing
            :allow-updating="true"
            :allow-adding="true"
            :allow-deleting="true"
            :texts= "{
                    addRow: 'Προσθήκη',
                }"
            
            mode="popup"
        >
            <DxTexts
                save-row-changes="Αποθηκευση"
                cancel-row-changes="Κλεισιμο"
                add-row="Προσθήκη"
                delete-row="Διαγραφή"
                edit-row="Επεξεργασία"
            />

            <DxPopup
                :show-title="true" 
                title="Στοιχεία Μοντέλоυ Desktop"
                :width="'90%'"
                :height="'90%'"
                :show-close-button="true"
            > 
                <DxToolbarItem
                    widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    :options="{ 
                        icon: 'save',
                        text: 'Αποθήκευση', 
                        type: 'success',
                        onClick: () => {
                            this.$refs['dataGridRef'].instance.saveEditData();
                        }
                    }"
                />
                <DxToolbarItem 
                    widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    :options="{ 
                        icon: 'close',
                        text: 'Κλείσιμο',
                        type: 'danger',
                        onClick: () => {
                            this.$refs['dataGridRef'].instance.cancelEditData();
                        }
                    }"
                />
            </DxPopup>
            
          <DxForm>
              <DxItem data-field="name" :col-span="2"/>
              <DxItem 
                  itemType="group" 
                  :col-count="2" 
                  :col-span="2" 
              >                  
                  <DxItem data-field="hw_processor_type_id"/>
                  <DxItem data-field="hw_processor_frequency_id"/>
                  <DxItem data-field="hw_memory_type_id"/>
                  <DxItem data-field="hw_memory_size_id"/>                  
                  <DxItem data-field="hw_harddisks_count"/>
                  <DxItem data-field="hw_harddisks_size"/>
                  <DxItem data-field="ports_count"/>
                  <DxItem data-field="ports_speed"/>                  
                  <DxItem data-field="rack_placement_ind"/>
              </DxItem>

              <DxItem
                  :col-span="2"
                  data-field="description"
                  :editor-options="{ height: 120 }"
                  editor-type="dxTextArea"
              />
          </DxForm>

        </DxEditing>

        
        <DxColumn
            data-field="name"
            caption="Όνομα"
            sort-order="asc"
        >
            <DxRequiredRule/>
        </DxColumn>

        <DxColumn
            data-field="description"
            caption="Επιπλέον Χαρακτηριστικά"
            :hiding-priority="10"
        >
        </DxColumn>
        

        <DxColumn
            data-field="hw_processor_type_id"
            caption="Τύπος Επεξεργαστή"
            :width="150"
            :hiding-priority="160"
        >
          <DxLookup
            :data-source="LookupCustomDS_sims_hw_processor_types"
            value-expr="id"
            display-expr="name"
            />
        </DxColumn>

        <DxColumn
            data-field="hw_processor_frequency_id"
            caption="Συχνότητα Επεξεργαστή (GHz)"
            :width="150"
            :hiding-priority="150"
        >
          <DxLookup
            :data-source="LookupCustomDS_sims_hw_processor_frequencies"
            value-expr="id"
            display-expr="name"
            />
        </DxColumn>

        <DxColumn
            data-field="hw_memory_type_id"
            caption="Τύπος Μνήμης"
            :width="150"
            :hiding-priority="140"
        >
          <DxLookup
            :data-source="LookupCustomDS_sims_hw_memory_types"
            value-expr="id"
            display-expr="name"
            />
        </DxColumn>

        <DxColumn
            data-field="hw_memory_size_id"
            caption="Μέγεθος Μνήμης (GB)"
            :width="150"
            :hiding-priority="130"
        >
          <DxLookup
            :data-source="LookupCustomDS_sims_hw_memory_sizes"
            value-expr="id"
            display-expr="name"
            />
        </DxColumn>

        <DxColumn
            data-field="hw_harddisks_count"
            caption="Αριθμός Δίσκων "
            :width="150"
            :hiding-priority="120"
            data-type="number"
        />

        <DxColumn
            data-field="hw_harddisks_size"
            caption="Συνολική Χωρητικότητα (GB)"
            :width="150"
            :hiding-priority="110"
            data-type="number"
        />

        <DxColumn
            data-field="ports_count"
            caption="Αριθμός Θυρών Δικτύο"
            :width="150"
            :hiding-priority="100"
            data-type="number"
        />

        <DxColumn
            data-field="ports_speed"
            caption="Ταχύτητα Θυρών (Mbps)"
            :width="150"
            :hiding-priority="90"
            data-type="number"
        />

        <DxColumn
            data-field="rack_placement_ind"
            caption="Δυνατότητα Τοποθέτησης σε RACK"
            :width="150"
            :hiding-priority="80"
            data-type="boolean"
        />

        <DxExport
            :enabled="true"
            :formats="['pdf', 'xlsx']"
            :texts= "{
                    exportAll: 'Εξαγωγή σε {0}',
                }"
        />

    </DxDataGrid>
  </div>
</template>

<script>
import DxDataGrid, {
    DxColumn,
    DxEditing, 
    DxPaging,
    DxPager,
    DxGroupPanel,
    DxSorting,
    DxFilterRow,
    DxHeaderFilter,
    DxExport,
    DxRequiredRule,
    //DxEmailRule,
    DxLookup,
    DxPopup,    
    DxForm,
    DxItem,
    DxTexts
} from 'devextreme-vue/data-grid';

import { DxToolbarItem } from 'devextreme-vue/popup'

import 'whatwg-fetch';
import CustomStore from 'devextreme/data/custom_store';
import OParameters from '../sims-globals';

const URL_SERVICE = OParameters.Host.URI + 'sims_hw_pc_server_models';
const URL_SERVICE_LOOKUP_sims_hw_memory_sizes = OParameters.Host.URI + 'sims_hw_memory_sizes';
const URL_SERVICE_LOOKUP_sims_hw_memory_types = OParameters.Host.URI + 'sims_hw_memory_types';
const URL_SERVICE_LOOKUP_sims_hw_processor_frequencies = OParameters.Host.URI + 'sims_hw_processor_frequencies';
const URL_SERVICE_LOOKUP_sims_hw_processor_types = OParameters.Host.URI + 'sims_hw_processor_types';

export default {
        components: {
            DxDataGrid,
            DxColumn,
            DxEditing, 
            DxPaging,
            DxPager,
            DxGroupPanel,
            DxSorting,
            DxFilterRow, 
            DxHeaderFilter,
            DxExport,
            DxRequiredRule,
            //DxEmailRule,
            DxLookup,
            DxPopup,
            DxForm,
            DxItem,
            DxTexts,
            DxToolbarItem
    },
    data() {
        return {          
            CustomStoreMain: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE),
                insert: (values) => this.sendRequest(URL_SERVICE, 'POST', values),
                update: (key, values) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
            }),

            LookupCustomDS_sims_hw_memory_sizes: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_memory_sizes + '?order=name'),
            }),

            LookupCustomDS_sims_hw_memory_types:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_memory_types + '?order=name'),
            }),

            LookupCustomDS_sims_hw_processor_frequencies: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_processor_frequencies + '?order=name'),
            }),

            LookupCustomDS_sims_hw_processor_types: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_processor_types + '?order=name'),
            }),

            title: 'Μοντέλα Server/NAS',
            pageSizes: [5, 10, 20]
        };
    }, 
    
    methods: {
        // 
        //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
        //
        sendRequest(url, method = 'GET', values = {}) {
            // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then((response) => response.json()
                .then((json) => {
                    if (response.ok) return json.records;
                    throw json.Message;
                }));
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {                    
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
        },
        onExporting (e){
            OParameters.methods.exportDataGridToPdfExcel(e, this.title, [40,40])
        }
    },
};
</script>

<style lang="scss">
</style>
