
<template>
    
    <div>    
        <h3 class="content-block openware-form-title">{{ title }}</h3>
    <div class="content-block dx-card responsive-paddings">
   
      <DxForm
            :form-data="state.formData"
            :read-only="true"
            label-mode='outside'
            :show-colon-after-label='true'
            label-location='top'
            :min-col-width='300'
            :col-count='2'        
            onInitialized="onInitialized"
            ref="formMaster"
      >

        <DxColCountByScreen :xs="1" :sm="1" :md="1" :lg="1"/>

        <DxGroupItem caption="Βασικά Στοιχεία" :col-span="4" :col-count="4">
            <DxSimpleItem 
                data-field="surname"
                :is-required="true"
            >
                <DxLabel text="Επίθετο"/>
            </DxSimpleItem>

            <DxSimpleItem 
                data-field="name"
                :is-required="true"
            >
                <DxLabel text="Όνομα"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="system_user_group_id"
                editor-type="dxSelectBox"
                :is-required="true"
                :editor-options="{
                    dataSource: state.LookupCustomDS_sims_system_user_groups,
                    searchEnabled: true,
                    displayExpr: 'name',
                    valueExpr:'id'
                }"
            >
                <DxLabel text="Ομάδα Χρηστών"/>
            </DxSimpleItem>

            <DxSimpleItem 
                data-field="email"
                :is-required="true"
            >
                <DxLabel text="Email"/>
            </DxSimpleItem>

            <!--
            <DxSimpleItem 
                data-field="password"
                :is-required="true"
            >
                <DxLabel text="Password"/>
            </DxSimpleItem>
            -->

        </DxGroupItem>

        <DxGroupItem caption="Σύνδεση με τον Υπάλληλο">
            <DxSimpleItem
                data-field="assigned_employment_staff_id"
                editor-type="dxSelectBox"
                :editor-options="{
                    dataSource: state.LookupCustomDS_sims_employment_staff,
                    searchEnabled: true,
                    displayExpr: 'name',
                    valueExpr:'id'
                }"
            >
                <DxLabel text="Υπάλληλος"/>
            </DxSimpleItem>

        </DxGroupItem>

        <DxGroupItem caption="Επιπλέον Πληροφορίες">
            <!--
            <DxSimpleItem data-field="service_tag">
                <DxLabel text="Service Tag"/>
            </DxSimpleItem>

            <DxSimpleItem data-field="express_service_code">
                <DxLabel text="Express Service Code"/>
            </DxSimpleItem>
            -->

            <DxSimpleItem
                data-field="description"
                editor-type="dxTextArea"
                :editor-options="{placeholder: 'Προσθήκη σχόλιου...', height: 75, rows: 10}"
            >
                <DxLabel :visible="false" text="Επιπλέον Πληροφορίες"/>
            </DxSimpleItem>
            
        </DxGroupItem>

      </DxForm>


    </div>
</div>
</template>

<script>

    //Form
    const URL_SERVICE = OParameters.Host.APIRoot + 'me';

    const URL_SERVICE_LOOKUP_sims_system_user_groups = OParameters.Host.URI + 'sims_system_user_groups';
    const URL_SERVICE_LOOKUP_sims_employment_staff = OParameters.Host.URI + 'sims_employment_staff_VIEW';

  import CustomStore from 'devextreme/data/custom_store';

  import { reactive, onMounted, computed} from 'vue';  

  import 'whatwg-fetch';

  import OParameters from '../sims-globals';

  import {
      DxForm, 
      DxLabel,
      DxSimpleItem, 
      DxGroupItem,
      DxColCountByScreen,
  } from 'devextreme-vue/form'; 

  import {
        //DxRequiredRule,
        //DxAsyncRule,
} from 'devextreme-vue/validator';


  export default {
      name: 'SystemUsersFormPage',
      components: {
          DxForm,
          DxLabel,
          DxSimpleItem,
          DxColCountByScreen,
          DxGroupItem,
          //DxRequiredRule, 
          //DxAsyncRule,
      },
    data() {
        return {
            title: "Προφίλ Χρήστη",
            calendarOptions: { value: new Date() },
            textAreaOptions: { placeholder: 'Προσθήκη σχολίου..'},
            pageSizes: [5, 10, 20],
            //dataGridRefKey: DATAGRIDREFKEY,
            dateBoxEditorOptions: {
                //pickerType: 'rollers',
                type: 'date',
                displayFormat: 'dd/MM/yyyy',
                dateSerializationFormat: 'yyyy-MM-dd',
                invalidDateMessage: 'Λάθος Ημερομηνία',
                showClearButton: true,
                //min: new Date(),
                max: new Date(2030, 11, 31)
            }
        }
    },
    props: {
        system_user_id: {
            type: Number,
            required: true
        }
    },
    watch: {
        system_user_id(newValue, oldValue) {
            this.$emit('system_user_id', newValue, oldValue);
            this.loadFormData();
        }
    },
    setup(props) {
        const gridWidth = computed(() => '100%'); // set the width to 100%
        const maxWidth = computed(() => '800px'); // set the max-width to 800px
        const state = reactive({
          formData: {},

          LookupCustomDS_sims_system_user_groups: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_system_user_groups + '?order=name'),
          }),

          LookupCustomDS_sims_employment_staff:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE_LOOKUP_sims_employment_staff + '?order=name'),
          }),

          
          CustomStoreMain: new CustomStore({
              key: 'id',
              //loadMode: 'raw',
              //load: () => sendRequest(URL_SERVICE),
              //byKey: (key) => sendRequestByKey(URL_SERVICE + "/" + encodeURIComponent(key)),
              byKey: () => sendRequestByKey(URL_SERVICE),
          }),

      });

      // 
      //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
      //
      const sendRequest = (url, method = 'GET', values = {}) => {
            //console.log('sendRequest START LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then(response => response.json())
                .then(json => {
                    return json.records;
                })
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
      };
      
      const sendRequestByKey = (url, method = 'GET') => {
          //console.log(' sendRequestByKey LOG: ' + url + ' METHOD: ' + method);
          return fetch(url, {
                    method,
                    headers: {'Accept': 'application/json',},
                    credentials: 'include',
                })
                .then(response => response.json())
                .then(json => json)
      };

      const clearForm = () => {
            state.formData = {};
      };

      onMounted(async () => {
        state.formData = await state.CustomStoreMain.byKey()
        if (props.system_user_id != null) state.formData = await state.CustomStoreMain.byKey(props.system_user_id);
      });

      return {
          clearForm,
          gridWidth,
          maxWidth,
          state,
          sendRequest
      };
    },
    methods: {
        async loadFormData() {
            this.isInsertMode = false
            //this.state.formData = OParameters.loggedUser

            this.state.formData = await this.state.CustomStoreMain.byKey()

            console.log("this.state.formData")
            console.log(this.state.formData)
        },

        onClickSave: async function() {

            const formValidator = this.dataForm.validate();
            let formSaved = false

            if (formValidator.isValid) {
                if (!this.isInsertMode) {
                    await this.saveData()
                    console.log('update')
                    formSaved = true
                } else {
                    await this.insertData()
                    console.log('insert')
                    formSaved = true
                }
            }

            if (formSaved) this.$emit('closePopupEvent', true)
        },
        onClickClose: function() {
            this.$emit('closePopupEvent', true);
        },
    },
    computed: {
        dataForm: function() {
            return this.$refs.formMaster.instance;
        },
    }
};
</script>


<style lang="scss">

</style>

<style scoped>

#form {
   margin-top: 25px;
}
.caption {
    font-size: 18px;
    font-weight: 500;
}
.option {
    margin-top: 10px;
}
.dx-fieldset-header {
    font-size: 16px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-top: 4px;
}
</style>
