<template>
  <div>    
    <div class="widget-container">
     
      <DxForm
            id="form"
            :form-data="state.formData"
            label-mode='outside'
            :read-only='false'
            :show-colon-after-label='true'
            label-location='top'
            :min-col-width='300'
            :col-count='2'        
            onInitialized="onInitialized"
            ref="formMaster"
      >

        <DxColCountByScreen :xs="1" :sm="1" :md="1" :lg="1"/>

        <DxGroupItem caption="Βασικά Στοιχεία" :col-span="2" :col-count="2">
                        
            <DxSimpleItem 
                data-field="entry_date"
                :is-required="true"
                editor-type="dxDateBox"
                :editor-options="dateBoxEditorOptions"
            >
                <DxLabel text="Ημερομηνία Εισαγωγής"/>
            </DxSimpleItem>

            <DxSimpleItem 
                data-field="active_ind"
                editor-type="dxCheckBox"
            >
                <DxLabel text="Ενεργό"/>
            </DxSimpleItem>

            <DxSimpleItem 
                data-field="leasing_code"
            >
                <DxLabel text="Κωδικός Μίσθωσης"/>
            </DxSimpleItem>
            
            <DxSimpleItem
                data-field="building_id"
                editor-type="dxSelectBox"
                searchEnabled = true
                :editor-options="{
                    searchEnabled: true,
                    showClearButton: true,
                    dataSource: state.LookupCustomDS_sims_buildings,
                    displayExpr: 'name',
                    valueExpr:'id', 
                    onValueChanged: this.onBuildingChange
                }"
            >
                <DxLabel text="Τοποθεσία Εγκατάστασης"/>
            </DxSimpleItem>
            
            <DxSimpleItem
                data-field="directorate_services_id"
                editor-type="dxSelectBox"
                searchEnabled = true
                :editor-options="{
                    searchEnabled: true,
                    showClearButton: true,
                    dataSource: state.LookupCustomDataSourcesims_directorate_services,
                    displayExpr: 'name',
                    valueExpr:'id'
                }"
            >
                <DxLabel text="Υπηρεσία"/>
            </DxSimpleItem>

            <DxSimpleItem 
                data-field="serial_no"
            >
                <DxLabel text="Σειριακός Αριθμός"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="hw_printer_model_id"
                editor-type="dxSelectBox"
                searchEnabled = true
                :is-required="true"
                :editor-options="{
                    dataSource: state.LookupCustomDS_sims_hw_printer_models,
                    searchEnabled: true,
                    displayExpr: 'name',
                    valueExpr:'id',
                    onValueChanged: this.onPrinterModelChange
                }"
            >
                <DxLabel text="Μοντέλο Εκτυπωτή"/>
            </DxSimpleItem>

            <DxSimpleItem 
                data-field="json_2_connection_types"
                editor-type="dxTagBox"
                :editor-options="{
                    dataSource: state.LookupCustomDS_sims_hw_connection_types,
                    valueExpr: 'id',
                    displayExpr: 'name',
                    readOnly: true,
                    onValueChanged: this.onJson2ConnectionTypesChange
                }"
                ref="dxTagBoxjson_2_connection_types"
            >
                <DxLabel text="Δυνατότητες Σύνδεσης"/>

            </DxSimpleItem>

            <DxSimpleItem
                data-field="hw_printer_category_id"
                editor-type="dxSelectBox"
                searchEnabled = true
                :is-required="true"
                :editor-options="{
                    dataSource: state.LookupCustomDS_sims_hw_printer_categories,
                    searchEnabled: true,
                    displayExpr: 'name',
                    valueExpr:'id',
                    readOnly: true
                }"
            >
                <DxLabel text="Κατηγορία Εκτυπωτή"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="hw_printer_print_technology_id"
                editor-type="dxSelectBox"
                searchEnabled = true
                :is-required="true"
                :editor-options="{
                    dataSource: state.LookupCustomDS_sims_hw_printer_print_technologies,
                    searchEnabled: true,
                    displayExpr: 'name',
                    valueExpr:'id',
                    readOnly: true
                }"
            >
                <DxLabel text="Τεχνολογία Εκτύπωσης"/>
            </DxSimpleItem>
            
            <DxGroupItem>
                <DxSimpleItem 
                    data-field="mac_address"
                    editor-type="dxTextBox"
                    
                    :editor-options="{
                        mask: 'HH:HH:HH:HH:HH:HH',
                        maskChar: '_',
                        maskRules: {
                            'H': /[0-9A-Fa-f]/,
                        },
                        useMaskedValue: true, 
                        readOnly: isIPPhoneInd
                    }"
                >
                    <DxLabel text="MAC Address"/>

                </DxSimpleItem>
                
                <DxSimpleItem
                      data-field="network_ip_id"
                      editor-type="dxSelectBox"
                      searchEnabled = true
                      
                      :editor-options="{
                          showClearButton: true,
                          dataSource: state.LookupCascadeCustomDS_sims_network_ips_VIEW,
                          searchEnabled: true,
                          displayExpr: 'name',
                          valueExpr:'id',
                          readOnly: isIPPhoneInd
                      }"
                >
                    <DxLabel text="Διεύθυνση IP"/>
                </DxSimpleItem>
            </DxGroupItem>
            <DxGroupItem>
            <DxSimpleItem
                      data-field="hw_printer_expendable_id"
                      editor-type="dxSelectBox"
                      searchEnabled = true
                      :editor-options="{
                          showClearButton: true,
                          dataSource: state.LookupCustomDS_sims_hw_printer_expendables,
                          searchEnabled: true,
                          displayExpr: 'name',
                          valueExpr:'id',
                          readOnly: true
                      }"
                >
                    <DxLabel text="Τύπος Αναλωσίμου"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="color_printing_ind"
                editor-type="dxCheckBox"
                :editor-options="{
                    readOnly: true
                }"
            >
                <DxLabel text="Έγχρωμος"/>
            </DxSimpleItem>
            </DxGroupItem>
        </DxGroupItem>

        <DxGroupItem caption="Δυνατότητες Εκτυπωτή" :col-count="3">
            <DxSimpleItem
                data-field="printing_ind"
                editor-type="dxCheckBox"
                :editor-options="{
                    readOnly: true
                }"
            >
                <DxLabel text="Εκτύπωση"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="scanning_ind"
                editor-type="dxCheckBox"
                :editor-options="{
                    readOnly: true
                }"
            >
                <DxLabel text="Σάρωση"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="copying_ind"
                editor-type="dxCheckBox"
                :editor-options="{
                    readOnly: true
                }"
            >
                <DxLabel text="Αντιγραφή"/>
            </DxSimpleItem>


            <DxSimpleItem
                data-field="fax_ind"
                editor-type="dxCheckBox"
                :editor-options="{
                    readOnly: true
                }"
            >
                <DxLabel text="ΦΑΞ"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="adf_ind"
                editor-type="dxCheckBox"
                :editor-options="{
                    readOnly: true
                }"
            >
                <DxLabel text="ADF"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="doubleside_printing_ind"
                editor-type="dxCheckBox"
                :editor-options="{
                    readOnly: true
                }"
            >
                <DxLabel text="Εκτύπωση Διπλής όψης"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="cddvd_printing_ind"
                editor-type="dxCheckBox"
                :editor-options="{
                    readOnly: true
                }"
            >
                <DxLabel text="Εκτύπωση CD/DVD"/>
            </DxSimpleItem>

        </DxGroupItem>

        <DxGroupItem caption="Επιπλέον Πληροφορίες"  :col-span="2" :col-count="1">
            <!--
            <DxSimpleItem data-field="service_tag">
                <DxLabel text="Service Tag"/>
            </DxSimpleItem>            

            <DxSimpleItem data-field="express_service_code">
                <DxLabel text="Express Service Code"/>
            </DxSimpleItem>
            -->

            <DxSimpleItem
                data-field="description"
                editor-type="dxTextArea"
                :editor-options="{placeholder: 'Προσθήκη σχόλιου...', height: 75, rows: 10}"
            >
                <DxLabel :visible="false" text="Επιπλέον Πληροφορίες"/>
            </DxSimpleItem>
            
        </DxGroupItem>

        <!--
        <DxGroupItem caption="Χρήση Εξοπλισμού">
            <DxSimpleItem
                data-field="assigned_employment_staff_id"
                editor-type="dxSelectBox"
                :editor-options="{
                    dataSource: state.LookupCustomDS_sims_employment_staff,
                    searchEnabled: true,
                    displayExpr: 'name',
                    valueExpr:'id'
                }"
            >
                <DxLabel text="Υπάλληλος"/>
            </DxSimpleItem>
        </DxGroupItem>
        -->

      </DxForm>

      <div class="dx-fieldset-header">Βλάβες</div>
      <div class="dx-fieldset">
            <DxDataGrid
                :data-source="state.CustomStoreDetail_sims_leasing_hw_printer_device_damages"
                :key-expr="id"
                :columns="columns"
                :allow-column-reordering="true" :allow-column-resizing="true" :column-auto-width="true"
                :show-borders="true" :show-column-lines="true" :show-row-lines="true"
                :row-alternation-enabled="true" :focused-row-enabled="true" :width="gridWidth"
                :style="{ 'max-width': maxWidth }"
                ref="gridDetail_sims_leasing_hw_printer_device_damages"
                :word-wrap-enabled="true"
                @exporting="onExporting"
                @rowClick="openForm"
                @init-new-row="onInitNewRow"
                @row-validating="onRowValidating">
            >
                <DxPaging :enabled="true" :page-size="5"/>

                <DxFilterRow :visible="false"/>
                <DxHeaderFilter :visible="false"/>
                <DxPager :visible="true" :allowed-page-sizes="pageSizes" :display-mode="displayMode" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/>
                <DxGroupPanel :visible="false"/>
                <DxSorting mode="multiple"/>
                <DxEditing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="row"/>

                <DxColumn   data-field="entry_date" 
                            caption="Ημερομηνία Βλάβης" 
                            data-type="date"
                            :editor-options="{
                                    //pickerType: 'rollers',
                                    dateSerializationFormat: 'yyyy-MM-dd',
                                    invalidDateMessage: 'Λάθος Ημερομηνία',
                                    showClearButton: true,
                                    //min: new Date(),
                                    max: new Date(2030, 11, 31)
                            }">
                    <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                </DxColumn>

                <DxColumn data-field="description" caption="Περιγραφή Βλάβης" :editor-type="'dxTextBox'">
                    
                </DxColumn>

            </DxDataGrid>
        </div>

        <div class="dx-fieldset-header">Μετρήσεις</div>
        <div class="dx-fieldset">
            <DxDataGrid
                :data-source="state.CustomStoreDetail_sims_leasing_hw_printer_device_measurements"
                :key-expr="id"
                :columns="columns"
                :allow-column-reordering="true" :allow-column-resizing="true" :column-auto-width="true"
                :show-borders="true" :show-column-lines="true" :show-row-lines="true"
                :row-alternation-enabled="true" :focused-row-enabled="true" :width="gridWidth"
                :style="{ 'max-width': maxWidth }"
                ref="gridDetail_sims_leasing_hw_printer_device_measurements"                      
                :word-wrap-enabled="true"

                @exporting="onExporting"
                @rowClick="openForm"
                @init-new-row="onInitNewRow"
                @row-validating="onRowValidating">
            >
                <DxPaging :enabled="true" :page-size="5"/>

                <DxFilterRow :visible="false"/>
                <DxHeaderFilter :visible="false"/>
                <DxPager :visible="true" :allowed-page-sizes="pageSizes" :display-mode="displayMode" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/>
                <DxGroupPanel :visible="false"/>
                <DxSorting mode="multiple"/>
                <DxEditing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="row"/>

                <DxColumn   data-field="entry_date" 
                            caption="Ημερομηνία Μέτρησης" 
                            data-type="date"
                            :editor-options="{
                                    //pickerType: 'rollers',
                                    dateSerializationFormat: 'yyyy-MM-dd',
                                    invalidDateMessage: 'Λάθος Ημερομηνία',
                                    showClearButton: true,
                                    //min: new Date(),
                                    max: new Date(2030, 11, 31)
                            }">
                    <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                </DxColumn>

                <DxColumn data-field="copies_black_count" caption="Αριθμός αντιγράφων (Black)" :editor-type="'dxTextBox'">
                    <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                </DxColumn>

                <DxColumn data-field="copies_black_difference" caption="Διαφορά από την προηγούμενη μέτρηση" :editor-type="'dxTextBox'">
                    <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                </DxColumn>
                
                <DxColumn data-field="copies_color_count" caption="Αριθμός αντιγράφων (Colour)" :editor-type="'dxTextBox'">
                    <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                </DxColumn>

                <DxColumn data-field="copies_color_difference" caption="Διαφορά από την προηγούμενη μέτρηση" :editor-type="'dxTextBox'">
                    <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                </DxColumn>

                <DxColumn data-field="description" caption="Επιπλέον πληροφορίες" :editor-type="'dxTextBox'"/>

            </DxDataGrid>
        </div>


      <div id="buttons">
          <DxToolbar>
              <DxItem 
                  location="after"
                  widget="dxButton"
                  @click="onClickSave"
                  :options="{
                      icon: 'save',
                      text: 'Αποθηκευση'
                  }"
              >
              </DxItem>
              <DxItem 
                  location="after" 
                  widget="dxButton"
                  @click="onClickClose"
                  :options="{
                      icon: 'close',
                      text: 'Κλεισιμο'
                  }"
                  >
              </DxItem>
          </DxToolbar>
    </div>
    </div>
</div>
</template>

<script>

  //Form
  const URL_SERVICE = OParameters.Host.URI + 'sims_leasing_hw_printer_devices';
  
  const URL_SERVICE_LOOKUP_sims_hw_printer_models = OParameters.Host.URI + 'sims_hw_printer_models';
  const URL_SERVICE_LOOKUP_sims_hw_connection_types = OParameters.Host.URI + 'sims_hw_connection_types';

  const URL_SERVICE_LOOKUP_sims_hw_printer_categories = OParameters.Host.URI + 'sims_hw_printer_categories';
  const URL_SERVICE_LOOKUP_sims_hw_printer_print_technologies = OParameters.Host.URI + 'sims_hw_printer_print_technologies';

  const URL_SERVICE_LOOKUP_sims_network_ips = OParameters.Host.URI + 'sims_network_ips_VIEW';
  //const URL_SERVICE_LOOKUP_sims_employment_staff = OParameters.Host.URI + 'sims_employment_staff_VIEW';
  const URL_SERVICE_LOOKUP_sims_buildings = OParameters.Host.URI + 'sims_buildings';
  const URL_SERVICE_LOOKUP_sims_directorate_services = OParameters.Host.URI + 'sims_directorate_service_departments';

  const URL_SERVICE_LOOKUP_sims_hw_printer_expendables = OParameters.Host.URI + 'sims_hw_printer_expendables';

  //Grids    
  const URL_SERVICE_sims_leasing_hw_printer_device_damages = OParameters.Host.URI + 'sims_leasing_hw_printer_device_damages';
  const URL_SERVICE_sims_leasing_hw_printer_device_measurements = OParameters.Host.URI + 'sims_leasing_hw_printer_device_measurements';
 
  //SEQUENCE
  const URL_SERVICE_seq_hw_nextval_VIEW = OParameters.Host.URI + 'seq_hw_nextval_VIEW';
  

  import CustomStore from 'devextreme/data/custom_store';

  import { reactive, onMounted, computed} from 'vue';
  import 'whatwg-fetch';
  import OParameters from '../sims-globals';

  import {
      DxForm, 
      DxLabel,
      DxSimpleItem, 

      DxGroupItem,
      DxColCountByScreen,
  } from 'devextreme-vue/form'; 

  import DxDataGrid, {
      DxColumn,
      DxEditing, 
      DxPaging,
      DxPager,
      DxGroupPanel,
      DxSorting,
      DxFilterRow,
      DxHeaderFilter,
      DxRequiredRule,
  } from 'devextreme-vue/data-grid';

  import {DxItem} from 'devextreme-vue/tab-panel';
  import DxToolbar from 'devextreme-vue/toolbar';

  export default {
      name: 'HwPrinterDevicesFormPage',
      components: {
          DxForm,
          DxLabel,
          DxSimpleItem,
          DxToolbar,
          DxItem,
          DxColCountByScreen,
          DxGroupItem,
          DxDataGrid, 
          DxColumn,
          DxEditing, 
          DxPaging,
          DxPager,
          DxGroupPanel,
          DxSorting,
          DxFilterRow,
          DxHeaderFilter,
          DxRequiredRule,

      },
    data() {
        return {
            title: "Διαχείριση Εκτυπωτή",
            calendarOptions: { value: new Date() },
            textAreaOptions: { placeholder: 'Προσθήκη σχολίου..'},
            pageSizes: [5, 10, 20],
            //dataGridRefKey: DATAGRIDREFKEY,
            dateBoxEditorOptions: {
                //pickerType: 'rollers',
                type: 'date',
                displayFormat: 'shortdate',
                dateSerializationFormat: 'yyyy-MM-dd',
                invalidDateMessage: 'Λάθος Ημερομηνία',
                showClearButton: true,
                //min: new Date(),
                max: new Date(2030, 11, 31)
            }
        }
    },
    props: {
        leasing_hw_printer_device_id: {
            type: Number,
            required: true
        }
    },
    watch: {
        leasing_hw_printer_device_id(newValue, oldValue) {
            this.$emit('leasing_hw_printer_device_id', newValue, oldValue);
            this.loadFormData(newValue);
        }
    },
    setup(props) {
        const gridWidth = computed(() => '100%'); // set the width to 100%
        const maxWidth = computed(() => '800px'); // set the max-width to 800px
        const state = reactive({
            formData: {},

            LookupCustomDS_sims_hw_printer_models:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_hw_printer_models),
                byKey: (key) => sendRequestByKey(URL_SERVICE_LOOKUP_sims_hw_printer_models + "/" + encodeURIComponent(key)),
            }),

            LookupCustomDS_sims_hw_connection_types: new CustomStore({
                    key: 'id',
                    loadMode: 'raw',
                    load: () => sendRequest(URL_SERVICE_LOOKUP_sims_hw_connection_types + '?order=name'),
            }),

            /*
            LookupCustomDS_sims_employment_staff:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_employment_staff + '?order=name'),
            }),
            */

            LookupCustomDS_sims_hw_printer_categories: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_hw_printer_categories + '?order=name'),
            }),

            LookupCustomDS_sims_hw_printer_print_technologies: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_hw_printer_print_technologies + '?order=name'),
            }),

            LookupCascadeCustomDS_sims_network_ips_VIEW:  new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                //load: () => sendRequest(URL_SERVICE_LOOKUP_sims_network_ips + '?order=name'),
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_network_ips + '?order=name&filter=building_id,eq,' + state.formData.building_id),              
                byKey: (key) => sendRequest(URL_SERVICE_LOOKUP_sims_network_ips + "/" + encodeURIComponent(key)),
            }),

            LookupCustomDS_sims_hw_printer_expendables:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_hw_printer_expendables + '?order=name'),
            }),

            LookupCustomDS_sims_buildings:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_buildings+ '?order=name'),
            }),

            LookupCustomDataSourcesims_directorate_services:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_directorate_services+ '?order=name'),
            }),
                
            CustomStoreDetail_sims_leasing_hw_printer_device_damages: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_sims_leasing_hw_printer_device_damages + '?filter=leasing_hw_printer_device_id,eq,' + props.leasing_hw_printer_device_id),
                insert: (values) => sendRequest(URL_SERVICE_sims_leasing_hw_printer_device_damages, 'POST', values),
                update: (key, values) => sendRequest(URL_SERVICE_sims_leasing_hw_printer_device_damages + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => sendRequest(URL_SERVICE_sims_leasing_hw_printer_device_damages + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => sendRequest(URL_SERVICE_sims_leasing_hw_printer_device_damages + "/" + encodeURIComponent(key)),
            }),
                
            CustomStoreDetail_sims_leasing_hw_printer_device_measurements: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_sims_leasing_hw_printer_device_measurements + '?filter=leasing_hw_printer_device_id,eq,' + props.leasing_hw_printer_device_id),
                insert: (values) => sendRequest(URL_SERVICE_sims_leasing_hw_printer_device_measurements, 'POST', values),
                update: (key, values) => sendRequest(URL_SERVICE_sims_leasing_hw_printer_device_measurements + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => sendRequest(URL_SERVICE_sims_leasing_hw_printer_device_measurements + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => sendRequest(URL_SERVICE_sims_leasing_hw_printer_device_measurements + "/" + encodeURIComponent(key)),
            }),

            CustomStore_seq_hw_nextval_VIEW:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                cacheRawData: 'false',
                load: () => sendRequest(URL_SERVICE_seq_hw_nextval_VIEW),
            }),

            CustomStoreMain: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE),
                insert: (values) => sendRequest(URL_SERVICE, 'POST', values),
                update: (key, values) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => sendRequestByKey(URL_SERVICE + "/" + encodeURIComponent(key)),
            })
      });

      // 
      //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
      //
      const sendRequest = (url, method = 'GET', values = {}) => {
            //console.log('sendRequest START LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then(response => response.json())
                .then(json => {
                    return json.records;
                })
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
      };

      const sendRequestByKey = (url, method = 'GET') => {
          //console.log(' sendRequestByKey LOG: ' + url + ' METHOD: ' + method);
          return fetch(url, {
                    method,
                    headers: {
                        'Accept': 'application/json',
                    },
                    credentials: 'include',
                })
                .then(response => response.json())
                .then(json => {
                    // convert string to array in order to prepare the field for dxTagBox
                    json.json_2_connection_types = JSON.parse(json.json_2_connection_types);
                    return json;
                })
      };

      /*
      const updateData = async () => {
            state.formData.json_2_connection_types = JSON.stringify(state.formData.json_2_connection_types);
            await state.CustomStoreMain.update(state.formData.id, state.formData);
      };

      const insertData = async () => {
            state.formData.json_2_connection_types = JSON.stringify(state.formData.json_2_connection_types);
            await state.CustomStoreMain.insert(state.formData);
      };
      */

      const saveData = async () => {
            let data2store = {...state.formData}
            data2store.json_2_connection_types = JSON.stringify(data2store.json_2_connection_types)
            await state.CustomStoreMain.update(state.formData.id, data2store);
      };

      const insertData = async () => {
            let data2store = {...state.formData}
            data2store.json_2_connection_types = JSON.stringify(data2store.json_2_connection_types)
            await state.CustomStoreMain.insert(data2store)
      };

      const clearForm = () => {
          state.formData = {};
      };

      var isGridValidated = true;
      var initialCodeValue
      var isInsertMode = false
  
      onMounted(async () => {
          if (props.leasing_hw_printer_device_id != null) state.formData = await state.CustomStoreMain.byKey(props.leasing_hw_printer_device_id);
      });

      return {
          saveData,
          insertData,
          clearForm,
          gridWidth,
          maxWidth,
          state,
          isGridValidated,
          initialCodeValue,
          isInsertMode
      };
    },
    methods: {
        
        async loadFormData(id) {
            if (id != null) {
                this.isInsertMode = false
                this.state.formData = await this.state.CustomStoreMain.byKey(id)

                // cascade ips support
                const cascaderows = await this.state.LookupCascadeCustomDS_sims_network_ips_VIEW.load()
                if (cascaderows) {
                    this.$refs.formMaster.instance.getEditor('network_ip_id').option('dataSource', cascaderows)
                }

            } else {
                this.getNextVal().then(value => {
                    this.isInsertMode = true
                    this.state.formData = {entry_date: new Date().toISOString().split('T')[0]}
                    this.state.formData.id = value
                });
            }

            this.initialCodeValue = this.state.formData.code
            await this.$refs.gridDetail_sims_leasing_hw_printer_device_damages.instance.refresh();
            await this.$refs.gridDetail_sims_leasing_hw_printer_device_measurements.instance.refresh();
        },

        async onBuildingChange (e) {
            if (e.event && (e.event.type === 'dxclick' || e.event.type === 'keydown')) {
                //
                // Cascade Update dxSelectBox
                //
                if (e.value) {
                    console.log('onBuildingChange')
                    //const cascaderows = await this.state.LookupCascadeCustomDS_sims_network_ips_VIEW.load({filter: ['network_id', 'eq', e.value]})
                    const cascaderows = await this.state.LookupCascadeCustomDS_sims_network_ips_VIEW.load()
                    if (cascaderows) {
                        //this.$refs.formMaster.instance.updateData('network_ip_id', null)
                        this.$refs.formMaster.instance.getEditor('network_ip_id').option('dataSource', cascaderows)
                    }
                }
            }
        },

        async onPrinterModelChange (e) {
            if (e.event && (e.event.type === 'dxclick' || e.event.type === 'keydown')) {
                // Handle the value change
                const modelrow = await this.state.LookupCustomDS_sims_hw_printer_models.byKey(e.value)
                
                this.$refs.formMaster.instance.updateData('json_2_connection_types', modelrow.json_2_connection_types)
                this.$refs.formMaster.instance.updateData('printing_ind', modelrow.printing_ind)
                this.$refs.formMaster.instance.updateData('scanning_ind', modelrow.scanning_ind)
                this.$refs.formMaster.instance.updateData('copying_ind', modelrow.copying_ind)
                this.$refs.formMaster.instance.updateData('fax_ind', modelrow.fax_ind)
                this.$refs.formMaster.instance.updateData('adf_ind', modelrow.adf_ind)
                this.$refs.formMaster.instance.updateData('doubleside_printing_ind', modelrow.doubleside_printing_ind)
                this.$refs.formMaster.instance.updateData('cddvd_printing_ind', modelrow.cddvd_printing_ind)
                this.$refs.formMaster.instance.updateData('color_printing_ind', modelrow.color_printing_ind)
                this.$refs.formMaster.instance.updateData('hw_printer_category_id', modelrow.hw_printer_category_id)
                this.$refs.formMaster.instance.updateData('hw_printer_print_technology_id', modelrow.hw_printer_print_technology_id)
                this.$refs.formMaster.instance.updateData('hw_printer_expendable_id', modelrow.hw_printer_expendable_id)                
                //this.$refs.formMaster.instance.repaint()
            }
        },

        async onJson2ConnectionTypesChange (e) {
            this.$refs.formMaster.instance.getEditor('network_ip_id').option('disabled', !(e.value.includes(1)||e.value.includes(13)||e.value.includes(17)))
            this.$refs.formMaster.instance.getEditor('mac_address').option('disabled', !(e.value.includes(1)||e.value.includes(13)||e.value.includes(17)))
        },

        async getNextVal() {
            //console.log('getNextVal')
            this.state.CustomStore_seq_hw_nextval_VIEW.clearRawDataCache()
            let row = await this.state.CustomStore_seq_hw_nextval_VIEW.load()
            return row[0].nextval
        },

        onClickSave: async function() {

            const formValidator = this.dataForm.validate();
            this.isGridValidated = true
            let formSaved = false

            if (formValidator.isValid) {
                if (!this.isInsertMode) {
                    await this.saveData()
                    formSaved = true
                } else {
                    await this.insertData()
                    formSaved = true
                }
            }

            await this.$refs.gridDetail_sims_leasing_hw_printer_device_damages.instance.saveEditData()
            await this.$refs.gridDetail_sims_leasing_hw_printer_device_measurements.instance.saveEditData()

            if (this.isGridValidated && formSaved) this.$emit('closePopupEvent', true)

        },
        onClickClose: function() {
            this.$emit('closePopupEvent', true);
        },
        onInitNewRow(row) {
            row.data.leasing_hw_printer_device_id = this.state.formData.id;
        },        
        onInitialized(e) {
            e.component.option('selectedItemKeys', [])
        },
        onRowValidating(e) {
            console.log('row validating')
            this.isGridValidated = this.isGridValidated && e.isValid;
            console.log(this.isGridValidated)
            console.log(e.isValid)
        },
    },
    computed: {
        dataForm: function() {
            return this.$refs.formMaster.instance;
        },
    }
};
</script>

<style lang="scss">

</style>

<style scoped>
    #form {
    margin-top: 25px;
    }
    .caption {
        font-size: 18px;
        font-weight: 500;
    }
    .option {
        margin-top: 10px;
    }
    .dx-fieldset-header {
        font-size: 16px;
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 10px;
        padding-top: 10px;
        margin-top: 4px;
    }
</style>
