<template>
    <div id="container">
        <h3 class="content-block openware-form-title">{{ title }}</h3>
        <DxPopover
            :shading="true"
            width="70%"
            target="#help-button"
            position="top"
            shading-color="rgba(0, 0, 0, 0.5)"
            v-model:visible="isPopoverVisible"
            :show-title="true"
            title="Βοήθεια: Φόρμα «Διαχείριση Τηλεφωνίας»"
            :toolbar-items="[{
                                widget: 'dxButton',
                                location: 'after',
                                options: {
                                    icon: 'close',
                                    onClick: () => {
                                        this.isPopoverVisible = false;
                                    }
                                }
                            }
                            ]"
         
        >
            <DxScrollView>
                <div class="application-help">
                    <div class="application-help-intro">
                        Στην Ενότητα Διαχείρισης Τηλεφωνίας ο χρήστης εισάγει τα σημεία τηλεφωνίας που διαχειρίζεται καθώς και όλους τους τηλεφωνικούς αριθμούς με τα βασικά τους στοιχεία  (Είδος γραμμής, πάροχος, Περιγραφή & ημερομηνία λήξης συμβολαίου κ.α.). Η εφαρμογή δίνει την δυνατότητα προβολής όλων των τηλεφωνικών αριθμών, διαθέσιμων και μη καθώς και χρήσιμων στοιχείων για τα router κάθε σημείου
                    </div>
                    <br />
                    <div class="application-help-content">
                        • Αρχικά ο χρήστης ορίζει ένα όνομα/χαρακτηριστικό της τηλεφωνίας για την οποία θα εισάγει στοιχεία π.χ. «Τηλεφωνία Αμαξοστάσιο». {Η κάθε τηλεφωνία έχει το δικό της «μοναδικό» Router. Αν σε ένα σημείο υπάρχουν δύο routers θα πρέπει να εισαχθούν δύο διαφορετικές ονομασίες τηλεφωνίας}.<br />
                        • Σημαντική θεωρείται η συμπλήρωση της ημερομηνίας λήξης προγράμματος συμβολαίου γιατί η εφαρμογή ενημερώνει στο Dashboard για τα προγράμματα που πλησιάζει η ημερομηνία λήξης τους.<br />
                        • Στην Ενότητα «Τηλεφωνικοί Αριθμοί», ο χρήστης εισάγει τους τηλεφωνικούς αριθμούς για την συγκεκριμένη τηλεφωνία. Εδώ δύναται να  υπάρχουν ένας οι και περισσότεροι αριθμοί ανάλογα με τον τύπο της τηλεφωνίας. <br />
                        • Η ενότητα «Στοιχεία Router» είναι μόνο για ανάγνωση. Εδώ ο χρήστης μπορεί να ελέγξει τα Βασικά Στοιχεία του Router, το οποίο χρησιμοποιείται στην τρέχουσα τηλεφωνία. {Τα στοιχεία σε αυτό τον πίνακα αντλούνται από το μενού «Εξοπλισμός/Hardware» - υπομενού «Router» ανάλογα με τις επιλογές στα πεδία «Χρήση σε Τηλεφωνία}.<br />
                    </div>
                </div>
            </DxScrollView>
        </DxPopover>

        <DxPopup
            :drag-enabled="false"
            :hide-on-outside-click="true"
            :show-close-button="true"
            :show-title="true"
            v-model:visible="isPopupVisible"
            title="Διαχείριση Τηλεφωνίας"
            :width="'90%'" 
            :height="'90%'"
            :toolbar-items="[
                            {
                                widget: 'dxButton',
                                location: 'after',
                                options: {
                                    icon: 'help', 
                                    onClick: () => {
                                        this.isPopoverVisible = !this.isPopoverVisible;
                                    },
                                    elementAttr: {
                                        id: 'help-button'
                                    }
                                }
                            }
                        ]"
        >
            <DxScrollView height="100%" width="100%">
                <TelephonyFormPage 
                    :telephony_id="selectedTelephonyID" 
                    @closePopupEvent="onClosePopupEmit">
                </TelephonyFormPage>
            </DxScrollView>
        </DxPopup>
            
        <DxDataGrid
            :key-expr="id"
            :data-source="CustomStoreMain"
            :toolbar="toolbarOptions"
            :columns="columns"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :show-borders="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :row-alternation-enabled="true"
            :focused-row-enabled="true"
            :selection-mode="'single'"
            :ref="dataGridRefKey"
            :word-wrap-enabled="true"

            @exporting="onExporting"
            @selectionChanged="onSelectionChanged"
            @init-new-row="onCustomInitNewRow"
        >
            <DxSelection mode="single" />
            <DxEditing
                :allow-updating="true"
                :allow-adding="true"
                :allow-deleting="true"
            />
            <DxToolbar>
                <DxItem location="after" 
                        widget="dxButton" 
                        :options="{
                            icon: 'add',
                            text: '',
                            hint: 'Προσθήκη',
                            onClick: () => {
                                this.onCustomInitNewRow()
                            }
                        }"

                />
                <DxItem name="exportButton" 
                    :options="{
                            hint: 'Εξαγωγή δεδομένων'

                        }"
                  />
            </DxToolbar>

            <DxPaging 
                :enabled="true"
                :page-size="5"
            />

            <DxFilterRow :visible="true"/>
            <DxHeaderFilter :visible="true"/>
            
            <DxPager
                :visible="true"
                :allowed-page-sizes="pageSizes"
                :display-mode="displayMode"
                :show-page-size-selector="true"
                :show-info="true"
                :show-navigation-buttons="true"
            />

            <DxGroupPanel :visible="false"/>

            <DxSorting mode="multiple"/>

            <DxColumn
                data-field="name"
                caption="Όνομα Τηλεφωνίας"
            >
            </DxColumn>

            <DxColumn
                data-field="building_id"
                caption="Κτιριακή Υποδομή"
                :hiding-priority="4"
                sort-order="asc"
            >
                <DxLookup
                    :data-source="LookupCustomDS_sims_buildings" 
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>

            <DxColumn
                data-field="sr_telecom_provider_id"
                caption="Πάροχος"
                :hiding-priority="3"
            >
            <DxLookup
                :data-source="LookupCustomDS_sims_sr_telecom_providers" 
                value-expr="id"
                display-expr="name"
                />
            </DxColumn>

            <DxColumn
                data-field="hw_phone_line_type_id"
                caption="Τύπος Γραμμής Τηλεφωνίας"
                :hiding-priority="2"
            >
            <DxLookup
                :data-source="LookupCustomDS_sims_hw_phone_line_types" 
                value-expr="id"
                display-expr="name"
                />
            </DxColumn>

            <DxColumn
                data-field="description"
                caption="Επιπλέον Πληροφορίες"
                :hiding-priority="0"
                :visible= "false"
            >
            </DxColumn>

            <DxColumn
                data-field="contract_description"
                caption="Περιγραφή Προγράμματος Συμβολαίου"
                :hiding-priority="1"
            >
            </DxColumn>

            <DxColumn
                data-field="contract_date_end"
                data-type="date"
                caption="Ημερομηνία Λήξης Προγράμματος"
                dataType="date"
                format="shortDate" 
                :hiding-priority="7"
            >
            </DxColumn>

            <DxColumn type="buttons">
                <DxButton 
                    text="Επεξεργασία"
                    icon="edit"
                    hint="Επεξεργασία"
                    :on-click="onCustomRowEdit"
                />
                <DxButton name="delete" hint="Διαγραφή"/>
            >
            </DxColumn>

            <DxExport
                :enabled="true"
                :formats="['pdf', 'xlsx']"
                :texts= "{
                        exportAll: 'Εξαγωγή σε {0}',
                    }"
            />

        </DxDataGrid>
    </div>
</template>

<script>

const DATAGRIDREFKEY = 'my-data-grid';

const URL_SERVICE = OParameters.Host.URI + 'sims_telephony';
const URL_SERVICE_LOOKUP_sims_buildings = OParameters.Host.URI + 'sims_buildings';
const URL_SERVICE_LOOKUP_sims_sr_telecom_providers = OParameters.Host.URI + 'sims_sr_telecom_providers';
const URL_SERVICE_LOOKUP_sims_hw_phone_line_types = OParameters.Host.URI + 'sims_hw_phone_line_types';

import DxDataGrid, {
    DxColumn,
    DxButton,
    DxEditing, 
    DxPaging,
    DxPager,
    DxGroupPanel,
    DxSorting,
    DxFilterRow,
    DxHeaderFilter,
    DxExport,
    DxSelection,
    DxLookup,
    DxToolbar,
    DxItem
} from 'devextreme-vue/data-grid';

import DxScrollView from 'devextreme-vue/scroll-view';
import DxPopup from 'devextreme-vue/popup';
import { DxPopover } from 'devextreme-vue/popover';

import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';

import TelephonyFormPage from './sims-telephony-form-page.vue';

import OParameters from '../sims-globals';

export default {
        components: {
            DxDataGrid,
            DxColumn,
            DxButton,
            DxEditing, 
            DxPaging,
            DxPager,
            DxGroupPanel,
            DxSorting,
            DxFilterRow, 
            DxHeaderFilter,
            DxExport,
            DxPopup,
            DxLookup,
            TelephonyFormPage,
            DxScrollView,
            DxSelection,
            DxToolbar,
            DxItem,
            DxPopover
    },
    data() {
        return {
            IPPattern: "^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$",
            CustomStoreMain: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE),
                insert: (values) => this.sendRequest(URL_SERVICE, 'POST', values),
                update: (key, values) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
            }),

            LookupCustomDS_sims_buildings:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_buildings+ '?order=name'),
            }),

            LookupCustomDS_sims_sr_telecom_providers:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_sr_telecom_providers+ '?order=name'),
            }),

            LookupCustomDS_sims_hw_phone_line_types:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_phone_line_types+ '?order=name'),
            }),

            isPopupVisible: false,
            isPopoverVisible: false,

            //selectedTelephonyID: 21,
            title: 'Τηλεφωνία',
            pageSizes: [5, 10, 20],
            dataGridRefKey: DATAGRIDREFKEY,
            toolbarOptions: {
                items: [
                    {
                        widget: 'dxButton',
                        options: {
                            icon: 'add',
                            text: 'Προσθηκη',
                            onClick: () => {
                                this.onCustomInitNewRow()
                            }
                        }
                    }
                ]
            }
        };
    }, 
    
    methods: {
        // 
        //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
        //
        sendRequest(url, method = 'GET', values = {}) {
            // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then((response) => response.json()
                .then((json) => {
                    if (response.ok) return json.records;
                    throw json.Message;
                }));
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
        },
        onSelectionChanged(e) {
            this.selectedTelephonyID = e.currentSelectedRowKeys[0];
        },
        onClosePopupEmit() {
            this.isPopupVisible = false;
            this.dataGrid.refresh();
        },
        onOpenForm(e) {
          this.$refs.form.open(e.data);
        },
        onCustomInitNewRow() {
            this.isPopupVisible = true;
            this.selectedTelephonyID = null;
        },
        onCustomRowEdit(e) {
            this.selectedTelephonyID = e.row.key;
            this.isPopupVisible = true;
        },
        onExporting (e){
            OParameters.methods.exportDataGridToPdfExcel(e, this.title)
        },
    },
    computed: {
        dataGrid: function() {
            return this.$refs[DATAGRIDREFKEY].instance;
        }
    },
};
</script>

<style lang="scss">
</style>
