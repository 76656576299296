<template>
    <div>
    <h3 class="content-block openware-form-title">{{ title }}</h3>
    <p>{{ data }}</p>
    <DxDataGrid
        :key-expr="id"
        :data-source="CustomStoreMain"
        :columns="columns"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :focused-row-enabled="true"
        ref="dataGrid"        
        @exporting="onExporting"
    >
        <DxPaging 
            :enabled="true"
            :page-size="5"
        />

        <DxFilterRow :visible="true"/>

        <DxHeaderFilter :visible="true"/>

        <DxPager
            :visible="true"
            :allowed-page-sizes="pageSizes"
            :display-mode="displayMode"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
        />

        <DxGroupPanel :visible="false"/>

        <DxSorting mode="multiple"/>

        <DxEditing
            :allow-updating="true"
            :allow-adding="true"
            :allow-deleting="true"
            :texts= "{
                    addRow: 'Προσθήκη',
                    deleteRow: 'Διαγραφή',
                    editRow:'Επεξεργασία',
                    saveAllChanges:'Αποθήκευση Όλων',
                    cancelAllChanges:'Ακύρωση Όλων'
                }"
            mode="batch"
        >
        </DxEditing>

        <DxColumn
            data-field="name"
            caption="Όνομα"
            sort-order="asc"
        >
            <DxRequiredRule/>
        </DxColumn>

        <!--
        <DxColumn
            data-field="description"
            caption="Επιπλέον Πληροφορίες"
        >
        </DxColumn>
        -->

        <DxColumn
            data-field="address"
            caption="Διεύθυνση"
        >
        </DxColumn>

        <DxExport
            :enabled="true"
            :formats="['pdf', 'xlsx']"
            :texts= "{
                    exportAll: 'Εξαγωγή σε {0}',
                }"
        />

    </DxDataGrid>
</div>
</template>

<script>
    import {
        DxDataGrid,
        DxColumn,
        DxEditing, 
        DxPaging, 
        DxPager,
        DxGroupPanel,
        DxSorting,
        DxFilterRow,
        DxHeaderFilter,
        DxExport,
        DxRequiredRule,
        //DxToolbar,
        //DxItem
    } from 'devextreme-vue/data-grid'; 
    import 'whatwg-fetch';
    import CustomStore from 'devextreme/data/custom_store';
        
    import OParameters from '../sims-globals';

    //import { exportDataGrid } from 'devextreme/pdf_exporter';

    const URL_SERVICE = OParameters.Host.URI + 'sims_buildings';

export default {
            components: {
                DxDataGrid,
                DxColumn,
                DxEditing, 
                DxPaging,
                DxPager,
                DxGroupPanel,
                DxSorting,
                DxFilterRow,
                DxHeaderFilter,
                DxExport,
                DxRequiredRule,
                //DxToolbar,
                //DxItem
        },
        data() {
            return {
                CustomStoreMain: new CustomStore({
                    key: 'id',
                    //loadMode: 'raw',
                    load: () => this.sendRequest(URL_SERVICE),
                    insert: (values) => this.sendRequest(URL_SERVICE, 'POST', values),
                    update: (key, values) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
                    remove: (key) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
                    byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
                }),
                title: 'Κτιριακή Υποδομή',
                pageSizes: [5, 10, 20],
            }
        },
        methods: {
            // 
            //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
            //
            sendRequest(url, method = 'GET', values = {}) {
                // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
                if (method === 'GET') {
                    return fetch(url, {
                        method,
                        credentials: 'include',
                    })
                    .then((response) => response.json()
                    .then((json) => {
                        if (response.ok) return json.records;
                        throw json.Message;
                    }));
                }
                return fetch(url, {
                    method,
                    body: JSON.stringify(values),
                    headers: {
                        'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    },
                    credentials: 'include',
                }).then((result) => {
                    if (result.ok) {
                        return result.text().then((text) => text && JSON.parse(text));
                    }
                    return result.json().then((json) => {
                        throw json.Message;
                    });
                });
            },
            onExporting (e){
                OParameters.methods.exportDataGridToPdfExcel(e, this.title)
            }
        },
    };
</script>
<style lang="scss">
</style>
