<template>
  <div id="container">
      <h3 class="content-block openware-form-title">{{ title }}</h3>
      <DxPopup
          :drag-enabled="false"
          :hide-on-outside-click="true"
          :show-close-button="true"
          :show-title="true"
          :columns="columns"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          title="Διαχείριση Αιτήματος//Ημερολόγιου εργασιών"
          :width="'90%'" 
          :height="'90%'"

          v-model:visible="isPopupVisible"
          titleTemplate="title"
          deferRendering="true"
          :animation="{
                        show: {
                            type: 'pop',
                            duration: 800,
                            from: {
                                scale: 0.90
                            }
                        }, 
                      }"
      >
          <DxScrollView height="100%" width="100%">
              <RequestFormPage
                  ref="requestFormPage"
                  :request_id="selectedRequestID" 
                  @closePopupEvent="onClosePopupEmit">
              </RequestFormPage>
          </DxScrollView>

      </DxPopup>
          
      <DxDataGrid
          :key-expr="id"
          :data-source="CustomStoreMain"
          :columns="columns"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          :selection-mode="'single'"
          :ref="dataGridRefKey"          
          :word-wrap-enabled="true"

          @exporting="onExporting"
          @selectionChanged="onSelectionChanged"
          @init-new-row="onCustomInitNewRow"
      >
          <DxSelection mode="single" />
          <DxEditing
              :allow-deleting="true"
              mode="row"
          />


            <DxToolbar>
                <DxItem location="after" 
                        widget="dxButton" 
                        :options="{
                            icon: 'add',
                            text: '',
                            hint: 'Προσθήκη',
                            onClick: () => {
                                this.onCustomInitNewRow()
                            }
                        }"

                />
                <DxItem name="exportButton" 
                    :options="{
                            hint: 'Εξαγωγή δεδομένων'

                        }"
                  />
            </DxToolbar>

          <DxPaging 
              :enabled="true"
              :page-size="5"
          />

          <DxFilterRow :visible="true"/>
          <DxHeaderFilter :visible="true" :word-wrap-enabled="true"/>
          
          <DxPager
              :visible="true"
              :allowed-page-sizes="pageSizes"
              :display-mode="displayMode"
              :show-page-size-selector="true"
              :show-info="true"
              :show-navigation-buttons="true"
          />

          <DxGroupPanel :visible="false"/>

          <DxSorting mode="multiple"/>

          <DxColumn
            data-field="request_completed_ind"
            caption="Ολοκληρωμένο"
          >
          </DxColumn>

          <DxColumn
              data-field="name" 
              caption="Περιγραφή Αιτήματος"
          >
          </DxColumn>

          <!--
          <DxColumn
              data-field="entry_date"
              caption="Ημερομηνία Εισαγωγής"
          >
          </DxColumn>
          -->

          <DxColumn
              data-field="request_employment_staff_id"
              caption="Υπάλληλος Αίτηματος"
              
            :hiding-priority="120"
          >
              <DxLookup
                  :data-source="LookupCustomDS_sims_employment_staff" 
                  value-expr="id"
                  display-expr="name"
              />
          </DxColumn>

          <DxColumn
            data-field="request_phone_telephony_numbers_id"
            caption="Τηλέφωνο υπαλλήλου"
            :width="150"
            :hiding-priority="110"
          >
            <DxLookup
                :data-source="LookupCustomDS_sims_telephony_numbers"
                value-expr="id"
                display-expr="phone_no"
            />
          </DxColumn>

          <DxColumn
              data-field="request_email_1"
              caption="Εmail Υπαλλήλου"
              :hiding-priority="90"
          >
          </DxColumn>

          <DxColumn
              data-field="assigned_employment_staff_id"
              caption="Υπάλληλος Υλοποίησης"
              :hiding-priority="80"
          >
              <DxLookup
                  :data-source="LookupCustomDS_sims_employment_staff" 
                  value-expr="id"
                  display-expr="name"
              />
          </DxColumn>

          <!--
          <DxColumn
              data-field="description"
              caption="Περιγραφή Αιτήματος"
              :hiding-priority="70"
          >
          </DxColumn>
          -->

          <DxColumn type="buttons">
              <DxButton 
                  text="Επεξεργασία"
                  icon="edit"
                  hint="Επεξεργασία"
                  :on-click="onCustomRowEdit"
              />
              <DxButton name="delete" hint="Διαγραφή"/>
          >
          </DxColumn>


        <DxExport
            :enabled="true"
            :formats="['pdf', 'xlsx']"
            :texts= "{
                    exportAll: 'Εξαγωγή σε {0}',
                }"
        />

      </DxDataGrid>
  </div>
</template>

<script>

const DATAGRIDREFKEY = 'my-data-grid';

const URL_SERVICE = OParameters.Host.URI + 'sims_requests';
const URL_SERVICE_LOOKUP_sims_request_activities = OParameters.Host.URI + 'sims_request_activities';
const URL_SERVICE_LOOKUP_sims_system_users = OParameters.Host.URI + 'sims_system_users';
const URL_SERVICE_LOOKUP_sims_employment_staff = OParameters.Host.URI + 'sims_employment_staff_VIEW';

const URL_SERVICE_LOOKUP_sims_telephony_numbers = OParameters.Host.URI + 'sims_telephony_numbers';

import DxDataGrid, {
  DxColumn,
  DxButton,
  DxEditing, 
  DxPaging,
  DxPager,
  DxGroupPanel,
  DxSorting,
  DxFilterRow,
  DxHeaderFilter,
  DxExport,
  DxSelection,
  DxLookup,DxToolbar, DxItem
} from 'devextreme-vue/data-grid';

import DxScrollView from 'devextreme-vue/scroll-view';
import DxPopup from 'devextreme-vue/popup';

import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';

import RequestFormPage from './sims-requests-form-page.vue';

import OParameters from '../sims-globals';

export default {
      components: {
          DxDataGrid,
          DxColumn,
          DxButton,
          DxEditing, 
          DxPaging,
          DxPager,
          DxGroupPanel,
          DxSorting,
          DxFilterRow, 
          DxHeaderFilter,
          DxExport,
          DxPopup,
          DxLookup,
          RequestFormPage,
          DxScrollView,
          DxSelection,DxToolbar, DxItem,
  },
  data() {
      return {
          IPPattern: "^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$",
          CustomStoreMain: new CustomStore({
              key: 'id',
              //loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE + '?order=id,desc'),
              insert: (values) => this.sendRequest(URL_SERVICE, 'POST', values),
              update: (key, values) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
              remove: (key) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
              byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
          }),

          LookupCustomDS_sims_request_activities: new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_request_activities + '?order=name'),
          }),

          LookupCustomDS_sims_employment_staff:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_employment_staff + '?order=name'),
          }),

          LookupCustomDS_sims_system_users: new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_system_users + '?order=name'),
          }),

          LookupCustomDS_sims_telephony_numbers: new CustomStore({
              key: 'id',
              //loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_telephony_numbers + '?order=name'),
              byKey: (key) => this.sendRequest(URL_SERVICE_LOOKUP_sims_telephony_numbers + "/" + encodeURIComponent(key)),
          }),


          isPopupVisible: false,
          isPopoverVisible: false,
          selectedRequestID: null,
          title: 'Αιτήματα/Ημερολόγιο εργασιών',
          pageSizes: [5, 10, 20],
          dataGridRefKey: DATAGRIDREFKEY,
      };
  }, 
  
  methods: {
        // 
        //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
        //
        sendRequest(url, method = 'GET', values = {}) {
            // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then((response) => response.json()
                .then((json) => {
                    if (response.ok) return json.records;
                    throw json.Message;
                }));
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
        },
        onSelectionChanged(e) {
            this.selectedRequestID = e.currentSelectedRowKeys[0];
        },
        onClosePopupEmit() {
            this.isPopupVisible = false;
            this.dataGrid.refresh();
        },
        onCustomInitNewRow() {
            this.isPopupVisible = true;
            this.$refs.requestFormPage.loadFormData(this.selectedRequestID)
            this.selectedRequestID = null;
        },
        onCustomRowEdit(e) {
            this.selectedRequestID = e.row.key
            this.isPopupVisible = true
        },
        onExporting (e){
            OParameters.methods.exportDataGridToPdfExcel(e, this.title)
        },
  },
  computed: {
      dataGrid: function() {
          return this.$refs[DATAGRIDREFKEY].instance;
      }
  },
};
</script>

<style lang="scss">
</style>
