<template>
  <div id="container">
      <h3 class="content-block openware-form-title">{{ title }}</h3>

      <DxDataGrid
          :key-expr="id"
          :data-source="CustomStoreMain"
          :columns="columns"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          :selection-mode="'single'"
          :ref="dataGridRefKey"
          :allow-export-selected-data="true"
          :word-wrap-enabled="true"

          @exporting="onExporting"
      >
          <DxSelection mode="single" />

          <DxPaging 
              :enabled="true"
              :page-size="5"
          />
            <DxToolbar>
                <DxItem name="exportButton" 
                    :options="{
                          hint: 'Εξαγωγή δεδομένων'
                    }"
                  />
            </DxToolbar>

          <DxFilterRow :visible="true"/>
          <DxHeaderFilter :visible="true"/>
          
          <DxPager
              :visible="true"
              :allowed-page-sizes="pageSizes"
              :display-mode="displayMode"
              :show-page-size-selector="true"
              :show-info="true"
              :show-navigation-buttons="true"
          />

          <DxGroupPanel :visible="false"/>

          <DxSorting mode="multiple"/>


          <DxColumn
              data-field="assigned_employment_staff_id"
              caption="Ονοματεπώνυμο" 
              sort-order="asc"
              :hiding-priority="15"
          >
            <DxLookup
                :data-source="LookupCustomDS_sims_employment_staff_VIEW"
                value-expr="id"
                display-expr="name_surname"
              />
          </DxColumn>

          <DxColumn
              data-field="employment_relationship_id"
              caption="Σχέση εργασίας"
              :hiding-priority="15"
          >
            <DxLookup
                :data-source="LookupCustomDS_sims_employment_relationships"
                value-expr="id"
                display-expr="name"
              />
          </DxColumn>

          <DxColumn
              data-field="enddate"
              caption="Ημερομηνία Λήξης Υπηρεσίας"
              dataType="date"
              format="shortDate" 
          >
          </DxColumn>

          <DxColumn
              data-field="code_model_name"
              caption="Κωδικός Αναγνώρισης Συσκευής (Μοντέλο)"
          >
          </DxColumn>

          <DxExport
            :enabled="true"
            :formats="['pdf', 'xlsx']"
            :texts= "{
                    exportAll: 'Εξαγωγή σε {0}',
                }"
          />
     </DxDataGrid>
  </div>
</template>

<script>

const DATAGRIDREFKEY = 'my-data-grid';

const URL_SERVICE = OParameters.Host.URI + 'sims_hw_2_employment_staff_VIEW';
const URL_SERVICE_LOOKUP_sims_employment_relationships = OParameters.Host.URI + 'sims_employment_relationships';
const URL_SERVICE_LOOKUP_sims_employment_staff_VIEW = OParameters.Host.URI + 'sims_employment_staff_VIEW';


const URL_SERVICE_LOOKUP_sims_employment_capacities = OParameters.Host.URI + 'sims_employment_capacities';

const URL_SERVICE_LOOKUP_sims_telephony_numbers = OParameters.Host.URI + 'sims_telephony_numbers';

import DxDataGrid, {
        DxColumn,
        DxPaging,
        DxPager,
        DxGroupPanel,
        DxSorting,
        DxFilterRow,
        DxHeaderFilter,
        DxExport,
        DxSelection,
        DxLookup,
        DxToolbar,
        DxItem
} from 'devextreme-vue/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';

import OParameters from '../sims-globals';

export default {
      components: {
          DxDataGrid,
          DxColumn,
          DxPaging,
          DxPager,
          DxGroupPanel,
          DxSorting,
          DxFilterRow, 
          DxHeaderFilter,
          DxExport,
          DxLookup,
          DxSelection,
          DxToolbar,
          DxItem,
  },
  data() {
      return {
          IPPattern: "^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$",
          CustomStoreMain: new CustomStore({
              key: 'id',
              //loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE),
              insert: (values) => this.sendRequest(URL_SERVICE, 'POST', values),
              update: (key, values) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
              remove: (key) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
              byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
          }),

          LookupCustomDS_sims_employment_relationships:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_employment_relationships+ '?order=name'),
          }),

          LookupCustomDS_sims_employment_capacities:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_employment_capacities+ '?order=name'),
          }),

          LookupCustomDS_sims_employment_staff_VIEW:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_employment_staff_VIEW+ '?order=name2'),
          }),

          LookupCustomDS_sims_telephony_numbers:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_telephony_numbers+ '?order=name'),
          }),

          isPopupVisible: false,
          isPopoverVisible: false,
          selectedΕmploymentStaffID: null,
          title: 'Προβολή Εξοπλισμού Ανά Υπάλληλο',
          pageSizes: [5, 10, 20],
          dataGridRefKey: DATAGRIDREFKEY,
      };
  }, 
  
  methods: {
        // 
        //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
        //
        sendRequest(url, method = 'GET', values = {}) {
            // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then((response) => response.json()
                .then((json) => {
                    if (response.ok) return json.records;
                    throw json.Message;
                }));
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
        },
        onExporting (e){
            OParameters.methods.exportDataGridToPdfExcel(e, this.title, [])
        },
  },
  computed: {
      dataGrid: function() {
          return this.$refs[DATAGRIDREFKEY].instance;
      }
  },
};
</script>

<style lang="scss">
</style>
