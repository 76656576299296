<template>
  <div>    
    <div class="widget-container">
     
      <DxForm
            id="form"
            :form-data="state.formData"
            label-mode='outside'
            :read-only='false'
            :show-colon-after-label='true'
            label-location='top'
            :min-col-width='300'
            :col-count='2'
            onInitialized="onInitialized"
            ref="formMaster"
      >

        <DxColCountByScreen :xs="1" :sm="1" :md="1" :lg="1"/>

        <DxGroupItem caption="Βασικά Στοιχεία" :col-span="3" :col-count="3">

            <!--
                <DxSimpleItem data-field="id"/>
            -->

            <DxSimpleItem
                data-field="entry_date"
                editor-type="dxDateBox"
                :editor-options="dateBoxEditorOptions"
            >
                <DxLabel text="Ημερομηνία Εισαγωγής"/>
            </DxSimpleItem>

            <DxSimpleItem 
                data-field="name"
            >
                <DxLabel text="Περιγραφή Αιτήματος"/>
                <DxRequiredRule
                    message="Υποχρεωτικό Πεδίο"
                />
            </DxSimpleItem>
            
            <DxSimpleItem
                data-field="request_employment_staff_id"
                editor-type="dxSelectBox"
                searchEnabled = true
                :editor-options="{
                    dataSource: state.LookupCustomDS_sims_employment_staff,
                    searchEnabled: true,
                    displayExpr: 'name',
                    valueExpr:'id',
                    onValueChanged: this.onRequestEmploymentStaffIDChange
                }"
            >
                <DxLabel text="Υπάλληλος Αίτηματος"/>

                <DxRequiredRule
                    message="Υποχρεωτικό Πεδίο"
                />

            </DxSimpleItem>

            <DxSimpleItem
                data-field="request_phone_telephony_numbers_id"
                editor-type="dxSelectBox"
                :editor-options="{
                    dataSource: state.LookupCustomDS_sims_telephony_numbers,
                    searchEnabled: true,
                    valueExpr:'id',
                    displayExpr: 'phone_no',
                    readOnly: true
                }"
            >
                <DxLabel text="Τηλέφωνο υπαλλήλου"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="request_email_1"
                :editor-options="{
                    readOnly: true
                }"
            >
                <DxLabel text="Εmail Υπαλλήλου"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="request_completed_ind"
                editor-type="dxCheckBox"
            >
                <DxLabel text="Ολοκληρωμένο"/>
            </DxSimpleItem>

            
        </DxGroupItem>


        <DxGroupItem caption="Ανάθεση υλοποίησης αιτήματος" :col-span="3" :col-count="3">
            <DxSimpleItem
                data-field="assigned_employment_staff_id"
                editor-type="dxSelectBox"
                :editor-options="{
                    dataSource: state.LookupCustomDS_sims_employment_staff,
                    searchEnabled: true,
                    displayExpr: 'name',
                    valueExpr:'id'
                }"
            >
                <DxLabel text="Υπάλληλος"/>
            </DxSimpleItem>

        </DxGroupItem>

        <DxGroupItem caption="Επιπλέον Πληροφορίες">
            <DxSimpleItem
                data-field="description"
                editor-type="dxTextArea"
                :editor-options="{placeholder: 'Προσθήκη σχόλιου...', height: 75, rows: 10}"
            >
                <DxLabel :visible="false" text="Επιπλέον Πληροφορίες"/>
            </DxSimpleItem>
        </DxGroupItem>

      </DxForm>

      <div class="dx-fieldset-header">Δρομολόγηση Αιτήματος</div>
      <div class="dx-fieldset">
          <DxDataGrid
              :data-source="state.CustomStoreDetail_sims_request_activities"
              :key-expr="id"
              :columns="columns"
              :allow-column-reordering="true" :allow-column-resizing="true" :column-auto-width="true"
              :show-borders="true" :show-column-lines="true" :show-row-lines="true"
              :row-alternation-enabled="true" :focused-row-enabled="true" :width="gridWidth"
              :style="{ 'max-width': maxWidth }"
              ref="gridDetail_sims_request_activities"
              @exporting="onExporting"
              @rowClick="openForm"
              @init-new-row="onInitNewRow"
              @row-validating="onRowValidating">
          >
              <DxPaging :enabled="true" :page-size="5"/>

              <DxFilterRow :visible="false"/>
              <DxHeaderFilter :visible="false"/>
              <DxPager :visible="true" :allowed-page-sizes="pageSizes" :display-mode="displayMode" :show-info="true" :show-navigation-buttons="true"/>
              <DxGroupPanel :visible="false"/>
              <DxSorting mode="multiple"/>
              <DxEditing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="row"/>

              <DxColumn data-field="entry_date" caption="Ημερομηνία" :allow-editing="false" dataType="date" :format="{ type: 'shortDate' }">
                  <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
              </DxColumn>

              <DxColumn data-field="description" caption="Περιγραφή ενεργειών">
                  <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
              </DxColumn>

              <DxColumn
                  data-field="assigned_employment_staff_id"
                  caption="Υπάλληλος"
              >
                  <DxLookup
                      :data-source="state.LookupCustomDS_sims_employment_staff" 
                      value-expr="id"
                      display-expr="name"
                  />
                  <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
              </DxColumn>

          </DxDataGrid>
    </div>

      <div id="buttons">
          <DxToolbar>
              <DxItem 
                  location="after"
                  widget="dxButton"
                  @click="onClickSave"
                  :options="{
                      icon: 'save',
                      text: 'Αποθηκευση'
                  }"
              >
              </DxItem>
              <DxItem 
                  location="after" 
                  widget="dxButton"
                  @click="onClickClose"
                  :options="{
                      icon: 'close',
                      text: 'Κλεισιμο'
                  }"
                  >
              </DxItem>
          </DxToolbar>
    </div>
    </div>
</div>
</template>

<script>

  //Form
  const URL_SERVICE = OParameters.Host.URI + 'sims_requests';  
  const URL_SERVICE_LOOKUP_sims_system_users = OParameters.Host.URI + 'sims_system_users';
  const URL_SERVICE_LOOKUP_sims_employment_staff = OParameters.Host.URI + 'sims_employment_staff_VIEW';
  const URL_SERVICE_LOOKUP_sims_telephony_numbers = OParameters.Host.URI + 'sims_telephony_numbers';

  //Grids
  //sims_request_activities
  const URL_SERVICE_sims_request_activities = OParameters.Host.URI + 'sims_request_activities';
  
  //SEQUENCE
  const URL_SERVICE_seq_general_VIEW = OParameters.Host.URI + 'seq_general_VIEW';

  import CustomStore from 'devextreme/data/custom_store';

  import { reactive, onMounted, computed} from 'vue';
  import 'whatwg-fetch';

  import OParameters from '../sims-globals';

  import {
      DxForm, 
      DxLabel,
      DxSimpleItem, 
      //DxRequiredRule,
      DxGroupItem,
      DxColCountByScreen,
  } from 'devextreme-vue/form'; 

  import {
        DxRequiredRule,
        //DxAsyncRule,
  } from 'devextreme-vue/validator';

  import DxDataGrid, {
      DxColumn,
      DxEditing, 
      DxPaging,
      DxPager,
      DxGroupPanel,
      DxSorting,
      DxFilterRow,
      DxHeaderFilter,
      DxLookup,
  } from 'devextreme-vue/data-grid';

  import {DxItem} from 'devextreme-vue/tab-panel';
  import DxToolbar from 'devextreme-vue/toolbar';

  export default {
      name: 'RequestFormPage',
      components: {
          DxForm,
          DxLabel,
          DxSimpleItem,
          DxRequiredRule,
          DxDataGrid,
          DxColumn,
          DxEditing,
          DxPaging,
          DxPager,
          DxGroupPanel,
          DxSorting,
          DxFilterRow,
          DxHeaderFilter,
          DxLookup,
          DxToolbar,
          DxItem,
          DxColCountByScreen,
          DxGroupItem,
          //DxAsyncRule
      },
    data() {
        return {
            title: "Διαχείριση Αιτήματος/Ημερολόγιου εργασιών",
            calendarOptions: { value: new Date() },
            textAreaOptions: { placeholder: 'Προσθήκη σχολίου..'},
            pageSizes: [5, 10, 20],
            //dataGridRefKey: DATAGRIDREFKEY,
            dateBoxEditorOptions: {
                //pickerType: 'rollers',
                type: 'date',
                displayFormat: 'dd/MM/yyyy',
                dateSerializationFormat: 'yyyy-MM-dd',
                invalidDateMessage: 'Λάθος Ημερομηνία',
                showClearButton: true,
                //min: new Date(),
                max: new Date(2030, 11, 31)
            }
        }
    },
    props: {
      request_id: {
          type: Number,
          required: true
      }
    },
    watch: {
      request_id(newValue, oldValue) {
          this.$emit('request-id-value-changed', newValue, oldValue);
          this.loadFormData(newValue);
      }
    },
    setup(props) {
      const gridWidth = computed(() => '100%'); // set the width to 100%
      const maxWidth = computed(() => '800px'); // set the max-width to 800px
      const state = reactive({
          formData: {},

          LookupCustomDS_sims_system_users:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE_LOOKUP_sims_system_users + '?order=name'),
          }),

          LookupCustomDS_sims_employment_staff:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE_LOOKUP_sims_employment_staff + '?order=name'),
              byKey: (key) => sendRequestByKey(URL_SERVICE_LOOKUP_sims_employment_staff + "/" + encodeURIComponent(key)),
          }),

          LookupCustomDS_sims_telephony_numbers:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE_LOOKUP_sims_telephony_numbers + '?order=phone_no'),
          }),

          CustomStoreDetail_sims_request_activities: new CustomStore({
              key: 'id',
              //loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE_sims_request_activities + '?filter=request_id,eq,' + state.formData.id /*props.hw_pc_desktop_device_id*/),
              insert: (values) => sendRequest(URL_SERVICE_sims_request_activities, 'POST', values),
              update: (key, values) => sendRequest(URL_SERVICE_sims_request_activities + '/' + encodeURIComponent(key), 'PUT', values),
              remove: (key) => sendRequest(URL_SERVICE_sims_request_activities + '/' + encodeURIComponent(key), 'DELETE'),
              byKey: (key) => sendRequest(URL_SERVICE_sims_request_activities + "/" + encodeURIComponent(key)),
          }),

          CustomStore_seq_general_VIEW:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                cacheRawData: 'false',
                load: () => sendRequest(URL_SERVICE_seq_general_VIEW),
          }),

          CustomStoreMain: new CustomStore({
              key: 'id',
              //loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE),
              insert: (values) => sendRequest(URL_SERVICE, 'POST', values),
              update: (key, values) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
              remove: (key) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
              byKey: (key) => sendRequestByKey(URL_SERVICE + "/" + encodeURIComponent(key)),
          })
      });

      // 
      //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
      //
      const sendRequest = (url, method = 'GET', values = {}) => {
            //console.log('sendRequest LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then(response => response.json())
                .then(json => {
                    return json.records;
                })
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
      };

      const sendRequestByKey = (url, method = 'GET') => {
          //console.log(' sendRequestByKey LOG: ' + url + ' METHOD: ' + method);
          return fetch(url, {
                    method,
                    headers: {
                        'Accept': 'application/json',
                    },
                    credentials: 'include',
                })
                .then(response => response.json())
                .then(json => {
                    // convert string to array in order to prepare the field for dxTagBox
                    //json.json_2_connection_types = JSON.parse(json.json_2_connection_types);
                    return json;
                })
      };

      const saveData = async () => {
            let data2store = {...state.formData}
            //data2store.json_2_connection_types = JSON.stringify(data2store.json_2_connection_types)
            await state.CustomStoreMain.update(state.formData.id, data2store);
      };

      const insertData = async () => {
            let data2store = {...state.formData}
            //data2store.json_2_connection_types = JSON.stringify(data2store.json_2_connection_types)
            await state.CustomStoreMain.insert(data2store)
      };

      const clearForm = () => {
          state.formData = {};
      };

      var isGridValidated = true;
      //var initialCodeValue
      var isInsertMode = false
  
      onMounted(async () => {
          if (props.request_id != null) state.formData = await state.CustomStoreMain.byKey(props.request_id);
      });

      return {
        saveData,
        insertData,
        clearForm,
        gridWidth,
        maxWidth,
        state,
        isGridValidated,
        //initialCodeValue,
        isInsertMode
      };
    },
    methods: {

        async loadFormData(id) {
            //
            //  nf 28/6/2023 
            //  init disposal value in order to invoke onchange event which disables dispose field
            //
            //  this.$refs.formMaster.instance.getEditor('device_disposal_ind').option('value', true)
            //

            if (id != null) {
                this.isInsertMode = false
                
                this.state.formData = await this.state.CustomStoreMain.byKey(id)
            } else {
                this.getNextVal().then(value => {
                    this.isInsertMode = true
                    this.state.formData = {entry_date: new Date().toISOString().split('T')[0]}

                    //this.state.formData.json_2_connection_types = []
                    //this.state.formData = {entry_date: new Date()}
                    this.state.formData.id = value
                });
            }
            await this.$refs.gridDetail_sims_request_activities.instance.refresh();
        },

        async onRequestEmploymentStaffIDChange (e) {
            if (e.event && (e.event.type === 'dxclick' || e.event.type === 'keydown')) {
                // Handle the value change
                let staffrow = await this.state.LookupCustomDS_sims_employment_staff.byKey(e.value)

                this.$refs.formMaster.instance.updateData('request_phone_telephony_numbers_id', staffrow.phone_telephony_numbers_id)
                this.$refs.formMaster.instance.updateData('request_email_1', staffrow.email_1)

            }
        },

        onDeviceDisposalIndValueChanged (e) {
            this.$refs.formMaster.instance.getEditor('device_disposal_reason').option('disabled', (!e.value == true))
            this.$refs.formMaster.instance.getEditor('device_disposal_date').option('disabled', (!e.value == true))

            if (e.value == true) {
                this.$refs.formMaster.instance.updateData('assigned_employment_staff_id', null)
                this.$refs.formMaster.instance.updateData('assigned_date', null)
                this.$refs.formMaster.instance.updateData('network_ip_id', null)
            }
        },

        /*
        async validateIsUniqueCode(params) {
            const row = await this.state.LookupValidateCustomDS_sims_hw_device_codes_VIEW.byKey(params.value)
            return new Promise((resolve) => {
                if (this.initialCodeValue != params.value) {
                    resolve(row.id === undefined);
                } else {
                    resolve(true)
                }
            });
        },
        */

        async getNextVal() {
            //console.log('getNextVal')
            this.state.CustomStore_seq_general_VIEW.clearRawDataCache()
            let row = await this.state.CustomStore_seq_general_VIEW.load()
            return row[0].nextval
        },

        onClickSave: async function() {
            const formValidator = this.dataForm.validate();
            this.isGridValidated = true
            let formSaved = false

            if (formValidator.isValid) {
                if (!this.isInsertMode) {
                    await this.saveData()
                    formSaved = true
                } else {
                    await this.insertData()
                    formSaved = true
                }
            }

            await this.dataGrid_sims_request_activities.saveEditData()
            if (this.isGridValidated && formSaved) this.$emit('closePopupEvent', true)

        },
        onClickClose: function() {
            this.$emit('closePopupEvent', true);
        },
        onInitNewRow(row) {
            row.data.request_id = this.state.formData.id;
            row.data.entry_date = new Date().toISOString().split('T')[0];
        },
        onInitialized(e) {
            e.component.option('selectedItemKeys', [])
        },
        onRowValidating(e) {
            this.isGridValidated = this.isGridValidated && e.isValid;
        },
    },
    computed: {
        dataGrid_sims_request_activities: function() {
            return this.$refs.gridDetail_sims_request_activities.instance;
        },

        dataForm: function() {
            return this.$refs.formMaster.instance;
        },
    }
};
</script>

<style lang="scss">

</style>

<style scoped>

#form {
   margin-top: 25px;
}
.caption {
    font-size: 18px;
    font-weight: 500;
}
.option {
    margin-top: 10px;
}
.dx-fieldset-header {
    font-size: 16px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-top: 4px;
}
</style>
