<template>
  <div id="container">
      <h3 class="content-block openware-form-title">{{ title }}</h3>
      <DxPopover
            :shading="true"
            width="70%"
            target="#help-button"
            position="top"
            shading-color="rgba(0, 0, 0, 0.5)"
            v-model:visible="isPopoverVisible"
            :show-title="true"
            title="Βοήθεια: Φόρμα «Διαχείριση Συσκευής Σταθερής Τηλεφωνίας»"
            :toolbar-items="[{
                                widget: 'dxButton',
                                location: 'after',
                                options: {
                                    icon: 'close',
                                    onClick: () => {
                                        this.isPopoverVisible = false;
                                    }
                                }
                            }
                            ]"
         
        >
            <DxScrollView>
                <div class="application-help">
                    <div class="application-help-intro">
                        Στην Ενότητα Διαχείριση Συσκευών Τηλεφωνίας, ο χρήστης εισάγει και τροποποιεί στοιχεία που αφορούν τις συσκευές τηλεφωνίας  που διαθέτει η υπηρεσία. Η κάθε συσκευή παραχωρείται προς χρήση και συνδέεται με αντίστοιχο υπάλληλο και από αυτά τα στοιχεία-δεδομένα ενημερώνονται σχετικοί πίνακες στην καρτέλα προσωπικού
                    </div>
                    <br />
                    <div class="application-help-content">
                        • Αρχικά ο χρήστης ορίζει έναν κωδικό αναγνώρισης συσκευής. Έναν μοναδικό κωδικό, ο οποίος ορίζεται από την υπηρεσία, για εσωτερική χρήση και συγκεκριμένα για τον διαχωρισμό της συσκευής σε όλο το σύνολο του εξοπλισμού.<br />
                        • Ο χρήστης αφού επιλέξει μοντέλο, ενημερώνονται αυτόματα κάποια από τα πεδία της φόρμας αντλώντας στοιχεία από τους αντίστοιχους πίνακες παραμετροποίησης.<br />
                        • Αν πρόκειται για IP Τηλέφωνο, ο χρήστης καλείται να συμπληρώσει τα αντίστοιχα στοιχεία δικτύου MAC και Διευθύνσεων IP (Διεύθυνση IP επιλέγει από πίνακα που περιλαμβάνει μόνο τις διαθέσιμες-ελεύθερες IP).<br />
                        • Στην ενότητα «Χρέωση Εξοπλισμού» ο χρήστης ορίζει ποιος υπάλληλος θα χρησιμοποιεί αφ εξής την συσκευή. <i>{Στην καρτέλα του συγκεκριμένου υπαλλήλου θα ενημερωθούν τα αντίστοιχα πεδία με τον τρέχων εξοπλισμό}</i>.<br />
                        • Η ενότητα «Απόσυρση Συσκευής» συμπληρώνεται μόνο εάν η συσκευή είναι μη λειτουργική. Σε αυτή την περίπτωση, με το που ενεργοποιηθεί η Απόσυρση Συσκευής, η εφαρμογή διαγράφει το όνομα του προσωπικού που χρησιμοποιεί την συσκευή.<br />
                    </div>
                </div>
            </DxScrollView>
        </DxPopover>

      <DxPopup
          :drag-enabled="false"
          :hide-on-outside-click="true"
          :show-close-button="true"
          :show-title="true"

          :columns="columns"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          title="Διαχείριση Συσκευής Σταθερής Τηλεφωνίας"
          :width="'90%'" 
          :height="'90%'"
            :toolbar-items="[
                            {
                                widget: 'dxButton',
                                location: 'after',
                                options: {
                                    icon: 'help', 
                                    onClick: () => {
                                        this.isPopoverVisible = !this.isPopoverVisible;
                                    },
                                    elementAttr: {
                                        id: 'help-button'
                                    }
                                }
                            }
                        ]"
          v-model:visible="isPopupVisible"
          titleTemplate="title"
          deferRendering="true"
          :animation="{
                        show: {
                            type: 'pop',
                            duration: 800,
                            from: {
                                scale: 0.90
                            }
                        }, 
                      }"
      >
          <DxScrollView height="100%" width="100%">
              <HwPhoneDevicesFormPage
                  ref="hwPhoneDevicesFormPage"
                  :hw_phone_device_id="selectedHwPhoneDeviceID" 
                  @closePopupEvent="onClosePopupEmit">
              </HwPhoneDevicesFormPage>
          </DxScrollView>

      </DxPopup>
          
      <DxDataGrid
          :key-expr="id"
          :data-source="CustomStoreMain"
          :toolbar="toolbarOptions"
          :columns="columns"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          :selection-mode="'single'"
          :ref="dataGridRefKey"          
          :word-wrap-enabled="true"

          @exporting="onExporting"
          @selectionChanged="onSelectionChanged"
          @init-new-row="onCustomInitNewRow"
      >
          <DxSelection mode="single" />
          <DxEditing
              :allow-deleting="true"
              mode="row"
          />

            <DxToolbar>
                <DxItem location="after" 
                        widget="dxButton" 
                        :options="{
                            icon: 'add',
                            text: '',
                            hint: 'Προσθήκη',
                            onClick: () => {
                                this.onCustomInitNewRow()
                            }
                        }"

                />
                <DxItem name="exportButton" 
                    :options="{
                            hint: 'Εξαγωγή δεδομένων'

                        }"
                  />
            </DxToolbar>


          <DxPaging 
              :enabled="true"
              :page-size="5"
          />

          <DxFilterRow :visible="true"/>
          <DxHeaderFilter :visible="true" :word-wrap-enabled="true"/>
          
          <DxPager
              :visible="true"
              :allowed-page-sizes="pageSizes"
              :display-mode="displayMode"
              :show-page-size-selector="true"
              :show-info="true"
              :show-navigation-buttons="true"
          />

          <DxGroupPanel :visible="false"/>

          <DxSorting mode="multiple"/>

          <!--
          <DxColumn
              data-field="entry_date"
              caption="Ημερομηνία Εισαγωγής"
              :hiding-priority="140"
          >
          </DxColumn>
          -->

          <DxColumn
              data-field="code"
              caption="Κωδικός Αναγνώρισης Συσκευής"
              alignment="left"
                sort-order="asc"
          >
          </DxColumn>

          <DxColumn
              data-field="hw_phone_device_model_id"
              caption="Μοντέλο Τηλεφώνου"
          >
              <DxLookup
                  :data-source="LookupCustomDS_sims_hw_phone_device_models" 
                  value-expr="id"
                  display-expr="name"
              />
          </DxColumn>

          <DxColumn
              data-field="wired_ind"
              caption="Ενσύρματο"
              :hiding-priority="120"
          >
          </DxColumn>

          <DxColumn
              data-field="wireless_ind"
              caption="Ασύρματο"
              :hiding-priority="110"
          >
          </DxColumn>

          <DxColumn
              data-field="ipphone_ind"
              caption="IP Τηλεφωνία"
              :hiding-priority="100"
          >
          </DxColumn>

          <!--
          <Column
              data-field="mobilephone_ind"
              caption="Κινητό"
              :hiding-priority="1220"
          >
          </DxColumn>
          -->

          <DxColumn
              data-field="serial_no"
              caption="Σειριακός αριθμός"
              :hiding-priority="90"
          >
          </DxColumn>

          <DxColumn
              data-field="assigned_employment_staff_id"
              caption="Χρήση από υπάλληλο"
              :hiding-priority="80"
          >
            <DxLookup
                :data-source="LookupCustomDS_sims_employment_staff" 
                value-expr="id"
                display-expr="name"
              />
          </DxColumn>

          <DxColumn
              data-field="device_disposal_ind"
              caption="Απόσυρση Συσκευής"
              :hiding-priority="70"
          >
          </DxColumn>

          <DxColumn type="buttons">
              <DxButton 
                  text="Επεξεργασία"
                  icon="edit"
                  hint="Επεξεργασία"
                  :on-click="onCustomRowEdit"
              />
              <DxButton name="delete" hint="Διαγραφή"/>
          >
          </DxColumn>

        <DxExport
            :enabled="true"
            :formats="['pdf', 'xlsx']"
            :texts= "{
                    exportAll: 'Εξαγωγή σε {0}',
                }"
        />

      </DxDataGrid>
  </div>
</template>

<script>

const DATAGRIDREFKEY = 'my-data-grid';

const URL_SERVICE = OParameters.Host.URI + 'sims_hw_phone_devices';
const URL_SERVICE_LOOKUP_sims_hw_phone_device_models = OParameters.Host.URI + 'sims_hw_phone_device_models';
const URL_SERVICE_LOOKUP_sims_employment_staff = OParameters.Host.URI + 'sims_employment_staff_VIEW';


import DxDataGrid, {
  DxColumn,
  DxButton,
  DxEditing, 
  DxPaging,
  DxPager,
  DxGroupPanel,
  DxSorting,
  DxFilterRow,
  DxHeaderFilter,
  DxExport,
  DxSelection,
  DxLookup,
  DxToolbar, 
  DxItem
} from 'devextreme-vue/data-grid';

import DxScrollView from 'devextreme-vue/scroll-view';
import DxPopup from 'devextreme-vue/popup';
import { DxPopover } from 'devextreme-vue/popover';

import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';

import HwPhoneDevicesFormPage from './sims-hw-phone-devices-form-page.vue';

import OParameters from '../sims-globals';

export default {
      components: {
          DxDataGrid,
          DxColumn,
          DxButton,
          DxEditing, 
          DxPaging,
          DxPager,
          DxGroupPanel,
          DxSorting,
          DxFilterRow, 
          DxHeaderFilter,
          DxExport,
          DxPopup,
          DxLookup,
          HwPhoneDevicesFormPage,
          DxScrollView,
          DxSelection,
          DxToolbar, 
          DxItem,
          DxPopover
  },
  data() {
      return {
          IPPattern: "^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$",
          CustomStoreMain: new CustomStore({
              key: 'id',
              //loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE),
              insert: (values) => this.sendRequest(URL_SERVICE, 'POST', values),
              update: (key, values) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
              remove: (key) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
              byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
          }),

          LookupCustomDS_sims_hw_phone_device_models:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_phone_device_models+ '?order=name'),
          }),

          LookupCustomDS_sims_employment_staff:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_employment_staff+ '?order=name'),
          }),

          isPopupVisible: false,
          isPopoverVisible: false,
          title: 'Συσκευές Τηλεφωνίας',
          pageSizes: [5, 10, 20],
          dataGridRefKey: DATAGRIDREFKEY,
          toolbarOptions: {
              items: [
                  {
                      widget: 'dxButton',
                      options: {
                          icon: 'add',
                          text: 'Προσθηκη',
                          onClick: () => {
                              this.onCustomInitNewRow()
                          }
                      }
                  }
              ]
          }
      };
  }, 
  
  methods: {
        // 
        //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
        //
        sendRequest(url, method = 'GET', values = {}) {
            //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then((response) => response.json()
                .then((json) => {
                    if (response.ok) return json.records;
                    throw json.Message;
                }));
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
        },
        onSelectionChanged(e) {
                this.selectedHwPhoneDeviceID = e.currentSelectedRowKeys[0];            
        },
        onClosePopupEmit() {
            this.isPopupVisible = false;
            this.dataGrid.refresh();
        },
        onCustomInitNewRow() {            
                this.selectedHwPhoneDeviceID = null;
                this.$refs.hwPhoneDevicesFormPage.loadFormData(this.selectedHwPhoneDeviceID)
                this.isPopupVisible = true;
        },
        onCustomRowEdit(e) {
            this.selectedHwPhoneDeviceID = e.row.key
            this.isPopupVisible = true
        },
        onExporting (e){
            OParameters.methods.exportDataGridToPdfExcel(e, this.title)
        }
  },
  computed: {
      dataGrid: function() {
          return this.$refs[DATAGRIDREFKEY].instance;
      }
  },
};
</script>

<style lang="scss">
</style>
