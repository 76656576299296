<template>
  <div id="container">
      <h3 class="content-block openware-form-title">{{ title }}</h3>
      <DxPopover
            :shading="true"
            width="70%"
            target="#help-button"
            position="top"
            shading-color="rgba(0, 0, 0, 0.5)"
            v-model:visible="isPopoverVisible"
            :show-title="true"
            title="Βοήθεια: Φόρμα «Διαχείριση Προσωπικού»"
            :toolbar-items="[{
                                widget: 'dxButton',
                                location: 'after',
                                options: {
                                    icon: 'close',
                                    onClick: () => {
                                        this.isPopoverVisible = false;
                                    }
                                }
                            }
                            ]"
         
        >
            <DxScrollView>
                <div class="application-help">
                    <div class="application-help-intro">
                        Στην Ενότητα Αναλώσιμα / Παραχώρηση αναλωσίμου σε υπάλληλο, ο χρήστης δηλώνει την παραχώρηση ποσότητας για τύπο αναλωσίμου που παραχωρεί για υπηρεσιακή χρήση σε συγκεκριμένο υπάλληλο. Με το που αποθηκευτεί η κάθε εγγραφή, η  εφαρμογή ενημερώνει τον πίνακα «Αποθέματα» αφαιρώντας τα παραχωρημένα αναλώσιμα από τις αντίστοιχες εγγραφές. <i>{Στο πεδίο «τύπος αναλωσίμου» εμφανίζονται μόνο τα αναλώσιμα για τα οποία υπάρχει απόθεμα}</i>
                    </div>
                    <br />
                    <div class="application-help-content">
                        • Η εφαρμογή ενημερώνει στο Dashboard για τους τύπους αναλωσίμων που δεν υπάρχει διαθεσιμότητα.<br />
                    </div>
                </div>
            </DxScrollView>
        </DxPopover>

        <DxPopup
          :drag-enabled="false"
          :hide-on-outside-click="true"
          :show-close-button="true"
          :show-title="true"

          :columns="columns"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          title="Παραχώρηση Αναλωσίμου σε Υπάλληλο"
          :width="'90%'" 
          :height="'90%'"
          :toolbar-items="[
                            {
                                widget: 'dxButton',
                                location: 'after',
                                options: {
                                    icon: 'help', 
                                    onClick: () => {
                                        this.isPopoverVisible = !this.isPopoverVisible;
                                    },
                                    elementAttr: {
                                        id: 'help-button'
                                    }
                                }
                            }
                        ]"
          v-model:visible="isPopupVisible"
      >
          <DxScrollView height="100%" width="100%">
              <WarehouseHWPrinterExpendablesExitsFormPage
                  ref="warehouseHWPrinterExpendablesExitsFormPage"
                  :warehouse_hw_printer_expendables_exits_id="selectedWarehouseHWPrinterExpendablesExitsID" 
                  @closePopupEvent="onClosePopupEmit">
              </WarehouseHWPrinterExpendablesExitsFormPage>
          </DxScrollView>

      </DxPopup>
          
      <DxDataGrid
          :key-expr="id"
          :data-source="CustomStoreMain"
          :columns="columns"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          :selection-mode="'single'"
          :ref="dataGridRefKey"
          :word-wrap-enabled="true"

          @exporting="onExporting"
          @selectionChanged="onSelectionChanged"
          @init-new-row="onCustomInitNewRow"
      >
          <DxSelection mode="single" />
          <DxEditing
              :allow-deleting="true"
              :texts= "{
                    addRow: 'Προσθήκη',
                    deleteRow: 'Διαγραφή',
                    editRow:'Επεξεργασία',
                    saveAllChanges:'Αποθήκευση Όλων',
                    cancelAllChanges:'Ακύρωση Όλων'
                }"
              mode="row"
          />
            <DxToolbar>
                <DxItem location="after" 
                        widget="dxButton" 
                        :options="{
                            icon: 'add',
                            text: '',
                            hint: 'Προσθήκη',
                            onClick: () => {
                                this.onCustomInitNewRow()
                            }
                        }"
                />
                <DxItem name="exportButton" 
                    :options="{
                            hint: 'Εξαγωγή δεδομένων'

                        }"
                  />
            </DxToolbar>

          <DxPaging 
              :enabled="true"
              :page-size="5"
          />

          <DxFilterRow :visible="true"/>
          <DxHeaderFilter :visible="true" :word-wrap-enabled="true"/>
          
          <DxPager
              :visible="true"
              :allowed-page-sizes="pageSizes"
              :display-mode="displayMode"
              :show-page-size-selector="true"
              :show-info="true"
              :show-navigation-buttons="true"
          />

          <DxGroupPanel :visible="false"/>

          <DxSorting mode="multiple"/>

          <DxColumn
              data-field="entry_date"
              caption="Ημερομηνία Παραχώρησης"
              dataType="date"
              format="shortDate" 
              :hiding-priority="140"
              sort-order="desc"
          >
          </DxColumn>

          <DxColumn
              data-field="hw_printer_expendable_id"
              caption="Τύπος Αναλωσίμου"
              :hiding-priority="110"
          >
              <DxLookup
                  :data-source="LookupCustomDS_sims_hw_printer_expendables" 
                  value-expr="id"
                  display-expr="name"
              />
          </DxColumn>

          <!--
          <DxColumn
              data-field="pages_autonomy_count"
              caption="Αυτονομία Σελίδων"
              :hiding-priority="130"
          >
          </DxColumn>
          -->

          <DxColumn
              data-field="quantity"
              caption="Αριθμός Αναλωσίμων Προς Παραχώρηση"
              :hiding-priority="150"
          >
          </DxColumn>

          <DxColumn
              data-field="assigned_employment_staff_id"
              caption="Υπάλληλος"
              :hiding-priority="160"
          >
              <DxLookup
                  :data-source="LookupCustomDS_sims_employment_staff" 
                  value-expr="id"
                  display-expr="name"
              />
          </DxColumn>


          <DxColumn
              data-field="description"
              caption="Επιπλέον Πληροφορίες"
              :hiding-priority="100"
          >
          </DxColumn>

          <!--
            <DxColumn type="buttons">
              <DxButton 
                  text="Επεξεργασία"
                  icon="edit"
                  hint="Επεξεργασία"
                  :on-click="onCustomRowEdit"
              />
              <DxButton name="delete" hint="Διαγραφή"/>
          >
          </DxColumn>
          -->

        <DxExport
            :enabled="true"
            :formats="['pdf', 'xlsx']"
            :texts= "{
                    exportAll: 'Εξαγωγή σε {0}',
                }"
        />

      </DxDataGrid>
  </div>
</template>

<script>

const DATAGRIDREFKEY = 'my-data-grid';

const URL_SERVICE = OParameters.Host.URI + 'sims_warehouse_hw_printer_expendables_exits';
const URL_SERVICE_LOOKUP_sims_hw_printer_expendables = OParameters.Host.URI + 'sims_hw_printer_expendables';

const URL_SERVICE_LOOKUP_sims_employment_staff = OParameters.Host.URI + 'sims_employment_staff_VIEW';

import DxDataGrid, {
            DxColumn,
            //DxButton,
            DxEditing, 
            DxPaging,
            DxPager,
            DxGroupPanel,
            DxSorting,
            DxFilterRow,
            DxHeaderFilter,
            DxExport,
            DxSelection,
            DxLookup,
            DxToolbar, 
            DxItem
} from 'devextreme-vue/data-grid';

import DxScrollView from 'devextreme-vue/scroll-view';
import DxPopup from 'devextreme-vue/popup';
import { DxPopover } from 'devextreme-vue/popover';

import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';

import WarehouseHWPrinterExpendablesExitsFormPage from './sims-warehouse-hw-printer-expendables-exits-form-page.vue';

import OParameters from '../sims-globals';

export default {
      components: {
          DxDataGrid,
          DxColumn,
          //DxButton,
          DxEditing, 
          DxPaging,
          DxPager,
          DxGroupPanel,
          DxSorting,
          DxFilterRow, 
          DxHeaderFilter,
          DxExport,
          DxPopup,
          DxLookup,
          WarehouseHWPrinterExpendablesExitsFormPage,
          DxScrollView,
          DxSelection,
          DxToolbar, 
          DxItem,
          DxPopover,

  },
  data() {
      return {
          IPPattern: "^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$",
          CustomStoreMain: new CustomStore({
              key: 'id',
              //loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE),
              insert: (values) => this.sendRequest(URL_SERVICE, 'POST', values),
              update: (key, values) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
              remove: (key) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
              byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
          }),

          LookupCustomDS_sims_hw_printer_expendables:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_printer_expendables+ '?order=name'),
          }),

          LookupCustomDS_sims_employment_staff: new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_employment_staff+ '?order=name'),
          }),

          isPopupVisible: false,
          isPopoverVisible: false,
          title: 'Παραχώρηση Αναλωσίμου σε Υπάλληλο',
          pageSizes: [5, 10, 20],
          dataGridRefKey: DATAGRIDREFKEY
      };
  }, 
  
  methods: {
        // 
        //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
        //
        sendRequest(url, method = 'GET', values = {}) {
            //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then((response) => response.json()
                .then((json) => {
                    if (response.ok) return json.records;
                    throw json.Message;
                }));
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
        },
        onSelectionChanged(e) {
                this.selectedWarehouseHWPrinterExpendablesExitsID = e.currentSelectedRowKeys[0];
        },
        onClosePopupEmit() {
            this.isPopupVisible = false;
            this.dataGrid.refresh();
        },
        onCustomInitNewRow() {
                this.isPopupVisible = true;
                this.selectedWarehouseHWPrinterExpendablesExitsID = null;
                this.$refs.warehouseHWPrinterExpendablesExitsFormPage.loadFormData(this.selectedWarehouseHWPrinterExpendablesExitsID);
        },
        onCustomRowEdit(e) {
            this.selectedWarehouseHWPrinterExpendablesExitsID = e.row.key
            this.isPopupVisible = true
        },
        onExporting (e){
            OParameters.methods.exportDataGridToPdfExcel(e, this.title)
        },
  },
  computed: {
      dataGrid: function() {
          return this.$refs[DATAGRIDREFKEY].instance;
      }
  },
};
</script>

<style lang="scss">
</style>
