<template>
  <div>
    <h3 class="content-block openware-form-title">{{ title }}</h3>
    <DxDataGrid
        :key-expr="id"            
        :data-source="CustomStoreMain"
        :columns="columns"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :focused-row-enabled="true"
        :word-wrap-enabled="true"
        @exporting="onExporting"
        @init-new-row="initNewRow"
        ref="dataGridRef"
    >

        <DxPaging 
            :enabled="true"
            :page-size="5"
        />

        <DxFilterRow :visible="true"/>
        <DxHeaderFilter :visible="true"/>
        
        <DxPager
            :visible="true"
            :allowed-page-sizes="pageSizes"
            :display-mode="displayMode"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
        />

        <DxGroupPanel :visible="false"/>

        <DxSorting mode="multiple"/>

        <DxEditing
            :allow-updating="true"
            :allow-adding="true"
            :allow-deleting="true"
            :texts= "{
                    addRow: 'Προσθήκη',
                }"
            
            mode="popup"
        >
            <DxTexts
                save-row-changes="Αποθηκευση"
                cancel-row-changes="Κλεισιμο"
                add-row="Προσθήκη"
                delete-row="Διαγραφή"
                edit-row="Επεξεργασία"
            />
          <DxPopup
            :show-title="true"
            title="Στοιχεία Μοντέλου Εκτυπωτή"
            :width="'90%'" 
            :height="'90%'"
            :show-close-button="true"
          > 
                <DxToolbarItem
                    widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    :options="{ 
                        icon: 'save',
                        text: 'Αποθήκευση', 
                        type: 'success',
                        onClick: () => {
                            this.$refs['dataGridRef'].instance.saveEditData();
                        }
                    }"
                />
                <DxToolbarItem 
                    widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    :options="{ 
                        icon: 'close',
                        text: 'Κλείσιμο',
                        type: 'danger',
                        onClick: () => {
                            this.$refs['dataGridRef'].instance.cancelEditData();
                        }
                    }"
                />
        </DxPopup>

        <DxForm>
            <DxItem data-field="name" :col-span="2"/>

            <!--
            <DxItem
                data-field="description"
                :editor-options="{ height: 120 }"
                :col-span="2"
                editor-type="dxTextArea"
            />
            -->

            <DxItem data-field="hw_printer_category_id"/>
            <DxItem data-field="hw_printer_print_technology_id"/>
            <DxItem data-field="color_printing_ind"/>
            <DxItem data-field="hw_printer_expendable_id"/>

            <DxItem 
                itemType="group" 
                :col-count="3" 
                :col-span="2" 
                caption="Δυνατότητες Εκτυπωτή"
            >
                <DxItem data-field="printing_ind"/>
                <DxItem data-field="scanning_ind"/>
                <DxItem data-field="copying_ind"/>
                <DxItem data-field="fax_ind"/>
                <DxItem data-field="adf_ind"/>
                <DxItem data-field="doubleside_printing_ind"/>
                <DxItem data-field="cddvd_printing_ind"/>
            </DxItem>
            
            <DxItem data-field="json_2_connection_types" :col-span="2"/>
            
            <DxItem
                :col-span="2"
                data-field="description"
                :editor-options="{ height: 120 }"
                editor-type="dxTextArea"
            />
        </DxForm>

        </DxEditing>

        <DxColumn
            data-field="name"
            caption="Όνομα"
            sort-order="asc"
        >
            <DxRequiredRule/>
        </DxColumn>

        <DxColumn
            :width="200"
            :allow-sorting="false"
            :calculate-filter-expression="calculateFilterExpression"
            data-field="hw_printer_category_id"
            caption="Κατηγορία Εκτυπωτή"
            :hiding-priority="140"
        >   
            <DxLookup
                :data-source="LookupCustomDS_sims_hw_printer_categories"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>

        <DxColumn
            :width="200"
            :allow-sorting="false"
            :calculate-filter-expression="calculateFilterExpression"
            data-field="hw_printer_print_technology_id"
            caption="Τεχνολογία Εκτύπωσης"
            :hiding-priority="130"
        >   
            <DxLookup
                :data-source="LookupCustomDS_sims_hw_printer_print_technologies"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>

        <DxColumn
            data-field="color_printing_ind"
            caption="Έγχρωμος"
            data-type="boolean"
            :width="150"
            :hiding-priority="120"
        >
        </DxColumn>

        <DxColumn
          :width="200"
          :allow-sorting="false"
          :calculate-filter-expression="calculateFilterExpression"
          data-field="hw_printer_expendable_id"
          caption="Τύπος Αναλωσίμου"
          :hiding-priority="110"
        >   
            <DxLookup
                :data-source="LookupCustomDS_sims_hw_printer_expendables"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>

        <DxColumn
            data-field="printing_ind"
            caption="Εκτύπωση"
            data-type="boolean"
            :width="150"
            :hiding-priority="100"
        >
        </DxColumn>

        <DxColumn
            data-field="scanning_ind"
            caption="Σάρωση"
            data-type="boolean"
            :width="150"
            :hiding-priority="90"
        >
        </DxColumn>

        <DxColumn
            data-field="copying_ind"
            caption="Αντιγραφή"
            data-type="boolean"
            :width="150"
            :hiding-priority="80"
        >
        </DxColumn>

        <DxColumn
            data-field="fax_ind"
            caption="ΦΑΞ"
            data-type="boolean"
            :width="150"
            :hiding-priority="70"
        >
        </DxColumn>

        <DxColumn
            data-field="adf_ind"
            caption="ADF"
            data-type="boolean"
            :width="150"
            :hiding-priority="60"
        >        
        </DxColumn>

        <DxColumn
            data-field="doubleside_printing_ind"
            caption="Εκτύπωση Διπλής όψης"
            data-type="boolean"
            :width="150"
            :hiding-priority="50"
        >
        </DxColumn>


        <DxColumn
            data-field="cddvd_printing_ind"
            caption="Εκτύπωση CD/DVD"
            data-type="boolean"
            :width="150"
            :hiding-priority="40"
        >
        </DxColumn>

        <DxColumn
          :width="200"
          :allow-sorting="false"
          :calculate-filter-expression="calculateFilterExpression"
          data-field="json_2_connection_types"
          caption="Δυνατότητες Σύνδεσης"
          :cell-template="cellTemplate"
          edit-cell-template="tagBoxEditor"
          :hiding-priority="30"
        >   
            <DxLookup
                :data-source="LookupCustomDS_sims_hw_connection_types"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>

        <DxColumn
            data-field="description"
            caption="Επιπλέον Χαρακτηριστικά"
            :hiding-priority="20"
        >
        </DxColumn>

        <DxExport
            :enabled="true"
            :formats="['pdf', 'xlsx']"
            :texts= "{
                    exportAll: 'Εξαγωγή σε {0}',
                }"
        />

        <template #tagBoxEditor="{ data: cellInfo }">
            <ConnectionsTagBoxComponent
                :value="cellInfo.value"
                :on-value-changed="(value) => onValueChanged(value, cellInfo)"
                :data-source="LookupCustomDS_sims_hw_connection_types"
                :data-grid-component="cellInfo.component"
            />
        </template>

    </DxDataGrid>
  </div>
</template>

<script>
import DxDataGrid, {
    DxColumn,
    DxEditing, 
    DxPaging,
    DxPager,
    DxGroupPanel,
    DxSorting,
    DxFilterRow,
    DxHeaderFilter,
    DxExport,
    DxRequiredRule,
    //DxEmailRule,
    DxLookup,
    DxPopup,
    DxForm,
    DxItem,
    DxTexts
} from 'devextreme-vue/data-grid';

import { DxToolbarItem } from 'devextreme-vue/popup'

import 'whatwg-fetch';
import CustomStore from 'devextreme/data/custom_store';
import 'devextreme-vue/text-area';

import ConnectionsTagBoxComponent from './sims_ConnectionsTagBoxComponent.vue';

import OParameters from '../sims-globals';

const URL_SERVICE = OParameters.Host.URI + 'sims_hw_printer_models';

const URL_SERVICE_LOOKUP_sims_hw_connection_types = OParameters.Host.URI + 'sims_hw_connection_types';

const URL_SERVICE_LOOKUP_sims_hw_printer_categories = OParameters.Host.URI + 'sims_hw_printer_categories';
const URL_SERVICE_LOOKUP_sims_hw_printer_print_technologies = OParameters.Host.URI + 'sims_hw_printer_print_technologies';
const URL_SERVICE_LOOKUP_sims_hw_printer_expendables = OParameters.Host.URI + 'sims_hw_printer_expendables';

export default {
        components: {
            DxDataGrid,
            DxColumn,
            DxEditing, 
            DxPaging,
            DxPager,
            DxGroupPanel,
            DxSorting,
            DxFilterRow, 
            DxHeaderFilter,
            DxExport,
            DxRequiredRule,
            //DxEmailRule,
            DxLookup,
            DxPopup,
            ConnectionsTagBoxComponent,
            DxForm,
            DxItem,
            DxTexts,
            DxToolbarItem
    },
    data() {
        return {
            CustomStoreMain: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE),
                insert: (values) => this.sendRequest(URL_SERVICE, 'POST', values),
                update: (key, values) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
            }),

            LookupCustomDS_sims_hw_connection_types: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_connection_types+ '?order=name'),
            }),

            LookupCustomDS_sims_hw_printer_categories: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_printer_categories+ '?order=name'),
            }),

            LookupCustomDS_sims_hw_printer_print_technologies: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_printer_print_technologies+ '?order=name'),
            }),

            LookupCustomDS_sims_hw_printer_expendables:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_printer_expendables+ '?order=name'),
            }),            

            title: 'Μοντέλα Εκτυπωτή',
            pageSizes: [5, 10, 20],
        };
    },
    methods: {
        // 
        //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
        //
        sendRequest(url, method = 'GET', values = {}) {
            // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then((response) => response.json()
                .then((json) => {
                    if (response.ok) return json.records;
                    throw json.Message;
                }));
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
        },
        cellTemplate(container, options) {
           /*
            if (options !== undefined) {
                console.log('Grid cell template');
                console.log(options);
            }
            */
           const noBreakSpace = '\u00A0';

            var text;
            try {
                text = (JSON.parse(options.value) || []).map((element) => options.column.lookup.calculateCellValue(element)).join(', ');
            } catch {
                text = '[]';//(options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(', ');
            }
            //const text = (options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(', ');
            container.textContent = (text || noBreakSpace);
            container.title = text;
        },
        onValueChanged(value, cellInfo) {
            /*
            console.log('Grid value changed');
            console.log(value);
            */

            cellInfo.setValue(JSON.stringify(value));
            //cellInfo.setValue(value);
            cellInfo.component.updateDimensions();
        },
        initNewRow(row) {
            row.data.json_2_connection_types = '[]';
            row.data.printing_ind = 0;
            row.data.scanning_ind = 0;
            row.data.copying_ind = 0;
            row.data.fax_ind = 0;
            row.data.adf_ind = 0;
            row.data.doubleside_printing_ind = 0;
            row.data.cddvd_printing_ind = 0;
        },
        onExporting (e){
            OParameters.methods.exportDataGridToPdfExcel(e, this.title, [30, 12, 15, 10, 15, 10, 8, 8, 8, 8, 8, 8, 20, 30])
        },
    },
};
</script>

<style lang="scss">
</style>
