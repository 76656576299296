import SimsRequestsReportPage from './views/sims-requests-report-page';
import SimsLeasingHwViewReportPage from './views/sims-leasing-hw-view-report-page';
import SimsHwViewDisposedReportPage from './views/sims-hw-view-disposed-report-page';
import SimsHw2EmploymentStaffVIEWReportPage from './views/sims-hw-2-employment-staff-VIEW-report-page';
import SimsEmploymentStaffVIEWReportPage from './views/sims-employment-staff-VIEW-report-page';
import SimsHwViewReportPage from './views/sims-hw-view-report-page';
import SimsTelephonyNumbers2EmploymentStaffVIEWReportPage from './views/sims-telephony-numbers-2-employment-staff-VIEW-report-page';
import SimsNetworkIps2HardwareVIEWReportPage from './views/sims-network-ips-2-hardware-VIEW-report-page';
import SimsSystemUsersPasswordFormPage from './views/sims-system-users-password-form-page';
import SimsDocxTest from './views/sims-docx-test';
import SimsSystemUsersProfileFormPage from './views/sims-system-users-profile-form-page';
import SimsSystemUsersFormPage from './views/sims-system-users-form-page';
import SimsSystemLoginsPage from './views/sims-system-users-page';
import SimsHwInventoryActivePage from './views/sims-hw-inventory-active-page';
import SimsRequestsFormPage from './views/sims-requests-form-page';
import SimsRequestsPage from './views/sims-requests-page';
import SimsSwSoftware2HwPcFormPage from './views/sims-sw-software-2-hw-pc-form-page';
import SimsTelephonyNumbers2EmplomentStaffViewPage from './views/sims-telephony-numbers-2-emploment-staff-view-page';
import SimsNetworkIpsPage from './views/sims-network-ips-2-hardware-view-page';
import SimsNetworksFormPage from './views/sims-networks-form-page';
import SimsLeasingHwScannerDevicesFormPage from './views/sims-leasing-hw-scanner-devices-form-page';
import SimsLeasingHwScannerDevicesPage from './views/sims-leasing-hw-scanner-devices-page';
import SimsHwPrinterDevicesLeasingFormPage from './views/sims-leasing-hw-printer-devices-form-page';
import SimsLeasingHwPrinterDevicesPage from './views/sims-leasing-hw-printer-devices-page';
import SimsSwSoftwareReportPage from './views/sims-sw-software-report-page';
import SimsSwSoftware2HwPcPage from './views/sims-sw-software-2-hw-pc-page';
import SimsHwPcServerModelsPage from './views/sims-hw-pc-server-models-page';
import SimsWarehouseHwPrinterExpendablesStocksPage from './views/sims-warehouse-hw-printer-expendables-stocks-page';
import SimsWarehouseHwPrinterExpendablesExitsFormPage from './views/sims-warehouse-hw-printer-expendables-exits-form-page';
import SimsWarehouseHwPrinterExpendablesExitsPage from './views/sims-warehouse-hw-printer-expendables-exits-page';
import SimsWarehouseHwPrinterExpendablesEntriesFormPage from './views/sims-warehouse-hw-printer-expendables-entries-form-page';
import SimsWarehouseHwPrinterExpendablesEntriesPage from './views/sims-warehouse-hw-printer-expendables-entries-page';
import SimsDashboardComponent from './views/sims-dashboard-component';
import SimsHwPcPartsDevicesFormPage from './views/sims-hw-pc-parts-devices-form-page';
import SimsHwPcPartsDevicesPage from './views/sims-hw-pc-parts-devices-page';
import SimsHwMultimediaDevicesFormPage from './views/sims-hw-multimedia-devices-form-page';
import SimsHwMultimediaDevicesPage from './views/sims-hw-multimedia-devices-page';
import SimsHwRouterDevicesFormPage from './views/sims-hw-router-devices-form-page';
import SimsHwRouterDevicesPage from './views/sims-hw-router-devices-page';
import SimsHwSwitchDevicesPage from './views/sims-hw-switch-devices-page';
import SimsHwSwitchDevicesFormPage from './views/sims-hw-switch-devices-form-page';
import SimsHwScannerDevicesFormPage from './views/sims-hw-scanner-devices-form-page';
import SimsHwScannerDevicesPage from './views/sims-hw-scanner-devices-page';
import SimsHwPrinterDevicesFormPage from './views/sims-hw-printer-devices-form-page';
import SimsHwPrinterDevicesPage from './views/sims-hw-printer-devices-page';
import SimsHwPhoneDevicesFormPage from './views/sims-hw-phone-devices-form-page';
import SimsHwPhoneDevicesPage from './views/sims-hw-phone-devices-page';
import SimsHwMouseDevicesPage from './views/sims-hw-mouse-devices-page';
import SimsHwMouseDevicesFormPage from './views/sims-hw-mouse-devices-form-page';
import SimsHwKeyboardDevicesFormPage from './views/sims-hw-keyboard-devices-form-page';
import SimsHwKeyboardDevicesPage from './views/sims-hw-keyboard-devices-page';
import SimsHwMonitorDevicesFormPage from './views/sims-hw-monitor-devices-form-page';
import SimsHwMonitorDevicesPage from './views/sims-hw-monitor-devices-page';
import SimsHwPcTabletDevicesPage from './views/sims-hw-pc-tablet-devices-page';
import SimsHwPcTabletDevicesFormPage from './views/sims-hw-pc-tablet-devices-form-page';
import SimsHwPcServerDevicesFormPage from './views/sims-hw-pc-server-devices-form-page';
import SimsHwPcServerDevicesPage from './views/sims-hw-pc-server-devices-page';
import SimsHwPcLaptopDevicesFormPage from './views/sims-hw-pc-laptop-devices-form-page';
import SimsHwPcLaptopDevicesPage from './views/sims-hw-pc-laptop-devices-page';
import SimsHwPcDesktopDevicesFormPage from './views/sims-hw-pc-desktop-devices-form-page';
import SimsHwPcDesktopDevicesPage from './views/sims-hw-pc-desktop-devices-page';
import SimsSwSoftwarePage from './views/sims-sw-software-page';
import SimsCredentialsSoftwarePage from './views/sims-credentials-software-page';
import SimsCredentialsDevicesPage from './views/sims-credentials-devices-page';
import SimsEmploymentStaffPage from './views/sims-employment-staff-page';

import SimsTelephonyPage from './views/sims-telephony-page';
import SimsTelephonyFormPage from './views/sims-telephony-form-page';
import SimsNetworksPage from './views/sims-networks-page';
import SimsHwSwitchModelsPage from './views/sims-hw-switch-models-page';
import SimsHwScannerModelsPage from './views/sims-hw-scanner-models-page';
import SimsHwPrinterModelsPage from './views/sims-hw-printer-models-page';
import SimsHwPhoneDeviceModelsPage from './views/sims-hw-phone-device-models-page';
import SimsHwMouseModelsPage from './views/sims-hw-mouse-models-page';
import SimsHwKeyboardModelsPage from './views/sims-hw-keyboard-models-page';
import SimsHwMultimediaDeviceCategoriesPage from './views/sims-hw-multimedia-device-categories-page';
import SimsHwRouterModelsPage from './views/sims-hw-router-models-page';
import SimsHwMonitorModelsPage from './views/sims-hw-monitor-models-page';
import SimsSwOperatingSystemTypesPage from './views/sims-sw-operating-system-types-page';
import SimsSwApplicationsPage from './views/sims-sw-applications-page';
import SimsHwPhoneLineTypesPage from './views/sims-hw-phone-line-types-page';
import SimsSrTelecomProvidersPage from './views/sims-sr-telecom-providers-page';
import SimsHwMultimediaDeviceModelsPage from './views/sims-hw-multimedia-device-models-page';
import SimsHwMultimediaDeviceTypesPage from './views/sims-hw-multimedia-device-types-page';
import SimsHwPcPartsPage from './views/sims-hw-pc-parts-page';
import SimsHwPcPartsTypesPage from './views/sims-hw-pc-parts-types-page';
import SimsHwPcPartsCategoriesPage from './views/sims-hw-pc-parts-categories-page';
import SimsHwSwitchTypesPage from './views/sims-hw-switch-types-page';
import SimsHwSwitchLayersPage from './views/sims-hw-switch-layers-page';
import SimsHwScannerScanResolutionsPage from './views/sims-hw-scanner-scan-resolutions-page';
import SimsHwScannerTypesPage from './views/sims-hw-scanner-types-page';
import SimsHwScannerColorDepthsPage from './views/sims-hw-scanner-color-depths-page';
import SimsHwPrinterExpendablesPage from './views/sims-hw-printer-expendables-page';
import SimsHwPrinterPrintTechnologiesPage from './views/sims-hw-printer-print-technologies-page';
import SimsHwPrinterCategoriesPage from './views/sims-hw-printer-categories-page';
import SimsHwScreenResponseTimesPage from './views/sims-hw-screen-response-times-page';
import SimsHwScreenRefreshRatesPage from './views/sims-hw-screen-refresh-rates-page';
import SimsHwScreenResolutionsPage from './views/sims-hw-screen-resolutions-page';
import SimsHwScreenDiagonalsPage from './views/sims-hw-screen-diagonals-page';
import SimsHwPcTabletModelsPage from './views/sims-hw-pc-tablet-models-page';
import SimsHwPcLaptopModelsPage from './views/sims-hw-pc-laptop-models-page';
import SimsHwPcDesktopModelsPage from './views/sims-hw-pc-desktop-models-page';
import SimsHwHarddiskSizesPage from './views/sims-hw-harddisk-sizes-page';
import SimsSimsHwHarddiskTypesPage from './views/sims-hw-harddisk-types-page';
import SimsHwMemorySizesPage from './views/sims-hw-memory-sizes-page';
import SimsHwMemoryTypesPage from './views/sims-hw-memory-types-page';
import SimsHwProcessorFrequenciesPage from './views/sims-hw-processor-frequencies-page';
import SimsHwProcessorTypesPage from './views/sims-hw-processor-types-page';
import SimsHwGraphicCardTypesPage from './views/sims-hw-graphic-card-types-page';
import SimsEmploymentRelationshipsPage from './views/sims-employment-relationships-page';
import SimsDirectorateServiceDepartmentOfficesPage from './views/sims-directorate-service-department-offices-page';
import SimsBuildings from "./views/sims-buildings-page";
//import SimsDirectorateGeneral from "./views/sims-directorate-general-page";
import SimsDirectorateServiceDepartmentsPage from './views/sims-directorate-service-departments-page';
import SimsDirectorateServicesPage from './views/sims-directorate-services-page';

import auth from "./auth";
import { createRouter, createWebHashHistory } from "vue-router";

import Home from "./views/home-page";
//import Profile from "./views/profile-page";

import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";
import appStartCardLayout from "./layouts/app-start-card";

import OParameters from './sims-globals';
import appInfo from "./app-info";

import navigation from './app-navigation';


function loadView(view) {
  return () => import (/* webpackChunkName: "login" */ `./views/${view}.vue`)    
}

const router = new createRouter({
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Home
    },
    /*
    {
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Profile
    },
    */
    {
      path: "/sims-buildings-page",
      name: "sims-buildings-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsBuildings
    },
    /*
    {
      path: "/sims-directorate-general-page",
      name: "sims-directorate-general-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsDirectorateGeneral
    },
    */ 
    {
      path: "/sims-directorate-services-page",
      name: "sims-directorate-services-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsDirectorateServicesPage
    }, 
    {
      path: "/sims-directorate-service-departments-page",
      name: "sims-directorate-service-departments-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsDirectorateServiceDepartmentsPage
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: appStartCardLayout,
        title: "Είσοδος Χρήστη" 
      },
      component: loadView("login-form")
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Επαναφορά Κωδικού",
        description: "Εισαγάγετε τη διεύθυνση email που χρησιμοποιήσατε για την εγγραφή σας και θα σας στείλουμε έναν σύνδεσμο για να επαναφέρετε τον κωδικό πρόσβασής σας μέσω email."
      },
      component: loadView("reset-password-form")
    },
    {
      path: "/create-account",
      name: "create-account",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign Up"
      },
      component: loadView("create-account-form"),
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Change Password"
      },
      component: loadView("change-password-form")
    },
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/recovery",
      redirect: "/home"
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home"
    }, 
    {
      path: "/sims-directorate-service-department-offices-page",
      name: "sims-directorate-service-department-offices-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsDirectorateServiceDepartmentOfficesPage
    }, 
    {
      path: "/sims-employment-relationships-page",
      name: "sims-employment-relationships-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsEmploymentRelationshipsPage
    }, 
    {
      path: "/sims-hw-graphic-card-types-page",
      name: "sims-hw-graphic-card-types-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwGraphicCardTypesPage
    }, 
    {
      path: "/sims-hw-processor-types-page",
      name: "sims-hw-processor-types-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwProcessorTypesPage
    }, 
    {
      path: "/sims-hw-processor-frequencies-page",
      name: "sims-hw-processor-frequencies-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwProcessorFrequenciesPage
    }, 
    {
      path: "/sims-hw-memory-types-page",
      name: "sims-hw-memory-types-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwMemoryTypesPage
    }, 
    {
      path: "/sims-hw-memory-sizes-page",
      name: "sims-hw-memory-sizes-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwMemorySizesPage
    }, 
    {
      path: "/sims-hw-harddisk-types-page",
      name: "sims-hw-harddisk-types-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsSimsHwHarddiskTypesPage
    }, 
    {
      path: "/sims-hw-harddisk-sizes-page",
      name: "sims-hw-harddisk-sizes-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwHarddiskSizesPage
    }, 
    {
      path: "/sims-hw-pc-desktop-models-page",
      name: "sims-hw-pc-desktop-models-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPcDesktopModelsPage
    }, 
    {
      path: "/sims-hw-pc-laptop-models-page",
      name: "sims-hw-pc-laptop-models-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPcLaptopModelsPage
    }, 
    {
      path: "/sims-hw-pc-tablet-models-page",
      name: "sims-hw-pc-tablet-models-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPcTabletModelsPage
    }, 
    {
      path: "/sims-hw-screen-diagonals-page",
      name: "sims-hw-screen-diagonals-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwScreenDiagonalsPage
    }, 
    {
      path: "/sims-hw-screen-resolutions-page",
      name: "sims-hw-screen-resolutions-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwScreenResolutionsPage
    }, 
    {
      path: "/sims-hw-screen-refresh-rates-page",
      name: "sims-hw-screen-refresh-rates-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwScreenRefreshRatesPage
    }, 
    {
      path: "/sims-hw-screen-response-times-page",
      name: "sims-hw-screen-response-times-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwScreenResponseTimesPage
    }, 
    {
      path: "/sims-hw-printer-categories-page",
      name: "sims-hw-printer-categories-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPrinterCategoriesPage
    }, 
    {
      path: "/sims-hw-printer-print-technologies-page",
      name: "sims-hw-printer-print-technologies-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPrinterPrintTechnologiesPage
    }, 
    {
      path: "/sims-hw-printer-expendables-page",
      name: "sims-hw-printer-expendables-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPrinterExpendablesPage
    }, 
    {
      path: "/sims-hw-scanner-color-depths-page",
      name: "sims-hw-scanner-color-depths-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwScannerColorDepthsPage
    }, 
    {
      path: "/sims-hw-scanner-types-page",
      name: "sims-hw-scanner-types-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwScannerTypesPage
    }, 
    {
      path: "/sims-hw-scanner-scan-resolutions-page",
      name: "sims-hw-scanner-scan-resolutions-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwScannerScanResolutionsPage
    }, 
    {
      path: "/sims-hw-switch-layers-page",
      name: "sims-hw-switch-layers-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwSwitchLayersPage
    }, 
    {
      path: "/sims-hw-switch-types-page",
      name: "sims-hw-switch-types-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwSwitchTypesPage
    }, 
    {
      path: "/sims-hw-pc-parts-categories-page",
      name: "sims-hw-pc-parts-categories-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPcPartsCategoriesPage
    }, 
    {
      path: "/sims-hw-pc-parts-types-page",
      name: "sims-hw-pc-parts-types-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPcPartsTypesPage
    }, 
    {
      path: "/sims-hw-pc-parts-page",
      name: "sims-hw-pc-parts-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPcPartsPage
    }, 
    {
      path: "/sims-hw-multimedia-device-types-page",
      name: "sims-hw-multimedia-device-types-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwMultimediaDeviceTypesPage
    }, 
    {
      path: "/sims-hw-multimedia-device-models-page",
      name: "sims-hw-multimedia-device-models-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwMultimediaDeviceModelsPage
    }, 
    {
      path: "/sims-sr-telecom-providers-page",
      name: "sims-sr-telecom-providers-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsSrTelecomProvidersPage
    }, 
    {
      path: "/sims-hw-phone-line-types-page",
      name: "sims-hw-phone-line-types-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPhoneLineTypesPage
    }, 
    {
      path: "/sims-sw-applications-page",
      name: "sims-sw-applications-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsSwApplicationsPage
    }, 
    {
      path: "/sims-sw-operating-system-types-page",
      name: "sims-sw-operating-system-types-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsSwOperatingSystemTypesPage
    }, 
    {
      path: "/sims-hw-monitor-models-page",
      name: "sims-hw-monitor-models-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwMonitorModelsPage
    }, 
    {
      path: "/sims-hw-router-models-page",
      name: "sims-hw-router-models-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwRouterModelsPage
    }, 
    {
      path: "/sims-hw-multimedia-devices-page",
      name: "sims-hw-multimedia-devices-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwMultimediaDeviceCategoriesPage
    }, 
    {
      path: "/sims-hw-multimedia-device-categories-page",
      name: "sims-hw-multimedia-device-categories-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwMultimediaDeviceCategoriesPage
    },

    {
      path: "/sims-hw-keyboard-models-page",
      name: "sims-hw-keyboard-models-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwKeyboardModelsPage
    }, 
    {
      path: "/sims-hw-mouse-models-page",
      name: "sims-hw-mouse-models-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwMouseModelsPage
    }, 
    {
      path: "/sims-hw-phone-device-models-page",
      name: "sims-hw-phone-device-models-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPhoneDeviceModelsPage
    }, 
    {
      path: "/sims-hw-printer-models-page",
      name: "sims-hw-printer-models-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPrinterModelsPage
    }, 
    {
      path: "/sims-hw-scanner-models-page",
      name: "sims-hw-scanner-models-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwScannerModelsPage
    }, 
    {
      path: "/sims-hw-switch-models-page",
      name: "sims-hw-switch-models-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwSwitchModelsPage
    }, 
    {
      path: "/sims-networks-page",
      name: "sims-networks-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsNetworksPage
    }, 
    {
      path: "/sims-telephony-form-page",
      name: "sims-telephony-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsTelephonyFormPage
    }, 
    {
      path: "/sims-telephony-page",
      name: "sims-telephony-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsTelephonyPage
    },
    {
      path: "/sims-employment-staff-page",
      name: "sims-employment-staff-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsEmploymentStaffPage
    }, 
    {
      path: "/sims-credentials-devices-page",
      name: "sims-credentials-devices-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsCredentialsDevicesPage
    }, 
    {
      path: "/sims-credentials-software-page",
      name: "sims-credentials-software-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsCredentialsSoftwarePage
    }, 
    {
      path: "/sims-sw-software-page",
      name: "sims-sw-software-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsSwSoftwarePage
    }, 
    {
      path: "/sims-hw-pc-desktop-devices-page",
      name: "sims-hw-pc-desktop-devices-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPcDesktopDevicesPage
    }, 
    {
      path: "/sims-hw-pc-desktop-devices-form-page",
      name: "sims-hw-pc-desktop-devices-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPcDesktopDevicesFormPage
    }, 
    {
      path: "/sims-hw-pc-laptop-devices-page",
      name: "sims-hw-pc-laptop-devices-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPcLaptopDevicesPage
    }, 
    {
      path: "/sims-hw-pc-laptop-devices-form-page",
      name: "sims-hw-pc-laptop-devices-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPcLaptopDevicesFormPage
    }, 
    {
      path: "/sims-hw-pc-server-devices-page",
      name: "sims-hw-pc-server-devices-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPcServerDevicesPage
    }, 
    {
      path: "/sims-hw-pc-server-devices-form-page",
      name: "sims-hw-pc-server-devices-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPcServerDevicesFormPage
    }, 
    {
      path: "/sims-hw-pc-tablet-devices-form-page",
      name: "sims-hw-pc-tablet-devices-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPcTabletDevicesFormPage
    }, 
    {
      path: "/sims-hw-pc-tablet-devices-page",
      name: "sims-hw-pc-tablet-devices-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPcTabletDevicesPage
    }, 
    {
      path: "/sims-hw-monitor-devices-page",
      name: "sims-hw-monitor-devices-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwMonitorDevicesPage
    }, 
    {
      path: "/sims-hw-monitor-devices-form-page",
      name: "sims-hw-monitor-devices-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwMonitorDevicesFormPage
    }, 
    {
      path: "/sims-hw-keyboard-devices-page",
      name: "sims-hw-keyboard-devices-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwKeyboardDevicesPage
    }, 
    {
      path: "/sims-hw-keyboard-devices-form-page",
      name: "sims-hw-keyboard-devices-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwKeyboardDevicesFormPage
    }, 
    {
      path: "/sims-hw-mouse-devices-form-page",
      name: "sims-hw-mouse-devices-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwMouseDevicesFormPage
    }, 
    {
      path: "/sims-hw-mouse-devices-page",
      name: "sims-hw-mouse-devices-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwMouseDevicesPage
    }, 
    {
      path: "/sims-hw-phone-devices-page",
      name: "sims-hw-phone-devices-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPhoneDevicesPage
    }, 
    {
      path: "/sims-hw-phone-devices-form-page",
      name: "sims-hw-phone-devices-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPhoneDevicesFormPage
    }, 
    {
      path: "/sims-hw-printer-devices-page",
      name: "sims-hw-printer-devices-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPrinterDevicesPage
    }, 
    {
      path: "/sims-hw-printer-devices-form-page",
      name: "sims-hw-printer-devices-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPrinterDevicesFormPage
    }, 
    {
      path: "/sims-hw-scanner-devices-page",
      name: "sims-hw-scanner-devices-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwScannerDevicesPage
    }, 
    {
      path: "/sims-hw-scanner-devices-form-page",
      name: "sims-hw-scanner-devices-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwScannerDevicesFormPage
    },
    {
      path: "/sims-hw-switch-devices-form-page",
      name: "sims-hw-switch-devices-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwSwitchDevicesFormPage
    }, 
    {
      path: "/sims-hw-switch-devices-page",
      name: "sims-hw-switch-devices-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwSwitchDevicesPage
    }, 
    {
      path: "/sims-hw-router-devices-page",
      name: "sims-hw-router-devices-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwRouterDevicesPage
    }, 
    {
      path: "/sims-hw-router-devices-form-page",
      name: "sims-hw-router-devices-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwRouterDevicesFormPage
    }, 
    {
      path: "/sims-hw-multimedia-devices-page",
      name: "sims-hw-multimedia-devices-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwMultimediaDevicesPage
    }, 
    {
      path: "/sims-hw-multimedia-devices-form-page",
      name: "sims-hw-multimedia-devices-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwMultimediaDevicesFormPage
    }, 
    {
      path: "/sims-hw-pc-parts-devices-page",
      name: "sims-hw-pc-parts-devices-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPcPartsDevicesPage
    }, 
    {
      path: "/sims-hw-pc-parts-devices-form-page",
      name: "sims-hw-pc-parts-devices-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPcPartsDevicesFormPage
    }, 
    {
      path: "/sims-dashboard-component",
      name: "sims-dashboard-component",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsDashboardComponent
    }, 
    {
      path: "/sims-warehouse-hw-printer-expendables-entries-page",
      name: "sims-warehouse-hw-printer-expendables-entries-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsWarehouseHwPrinterExpendablesEntriesPage
    }, 
    {
      path: "/sims-warehouse-hw-printer-expendables-entries-form-page",
      name: "sims-warehouse-hw-printer-expendables-entries-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsWarehouseHwPrinterExpendablesEntriesFormPage
    }, 
    {
      path: "/sims-warehouse-hw-printer-expendables-exits-page",
      name: "sims-warehouse-hw-printer-expendables-exits-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsWarehouseHwPrinterExpendablesExitsPage
    }, 
    {
      path: "/sims-warehouse-hw-printer-expendables-exits-form-page",
      name: "sims-warehouse-hw-printer-expendables-exits-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsWarehouseHwPrinterExpendablesExitsFormPage
    }, 
    {
      path: "/sims-warehouse-hw-printer-expendables-stocks-page",
      name: "sims-warehouse-hw-printer-expendables-stocks-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsWarehouseHwPrinterExpendablesStocksPage
    }, 
    {
      path: "/sims-hw-pc-server-models-page",
      name: "sims-hw-pc-server-models-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPcServerModelsPage
    }, 
    {
      path: "/sims-sw-software-2-hw-pc-page",
      name: "sims-sw-software-2-hw-pc-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsSwSoftware2HwPcPage
    }, 
    {
      path: "/sims-sw-software-report-page",
      name: "sims-sw-software-report-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsSwSoftwareReportPage
    }, 
    {
      path: "/sims-leasing-hw-printer-devices-page",
      name: "sims-leasing-hw-printer-devices-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsLeasingHwPrinterDevicesPage
    }, 
    {
      path: "/sims-leasing-hw-printer-devices-form-page",
      name: "sims-leasing-hw-printer-devices-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwPrinterDevicesLeasingFormPage
    }, 
    {
      path: "/sims-leasing-hw-scanner-devices-page",
      name: "sims-leasing-hw-scanner-devices-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsLeasingHwScannerDevicesPage
    }, 
    {
      path: "/sims-leasing-hw-scanner-devices-form-page",
      name: "sims-leasing-hw-scanner-devices-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsLeasingHwScannerDevicesFormPage
    }, 
    {
      path: "/sims-networks-form-page",
      name: "sims-networks-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsNetworksFormPage
    }, 
    {
      path: "/sims-network-ips-2-hardware-view-page",
      name: "sims-network-ips-2-hardware-view-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsNetworkIpsPage
    }, 
    {
      path: "/sims-telephony-numbers-2-emploment-staff-view-page",
      name: "sims-telephony-numbers-2-emploment-staff-view-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsTelephonyNumbers2EmplomentStaffViewPage
    }, 
    {
      path: "/sims-sw-software-2-hw-pc-form-page",
      name: "sims-sw-software-2-hw-pc-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsSwSoftware2HwPcFormPage
    }, 
    {
      path: "/sims-requests-page",
      name: "sims-requests-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsRequestsPage
    }, 
    {
      path: "/sims-requests-form-page",
      name: "sims-requests-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsRequestsFormPage
    }, 
    {
      path: "/sims-hw-inventory-active-page",
      name: "sims-hw-inventory-active-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwInventoryActivePage
    }, 
    {
      path: "/sims-system-users-page",
      name: "sims-system-users-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsSystemLoginsPage
    }, 
    {
      path: "/sims-system-users-form-page",
      name: "sims-system-users-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsSystemUsersFormPage
    }, 
    {
      path: "/sims-system-users-profile-form-page",
      name: "sims-system-users-profile-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsSystemUsersProfileFormPage
    }, 
    {
      path: "/sims-docx-test",
      name: "sims-docx-test",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsDocxTest
    }, 
    {
      path: "/sims-system-users-password-form-page",
      name: "sims-system-users-password-form-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsSystemUsersPasswordFormPage
    }, 
    {
      path: "/sims-network-ips-2-hardware-view-report-page",
      name: "sims-network-ips-2-hardware-view-report-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsNetworkIps2HardwareVIEWReportPage
    }, 
    {
      path: "/sims-telephony-numbers-2-employment-staff-view-report-page",
      name: "sims-telephony-numbers-2-employment-staff-view-report-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsTelephonyNumbers2EmploymentStaffVIEWReportPage
    }, 
    {
      path: "/sims-hw-view-report-page",
      name: "sims-hw-view-report-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwViewReportPage
    }, 
    {
      path: "/sims-employment-staff-view-report-page",
      name: "sims-employment-staff-view-report-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsEmploymentStaffVIEWReportPage
    }, 
    {
      path: "/sims-hw-2-employment-staff-view-report-page",
      name: "sims-hw-2-employment-staff-view-report-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHw2EmploymentStaffVIEWReportPage
    }, 
    {
      path: "/sims-hw-view-disposed-report-page",
      name: "sims-hw-view-disposed-report-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsHwViewDisposedReportPage
    }, 
    {
      path: "/sims-leasing-hw-view-report-page",
      name: "sims-leasing-hw-view-report-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsLeasingHwViewReportPage
    }, 
    {
      path: "/sims-requests-report-page",
      name: "sims-requests-report-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SimsRequestsReportPage
    }
  ],
  history: createWebHashHistory()
});

router.beforeEach(async (to, from, next) => {

  document.title = to.meta?.title ?? appInfo.windowTitle
  
  if (to.name === "login-form" && !auth.loggedIn()) {
    try {
      //console.log(1)
        await auth.getMe(); 
        //console.log(2)
    } catch (error) {
        //console.error('There is no me :', error);
    }
  }
  //console.log(3)
  if (to.name === "login-form" && auth.loggedIn()) {
    //console.log("to login-form & loggedIn")
    next({ name: "home" });
    //console.log(5)
    //next({ name: "sims-system-users-profile-form-page" });
  }


  navigation.map((item) => {
    /*
    console.log("item")
    console.log(item)
    */
    item.visible = item.level.includes(OParameters.loggedUser.system_user_group_id)
  })


  /*
  console.log("router to name")
  console.log(to.name)
  console.log("logged user group")
  console.log(OParameters.loggedUser.system_user_group_id)
  */

  /*
  console.log("router")
  console.log(to)
  console.log("logged user")
  console.log(OParameters.loggedUser.system_user_group_id)

  const items = navigation.map((item) => {
    console.log("item")
    console.log(item)
  })
  */

  /*
  console.log("navigation")
  console.log(navigation)

  function findItemAndParent(menu, path, parent = null) {
    for (const item of menu) {
      console.log("item.path", item.path)
      console.log("path", path)
      if (item.path === path && parent?.level) return { item, parent };
      if (item.items) {
        const found = findItemAndParent(item.items, path, item.level ? item : parent);
        if (found) return found;
      }
    }
    return null;
  }
  */

  /*
  const menu = navigation
  const pathToFind = "/" + to.name
  
  const found = findItemAndParent(menu, pathToFind);

  console.log(found ? found : 'Item not found');
  */

  //console.log(items)

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!auth.loggedIn()) {
      next({
        name: "login-form",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router