<template>
  <div>
    <h3 class="content-block openware-form-title">{{ title }}</h3>
    <DxDataGrid
        :key-expr="id"            
        :data-source="CustomStoreMain"
        :columns="columns"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :focused-row-enabled="true"
        :word-wrap-enabled="true"

        @exporting="onExporting"
    >

        <DxPaging 
            :enabled="true"
            :page-size="5"
        />

        <DxFilterRow :visible="true"/>
        <DxHeaderFilter :visible="true"/>
        
        <DxPager
            :visible="true"
            :allowed-page-sizes="pageSizes"
            :display-mode="displayMode"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
        />

        <DxGroupPanel :visible="false"/>

        <DxSorting mode="multiple"/>

        <DxEditing
            :allow-updating="true"
            :allow-adding="true"
            :allow-deleting="true"
            :texts= "{
                    addRow: 'Προσθήκη',
                    deleteRow: 'Διαγραφή',
                    editRow:'Επεξεργασία',
                    saveAllChanges:'Αποθήκευση Όλων',
                    cancelAllChanges:'Ακύρωση Όλων'
                }"
            mode="batch"
        >
        </DxEditing>

        <!--
        <DxColumn
            data-field="directorate_general_id"
            caption="Γενική Διεύθυνση"
            :hiding-priority="400"
        >
            <DxLookup
                :data-source="LookupCustomDataSourcesims_directorate_general" 
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>
        -->

        <DxColumn
            data-field="name"
            caption="Όνομα Διεύθυνσης"
            :hiding-priority="500"
        >
            <DxRequiredRule/>
        </DxColumn>

        <DxColumn 
            data-field="email"
            caption="Email Διεύθυνσης"
            :hiding-priority="300"
        >
            <DxEmailRule />
            <DxRequiredRule/>
        </DxColumn>

        <DxColumn 
            data-field="password"
            caption="Κωδικός Πρόσβασης email"
            :hiding-priority="200"
        >
            <DxRequiredRule/>
        </DxColumn>


        <DxColumn
            data-field="description"
            caption="Επιπλέον Πληροφορίες"
            :hiding-priority="100"
        >
        </DxColumn>


        <DxExport
            :enabled="true"
            :formats="['pdf', 'xlsx']"
            :texts= "{
                    exportAll: 'Εξαγωγή σε {0}',
                }"
        />

    </DxDataGrid>
  </div>
</template>

<script>
import DxDataGrid, {
    DxColumn,
    DxEditing, 
    DxPaging,
    DxPager,
    DxGroupPanel,
    DxSorting,
    DxFilterRow,
    DxHeaderFilter,
    DxExport,
    DxRequiredRule,
    DxEmailRule,
    //DxLookup
} from 'devextreme-vue/data-grid';
import 'whatwg-fetch';
import CustomStore from 'devextreme/data/custom_store';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';        
import { exportDataGrid } from 'devextreme/excel_exporter';

import OParameters from '../sims-globals';

const URL_SERVICE = OParameters.Host.URI + 'sims_directorate_services';
const URL_SERVICE_LOOKUP_sims_directorate_general = OParameters.Host.URI + 'sims_directorate_general';

export default {
        components: {
            DxDataGrid,
            DxColumn,
            DxEditing, 
            DxPaging,
            DxPager,
            DxGroupPanel,
            DxSorting,
            DxFilterRow, 
            DxHeaderFilter,
            DxExport,
            DxRequiredRule,
            DxEmailRule,
            //DxLookup
    },
    data() {
        return {
            CustomStoreMain: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE),
                insert: (values) => this.sendRequest(URL_SERVICE, 'POST', values),
                update: (key, values) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
            }),

            LookupCustomDataSourcesims_directorate_general:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_directorate_general),
            }),
            title: 'Απογραφή Ενεργού Εξοπλισμού',
            pageSizes: [5, 10, 20]
        };
    }, 

    methods: {
        // 
        //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
        //
        sendRequest(url, method = 'GET', values = {}) {
            // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then((response) => response.json()
                .then((json) => {
                    if (response.ok) return json.records;
                    throw json.Message;
                }));
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
        },
        onExporting(e) {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet(this.title);
            exportDataGrid({
                component: e.component,
                worksheet,
                autoFilterEnabled: true,
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), this.title + '.xlsx');
                });
            });
            e.cancel = true;
        },
    },
};
</script>

<style lang="scss">
</style>
