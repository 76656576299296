<template>
  <div id="container">
      <h3 class="content-block openware-form-title">{{ title }}</h3>
      <DxPopup
          :drag-enabled="false"
          :hide-on-outside-click="true"
          :show-close-button="true"
          :show-title="true"

          :columns="columns"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          title="Αποθέματα Αναλωσίμων"
          :width="'90%'" 
          :height="'90%'"
          v-model:visible="isPopupVisible"
      >
          <!--
          <DxScrollView height="100%" width="100%">
              <WarehouseHWPrinterExpendablesStocksFormPage
                  ref="warehouseHWPrinterExpendablesStocksFormPage"
                  :warehouse_hw_printer_expendables_stocks_id="selectedWarehouseHWPrinterExpendablesStocksID" 
                  @closePopupEvent="onClosePopupEmit">
              </WarehouseHWPrinterExpendablesStocksFormPage>
          </DxScrollView>
          -->
      </DxPopup>
          
      <DxDataGrid
          :key-expr="id"
          :data-source="CustomStoreMain"
          :columns="columns"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          :selection-mode="'single'"
          :ref="dataGridRefKey"          
          :word-wrap-enabled="true"

          @exporting="onExporting"
          @selectionChanged="onSelectionChanged"
          @init-new-row="onCustomInitNewRow"
      >
          <DxSelection mode="single" />
          <!--
          <DxEditing
              mode="row"
          />
            <DxToolbar>
                <DxItem location="after" 
                        widget="dxButton" 
                        :options="{
                            icon: 'add',
                            text: '',
                            hint: 'Προσθήκη',
                            onClick: () => {
                                this.onCustomInitNewRow()
                            }
                        }"
                />
                <DxItem name="exportButton" 
                    :options="{
                            hint: 'Εξαγωγή δεδομένων'

                        }"
                  />
            </DxToolbar>
          -->
            <DxToolbar>
                <DxItem name="exportButton" 
                    :options="{
                            hint: 'Εξαγωγή δεδομένων'

                        }"
                  />
            </DxToolbar>

          <DxPaging 
              :enabled="true"
              :page-size="5"
          />

          <DxFilterRow :visible="true"/>
          <DxHeaderFilter :visible="true" :word-wrap-enabled="true"/>
          
          <DxPager
              :visible="true"
              :allowed-page-sizes="pageSizes"
              :display-mode="displayMode"
              :show-page-size-selector="true"
              :show-info="true"
              :show-navigation-buttons="true"
          />

          <DxGroupPanel :visible="false"/>

          <DxSorting mode="multiple"/>

          <DxColumn
              data-field="hw_printer_expendable_id"
              caption="Τύπος Αναλωσίμου"
              :hiding-priority="110"
                sort-order="asc"
          >
              <DxLookup
                  :data-source="LookupCustomDS_sims_hw_printer_expendables" 
                  value-expr="id"
                  display-expr="name"
              />
          </DxColumn>

          <DxColumn
              data-field="quantity"
              caption="Αριθμός αναλωσίμων"
              :hiding-priority="130"
          >
          </DxColumn>

          <!--
          <DxColumn
              data-field="pages_autonomy_count"
              caption="Αυτονομία Σελίδων"
              :hiding-priority="130"
          >
          </DxColumn>
          -->

          <DxColumn
              data-field="hw_printer_expendable_id"
              caption="Αυτονομία Σελίδων"
              :hiding-priority="110"
          >
              <DxLookup
                  :data-source="LookupCustomDS_sims_hw_printer_expendables" 
                  value-expr="id"
                  display-expr="pages_autonomy_count"
              />
          </DxColumn>

          <!--
            <DxColumn type="buttons">
              <DxButton 
                  text="Επεξεργασία"
                  icon="edit"
                  hint="Επεξεργασία"
                  :on-click="onCustomRowEdit"
              />
              <DxButton name="delete" hint="Διαγραφή"/>
          >
          </DxColumn>
          -->

        <DxExport
            :enabled="true"
            :formats="['pdf', 'xlsx']"
            :texts= "{
                    exportAll: 'Εξαγωγή σε {0}',
                }"
        />

      </DxDataGrid>
  </div>
</template>

<script>

const DATAGRIDREFKEY = 'my-data-grid';

const URL_SERVICE = OParameters.Host.URI + 'sims_warehouse_hw_printer_expendables_stocks';
const URL_SERVICE_LOOKUP_sims_hw_printer_expendables = OParameters.Host.URI + 'sims_hw_printer_expendables';

import DxDataGrid, {
            DxColumn,
            //DxButton,
            //DxEditing, 
            DxPaging,
            DxPager,
            DxGroupPanel,
            DxSorting,
            DxFilterRow,
            DxHeaderFilter,
            DxExport,
            DxSelection,
            DxLookup,
            DxToolbar, 
            DxItem
} from 'devextreme-vue/data-grid';

//import DxScrollView from 'devextreme-vue/scroll-view';
import DxPopup from 'devextreme-vue/popup';

import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';

//import WarehouseHWPrinterExpendablesStocksFormPage from './sims-warehouse-hw-printer-expendables-stocks-form-page.vue';

import OParameters from '../sims-globals';

export default {
      components: {
          DxDataGrid,
          DxColumn,
          //DxButton,
          //DxEditing, 
          DxPaging,
          DxPager,
          DxGroupPanel,
          DxSorting,
          DxFilterRow, 
          DxHeaderFilter,
          DxExport,
          DxPopup,
          DxLookup,
          //WarehouseHWPrinterExpendablesStocksFormPage,
          //DxScrollView,
          DxSelection,
          DxToolbar, 
          DxItem

  },
  data() {
      return {
          IPPattern: "^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$",
          CustomStoreMain: new CustomStore({
              key: 'id',
              //loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE),
              insert: (values) => this.sendRequest(URL_SERVICE, 'POST', values),
              update: (key, values) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
              remove: (key) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
              byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
          }),

          LookupCustomDS_sims_hw_printer_expendables:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_printer_expendables+ '?order=name'),
          }),

          isPopupVisible: false,
          title: 'Αποθέματα Αναλωσίμων',
          pageSizes: [5, 10, 20],
          dataGridRefKey: DATAGRIDREFKEY
      };
  }, 
  
  methods: {
        // 
        //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
        //
        sendRequest(url, method = 'GET', values = {}) {
            //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then((response) => response.json()
                .then((json) => {
                    if (response.ok) return json.records;
                    throw json.Message;
                }));
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
        },
        onSelectionChanged(e) {
                this.selectedWarehouseHWPrinterExpendablesStocksID = e.currentSelectedRowKeys[0];            
        },
        onClosePopupEmit() {
            this.isPopupVisible = false;
            this.dataGrid.refresh();
        },
        onCustomInitNewRow() {            
                this.isPopupVisible = true;
                this.selectedWarehouseHWPrinterExpendablesStocksID = null;
        },
        onCustomRowEdit(e) {
            this.selectedWarehouseHWPrinterExpendablesStocksID = e.row.key
            this.isPopupVisible = true
        },
        onExporting (e){
            OParameters.methods.exportDataGridToPdfExcel(e, this.title, [])
        },
  },
  computed: {
      dataGrid: function() {
          return this.$refs[DATAGRIDREFKEY].instance;
      }
  },
};
</script>

<style lang="scss">
</style>
