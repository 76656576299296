<template>
  <div id="container">
      <h3 class="content-block openware-form-title">{{ title }}</h3>
      <DxPopover
            :shading="true"
            width="70%"
            target="#help-button"
            position="top"
            shading-color="rgba(0, 0, 0, 0.5)"
            v-model:visible="isPopoverVisible"
            :show-title="true"
            title="Βοήθεια: Φόρμα «Διαχείριση Μίσθωσης Εκτυπωτή»"
            :toolbar-items="[{
                                widget: 'dxButton',
                                location: 'after',
                                options: {
                                    icon: 'close',
                                    onClick: () => {
                                        this.isPopoverVisible = false;
                                    }
                                }
                            }
                            ]"
        >
            <DxScrollView>
                <div class="application-help">
                    <div class="application-help-intro">
                        Στην Ενότητα Μίσθωση συσκευών ο χρήστης μπορεί να εισάγει και να διαχειριστεί όλες τις υπό μίσθωση συσκευές που διαθέτει η υπηρεσία και αφορούν εκτυπωτικά και μέσα σάρωσης. Η εφαρμογή δίνει την δυνατότητα στο χρήστη να διατηρεί ιστορικό βλαβών και μετρήσεων αντιγράφων έτσι ώστε να υπάρχει ανά πάσα στιγμή πλήρη εικόνα για καθεμία συσκευή
                    </div>
                    <br />
                    <div class="application-help-content">
                        • Αρχικά ο χρήστης χαρακτηρίζει αν η συσκευή που εισάγει ή τροποποιεί είναι ενεργή ή όχι, δηλαδή αν περιλαμβάνεται σε ενεργή σύμβαση μίσθωσης ή έχει λήξει και έχει αποσυρθεί από τον αντίστοιχο ανάδοχο. Με αυτό τον τρόπο ο χρήστης θα γνωρίζει ποιες είναι οι εν ενεργεία συσκευές μίσθωσης ανά πάσα χρονική στιγμή.<br />
                        • Αν η συσκευή είναι δικτυακή (LAN, Wi-Fi) ο χρήστης καλείται να συμπληρώσει τα αντίστοιχα στοιχεία δικτύου MAC και Διεύθυνσης IP (Διεύθυνση IP επιλέγει από πίνακα που περιλαμβάνει μόνο τις διαθέσιμες-ελεύθερες IP).<br />
                        • Ο χρήστης έχει την δυνατότητα να διατηρεί ιστορικό βλαβών της κάθε συσκευής καθώς και το ιστορικό των μετρήσεων εκτυπώσεων ή σαρώσεων.<br />
                    </div>
                </div>
            </DxScrollView>
        </DxPopover>

        <DxPopup
          :drag-enabled="false"
          :hide-on-outside-click="true"
          :show-close-button="true"
          :show-title="true"

          :columns="columns"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          title="Διαχείριση Μίσθωσης Εκτυπωτή"
          :width="'90%'" 
          :height="'90%'"
          :toolbar-items="[
                            {
                                widget: 'dxButton',
                                location: 'after',
                                options: {
                                    icon: 'help', 
                                    onClick: () => {
                                        this.isPopoverVisible = !this.isPopoverVisible;
                                    },
                                    elementAttr: {
                                        id: 'help-button'
                                    }
                                }
                            }
                        ]"
          v-model:visible="isPopupVisible"
      >
          <DxScrollView height="100%" width="100%">
              <LeasingHwPrinterDevicesFormPage
                  ref="hwLeasingPrinterDevicesFormPage"
                  :leasing_hw_printer_device_id="selectedLeasingHwPrinterDeviceID" 
                  @closePopupEvent="onClosePopupEmit">
              </LeasingHwPrinterDevicesFormPage>
          </DxScrollView>

      </DxPopup>
          
      <DxDataGrid
          :key-expr="id"
          :data-source="CustomStoreMain"
          :columns="columns"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          :selection-mode="'single'"
          :ref="dataGridRefKey"
          :word-wrap-enabled="true"

          @exporting="onExporting"
          @selectionChanged="onSelectionChanged"
          @init-new-row="onCustomInitNewRow"
      >
          <DxSelection mode="single" />
          <DxEditing
              :allow-deleting="true"
              mode="row"
          />
            <DxToolbar>
                <DxItem location="after" 
                        widget="dxButton" 
                        :options="{
                            icon: 'add',
                            text: '',
                            hint: 'Προσθήκη',
                            onClick: () => {
                                this.onCustomInitNewRow()
                            }
                        }"

                />
                <DxItem name="exportButton" 
                    :options="{
                            hint: 'Εξαγωγή δεδομένων'

                        }"
                  />
            </DxToolbar>

          <DxPaging 
              :enabled="true"
              :page-size="5"
          />

          <DxFilterRow :visible="true"/>
          <DxHeaderFilter :visible="true" :word-wrap-enabled="true"/>
          
          <DxPager
              :visible="true"
              :allowed-page-sizes="pageSizes"
              :display-mode="displayMode"
              :show-page-size-selector="true"
              :show-info="true"
              :show-navigation-buttons="true"
          />

          <DxGroupPanel :visible="false"/>

          <DxSorting mode="multiple"/>

          <DxColumn
              data-field="active_ind"
              caption="Ενεργό"
              :hiding-priority="140"
                sort-order="desc"
          >
          </DxColumn>

          <DxColumn
              data-field="leasing_code"
              caption="Κωδικός Μίσθωσης"
                sort-order="asc"
          >
          </DxColumn>

          <DxColumn
              data-field="hw_printer_model_id"
              caption="Μοντέλο Εκτυπωτή"
              :hiding-priority="110"
          >
              <DxLookup
                  :data-source="LookupCustomDS_sims_hw_printer_models" 
                  value-expr="id"
                  display-expr="name"
              />
          </DxColumn>

          <!--
          <DxColumn
              data-field="serial_no"
              caption="Σειριακός αριθμός"
              :hiding-priority="120"
          >
          </DxColumn>
          -->

          <DxColumn
              data-field="building_id"
              caption="Τοποθεσία Εγκατάστασης"
              :hiding-priority="100"
          >
              <DxLookup
                  :data-source="LookupCustomDS_sims_buildings" 
                  value-expr="id"
                  display-expr="name"
              />
          </DxColumn>

          <DxColumn
              data-field="directorate_services_id"
              caption="Υπηρεσία"
              :hiding-priority="90"
          >
              <DxLookup
                  :data-source="LookupCustomDataSourcesims_directorate_services" 
                  value-expr="id"
                  display-expr="name"
              />
          </DxColumn>

           <DxColumn type="buttons">
              <DxButton 
                  text="Επεξεργασία"
                  icon="edit"
                  hint="Επεξεργασία"
                  :on-click="onCustomRowEdit"
              />
              <DxButton name="delete" hint="Διαγραφή"/>
          >
          </DxColumn>

        <DxExport
            :enabled="true"
            :formats="['pdf', 'xlsx']"
            :texts= "{
                    exportAll: 'Εξαγωγή σε {0}',
                }"
        />

      </DxDataGrid>
  </div>
</template>

<script>

const DATAGRIDREFKEY = 'my-data-grid';

const URL_SERVICE = OParameters.Host.URI + 'sims_leasing_hw_printer_devices';
const URL_SERVICE_LOOKUP_sims_hw_pc_printer_models = OParameters.Host.URI + 'sims_hw_printer_models';
const URL_SERVICE_LOOKUP_sims_buildings = OParameters.Host.URI + 'sims_buildings';
const URL_SERVICE_LOOKUP_sims_directorate_services = OParameters.Host.URI + 'sims_directorate_service_departments';

import DxDataGrid, {
  DxColumn,
  DxButton,
  DxEditing, 
  DxPaging,
  DxPager,
  DxGroupPanel,
  DxSorting,
  DxFilterRow,
  DxHeaderFilter,
  DxExport,
  DxSelection,
  DxLookup,
  DxToolbar, 
  DxItem
} from 'devextreme-vue/data-grid';
import { DxPopover } from 'devextreme-vue/popover';

import DxScrollView from 'devextreme-vue/scroll-view';
import DxPopup from 'devextreme-vue/popup';

import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';
import LeasingHwPrinterDevicesFormPage from './sims-leasing-hw-printer-devices-form-page.vue';

import OParameters from '../sims-globals';

export default {
      components: {
          DxDataGrid,
          DxColumn,
          DxButton,
          DxEditing, 
          DxPaging,
          DxPager,
          DxGroupPanel,
          DxSorting,
          DxFilterRow, 
          DxHeaderFilter,
          DxExport,
          DxPopup,
          DxLookup,
          LeasingHwPrinterDevicesFormPage,
          DxScrollView,
          DxSelection,
          DxToolbar, 
          DxItem,
          DxPopover
  },
  data() {
      return {
          IPPattern: "^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$",
          CustomStoreMain: new CustomStore({
              key: 'id',
              //loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE),
              insert: (values) => this.sendRequest(URL_SERVICE, 'POST', values),
              update: (key, values) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
              remove: (key) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
              byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
          }),

          LookupCustomDS_sims_hw_printer_models:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_pc_printer_models+ '?order=name'),
          }),

          LookupCustomDS_sims_buildings:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_buildings+ '?order=name'),
          }),

          LookupCustomDataSourcesims_directorate_services:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_directorate_services+ '?order=name'),
            }),

          isPopupVisible: false,
          isPopoverVisible: false,
          //selectedLeasingHwPrinterDeviceID: -1,
          title: 'Μίσθωση Εκτυπωτών',
          pageSizes: [5, 10, 20],
          dataGridRefKey: DATAGRIDREFKEY,
      };
  }, 
  
  methods: {
        // 
        //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
        //
        sendRequest(url, method = 'GET', values = {}) {
            //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then((response) => response.json()
                .then((json) => {
                    if (response.ok) return json.records;
                    throw json.Message;
                }));
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
        },
        onSelectionChanged(e) {
                this.selectedLeasingHwPrinterDeviceID = e.currentSelectedRowKeys[0];
        },
        onClosePopupEmit() {
            this.isPopupVisible = false;
            this.dataGrid.refresh();
        },
        onCustomInitNewRow() {
                this.selectedLeasingHwPrinterDeviceID = null;
                this.isPopupVisible = true;
        },
        onCustomRowEdit(e) {
            this.selectedLeasingHwPrinterDeviceID = e.row.key
            this.isPopupVisible = true
        },
        onExporting (e){
            OParameters.methods.exportDataGridToPdfExcel(e, this.title)
        },
  },
  computed: {
      dataGrid: function() {
          return this.$refs[DATAGRIDREFKEY].instance;
      }
  },
};
</script>

<style lang="scss">
</style>
