<template>
    <div>    
        <div class="widget-container">
            <DxForm
            id="form"
            :form-data="state.formData"
            label-mode='outside'
            :read-only='false'
            :show-colon-after-label='true'
            label-location='top'
            :min-col-width='300'
            :col-count='2'
            ref="formMaster"
            >

            <DxColCountByScreen :xs="1" :sm="1" :md="1" :lg="1"/>

            <DxGroupItem caption="Βασικά Στοιχεία" :col-span="3" :col-count="3">
                <!--
                    <DxSimpleItem data-field="id"/>
                -->
                <DxSimpleItem
                    data-field="entry_date"
                    editor-type="dxDateBox"
                    :editor-options="dateBoxEditorOptions"
                >
                    <DxLabel text="Ημερομηνία Εισαγωγής"/>
                </DxSimpleItem>

                <DxSimpleItem 
                data-field="code"
                editor-type="dxNumberBox"
                    :is-required="true"
                    :help-text="helpText"
                >
                <DxLabel text="Κωδικός Αναγνώρισης Συσκευής"/>
                    <DxRequiredRule message="O Κωδικός Αναγνώρισης Συσκευής είναι υποχρεωτικός"/>
                    <DxAsyncRule
                    message="O Κωδικός Αναγνώρισης Συσκευής υπάρχει ήδη" 
                    :validation-callback="validateIsUniqueCode"
                />
                </DxSimpleItem>

                <DxSimpleItem 
                    data-field="serial_no"
                >
                    <DxLabel text="Σειριακός Αριθμός"/>
                </DxSimpleItem>

                <DxSimpleItem
                    data-field="hw_pc_desktop_model_id"
                    editor-type="dxSelectBox"
                    searchEnabled = true
                    :editor-options="{
                        dataSource: state.LookupCustomDS_sims_hw_pc_desktop_models,
                        searchEnabled: true,
                        displayExpr: 'name',
                        valueExpr:'id',
                        sort: 'name',
                        onValueChanged: this.onDesktopModelChange
                    }"
                >
                    <DxLabel text="Μοντέλο Desktop"/>
                </DxSimpleItem>

                <DxSimpleItem
                    data-field="hw_processor_type_id"
                    editor-type="dxSelectBox"
                    :editor-options="{
                        dataSource: state.LookupCustomDS_sims_hw_processor_types,
                        searchEnabled: true,
                        displayExpr: 'name',
                        valueExpr:'id'
                    }"
                >
                    <DxLabel text="Τύπος Επεξεργαστή"/>
                </DxSimpleItem>

                <DxSimpleItem
                    data-field="hw_processor_frequency_id"
                    editor-type="dxSelectBox"
                    :editor-options="{
                        dataSource: state.LookupCustomDS_sims_hw_processor_frequencies,
                        searchEnabled: true,
                        displayExpr: 'name',
                        valueExpr:'id'
                    }"
                >
                    <DxLabel text="Συχνότητα Επεξεργαστή (GHz)"/>
                </DxSimpleItem>

                <DxSimpleItem
                    data-field="hw_memory_type_id"
                    editor-type="dxSelectBox"
                    :editor-options="{
                        dataSource: state.LookupCustomDS_sims_hw_memory_types,
                        searchEnabled: true,
                        displayExpr: 'name',
                        valueExpr:'id'
                    }"
                >
                    <DxLabel text="Τύπος μνήμης"/>
                </DxSimpleItem>

                <DxSimpleItem
                    data-field="hw_memory_size_id"
                    editor-type="dxSelectBox"
                    :editor-options="{
                        dataSource: state.LookupCustomDS_sims_hw_memory_sizes,
                        searchEnabled: true,
                        displayExpr: 'name',
                        valueExpr:'id'
                    }"
                >
                    <DxLabel text="Μέγεθος μνήμης (GB)"/>
                </DxSimpleItem>

                <DxSimpleItem
                    data-field="hw_graphic_card_type_id"
                    editor-type="dxSelectBox"
                    :editor-options="{
                        dataSource: state.LookupCustomDS_sims_hw_graphic_card_types,
                        searchEnabled: true,
                        displayExpr: 'name',
                        valueExpr:'id'
                    }"
                >
                    <DxLabel text="Κάρτα Γραφικών"/>
                </DxSimpleItem>

                <DxSimpleItem
                    data-field="hw_harddisk_type_id"
                    editor-type="dxSelectBox"
                    :editor-options="{
                        dataSource: state.LookupCustomDS_sims_hw_harddisk_types,
                        searchEnabled: true,
                        displayExpr: 'name',
                        valueExpr:'id'
                    }"
                >
                    <DxLabel text="Τύπος Σκληρού Δίσκου"/>
                </DxSimpleItem>

                <DxSimpleItem
                    data-field="hw_harddisk_size_id"
                    editor-type="dxSelectBox"
                    :editor-options="{
                        dataSource: state.LookupCustomDS_sims_hw_harddisk_sizes,
                        searchEnabled: true,
                        displayExpr: 'name',
                        valueExpr:'id'
                    }"
                >
                    <DxLabel text="Μέγεθος Σκληρού Δίσκου (GB)"/>
                </DxSimpleItem>

                <DxSimpleItem
                    data-field="hw_2nd_harddisk_type_id"
                    editor-type="dxSelectBox"
                    :editor-options="{
                        dataSource: state.LookupCustomDS_sims_hw_harddisk_types,
                        searchEnabled: true,
                        displayExpr: 'name',
                        valueExpr:'id'
                    }"
                >
                    <DxLabel text="Τύπος 2ου Σκληρού Δίσκου"/>
                </DxSimpleItem>

                <DxSimpleItem
                    data-field="hw_2nd_harddisk_size_id"
                    editor-type="dxSelectBox"
                    :editor-options="{
                        dataSource: state.LookupCustomDS_sims_hw_harddisk_sizes,
                        searchEnabled: true,
                        displayExpr: 'name',
                        valueExpr:'id'
                    }"
                >
                    <DxLabel text="Μέγεθος 2ου Σκληρού Δίσκου (GB)"/>
                </DxSimpleItem>

                <DxSimpleItem
                    data-field="hw_3rd_harddisk_type_id"
                    editor-type="dxSelectBox"
                    :editor-options="{
                        dataSource: state.LookupCustomDS_sims_hw_harddisk_types,
                        searchEnabled: true,
                        displayExpr: 'name',
                        valueExpr:'id'
                    }"
                >
                    <DxLabel text="Τύπος 3ου Σκληρού Δίσκου"/>
                </DxSimpleItem>

                <DxSimpleItem
                    data-field="hw_3rd_harddisk_size_id"
                    editor-type="dxSelectBox"
                    :editor-options="{
                        dataSource: state.LookupCustomDS_sims_hw_harddisk_sizes,
                        searchEnabled: true,
                        displayExpr: 'name',
                        valueExpr:'id'
                    }"
                >
                    <DxLabel text="Μέγεθος 3ου Σκληρού Δίσκου (GB)"/>
                </DxSimpleItem>

                <DxSimpleItem
                    data-field="sw_operating_system_type_id"
                    editor-type="dxSelectBox"
                    :editor-options="{
                        dataSource: state.LookupCustomDS_sims_sw_operating_system_types,
                        searchEnabled: true,
                        displayExpr: 'name',
                        valueExpr:'id'
                    }"
                >
                    <DxLabel text="Λειτουργικό Σύστημα Υπολογιστή"/>
                </DxSimpleItem>

                <DxSimpleItem 
                    data-field="name"
                >
                    <DxLabel text="Όνομα Υπολογιστή"/>
                </DxSimpleItem>

            </DxGroupItem>

            <DxGroupItem caption="Απομακρυσμένος Έλεγχος Υπολογιστή" :col-span="3" :col-count="3">
                <DxSimpleItem data-field="software_remote_control_description">
                    <DxLabel text="Εφαρμογή Απομακρυσμένης Πρόσβασης"/>
                </DxSimpleItem>

                <DxSimpleItem data-field="software_remote_control_id">
                    <DxLabel text="ID/Όνομα Χρήστη"/>
                </DxSimpleItem>

                <DxSimpleItem data-field="software_access_code">
                    <DxLabel text="Κωδικός Πρόσβασης"/>
                </DxSimpleItem>
            </DxGroupItem>

            <DxGroupItem caption="Χρήση Εξοπλισμού" :col-span="3" :col-count="3">
                <DxSimpleItem
                    data-field="assigned_employment_staff_id"
                    editor-type="dxSelectBox"
                    :editor-options="{
                        dataSource: state.LookupCustomDS_sims_employment_staff,
                        searchEnabled: true,
                        displayExpr: 'name',
                        valueExpr:'id'
                    }"
                >
                    <DxLabel text="Υπάλληλος"/>
                </DxSimpleItem>
                
                <DxSimpleItem
                    data-field="assigned_date"
                    editor-type="dxDateBox"
                    :editor-options="dateBoxEditorOptions"
                >
                    <DxLabel text="Έναρξη Χρήσης από"/>
                </DxSimpleItem>

            </DxGroupItem>

            <DxGroupItem caption="Απόσυρση Συσκευής" :col-span="3" :col-count="3">
                <DxSimpleItem
                    data-field="device_disposal_ind"
                    editor-type="dxCheckBox"
                    :editor-options="{
                        onValueChanged: this.onDeviceDisposalIndValueChanged,
                    }"
                >
                    <DxLabel text="Απόσυρση Συσκευής"/>
                </DxSimpleItem>

                <DxSimpleItem
                    data-field="device_disposal_reason"
                    :is-required="true"
                >
                    <DxLabel text="Λόγος Απόσυρσης"/>
                </DxSimpleItem>

                <DxSimpleItem
                    data-field="device_disposal_date"
                    editor-type="dxDateBox"
                    :is-required="true"
                    :editor-options="dateBoxEditorOptions"
                >
                    <DxLabel text="Ημερομηνία Απόσυρσης"/>
                </DxSimpleItem>
            </DxGroupItem>

            <DxGroupItem caption="Επιπλέον Πληροφορίες">
                <DxSimpleItem
                    data-field="description"
                    editor-type="dxTextArea"
                    :editor-options="{placeholder: 'Προσθήκη σχόλιου...', height: 75, rows: 10}"
                >
                    <DxLabel :visible="false" text="Επιπλέον Πληροφορίες"/>
                </DxSimpleItem>
            </DxGroupItem>

            </DxForm>
            
        <div class="dx-fieldset-header">Εγκατεστημένες Εφαρμογές στον Υπολογιστή</div>
        <div class="dx-fieldset">

            <DxDataGrid
                :data-source="state.CustomStoreDetail_sims_sw_software_2_hw_pc_VIEW"
                :key-expr="id"
                :columns="columns"
                :allow-column-reordering="true" :allow-column-resizing="true" :column-auto-width="true"
                :show-borders="true" :show-column-lines="true" :show-row-lines="true"
                :row-alternation-enabled="true" :focused-row-enabled="true" :width="gridWidth"
                :style="{ 'max-width': maxWidth }"
                ref="gridDetail_sims_sw_software_2_hw_pc_VIEW"
                @exporting="onExporting"
                @rowClick="openForm">
            >
                <DxPaging :enabled="true" :page-size="5"/>
                <DxFilterRow :visible="false"/>
                <DxHeaderFilter :visible="false"/>
                <DxPager :visible="true" :allowed-page-sizes="pageSizes" :display-mode="displayMode" :show-info="true" :show-navigation-buttons="true"/>
                <DxGroupPanel :visible="false"/>
                <DxSorting mode="multiple"/>
                <DxColumn data-field="name" caption="Όνομα Εφαρμογής"/>
                <DxColumn data-field="serial_no" caption="Σεριακός Αριθμός"/>
            </DxDataGrid>
        </div>

        <div class="dx-fieldset-header">Στοιχεία Δικτύου Υπολογιστή</div>
        <div class="dx-fieldset">
                <DxDataGrid
                    :data-source="state.CustomStoreDetail_sims_hw_pc_desktop_device_macs"
                    :key-expr="id"
                    :columns="columns"
                    :allow-column-reordering="true" :allow-column-resizing="true" :column-auto-width="true"
                    :show-borders="true" :show-column-lines="true" :show-row-lines="true"
                    :row-alternation-enabled="true" :focused-row-enabled="true" :width="gridWidth"
                    :style="{ 'max-width': maxWidth }"
                    ref="gridDetail_sims_hw_pc_desktop_device_macs"
                    @exporting="onExporting"
                    @rowClick="openForm"
                    @init-new-row="onInitNewRow"
                    @row-validating="onRowValidating">
                >
                    <DxPaging :enabled="true" :page-size="5"/>

                    <DxFilterRow :visible="false"/>
                    <DxHeaderFilter :visible="false"/>
                    <DxPager :visible="true" :allowed-page-sizes="pageSizes" :display-mode="displayMode" :show-info="true" :show-navigation-buttons="true"/>
                    <DxGroupPanel :visible="false"/>
                    <DxSorting mode="multiple"/>
                    <DxEditing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="row"/>

                    <DxColumn data-field="mac_address" caption="Διεύθυνση MAC" 
                        :editor-options="{
                            mask: 'HH:HH:HH:HH:HH:HH',
                            maskChar: '_',
                            maskRules: {
                                'H': /[0-9A-Fa-f]/,
                            },
                            useMaskedValue: true,
                        }"
                    >
                        <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                    </DxColumn>

                </DxDataGrid>
            
                <DxDataGrid
                    :data-source="state.CustomStoreDetail_sims_hw_pc_desktop_device_ips"
                    :key-expr="id"
                    :columns="columns"
                    :allow-column-reordering="true" :allow-column-resizing="true" :column-auto-width="true"
                    :show-borders="true" :show-column-lines="true" :show-row-lines="true"
                    :row-alternation-enabled="true" :focused-row-enabled="true" :width="gridWidth"
                    :style="{ 'max-width': maxWidth }"
                    ref="gridDetail_sims_hw_pc_desktop_device_ips"
                    @exporting="onExporting"
                    @rowClick="openForm"
                    @init-new-row="onInitNewRow"
                    @row-validating="onRowValidating">
                >
                    <DxPaging :enabled="true" :page-size="5"/>

                    <DxFilterRow :visible="false"/>
                    <DxHeaderFilter :visible="false"/>
                    <DxPager :visible="true" :allowed-page-sizes="pageSizes" :display-mode="displayMode" :show-info="true" :show-navigation-buttons="true"/>
                    <DxGroupPanel :visible="false"/>
                    <DxSorting mode="multiple"/>
                    <DxEditing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="batch"/>

                    <DxColumn
                        data-field="network_ip_id"
                        caption="Διεύθυνση IP"
                    >
                        <DxLookup
                            :data-source="state.LookupCustomDS_sims_network_ips" 
                            value-expr="id"
                            display-expr="name"
                        />
                        <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                    </DxColumn>
                </DxDataGrid>
        </div>

        <div class="dx-fieldset-header">Λογαριασμοί Πρόσβασης Υπολογιστή</div>
        <div class="dx-fieldset">
            <DxDataGrid
                :data-source="state.CustomStoreDetail_sims_hw_pc_desktop_device_logins"
                :key-expr="id"
                :columns="columns"
                :allow-column-reordering="true" :allow-column-resizing="true" :column-auto-width="true"
                :show-borders="true" :show-column-lines="true" :show-row-lines="true"
                :row-alternation-enabled="true" :focused-row-enabled="true" :width="gridWidth"
                :style="{ 'max-width': maxWidth }"
                ref="gridDetail_sims_hw_pc_desktop_device_logins"
                @exporting="onExporting"
                @rowClick="openForm"
                @init-new-row="onInitNewRow"
                @row-validating="onRowValidating">
            >
                <DxPaging :enabled="true" :page-size="5"/>

                <DxFilterRow :visible="false"/>
                <DxHeaderFilter :visible="false"/>
                <DxPager :visible="true" :allowed-page-sizes="pageSizes" :display-mode="displayMode" :show-info="true" :show-navigation-buttons="true"/>
                <DxGroupPanel :visible="false"/>
                <DxSorting mode="multiple"/>
                <DxEditing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="row"/>

                <DxColumn data-field="username" caption="Όνομα Χρήστη">
                    <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                </DxColumn>

                <DxColumn data-field="password" caption="Κωδικός Πρόσβασης" :cell-template="(cellElement) => cellElement.textContent = '••••••••'">
                    <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
                </DxColumn>

            </DxDataGrid>
        </div>

        <div id="buttons">
            <DxToolbar>
                <DxItem 
                    location="after"
                    widget="dxButton"
                    @click="onClickSave"
                    :options="{
                        icon: 'save',
                        text: 'Αποθηκευση'
                    }"
                >
                </DxItem>
                <DxItem 
                    location="after" 
                    widget="dxButton"
                    @click="onClickClose"
                    :options="{
                        icon: 'close',
                        text: 'Κλεισιμο'
                    }"
                    >
                </DxItem>
            </DxToolbar>
        </div>
        </div>
    </div>
</template>

<script>

  //Form
  const URL_SERVICE = OParameters.Host.URI + 'sims_hw_pc_desktop_devices';
  
  const URL_SERVICE_LOOKUP_sims_hw_pc_desktop_models = OParameters.Host.URI + 'sims_hw_pc_desktop_models_VIEW';
  const URL_SERVICE_LOOKUP_sims_hw_graphic_card_types = OParameters.Host.URI + 'sims_hw_graphic_card_types';
  const URL_SERVICE_LOOKUP_sims_hw_processor_types = OParameters.Host.URI + 'sims_hw_processor_types';
  const URL_SERVICE_LOOKUP_sims_hw_processor_frequencies = OParameters.Host.URI + 'sims_hw_processor_frequencies';
  const URL_SERVICE_LOOKUP_sims_hw_memory_types = OParameters.Host.URI + 'sims_hw_memory_types';
  const URL_SERVICE_LOOKUP_sims_hw_memory_sizes = OParameters.Host.URI + 'sims_hw_memory_sizes';

  const URL_SERVICE_LOOKUP_sims_hw_harddisk_types = OParameters.Host.URI + 'sims_hw_harddisk_types';
  const URL_SERVICE_LOOKUP_sims_hw_harddisk_sizes = OParameters.Host.URI + 'sims_hw_harddisk_sizes';
  const URL_SERVICE_LOOKUP_sims_sw_operating_system_types = OParameters.Host.URI + 'sims_sw_operating_system_types';
  const URL_SERVICE_LOOKUP_sims_network_ips = OParameters.Host.URI + 'sims_network_ips_VIEW';

  const URL_SERVICE_LOOKUP_sims_employment_staff = OParameters.Host.URI + 'sims_employment_staff_VIEW';

  const URL_SERVICE_LOOKUP_sims_hw_device_codes_VIEW = OParameters.Host.URI + 'sims_hw_device_codes_VIEW';

  //GRIDS
  //sims_hw_pc_desktop_device_logins
  const URL_SERVICE_sims_hw_pc_desktop_device_logins = OParameters.Host.URI + 'sims_hw_pc_desktop_device_logins';
  //sims_hw_pc_desktop_device_ips
  const URL_SERVICE_sims_hw_pc_desktop_device_ips = OParameters.Host.URI + 'sims_hw_pc_desktop_device_ips';
  //sims_hw_pc_desktop_device_macs
  const URL_SERVICE_sims_hw_pc_desktop_device_macs = OParameters.Host.URI + 'sims_hw_pc_desktop_device_macs';
  //sims_sw_software_2_hw_pc_VIEW
  const URL_SERVICE_sims_sw_software_2_hw_pc_VIEW = OParameters.Host.URI + 'sims_sw_software_2_hw_pc_VIEW';

  //SEQUENCE
  const URL_SERVICE_seq_hw_nextval_VIEW = OParameters.Host.URI + 'seq_hw_nextval_VIEW';

  //const DATAGRIDREFKEY = 'my-data-grid';

  import CustomStore from 'devextreme/data/custom_store';

  import { reactive, onMounted, computed} from 'vue';  

  import 'whatwg-fetch';

  import OParameters from '../sims-globals';

  import {
      DxForm, 
      DxLabel,
      DxSimpleItem, 
      //DxRequiredRule,
      DxGroupItem,
      DxColCountByScreen,
  } from 'devextreme-vue/form'; 

  import {
        DxRequiredRule,
        DxAsyncRule,
  } from 'devextreme-vue/validator';

  import DxDataGrid, {
      DxColumn,
      DxEditing, 
      DxPaging,
      DxPager,
      DxGroupPanel,
      DxSorting,
      DxFilterRow,
      DxHeaderFilter,
      DxLookup,
  } from 'devextreme-vue/data-grid';

  import {/*DxTabPanel*/ DxItem} from 'devextreme-vue/tab-panel';

  import DxToolbar from 'devextreme-vue/toolbar';

  export default {
      name: 'HwPcDesktopDevicesFormPage',
      components: {
          DxForm,
          DxLabel,
          DxSimpleItem,
          //DxRequiredRule,
          DxDataGrid,
          DxColumn,
          DxEditing,
          DxPaging,
          DxPager,
          DxGroupPanel,
          DxSorting,
          DxFilterRow,
          DxHeaderFilter,
          DxLookup,
          DxToolbar,
          DxItem,
          DxColCountByScreen,
          DxGroupItem,
          //DxTabPanel,
          DxRequiredRule,
          DxAsyncRule,
    },

    data() {
        return {
            helpText: "",
            title: "Διαχείριση Desktop Υπολογιστή",
            calendarOptions: { value: new Date() },
            textAreaOptions: { placeholder: 'Προσθήκη σχολίου..'},
            pageSizes: [5, 10, 20],
            //dataGridRefKey: DATAGRIDREFKEY,
            dateBoxEditorOptions: {
              //pickerType: 'rollers',
              type: 'date',
              displayFormat: 'dd/MM/yyyy',
              dateSerializationFormat: 'yyyy-MM-dd',
              invalidDateMessage: 'Λάθος Ημερομηνία',
              showClearButton: true,
              //min: new Date(),
              max: new Date(2030, 11, 31),
            },
        }
    },

    props: {
      hw_pc_desktop_device_id: {
          type: Number,
          required: true
      }
    },

    watch: {
      hw_pc_desktop_device_id(newValue, oldValue) {
          this.$emit('hw-pc-desktop-device-id-value-changed', newValue, oldValue);
          this.loadFormData(newValue);
      }
    },

    setup(props) {
      const gridWidth = computed(() => '100%'); // set the width to 100%
      const maxWidth = computed(() => '800px'); // set the max-width to 800px
      const state = reactive({
          formData: {},

          LookupCustomDS_sims_hw_processor_types:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_hw_processor_types + '?order=name'),
          }),

          LookupCustomDS_sims_hw_pc_desktop_models:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_hw_pc_desktop_models + '?order=name'),
                byKey: (key) => sendRequestByKey(URL_SERVICE_LOOKUP_sims_hw_pc_desktop_models + "/" + encodeURIComponent(key)),
          }),

          LookupCustomDS_sims_hw_graphic_card_types:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_hw_graphic_card_types + '?order=name'),
          }),

          LookupCustomDS_sims_hw_processor_frequencies:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_hw_processor_frequencies + '?order=name'),
          }),

          LookupCustomDS_sims_hw_memory_sizes:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_hw_memory_sizes + '?order=name'),
          }),

          LookupCustomDS_sims_hw_memory_types:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_hw_memory_types + '?order=name'),
          }),

          LookupCustomDS_sims_hw_harddisk_types:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_hw_harddisk_types + '?order=name'),
          }),

          LookupCustomDS_sims_hw_harddisk_sizes:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_hw_harddisk_sizes + '?order=name'),
          }),

          LookupCustomDS_sims_sw_operating_system_types:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_sw_operating_system_types + '?order=name'),
          }),

          LookupCustomDS_sims_network_ips:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_network_ips + '?order=name'),
                byKey: (key) => sendRequest(URL_SERVICE_LOOKUP_sims_network_ips + "/" + encodeURIComponent(key)),
          }),

          LookupCustomDS_sims_employment_staff:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_employment_staff + '?order=name'),
          }),

          LookupValidateCustomDS_sims_hw_device_codes_VIEW:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_hw_device_codes_VIEW + '?order=name'),
                byKey: (key) => sendRequestGetRow(URL_SERVICE_LOOKUP_sims_hw_device_codes_VIEW + "/" + encodeURIComponent(key)),
          }),

          CustomStoreDetail_sims_hw_pc_desktop_device_macs: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                cacheRawData: 'false',
                load: () => sendRequest(URL_SERVICE_sims_hw_pc_desktop_device_macs + '?filter=hw_pc_desktop_device_id,eq,' + state.formData.id /*props.hw_pc_desktop_device_id*/),
                insert: (values) => sendRequest(URL_SERVICE_sims_hw_pc_desktop_device_macs, 'POST', values),
                update: (key, values) => sendRequest(URL_SERVICE_sims_hw_pc_desktop_device_macs + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => sendRequest(URL_SERVICE_sims_hw_pc_desktop_device_macs + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => sendRequest(URL_SERVICE_sims_hw_pc_desktop_device_macs + "/" + encodeURIComponent(key)),
        }),

        CustomStoreDetail_sims_hw_pc_desktop_device_ips: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                cacheRawData: 'false',
                load: () => sendRequest(URL_SERVICE_sims_hw_pc_desktop_device_ips + '?filter=hw_pc_desktop_device_id,eq,' + state.formData.id /*props.hw_pc_desktop_device_id*/),
                insert: (values) => sendRequest(URL_SERVICE_sims_hw_pc_desktop_device_ips, 'POST', values),
                update: (key, values) => sendRequest(URL_SERVICE_sims_hw_pc_desktop_device_ips + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => sendRequest(URL_SERVICE_sims_hw_pc_desktop_device_ips + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => sendRequest(URL_SERVICE_sims_hw_pc_desktop_device_ips + "/" + encodeURIComponent(key)),
        }),

        CustomStoreDetail_sims_hw_pc_desktop_device_logins: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                cacheRawData: 'false',
                load: () => sendRequest(URL_SERVICE_sims_hw_pc_desktop_device_logins + '?filter=hw_pc_desktop_device_id,eq,' + state.formData.id /*props.hw_pc_desktop_device_id*/),
                insert: (values) => sendRequest(URL_SERVICE_sims_hw_pc_desktop_device_logins, 'POST', values),
                update: (key, values) => sendRequest(URL_SERVICE_sims_hw_pc_desktop_device_logins + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => sendRequest(URL_SERVICE_sims_hw_pc_desktop_device_logins + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => sendRequest(URL_SERVICE_sims_hw_pc_desktop_device_logins + "/" + encodeURIComponent(key)),
        }),

        CustomStoreDetail_sims_sw_software_2_hw_pc_VIEW:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_sims_sw_software_2_hw_pc_VIEW + '?filter=hw_pc_id,eq,' + state.formData.id /*props.hw_pc_desktop_device_id*/),
        }),

        CustomStore_seq_hw_nextval_VIEW:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                cacheRawData: 'false',
                load: () => sendRequest(URL_SERVICE_seq_hw_nextval_VIEW),
        }),

        CustomStoreMain: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE),
                insert: (values) => sendRequest(URL_SERVICE, 'POST', values),
                update: (key, values) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => {
                    return sendRequestByKey(URL_SERVICE + "/" + encodeURIComponent(key))
                },
        }),
      });

      // 
      //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
      //
      const sendRequest = (url, method = 'GET', values = {}) => {
          //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
          if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then(response => response.json())
                .then(json => {
                    return json.records;
                })
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
      };

      const sendRequestByKey = (url, method = 'GET') => {
          //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
          return fetch(url, {
                    method,
                    headers: {
                        'Accept': 'application/json',
                    },
                    credentials: 'include',
                })
                .then(response => response.json())
      };

      const sendRequestGetRow = (url, method = 'GET') => {
      //console.log(' sendRequestByKey LOG: ' + url + ' METHOD: ' + method);
            return fetch(url, {
                    method, headers: {'Accept': 'application/json',}, credentials: 'include',
            })
            .then(response => response.json())
            .then(json => json)
      };
      
      const saveData = async () => {
          // console.log('Save data');
            return await state.CustomStoreMain.update(state.formData.id, state.formData);
      };

      const insertData = async () => {
          // console.log('Insert data');
           return await state.CustomStoreMain.insert(state.formData);
      };

      const clearForm = () => {
            state.formData = {};
      };

      var isGridValidated = true
      var initialCodeValue
      var isInsertMode = false
  
      onMounted(async () => {
            if (props.hw_pc_desktop_device_id != null) state.formData = await state.CustomStoreMain.byKey(props.hw_pc_desktop_device_id);
      });

      return {
          saveData,
          insertData,
          clearForm,
          gridWidth,
          maxWidth,
          state,
          isGridValidated,
          initialCodeValue,
          isInsertMode
      };
    },
    methods: {
        
        async loadFormData(id) {
            //
            //  nf 28/6/2023 
            //  init disposal value in order to invoke onchange event which disables dispose field
            //
            this.$refs.formMaster.instance.getEditor('device_disposal_ind').option('value', true)

            if (id != null) {
                this.isInsertMode = false
                this.updateLastCode(this.isInsertMode)
                
                this.state.formData = await this.state.CustomStoreMain.byKey(id)
            } else {
                this.getNextVal().then(value => {
                    this.isInsertMode = true
                    this.updateLastCode(this.isInsertMode)
                    this.state.formData = {entry_date: new Date().toISOString().split('T')[0]}
                    this.state.formData.id = value
                });
            }

            this.initialCodeValue = this.state.formData.code
            
            this.$refs.gridDetail_sims_hw_pc_desktop_device_ips.instance.refresh();
            this.$refs.gridDetail_sims_hw_pc_desktop_device_macs.instance.refresh();
            this.$refs.gridDetail_sims_hw_pc_desktop_device_logins.instance.refresh();
            this.$refs.gridDetail_sims_sw_software_2_hw_pc_VIEW.instance.refresh();
        },

        async onDesktopModelChange (e) {
            if (e.event && (e.event.type === 'dxclick' || e.event.type === 'keydown')) {
                // Handle the value change
                const modelrow = await this.state.LookupCustomDS_sims_hw_pc_desktop_models.byKey(e.value)

                this.$refs.formMaster.instance.updateData('hw_graphic_card_type_id', modelrow.hw_graphic_card_type_id)
                this.$refs.formMaster.instance.updateData('hw_processor_type_id', modelrow.hw_processor_type_id)
                this.$refs.formMaster.instance.updateData('hw_processor_frequency_id', modelrow.hw_processor_frequency_id)
                this.$refs.formMaster.instance.updateData('hw_memory_type_id', modelrow.hw_memory_type_id)
                this.$refs.formMaster.instance.updateData('hw_memory_size_id', modelrow.hw_memory_size_id)
                this.$refs.formMaster.instance.updateData('hw_harddisk_type_id', modelrow.hw_harddisk_type_id)
                this.$refs.formMaster.instance.updateData('hw_harddisk_size_id', modelrow.hw_harddisk_size_id)
            }
        },

        onDeviceDisposalIndValueChanged (e) {            
                this.$refs.formMaster.instance.getEditor('device_disposal_reason').option('disabled', (!e.value == true))
                this.$refs.formMaster.instance.getEditor('device_disposal_date').option('disabled', (!e.value == true))

                if (e.value == true) {
                    this.$refs.formMaster.instance.updateData('assigned_employment_staff_id', null)
                    this.$refs.formMaster.instance.updateData('assigned_date', null)
                    this.$refs.formMaster.instance.updateData('network_ip_id', null)

                    let dataCount = this.dataGrid_sims_hw_pc_desktop_device_ips.getDataSource().totalCount()

                    for (let i = 0; i < dataCount; i++) {
                        this.dataGrid_sims_hw_pc_desktop_device_ips.deleteRow(i)
                    }

                }
            
        },
        
        async validateIsUniqueCode(params) {
            const row = await this.state.LookupValidateCustomDS_sims_hw_device_codes_VIEW.byKey(params.value)
            return new Promise((resolve) => {
                if (this.initialCodeValue != params.value) {
                    resolve(row.id === undefined);
                } else {
                    resolve(true)
                }
            });
        },

        
        async updateLastCode(isInsertMode) {
            if (isInsertMode) {
                let lc = await OParameters.methods.getLastInsertedCode()
                this.helpText = "Τελευταίος καταχωρημένος Κωδικός Αναγνώρισης Συσκευής: " + lc[0].code
            } else {
                this.helpText = ""
            }
        },

        async getNextVal() {
            //console.log('getNextVal')
            this.state.CustomStore_seq_hw_nextval_VIEW.clearRawDataCache()
            let row = await this.state.CustomStore_seq_hw_nextval_VIEW.load()
            return row[0].nextval
        },

        onClickSave: async function() {

            const formValidator = this.dataForm.validate();
            this.isGridValidated = true
            let formSaved = false

            if (formValidator.isValid) {
                if (!this.isInsertMode) {
                    await this.saveData()
                    formSaved = true
                } else {
                    await this.insertData()
                    formSaved = true
                }
            }

            await this.dataGrid_sims_hw_pc_desktop_device_macs.saveEditData()
            await this.dataGrid_sims_hw_pc_desktop_device_ips.saveEditData()
            await this.dataGrid_sims_hw_pc_desktop_device_logins.saveEditData()

            if (this.isGridValidated && formSaved) this.$emit('closePopupEvent', true)

        },
        onClickClose: function() {
            this.$emit('closePopupEvent', true);
        },
        onInitNewRow(row) {
            row.data.hw_pc_desktop_device_id = this.state.formData.id;
        },
        onRowValidating(e) {
            this.isGridValidated = this.isGridValidated && e.isValid;
        }

    },
    computed: {   
        dataGrid_sims_hw_pc_desktop_device_macs: function() {
            return this.$refs.gridDetail_sims_hw_pc_desktop_device_macs.instance;
        },      
        dataGrid_sims_hw_pc_desktop_device_ips: function() {
            return this.$refs.gridDetail_sims_hw_pc_desktop_device_ips.instance;
        },
        dataGrid_sims_hw_pc_desktop_device_logins: function() {
            return this.$refs.gridDetail_sims_hw_pc_desktop_device_logins.instance;
        },
        dataGrid_sims_sw_software_2_hw_pc_VIEW: function() {
            return this.$refs.gridDetail_sims_sw_software_2_hw_pc_VIEW.instance;
        },
        dataForm: function() {
            return this.$refs.formMaster.instance;
        },
    }
};
</script>

<style lang="scss">

</style>

<style scoped>

#form {
   margin-top: 25px;
}
.caption {
    font-size: 18px;
    font-weight: 500;
}
.option {
    margin-top: 10px;
}
.dx-fieldset-header {
    font-size: 16px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-top: 4px;
}

</style>
