<template>
  <div>    
    <div class="widget-container">
     
        <DxForm
          id="form"
          :form-data="state.formData"
          label-mode='outside'
          :read-only='false'
          :show-colon-after-label='true'
          label-location='top'
          :min-col-width='300'
          :col-count='1'
          ref="formMaster"
        >

      <DxGroupItem caption="Βασικά Στοιχεία" :col-span="2" :col-count="2">
      <DxSimpleItem 
          data-field="name"
          :is-required="true"
      >
          <DxLabel text="Όνομα Δικτύου"/>
      </DxSimpleItem>

      <DxSimpleItem
          data-field="building_id"
          editor-type="dxSelectBox"
          :editor-options="{
            dataSource: state.LookupCustomDS_sims_buildings,
            displayExpr: 'name',
            valueExpr: 'id'
          }"
      >
          <DxLabel text="Κτίριο"/>
          <DxRequiredRule message="Υποχρεωτικό πεδίο"/>
      </DxSimpleItem>

      </DxGroupItem>

      <DxGroupItem caption="Στοιχεία Δικτύου" :col-span="3" :col-count="3">

<DxSimpleItem data-field="ip_start">
        <DxLabel text="Αρχική IP"/>
        <DxPatternRule
            message="Η διεύθυνση IP πρέπει να έχει σωστή μορφή."
            :pattern="IPPattern"
        />
        <DxRequiredRule 
            message="Υποχρεωτικό πεδίο"
        />
  </DxSimpleItem>

  <DxSimpleItem data-field="ip_end">
        <DxLabel text="Τελευταία IP"/>
        <DxPatternRule
            message="Η διεύθυνση IP πρέπει να έχει σωστή μορφή."
            :pattern="IPPattern"
        />
        <DxRequiredRule 
            message="Υποχρεωτικό πεδίο"
        />
  </DxSimpleItem>

  <DxSimpleItem data-field="gateway">
        <DxLabel text="Προεπιλεγμένη Πύλη"/>
        <DxPatternRule
            message="Η διεύθυνση IP πρέπει να έχει σωστή μορφή."
            :pattern="IPPattern"
        />
        <DxRequiredRule 
            message="Υποχρεωτικό πεδίο"
        />
    </DxSimpleItem>

    <DxSimpleItem data-field="subnet_mask">
        <DxLabel text="Subnet Mask"/>
        <DxPatternRule
            message="Η διεύθυνση IP πρέπει να έχει σωστή μορφή."
            :pattern="IPPattern"
        />
    </DxSimpleItem>

  <DxSimpleItem data-field="dns">
        <DxLabel text="Διακομιστής DNS"/>
        <DxPatternRule
            message="Η διεύθυνση IP πρέπει να έχει σωστή μορφή."
            :pattern="IPPattern"
        />
  </DxSimpleItem>

</DxGroupItem>


          <DxGroupItem caption="Επιπλέον Πληροφορίες">
              <DxSimpleItem 
                  data-field="description"
                  editor-type="dxTextArea"
              >
                  <DxLabel :visible="false" text=""/>
              </DxSimpleItem>
          </DxGroupItem>

        </DxForm>

        <div class="dx-fieldset-header">Στοιχεία Router</div>
        <div class="dx-fieldset">
          
          <DxDataGrid
              :key-expr="id"
              :data-source="state.CustomStoreDetail"
              :columns="columns"
              :allow-column-reordering="true"
              :allow-column-resizing="true"
              :column-auto-width="true"
              :show-borders="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :row-alternation-enabled="true"
              :focused-row-enabled="true"
              :width="gridWidth"
              :style="{ 'max-width': maxWidth }"
              :word-wrap-enabled="true"
              ref="gridDetail"

              @exporting="onExporting"
              @rowClick="openForm"

          >
              <DxSorting mode="multiple"/>

              <!--
            <DxEditing
                :allow-updating="false"
                :allow-adding="false"
                :allow-deleting="false"
                mode="batch"
            />
            -->

              <DxColumn data-field="code" caption="Κωδικός"/>
              <DxColumn data-field="name" caption="Μοντέλο"/>
              <DxColumn data-field="ip" caption="IP"/>
              <DxColumn data-field="username" caption="Όνομα Χρήστη"/>
              <DxColumn data-field="password" caption="Κωδικός Πρόσβασης" :cell-template="(cellElement) => cellElement.textContent = '••••••••'"/>
              <DxColumn data-field="ssid" caption="SSID"/>
              <DxColumn data-field="wpa" caption="WPA"/>
              <DxColumn data-field="wpa_passphrase" caption="WPA Passphrase"/>

              <!--
              <DxColumn
                  data-field="phone_number_type_id"
                  caption="Τύπος Αριθμού"
              >
                  <DxLookup
                  :data-source="state.LookupCustomDS_sims_telephony_number_types" 
                  value-expr="id"
                  display-expr="name"
                  />
              </DxColumn>
              -->

          </DxDataGrid>
          </div>
        
          <div class="dx-fieldset-header">Switch στο Δίκτυο</div>
        <div class="dx-fieldset">
          
          <DxDataGrid
              :key-expr="id"
              :data-source="state.CustomStoreDetail_sims_hw_switch_devices_VIEW"
              :columns="columns"
              :allow-column-reordering="true"
              :allow-column-resizing="true"
              :column-auto-width="true"
              :show-borders="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :row-alternation-enabled="true"
              :focused-row-enabled="true"
              :width="gridWidth"
              :style="{ 'max-width': maxWidth }"
              :word-wrap-enabled="true"
              ref="gridDetail_sims_hw_switch_devices_VIEW"

              @exporting="onExporting"
              @rowClick="openForm">

          >
              <DxSorting mode="multiple"/>

              <DxColumn data-field="code" caption="Κωδικός"/>
              <DxColumn data-field="name" caption="Μοντέλο"/>
              <DxColumn data-field="ip" caption="IP"/>
              <DxColumn data-field="username" caption="Όνομα Χρήστη"/>
              <DxColumn data-field="password" caption="Κωδικός Πρόσβασης" :cell-template="(cellElement) => cellElement.textContent = '••••••••'"/>
              <DxColumn data-field="ssid" caption="SSID"/>
              <DxColumn data-field="wpa" caption="WPA"/>
              <DxColumn data-field="wpa_passphrase" caption="WPA Passphrase"/>


          </DxDataGrid>
          </div>
        
          <div id="buttons">
              <DxToolbar>
                  <dxItem 
                      location="after"
                      widget="dxButton"
                      @click="onClickSave"
                      :options="{
                          icon: 'save',
                          text: 'Αποθηκευση'
                      }"
                  >
                  </dxItem>
                  <dxItem 
                      location="after" 
                      widget="dxButton"
                      @click="onClickClose"
                      :options="{
                          icon: 'close',
                          text: 'Κλείσιμο'
                      }"
                      >
                  </dxItem>
              </DxToolbar>
        </div>
    </div>
</div>
</template>

<script>

  const URL_SERVICE = OParameters.Host.URI + 'sims_networks';  
  const URL_SERVICE_LOOKUP_sims_buildings = OParameters.Host.URI + 'sims_buildings';

  const URL_SERVICE_sims_hw_router_devices_VIEW = OParameters.Host.URI + 'sims_hw_router_devices_VIEW';

  const URL_SERVICE_sims_hw_switch_devices_VIEW = OParameters.Host.URI + 'sims_hw_switch_devices_VIEW';
  
  //const DATAGRIDREFKEY = 'my-data-grid';

  import CustomStore from 'devextreme/data/custom_store';

  import { reactive, onMounted, computed } from 'vue';  
  import 'whatwg-fetch';

  import OParameters from '../sims-globals';

  import {
      DxForm, 
      DxLabel,
      DxSimpleItem, 
      DxRequiredRule,
      DxGroupItem
  } from 'devextreme-vue/form';

  import DxDataGrid, {
      DxColumn,
      DxSorting,
      //DxLookup,
      DxPatternRule
  } from 'devextreme-vue/data-grid';

  import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';

  export default {
      name: 'NetworkFormPage',
      components: {
      DxForm,
      DxLabel,
      DxSimpleItem,
      DxRequiredRule,
      DxDataGrid,
      DxColumn,
      DxSorting,
      //DxLookup,
      DxToolbar,
      DxItem,
      DxGroupItem,
      DxPatternRule
    },
    data() {
        return {
            IPPattern: "^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$",
            title: "Διαχείριση Τηλεφωνίας",
            calendarOptions: { value: new Date() },
            textAreaOptions: { placeholder: 'Προσθήκη σχολίου..'},
            pageSizes: [5, 10, 20],
            //dataGridRefKey: DATAGRIDREFKEY,
            dateBoxEditorOptions: {
                //pickerType: 'rollers',
                type: 'date',
                displayFormat: 'dd/MM/yyyy',
                dateSerializationFormat: 'yyyy-MM-dd',
                invalidDateMessage: 'Λάθος Ημερομηνία',
                showClearButton: true,
                min: new Date(),
                max: new Date(2030, 11, 31)
            },
        }
    },
    props: {
        network_id: {
            type: Number,
            required: true
        }
    },
    watch: {
        network_id(newValue, oldValue) {          
            this.$emit('network_id-value-changed', newValue, oldValue);
            this.loadFormData(newValue);
        }
    },
    setup(props) {
        const gridWidth = computed(() => '100%'); // set the width to 100%
        const maxWidth = computed(() => '1000px'); // set the max-width to 800px
        const state = reactive({
            formData: {},

            LookupCustomDS_sims_buildings:  new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_LOOKUP_sims_buildings+ '?order=name'),
                byKey: (key) => sendRequestByKey(URL_SERVICE_LOOKUP_sims_buildings + "/" + encodeURIComponent(key)),
            }),

            CustomStoreDetail: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_sims_hw_router_devices_VIEW + '?filter=network_id,eq,' + props.network_id),
                insert: (values) => sendRequest(URL_SERVICE_sims_hw_router_devices_VIEW, 'POST', values),
                update: (key, values) => sendRequest(URL_SERVICE_sims_hw_router_devices_VIEW + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => sendRequest(URL_SERVICE_sims_hw_router_devices_VIEW + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => sendRequest(URL_SERVICE_sims_hw_router_devices_VIEW + "/" + encodeURIComponent(key)),
            }),

            CustomStoreDetail_sims_hw_switch_devices_VIEW: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_sims_hw_switch_devices_VIEW + '?filter=network_id,eq,' + props.network_id),
                insert: (values) => sendRequest(URL_SERVICE_sims_hw_switch_devices_VIEW, 'POST', values),
                update: (key, values) => sendRequest(URL_SERVICE_sims_hw_switch_devices_VIEW + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => sendRequest(URL_SERVICE_sims_hw_switch_devices_VIEW+ '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => sendRequest(URL_SERVICE_sims_hw_switch_devices_VIEW + "/" + encodeURIComponent(key)),
            }),

            CustomStoreMain: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE),
                insert: (values) => sendRequest(URL_SERVICE, 'POST', values),
                update: (key, values) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => sendRequestByKey(URL_SERVICE + "/" + encodeURIComponent(key)),
            }),
        });

        // 
        //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
        //
        const sendRequest = (url, method = 'GET', values = {}) => {
            //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                  return fetch(url, {
                      method,
                      credentials: 'include',
                  })
                  .then(response => response.json())
                  .then(json => {
                    return json.records;
                  })
              }
              return fetch(url, {
                  method,
                  body: JSON.stringify(values),
                  headers: {
                      'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                  },
                  credentials: 'include',
              }).then((result) => {
                  if (result.ok) {
                      return result.text().then((text) => text && JSON.parse(text));
                  }
                  return result.json().then((json) => {
                      throw json.Message;
                  });
              });
        };

        const sendRequestByKey = (url, method = 'GET') => {
            //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            return fetch(url, {
                      method,
                      headers: {
                          'Accept': 'application/json',
                      },
                      credentials: 'include',
                  })
                  .then(response => response.json())
        };

        const saveData = async () => {
            //console.log('update data');
            await state.CustomStoreMain.update(state.formData.id, state.formData);
        };

        const insertData = async () => {
            console.log('Insert data');
            var x = await state.CustomStoreMain.insert(state.formData);
            console.log(x);
        };

        const clearForm = () => {
            state.formData = {};
        };

        var isGridValidated = true;
    
        onMounted(async () => {
            //state.formData = await state.CustomStoreMain.byKey(props.network_id);
            if (props.network_id != null) state.formData = await state.CustomStoreMain.byKey(props.network_id);

        });

        return {
            saveData,
            insertData,
            clearForm,
            gridWidth,
            maxWidth,
            state,
            isGridValidated
        };
    },
    methods: {
        async loadFormData(id) {
            if (id != undefined) {
                this.state.formData = await this.state.CustomStoreMain.byKey(id);
                /*
                    await this.state.CustomStoreDetail.load();
                    await this.state.CustomStoreDetail_sims_hw_switch_devices_VIEW.load();
                */
            } else {
                this.state.formData = {};
            }
            await this.dataGrid.refresh()
            await this.dataGridSimsHWSwitchDevicesVIEW.refresh();
        },
        onClickSave: async function() {
            const formValidator = this.dataForm.validate();
            this.isGridValidated = true

            if (formValidator.isValid) {
                if (this.state.formData.id != undefined) {
                    this.saveData()
                    await this.dataGrid.saveEditData();
                } else {
                    this.insertData()
                }

                if (this.isGridValidated) {
                    this.$emit('closePopupEvent', true);
                }
            }
        },
        onClickClose: function() {
            this.$emit('closePopupEvent', true);
        },
        onInitNewRow(row) {
            row.data.network_id = this.state.formData.id;
        },        
        onRowValidating(e) {
          this.isGridValidated = this.isGridValidated && e.isValid;
          console.log('onRowValidating')
          console.log(e.isValid)
        }
    },
    computed: {
        dataGrid: function() {
            return this.$refs.gridDetail.instance;
        },
        dataGridSimsHWSwitchDevicesVIEW: function() {
            return this.$refs.gridDetail_sims_hw_switch_devices_VIEW.instance;
        },
        dataForm: function() {
            return this.$refs.formMaster.instance;
        },
    }
};
</script>

<style lang="scss">

</style>

<style scoped>

#form {
   margin-top: 25px;
}
.caption {
    font-size: 18px;
    font-weight: 500;
}
.option {
    margin-top: 10px;
}
</style>
