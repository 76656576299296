<template>
  <dx-scroll-view height="100%" width="100%" class="with-footer single-card">
    <div id="page">
    <DxResponsiveBox
      :screen-by-width="screen"
      single-column-screen="sm"
    >
      <DxRow :ratio="1"/>
      <DxRow :ratio="0.5"/>
      <DxRow :ratio="1"/>

      <DxCol :ratio="0.75"/>
      <DxCol :ratio="0.35"/>
      <DxCol :ratio="0.75"/>

      <DxItem>
        <DxLocation :row="0" :col="1" />
          <img class="login-logo" :src="OParameters.logoLoginImageSource" />
          <!--
            <div class="title">{{title}}</div>
          -->
      </DxItem>

      <DxItem>
          <DxLocation :row="1" :col="0"/>
      </DxItem>
      <DxItem>
        <DxLocation :row="1" :col="1"/>
          <div id="loginbox">
            <div>
              
              <div class="description">{{description}}</div>
            </div>
            <slot />
          </div>
      </DxItem>
      <DxItem>
        <DxLocation :row="1" :col="2"/>
      </DxItem>
      <DxItem>
        <DxLocation :row="2" :col="0" :colspan="3"/>
      </DxItem>
    </DxResponsiveBox>
  </div>

  </dx-scroll-view>
</template>

<script>

import OParameters from '../sims-globals';

import DxScrollView from "devextreme-vue/scroll-view";
import { DxResponsiveBox, DxItem, DxLocation, DxCol, DxRow } from 'devextreme-vue/responsive-box';

import { useRoute } from 'vue-router';
import { watch, ref } from 'vue';

export default {
  components: {
    DxScrollView, 
    DxResponsiveBox, 
    DxItem, 
    DxLocation, 
    DxCol, 
    DxRow,
  },
  data() {
    return {      
      OParameters,
      screen(width) {
        return (width < 700) ? 'sm' : 'lg';
      },
    };
  },
  setup() {
    const route = useRoute();

    const title = ref(route.meta.title);
    const description = ref("");

    watch(() => route.path,
     () => {
        title.value = route.meta.title;
        description.value = route.meta.description;
     }
    )
    return {
      title,
      description,
    }
  }
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
</style>

<style scoped>
html, body { height: 100%; }

.login-logo {
  padding: 15% 20% 10% 20%;
}

#responsiveBox p {
    font-size: 16px;
    padding-top: 10px;
    text-align: center;
}

/*.header { background: #f39e6c }*/
.content { background: #f5e5a6 }
.left-side-bar { background: #94d7c7 }
.right-side-bar { background: #77c7e7 }
.footer { background: #7b9bcf }

</style>
