<template>
  <!-- Your UI components like button to generate doc -->
  <button @click="generateDoc">Generate Document</button>
</template>

<script>
import { defineComponent } from 'vue';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import { saveAs } from 'file-saver';

export default defineComponent({
  name: 'YourComponentName',
  methods: {
    async generateDoc() {
      try {
        const response = await fetch('/openware/templates/template.docx');
        
        const arrayBuffer = await response.arrayBuffer();
        const zip = new PizZip(arrayBuffer);
        const doc = new Docxtemplater().loadZip(zip);

        doc.setData({
          value1: 'BRAVO !!!!!', // replace with your template variables
        });

        try {
          doc.render();
        } catch (error) {
          const e = {
            message: error.message,
            name: error.name,
            stack: error.stack,
            properties: error.properties,
          };
          console.error(JSON.stringify({ error: e }));
          throw error;
        }

        const blob = doc.getZip().generate({ type: 'blob' });
        saveAs(blob, 'output.docx');
      } catch (error) {
        console.error('Error generating document', error);
      }
    },
  },
});
</script>