export default [
  {
    text: "Dashboard",
    level: [1, 2, 3, 4],
    path: "/home",
    icon: "home"
  },
  {
    text: "Λογαριασμός",
    level: [1, 2, 3, 4],
    icon: "user",
    items: [
      {
        text: 'Προφίλ Χρήστη',
        icon: 'spinright',
        path: '/sims-system-users-profile-form-page',
      }
      /*
      {
        text: "Προφίλ Χρήστη old",
        icon: "spinright",
        path: "/profile"
      },
      {
        text: 'Αλλαγή Κωδικού',
        icon: 'spinright',
        path: '/sims-system-users-password-form-page',
      },
      */
    ]
  },
  {
    text: "Χρήστες",
    level: [1, 2],
    icon: "group",
    items: [      
      {
        text: 'Χρήστες',
        icon: 'spinright',
        path: '/sims-system-users-page',
      },
    ]
  },
  {
    text: "Παραμετροποίηση",
    level: [1, 2],    
    icon: "preferences",
    items: [       
      {
        text: "Οργάνωση",
        icon: "overflow",
        items: [
          {
            text: "Κτιριακή Υποδομή",
            icon: "spinright",
            path: "/sims-buildings-page",
          },
          {
            text: 'Σχέσεις εργασίας',
            icon: "spinright",
            path: '/sims-employment-relationships-page',
            //icon: 'folder'
          },
          /*
          {
            text: "Γενική Διεύθυνση",
            //icon: "spinright",
            path: "/sims-directorate-general-page"
          },
          {
            text: 'Διευθύνσεις Υπηρεσιών',
            path: '/sims-directorate-services-page'
            //icon: 'folder'
          },
          {
            text: 'Τμήματα Υπηρεσιών',
            path: '/sims-directorate-service-departments-page',
            //icon: 'folder'
          }, 
          {
            text: 'Γραφεία Υπηρεσιών',
            path: '/sims-directorate-service-department-offices-page',
            //icon: 'folder'
          }
          */
        ]
      },
      {
        text: 'Υπολογιστές/Tablets',
        icon: "overflow",
        items: [
          {
              text: 'Μοντέλα Desktop',
              icon: "spinright",
              path: '/sims-hw-pc-desktop-models-page'
          },
          {
            text: 'Μοντέλα Laptop',
            icon: "spinright",
            path: '/sims-hw-pc-laptop-models-page'
          },
          {
            text: 'Μοντέλα Tablet/Smartphone',
            icon: "spinright",
            path: '/sims-hw-pc-tablet-models-page'
          },          
          {
            text: 'Μοντέλα Server/NAS',
            path: '/sims-hw-pc-server-models-page',
            icon: 'spinright'
          }
        ]
      },
      {
        text: 'Οθόνες',
        icon: "overflow",
        items: [
          {
            text: 'Μοντέλα Οθονών',
            icon: "spinright",
            path: '/sims-hw-monitor-models-page',
          },
          {
            text: 'Διαγώνιος Οθόνης',
            icon: "spinright",
            path: '/sims-hw-screen-diagonals-page',
          },
          {
            text: 'Ανάλυση Οθόνης',
            icon: "spinright",
            path: '/sims-hw-screen-resolutions-page',
          },
          {
            text: 'Ρυθμός Ανανέωσης Οθόνης',
            icon: "spinright",
            path: '/sims-hw-screen-refresh-rates-page',
          },
          {
            text: 'Χρόνος Απόκρισης Οθόνης',
            icon: "spinright",
            path: '/sims-hw-screen-response-times-page',
          }
        ]
      },
      {
        text: 'Πληκτρολόγια',
        icon: "overflow",
        items: [ 
          {
            text: 'Μοντέλα Πληκτρολογίου',
            icon: "spinright",
            path: '/sims-hw-keyboard-models-page',
          }
        ]
      },
      {
        text: 'Ποντίκια',
        icon: "overflow",
        items: [ 
          {
            text: 'Μοντέλα Mouse',
            icon: "spinright",
            path: '/sims-hw-mouse-models-page'
          }
        ]
      },
      {
        text: 'Εκτυπωτές/Σαρωτές',
        icon: "overflow",
        items: [
            {
              text: 'Μοντέλα Εκτυπωτών',
              icon: "spinright",
              path: '/sims-hw-printer-models-page',
            },
            {
              text: 'Κατηγορία Εκτυπωτή',
              icon: "spinright",
              path: '/sims-hw-printer-categories-page',
            },
            {
              text: 'Τεχνολογία Εκτύπωσης',
              icon: "spinright",
              path: '/sims-hw-printer-print-technologies-page',
            },
            {
              text: 'Τύπος Αναλωσίμου',
              icon: "spinright",
              path: '/sims-hw-printer-expendables-page',
            }, 
            {
              text: 'Μοντέλα Σαρωτή',
              icon: "spinright",
              path: '/sims-hw-scanner-models-page',
            },
            {
              text: 'Τύπος Σαρωτή',
              icon: "spinright",
              path: '/sims-hw-scanner-types-page',
            },
            {
              text: 'Ανάλυση Σάρωσης',
              icon: "spinright",
              path: '/sims-hw-scanner-scan-resolutions-page',
            },
            {
              text: 'Βάθος Χρώματος',
              icon: "spinright",
              path: '/sims-hw-scanner-color-depths-page',
            }
        ]
      },
      {
        text: 'Βασικά Εξαρτήματα Η/Υ',
        icon: "overflow",
        items: [
          {
            text: 'Τύπος Επεξεργαστή',
            icon: "spinright",
            path: '/sims-hw-processor-types-page',
            //icon: 'folder'
          },
          {
            text: 'Συχνότητα Επεξεργαστή',
            icon: "spinright",
            path: '/sims-hw-processor-frequencies-page',
            //icon: 'folder'
          },
          {
            text: 'Τύπος RAM',
            icon: "spinright",
            path: '/sims-hw-memory-types-page',
            //icon: 'folder'
          },
          {
            text: 'Μέγεθος RAM',
            icon: "spinright",
            path: '/sims-hw-memory-sizes-page',
            //icon: 'folder'
          },
          {
            text: 'Τύπος Σκληρού Δίσκου',
            icon: "spinright",
            path: '/sims-hw-harddisk-types-page',
            //icon: 'folder'
            //icon: "spinright",
          },
          {
            text: 'Μέγεθος Σκληρού Δίσκου',
            icon: "spinright",
            path: '/sims-hw-harddisk-sizes-page',
          },
          {
            text: 'Τύπος Κάρτας Γραφικών',
            icon: "spinright",
            path: '/sims-hw-graphic-card-types-page',
            //icon: 'folder'
          }
        ]
      },
      {
        text: 'Συσκευές Multimedia',
        icon: "overflow",
        items: [
              {
                text: 'Χαρακτηριστικά Συσκευής',
                icon: "spinright",
                path: '/sims-hw-multimedia-device-categories-page',
              },
              {
                text: 'Μοντέλα Συσκευής',
                icon: "spinright",
                path: '/sims-hw-multimedia-device-models-page',
              },
              {
                text: 'Είδη Συσκευής',
                icon: "spinright",
                path: '/sims-hw-multimedia-device-types-page',
              }
          ]
      },
      {
        text: 'Τηλεφωνία',
        icon: "overflow",
        items: [
              {
                text: 'Μοντέλα Συσκευών',
                icon: "spinright",
                path: '/sims-hw-phone-device-models-page',
              },
              {
                text: 'Πάροχοι τηλεφωνίας',
                icon: "spinright",
                path: '/sims-sr-telecom-providers-page',
              },
              {
                text: 'Τύποι Γραμμών',
                icon: "spinright",
                path: '/sims-hw-phone-line-types-page',
              },
          ]
      },
      {
        text: 'Lan/Wan',
        icon: "overflow",
        items: [
          {
            text: 'Μοντέλα Switch',
            icon: "spinright",
            path: '/sims-hw-switch-models-page',
          },
          {
            text: 'Μοντέλα Router',
            icon: "spinright",
            path: '/sims-hw-router-models-page',
          },
          {
            text: 'Τύπος Switch',
            icon: "spinright",
            path: '/sims-hw-switch-types-page',
          },
          {
            text: 'Layers',
            icon: "spinright",
            path: '/sims-hw-switch-layers-page',
          },
        ]
      },
      {
        text: 'Εφαρμογές',
        icon: "overflow",
        items: [            
            {
              text: 'Λειτουργικά Συστήματα',
              icon: "spinright",
              path: '/sims-sw-operating-system-types-page',
            },
            {
              text: 'Εφαρμογές',
              icon: "spinright",
              path: '/sims-sw-applications-page',
            },
          ]
      },
      {
        text: 'Άλλα Εξαρτήματα',
        icon: "overflow",
        items: [
          {
            text: 'Κατηγορία Εξαρτημάτων',
            icon: "spinright",
            path: '/sims-hw-pc-parts-categories-page',
          },
          {
            text: 'Είδος Εξαρτημάτων',
            icon: "spinright",
            path: '/sims-hw-pc-parts-types-page',
          },
          {
            text: 'Εξαρτήματα',
            icon: "spinright",
            path: '/sims-hw-pc-parts-page',
          }
        ]
      }
    ]
  },
  {
    text: "Κεντρική Διαχείριση",
    level: [1, 2, 3],
    icon: "toolbox",
    items: [
              {
                text: 'Υπηρεσίες',
                icon: "overflow",
                items: [
                    /*
                    {
                      text: "Γενική Διεύθυνση",
                      icon: "spinright",
                      path: "/sims-directorate-general-page"
                    },
                    */
                   {
                      text: 'Διευθύνσεις Υπηρεσιών',
                      icon: "spinright",
                      path: '/sims-directorate-services-page'
                      //icon: 'folder'
                    },
                    {
                      text: 'Τμήματα Υπηρεσιών',
                      icon: "spinright",
                      path: '/sims-directorate-service-departments-page',
                      //icon: 'folder'
                    }, 
                    {
                      text: 'Γραφεία Υπηρεσιών',
                      icon: "spinright",
                      path: '/sims-directorate-service-department-offices-page',
                      //icon: 'folder'
                    },
                  ]
              },
              {
                text: 'Προσωπικό',
                icon: "overflow",
                items: [
                      {
                        text: 'Προσωπικό',
                        icon: "spinright",
                        path: '/sims-employment-staff-page',
                        
                      },
                  ]
              },
              {
                text: 'Δίκτυα',
                icon: "overflow",
                items: [
                      {
                        text: 'Δίκτυα LAN & WLAN',
                        path: '/sims-networks-page',
                        icon: "spinright",
                      },
                      {
                        text: 'Προβολή Διευθύνσεων IP',
                        path: '/sims-network-ips-2-hardware-view-page',
                        icon: 'spinright'
                      },
                  ]
              },
              {
                text: 'Τηλεφωνία',
                icon: "overflow",
                items: [
                      {
                        text: 'Τηλεφωνία',
                        path: '/sims-telephony-page',
                        icon: "spinright",
                      },
                      {
                        text: 'Προβολή Αριθμών',
                        path: '/sims-telephony-numbers-2-emploment-staff-view-page',
                        icon: "spinright"
                      }
  
                  ]
              },
              {
                text: 'Εξοπλισμός/Hardware',
                icon: "overflow",
                items: [
                    {
                      text: 'Desktop Υπολογιστές',
                      icon: "spinright",
                      path: '/sims-hw-pc-desktop-devices-page',
                    },
                    {
                      text: 'Laptop Υπολογιστές',
                      icon: "spinright",
                      path: '/sims-hw-pc-laptop-devices-page',
                    },
                    {
                      text: 'Servers/NAS',
                      icon: "spinright",
                      path: '/sims-hw-pc-server-devices-page',
                    },
                    {
                      text: 'Tablets/SmartPhones',
                      icon: "spinright",
                      path: '/sims-hw-pc-tablet-devices-page',
                    },
                    {
                      text: 'Οθόνες',
                      icon: "spinright",
                      path: '/sims-hw-monitor-devices-page',
                    },
                    {
                      text: 'Πληκτρολόγια',
                      icon: "spinright",
                      path: '/sims-hw-keyboard-devices-page',
                    },
                    {
                      text: 'Ποντίκια',
                      icon: "spinright",
                      path: '/sims-hw-mouse-devices-page',
                    },
                    {
                      text: 'Συσκευές Τηλεφωνίας',
                      icon: "spinright",
                      path: '/sims-hw-phone-devices-page',
                    },
                    {
                      text: 'Εκτυπωτές',
                      icon: "spinright",
                      path: '/sims-hw-printer-devices-page',
                    },
                    {
                      text: 'Σαρωτές',
                      icon: "spinright",
                      path: '/sims-hw-scanner-devices-page',
                    },
                    {
                      text: 'Switches',
                      icon: "spinright",
                      path: '/sims-hw-switch-devices-page',
                    },
                    {
                      text: 'Router',
                      icon: "spinright",
                      path: '/sims-hw-router-devices-page',
                    },
                    {
                      text: 'Multimedia Συσκευές',
                      icon: "spinright",
                      path: '/sims-hw-multimedia-devices-page',
                    },
                    {
                      text: 'Εξαρτήματα Υπολογιστών',
                      icon: "spinright",
                      path: '/sims-hw-pc-parts-devices-page',
                    }
                  
                ]
              },
              {
                text: 'Εφαρμογές & Άδειες Χρήσης',
                icon: "overflow",
                items: [
                        {
                          text: 'Προβολή Εφαρμογών',
                          path: '/sims-sw-software-report-page',
                          icon: 'spinright'
                        },
                        {
                          text: 'Διαχείριση Εφαρμογών',
                          icon: "spinright",
                          path: '/sims-sw-software-page',
                          //icon: 'folder'
                        },
                        {
                          text: 'Add/Remove Εφαρμογής σε Υπολογιστή',
                          path: '/sims-sw-software-2-hw-pc-page',
                          icon: 'spinright'
                        }
                  ]
              },
              {
                text: 'Αναλώσιμα Εκτυπωτών',
                icon: "overflow",
                items: [
                        {
                          text: 'Αποθέματα',
                          path: '/sims-warehouse-hw-printer-expendables-stocks-page',
                          icon: 'spinright'
                        },
                        {
                          text: 'Εισαγωγή Aναλωσίμου',
                          path: '/sims-warehouse-hw-printer-expendables-entries-page',
                          icon: 'spinright'
                        },
                        {
                          text: 'Παραχώρηση Αναλωσίμου',
                          path: '/sims-warehouse-hw-printer-expendables-exits-page',
                          icon: 'spinright'
                        }
                  ]
              }
           ]
  },
  {
    text: 'Επιπλέον Υπηρεσίες',
    level: [1, 2, 3],
    icon: "mediumiconslayout",
    items: [
      {
        text: 'Μίσθωση Συσκευών',
        icon: "overflow",
        items: [
          {
            text: 'Μίσθωση Εκτυπωτών',
            path: '/sims-leasing-hw-printer-devices-page',
            icon: 'spinright'
          },
          {
            text: 'Μίσθωση Σαρωτών',
            path: '/sims-leasing-hw-scanner-devices-page',
            icon: 'spinright'
          }
        ]
        //path: '/sims-sw-software-page',
        //icon: 'folder'
      },
      {
        text: 'Αιτήματα/Ημερολόγιο Εργασιών',
        icon: "spinright",
        path: '/sims-requests-page',
        //icon: 'folder'
      },
      /*
      {
        text: 'Απογραφή Ενεργού Εξοπλισμού',
        icon: "spinright",
        path: '/sims-hw-inventory-active-page',
        //icon: 'folder'
      }  
      */
    ]
  },
  {
    text: 'Προσβασιμότητα',
    level: [1, 2],
    icon: "key",
    items: [
      
      {
        text: 'Διαπιστευτήρια Software',
        icon: "spinright",
        path: '/sims-credentials-software-page',
        //icon: 'folder'
      },
      {
        text: 'Διαπιστευτήρια Hardware',
        icon: "spinright",
        path: '/sims-credentials-devices-page',
        //icon: 'folder'
      }
      ]
  },
  {
    text: 'Εκτυπώσεις-Αναφορές',
    level: [1, 2, 3, 4],
    icon: "print",
    items: [
        {
          text: 'Προβολή Διευθύνσεων IP Ανάλογα Με Το Δίκτυο',
          path: '/sims-network-ips-2-hardware-view-report-page',
          icon: 'spinright'
        }, 
        {
          text: 'Προβολή Τηλεφώνων Ανά Κτίριο Υπηρεσίας',
          path: '/sims-telephony-numbers-2-employment-staff-view-report-page',
          icon: 'spinright'
        },
        {
          text: 'Προβολή Όλου Του Εξοπλισμού',
          path: '/sims-hw-view-report-page',
          icon: 'spinright'
        }, 
        {
          text: 'Προβολή Στοιχείων Επικοινωνίας Υπαλλήλων',
          path: '/sims-employment-staff-view-report-page',
          icon: 'spinright'
        }, 
        {
          text: 'Προβολή Εξοπλισμού Ανά Υπάλληλο',
          path: '/sims-hw-2-employment-staff-view-report-page',
          icon: 'spinright'
        }, 
        {
          text: 'Προβολή Εξοπλισμού Προς Απόσυρση',
          path: '/sims-hw-view-disposed-report-page',
          icon: 'spinright'
        }, 
        {
          text: 'Προβολή Ενεργών Συσκευών Μίσθωσης',
          path: '/sims-leasing-hw-view-report-page',
          icon: 'spinright'
        }, 
        {
          text: 'Προβολή Εκκρεμών Αιτημάτων',
          path: '/sims-requests-report-page',
          icon: 'spinright'
        }
      ]
  },
  /*
  {
    text: 'Sims Docx Test',
    level: [1, 2, 3, 4],
    path: '/sims-docx-test',
    icon: 'folder'
  }
  */
];

