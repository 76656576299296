<template>
  <div id="container">
      <h3 class="content-block openware-form-title">{{ title }}</h3>
      <DxPopover
            :shading="true"
            width="70%"
            target="#help-button"
            position="top"
            shading-color="rgba(0, 0, 0, 0.5)"
            v-model:visible="isPopoverVisible"
            :show-title="true"
            title="Βοήθεια: Φόρμα «Διαχείριση Desktop Υπολογιστών»"
            :toolbar-items="[{
                                widget: 'dxButton',
                                location: 'after',
                                options: {
                                    icon: 'close',
                                    onClick: () => {
                                        this.isPopoverVisible = false;
                                    }
                                }
                            }
                            ]"
        >
            <DxScrollView>
                <div class="application-help">
                    <div class="application-help-intro">
                        Στην Ενότητα Διαχείριση Desktop Υπολογιστών, ο χρήστης εισάγει και τροποποιεί στοιχεία που αφορούν τους σταθμούς εργασίας που διαθέτει η υπηρεσία. Η κάθε συσκευή παραχωρείται προς χρήση και συνδέεται με αντίστοιχο υπάλληλο και από αυτά τα στοιχεία-δεδομένα ενημερώνονται σχετικοί πίνακες στην καρτέλα προσωπικού
                    </div>
                    <br />
                    <div class="application-help-content">
                        • Αρχικά ο χρήστης ορίζει έναν κωδικό αναγνώρισης συσκευής. Έναν μοναδικό κωδικό, ο οποίος ορίζεται από την υπηρεσία, για εσωτερική χρήση και συγκεκριμένα για τον διαχωρισμό της συσκευής σε όλο το σύνολο του εξοπλισμού.<br />
                        • Ο χρήστης αφού επιλέξει μοντέλο, ενημερώνονται αυτόματα κάποια από τα πεδία της φόρμας αντλώντας στοιχεία από τους αντίστοιχους πίνακες παραμετροποίησης.<br />
                        • Στην ενότητα «Χρέωση Εξοπλισμού» ο χρήστης ορίζει ποιος υπάλληλος θα χρησιμοποιεί αφ εξής την συσκευή. <i>{Στην καρτέλα του συγκεκριμένου υπαλλήλου θα ενημερωθούν τα αντίστοιχα πεδία με τον τρέχων εξοπλισμό}</i>.<br />
                        • Η ενότητα «Απόσυρση Συσκευής» συμπληρώνεται μόνο εάν η συσκευή είναι μη λειτουργική. Σε αυτή την περίπτωση, με το που ενεργοποιηθεί η Απόσυρση Συσκευής, η εφαρμογή διαγράφει το όνομα του προσωπικού που χρησιμοποιεί την συσκευή.<br />
                        • Η ενότητα «Εγκατεστημένες Εφαρμογές» είναι μόνο για Ανάγνωση. Εδώ ο χρήστης μπορεί να ελέγξει ποιες εφαρμογές υπάρχουν εγκατεστημένες στην συσκευή. <i>{Τα στοιχεία σε αυτό τον πίνακα αντλούνται από το μενού Εφαρμογές & Άδειες Χρήσης}</i>.<br />
                        • Στην ενότητα «Στοιχεία Δικτύου» ο χρήστης καλείται να συμπληρώσει τα αντίστοιχα στοιχεία δικτύου MAC και Διευθύνσεων IP (Διεύθυνση IP επιλέγει από πίνακα που περιλαμβάνει μόνο τις διαθέσιμες-ελεύθερες IP).<br />
                        • Στην Ενότητα «Λογαριασμοί Πρόσβασης» συμπληρώνονται τα διαπιστευτήρια εισόδου και εργασίας στην συσκευή.<br />
                    </div>
                </div>
            </DxScrollView>
        </DxPopover>

        <DxPopup
          :drag-enabled="false"
          :hide-on-outside-click="true"
          :show-close-button="true"
          :show-title="true"

          :columns="columns"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          title="Διαχείριση Desktop Υπολογιστή"
          :width="'90%'" 
          :height="'90%'"
          :toolbar-items="[
                            {
                                widget: 'dxButton',
                                location: 'after',
                                options: {
                                    icon: 'help', 
                                    onClick: () => {
                                        this.isPopoverVisible = !this.isPopoverVisible;
                                    },
                                    elementAttr: {
                                        id: 'help-button'
                                    }
                                }
                            }
                        ]"
          v-model:visible="isPopupVisible"
          titleTemplate="title"
          deferRendering="true"
          :animation="{
                        show: {
                            type: 'pop',
                            duration: 800,
                            from: {
                                scale: 0.90
                            }
                        }, 
                      }"
      >
          <DxScrollView height="100%" width="100%">
              <HwPcDesktopDevicesFormPage
                  ref="hwPcDesktopDevicesFormPage"
                  :hw_pc_desktop_device_id="selectedHwPcDesktopDeviceID" 
                  @closePopupEvent="onClosePopupEmit">
              </HwPcDesktopDevicesFormPage>
          </DxScrollView>

      </DxPopup>
          
      <DxDataGrid
          :key-expr="id"
          :data-source="CustomStoreMain"
          :toolbar="toolbarOptions"
          :columns="columns"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          :selection-mode="'single'"
          :ref="dataGridRefKey"          
          :word-wrap-enabled="true"

          @exporting="onExporting"
          @selectionChanged="onSelectionChanged"
          @init-new-row="onCustomInitNewRow"
      >
          <DxSelection mode="single" />
          <DxEditing
              :allow-deleting="true"
              mode="row"
          />

          <DxPaging
              :enabled="true"
              :page-size="5"
          />
          
          <DxToolbar>
            <DxItem location="after" 
                    widget="dxButton" 
                        :options="{
                            icon: 'add',
                            text: '',
                            hint: 'Προσθήκη',
                        onClick: () => {
                            this.onCustomInitNewRow()
                        }
                    }"
            />
            <DxItem name="exportButton" 
                    :options="{
                            hint: 'Εξαγωγή δεδομένων'

                        }"
                  />
        </DxToolbar>

          <DxFilterRow :visible="true"/>
          <DxHeaderFilter :visible="true" :word-wrap-enabled="true"/>
          
          <DxPager
              :visible="true"
              :allowed-page-sizes="pageSizes"
              :display-mode="displayMode"
              :show-page-size-selector="true"
              :show-info="true"
              :show-navigation-buttons="true"
          />

          <DxGroupPanel :visible="false"/>

          <DxSorting mode="multiple"/>

          <DxColumn
              data-field="code"
              caption="Κωδικός Αναγνώρισης Συσκευής"
              alignment="left"
                sort-order="asc"              
          >
          </DxColumn>

          <DxColumn
              data-field="hw_pc_desktop_model_id"
              caption="Μοντέλο Desktop"
          >
              <DxLookup
                  :data-source="LookupCustomDS_sims_hw_pc_desktop_models" 
                  value-expr="id"
                  display-expr="name"
              />
          </DxColumn>

          <DxColumn
              data-field="serial_no"
              caption="Σειριακός αριθμός"
              :hiding-priority="120"
          >
          </DxColumn>

          <DxColumn
              data-field="hw_processor_type_id"
              caption="Τύπος Επεξεργαστή"
              :hiding-priority="90"
          >
              <DxLookup
                  :data-source="LookupCustomDS_sims_hw_processor_types" 
                  value-expr="id"
                  display-expr="name"
              />
          </DxColumn>

          <DxColumn
              data-field="hw_memory_size_id"
              caption="Μέγεθος μνήμης RAM (GB)"
              :hiding-priority="60"
          >
            <DxLookup
                :data-source="LookupCustomDS_sims_hw_memory_sizes" 
                value-expr="id"
                display-expr="name"
              />
          </DxColumn>

          <DxColumn
              data-field="hw_harddisk_size_id"
              caption="Μέγεθος Δίσκου (GB)"
              :hiding-priority="40"
          >
            <DxLookup
                :data-source="LookupCustomDS_sims_hw_harddisk_sizes" 
                value-expr="id"
                display-expr="name"
              />
          </DxColumn>

          <DxColumn
              data-field="hw_graphic_card_type_id"
              caption="Κάρτα Γραφικών"
              :hiding-priority="100"
          >
          <DxLookup
              :data-source="LookupCustomDS_sims_hw_graphic_card_types" 
              value-expr="id"
              display-expr="name"
              />
          </DxColumn>

          <DxColumn
              data-field="sw_operating_system_type_id"
              caption="Λειτουργικό Σύστημα"
              :hiding-priority="12"
          >
            <DxLookup
                :data-source="LookupCustomDS_sims_sw_operating_system_types" 
                value-expr="id"
                display-expr="name"
              />
          </DxColumn>

          <DxColumn
              data-field="assigned_employment_staff_id"
              caption="Χρήση Εξοπλισμού"
              :hiding-priority="14"
          >
            <DxLookup
                :data-source="LookupCustomDS_sims_employment_staff" 
                value-expr="id"
                display-expr="name"
              />
          </DxColumn>

          <DxColumn
              data-field="device_disposal_ind"
              caption="Απόσυρση Συσκευής"
              :hiding-priority="125"
          >
          </DxColumn>

          <DxColumn type="buttons">
              <DxButton 
                  text="Επεξεργασία"
                  icon="edit"
                  hint="Επεξεργασία"
                  :on-click="onCustomRowEdit"
              />
              <DxButton name="delete" hint="Διαγραφή"/>
          >
          </DxColumn>

        <DxExport
            :enabled="true"
            :formats="['pdf', 'xlsx']"
            :texts= "{
                    exportAll: 'Εξαγωγή σε {0}',
                }"
        />

      </DxDataGrid>
  </div>
</template>

<script>

const DATAGRIDREFKEY = 'my-data-grid';

const URL_SERVICE = OParameters.Host.URI + 'sims_hw_pc_desktop_devices';
//const URL_SERVICE_LOOKUP_sims_hw_pc_desktop_models = OParameters.Host.URI + 'sims_hw_pc_desktop_models_VIEW';
const URL_SERVICE_LOOKUP_sims_hw_pc_desktop_models = OParameters.Host.URI + 'sims_hw_pc_desktop_models';
const URL_SERVICE_LOOKUP_sims_hw_graphic_card_types = OParameters.Host.URI + 'sims_hw_graphic_card_types';
const URL_SERVICE_LOOKUP_sims_hw_processor_types = OParameters.Host.URI + 'sims_hw_processor_types';
const URL_SERVICE_LOOKUP_sims_hw_processor_frequencies = OParameters.Host.URI + 'sims_hw_processor_frequencies';
const URL_SERVICE_LOOKUP_sims_hw_memory_types = OParameters.Host.URI + 'sims_hw_memory_types';
const URL_SERVICE_LOOKUP_sims_hw_memory_sizes = OParameters.Host.URI + 'sims_hw_memory_sizes';
const URL_SERVICE_LOOKUP_sims_hw_harddisk_types = OParameters.Host.URI + 'sims_hw_harddisk_types';
const URL_SERVICE_LOOKUP_sims_hw_harddisk_sizes = OParameters.Host.URI + 'sims_hw_harddisk_sizes';
const URL_SERVICE_LOOKUP_sims_sw_operating_system_types = OParameters.Host.URI + 'sims_sw_operating_system_types';
const URL_SERVICE_LOOKUP_sims_employment_staff = OParameters.Host.URI + 'sims_employment_staff_VIEW';


import DxDataGrid, {
  DxColumn,
  DxButton,
  DxEditing, 
  DxPaging,
  DxPager,
  DxGroupPanel,
  DxSorting,
  DxFilterRow,
  DxHeaderFilter,
  DxExport,
  DxSelection,
  DxLookup,
  DxToolbar,
  DxItem
} from 'devextreme-vue/data-grid';

import DxScrollView from 'devextreme-vue/scroll-view';
import DxPopup from 'devextreme-vue/popup';
import { DxPopover } from 'devextreme-vue/popover';

import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';

import HwPcDesktopDevicesFormPage from './sims-hw-pc-desktop-devices-form-page.vue';

import OParameters from '../sims-globals';

export default {
      components: {
          DxDataGrid,
          DxColumn,
          DxButton,
          DxEditing, 
          DxPaging,
          DxPager,
          DxGroupPanel,
          DxSorting,
          DxFilterRow, 
          DxHeaderFilter,
          DxExport,
          DxPopup,
          DxLookup,
          HwPcDesktopDevicesFormPage,
          DxScrollView,
          DxSelection,
          DxToolbar,
          DxItem,
          DxPopover
  },
  data() {
      return {
          IPPattern: "^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$",
          CustomStoreMain: new CustomStore({
              key: 'id',
              //loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE),
              insert: (values) => this.sendRequest(URL_SERVICE, 'POST', values),
              update: (key, values) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
              remove: (key) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
              byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
          }),

          LookupCustomDS_sims_hw_processor_types:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_processor_types + '?order=name'),
          }),

          LookupCustomDS_sims_hw_pc_desktop_models:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_pc_desktop_models+ '?order=name'),
          }),

          LookupCustomDS_sims_hw_graphic_card_types:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_graphic_card_types+ '?order=name'),
          }),

          LookupCustomDS_sims_hw_processor_frequencies:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_processor_frequencies + '?order=name'),
          }),

          LookupCustomDS_sims_hw_memory_sizes:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_memory_sizes + '?order=name'),
          }),

          LookupCustomDS_sims_hw_memory_types:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_memory_types + '?order=name'),
          }),

          LookupCustomDS_sims_hw_harddisk_types:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_harddisk_types+ '?order=name'),
          }),

          LookupCustomDS_sims_hw_harddisk_sizes:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_harddisk_sizes+ '?order=name'),
          }),

          LookupCustomDS_sims_sw_operating_system_types:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_sw_operating_system_types+ '?order=name'),
          }),

          LookupCustomDS_sims_employment_staff:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_employment_staff+ '?order=name'),
          }),

          isPopupVisible: false,
          isPopoverVisible: false,
          selectedHwPcDesktopDeviceID: null,
          title: 'Desktop Υπολογιστές',
          popUpTitle: 'Διαχείριση Desktop Υπολογιστή',
          pageSizes: [5, 10, 20],
          dataGridRefKey: DATAGRIDREFKEY,
          toolbarOptions: {
              items: [
                  {
                      widget: 'dxButton',
                      options: {
                          icon: 'add',
                          text: 'Προσθηκη',
                          onClick: () => {
                              this.onCustomInitNewRow()
                          }
                      }
                  }
              ]
          }
      };
  }, 
  
  methods: {
        // 
        //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
        //
        sendRequest(url, method = 'GET', values = {}) {
            // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then((response) => response.json()
                .then((json) => {
                    if (response.ok) return json.records;
                    throw json.Message;
                }));
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
        },
        onSelectionChanged(e) {
                this.selectedHwPcDesktopDeviceID = e.currentSelectedRowKeys[0];
        },
        onClosePopupEmit() {
                this.isPopupVisible = false;
                this.dataGrid.refresh();
        },
        onCustomInitNewRow() {
                this.selectedHwPcDesktopDeviceID = null;
                this.$refs.hwPcDesktopDevicesFormPage.loadFormData(this.selectedHwPcDesktopDeviceID)
                this.isPopupVisible = true;
        },
        onCustomRowEdit(e) {
                this.selectedHwPcDesktopDeviceID = e.row.key
                this.isPopupVisible = true
        },
        onExporting (e){
            OParameters.methods.exportDataGridToPdfExcel(e, this.title, [])
        }
  },
  computed: {
      dataGrid: function() {
          return this.$refs[DATAGRIDREFKEY].instance;
      }
  },
};
</script>

<style lang="scss">
</style>
