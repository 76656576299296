<template>
  <div>
    <h3 class="content-block openware-form-title">{{ title }}</h3>
        <DxPopover
            :shading="true"
            width="70%"
            target="#help-button"
            position="top"
            shading-color="rgba(0, 0, 0, 0.5)"
            v-model:visible="isPopoverVisible"
            :show-title="true"
            title="Βοήθεια: Φόρμα «Διαχείριση Δικτύου»"
            :toolbar-items="[{
                                widget: 'dxButton',
                                location: 'after',
                                options: {
                                    icon: 'close',
                                    onClick: () => {
                                        this.isPopoverVisible = false;
                                    }
                                }
                            }
                            ]"
            
        >
            <DxScrollView>
                <div class="application-help">
                    <div class="application-help-intro">
                        Στην Ενότητα Διαχείρισης Δικτύων ο χρήστης εισάγει τα διαθέσιμα δίκτυα που υπάρχουν στην υπηρεσία καθώς και το εύρος ζώνης για κάθε δίκτυο ξεχωριστά. Η εφαρμογή δημιουργεί αυτόματα πίνακες με τις διαθέσιμες IP διευθύνσεις, από τις οποίες αντλούνται στοιχεία στους πίνακες εξοπλισμού. Μέσα από αυτήν την ενότητα υπάρχει η δυνατότητα προβολής διαθέσιμων και δεσμευμένων διευθύνσεων IP καθώς και των στοιχείων Router κάθε δικτύου
                    </div>
                    <br />
                    <div class="application-help-content">
                        • Αρχικά ο χρήστης ορίζει ένα όνομα/χαρακτηριστικό του δικτύου για το οποίο θα εισάγει στοιχεία π.χ. «Δίκτυο Δημαρχείου Syzefxis».<br />
                        • Στην Ενότητα «Στοιχεία Δικτύου», ο χρήστης ορίζει την αρχική και τελευταία διεύθυνση IP καθώς και άλλα χρήσιμα στοιχεία. Η Εφαρμογή δημιουργεί αυτόματα πίνακα με όλες τις ενδιάμεσες διευθύνσεις IP π.χ. αν θα δηλωθεί στο πεδίο «Αρχική IP» η τιμή 192.168.1.1 και στο πεδίο  «Τελευταία IP» η τιμή 192.168.1.10, η εφαρμογή θα δημιουργήσει όλες τις διευθύνσεις από 192.168.1.1 έως και 192.168.1.10.<br />
                        • Η ενότητα «Στοιχεία Router» είναι μόνο για ανάγνωση. Εδώ ο χρήστης μπορεί να ελέγξει τα Βασικά Στοιχεία του Router, το οποίο είναι συνδεμένο στο τρέχων δίκτυο. <i>{Τα στοιχεία σε αυτό τον πίνακα αντλούνται από το μενού «Εξοπλισμός/Hardware» - υπομενού «Router» ανάλογα με τις επιλογές στα πεδία «Χρήση σε Δίκτυο}</i>.<br />
                        • Η ενότητα «Switch στο Δίκτυο» είναι μόνο για ανάγνωση. Εδώ εμφανίζονται τα στοιχεία όλων των Switch που χρησιμοποιούνται στο τρέχων δίκτυο. <i>{Τα στοιχεία σε αυτό τον πίνακα αντλούνται από το μενού «Εξοπλισμός/Hardware» - υπομενού «Switches» ανάλογα με τις επιλογές στα πεδία «Χρήση σε Δίκτυο}</i>.
                    </div>
                </div>
            </DxScrollView>
        </DxPopover>

    <DxPopup
        :drag-enabled="false"
        :hide-on-outside-click="true"
        :show-close-button="true"
        :show-title="true"
        v-model:visible="isPopupVisible"
        title="Διαχείριση Δικτύου"
        :width="'90%'" 
        :height="'90%'"
        :toolbar-items="[
                        {
                            widget: 'dxButton',
                            location: 'after',
                            options: {
                                icon: 'help', 
                                onClick: () => {
                                    this.isPopoverVisible = !this.isPopoverVisible;
                                },
                                elementAttr: {
                                    id: 'help-button'
                                }
                            }
                        }
                    ]"
            deferRendering="true"
            :animation="{
                        show: {
                            type: 'pop',
                            duration: 800,
                            from: {
                                scale: 0.90
                            }
                        }, 
                      }"
         
    >
        <DxScrollView height="100%" width="100%">
            <NetworkFormPage 
                :network_id="selectedNetworkID" 
                @closePopupEvent="onClosePopupEmit">
            </NetworkFormPage>
        </DxScrollView>
    </DxPopup>

    <DxDataGrid
        :key-expr="id"
        :data-source="CustomStoreMain"
        :toolbar="toolbarOptions"
        :columns="columns"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :focused-row-enabled="true"
        :selection-mode="'single'"
        :ref="dataGridRefKey"
        :word-wrap-enabled="true"

        @exporting="onExporting"
        @selectionChanged="onSelectionChanged"
        @init-new-row="onCustomInitNewRow"
    >

        <DxPaging 
            :enabled="true"
            :page-size="5"
        />

        <DxToolbar>
            <DxItem location="after" 
                    widget="dxButton" 
                        :options="{
                            icon: 'add',
                            text: '',
                            hint: 'Προσθήκη',
                        onClick: () => {
                            this.onCustomInitNewRow()
                        }
                    }"
            />
            <DxItem name="exportButton" 
                    :options="{
                            hint: 'Εξαγωγή δεδομένων'

                        }"
                  />
        </DxToolbar>

        <DxFilterRow :visible="true"/>
        <DxHeaderFilter :visible="true"/>

        <DxPager
            :visible="true"
            :allowed-page-sizes="pageSizes"
            :display-mode="displayMode"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
        />

        <DxGroupPanel :visible="false"/>

        <DxSorting mode="multiple"/>

        <DxEditing
            :allow-updating="true"
            :allow-adding="true"
            :allow-deleting="true"
            :texts= "{
                    addRow: 'Προσθήκη',
                }"
            
            mode="popup"
        >
            <DxTexts
                save-row-changes="Αποθηκευση"
                cancel-row-changes="Κλεισιμο"
                add-row="Προσθήκη"
                delete-row="Διαγραφή"
                edit-row="Επεξεργασία"
            />
        </DxEditing>

        <DxToolbar>
            <DxItem location="after" 
                    widget="dxButton" 
                        :options="{
                            icon: 'add',
                            text: '',
                            hint: 'Προσθήκη',
                        onClick: () => {
                            this.onCustomInitNewRow()
                        }
                    }"

            />
            <DxItem name="exportButton" 
                    :options="{
                            hint: 'Εξαγωγή δεδομένων'

                        }"
                  />
        </DxToolbar>

        <DxColumn
            data-field="name"
            caption="Όνομα Δικτύου"
            sort-order="asc"
        >
            <DxRequiredRule/>
        </DxColumn>

        <DxColumn
            data-field="building_id"
            caption="Κτίριο"
        >
          <DxLookup
            :data-source="LookupCustomDS_sims_buildings" 
            value-expr="id"
            display-expr="name"
            />
        </DxColumn>

        <DxColumn
            data-field="ip_start"
            caption="Αρχική IP"
            :hiding-priority="20"
        >
            <DxPatternRule
                message="Η διεύθυνση IP πρέπει να έχει σωστή μορφή."
                :pattern="IPPattern"
            />
            <DxRequiredRule 
                message="Υποχρεωτικό πεδίο"
            />
        </DxColumn>

        <DxColumn
            data-field="ip_end"
            caption="Τελευταία IP"
            :hiding-priority="19"
        >
            <DxPatternRule
                message="Η διεύθυνση IP πρέπει να έχει σωστή μορφή."
                :pattern="IPPattern"
            />
            <DxRequiredRule 
                message="Υποχρεωτικό πεδίο"
            />
        </DxColumn>

        <DxColumn
            data-field="gateway"
            caption="Προεπιλεγμένη Πύλη"
            :hiding-priority="18"
        >
            <DxRequiredRule 
                message="Υποχρεωτικό πεδίο"
            />

            <DxPatternRule
                message="Η διεύθυνση IP πρέπει να έχει σωστή μορφή."
                :pattern="IPPattern"
            />
        </DxColumn>

        <DxColumn
            data-field="subnet_mask"
            caption="Subnet Mask"
            :hiding-priority="17"
        >

            <DxPatternRule
                message="Η διεύθυνση IP πρέπει να έχει σωστή μορφή."
                :pattern="IPPattern"
            />

        </DxColumn>

        <DxColumn
            data-field="dns"
            caption="Διακομιστής DNS"
            :hiding-priority="16"
        >
            <DxPatternRule
                message="Η διεύθυνση IP πρέπει να έχει σωστή μορφή."
                :pattern="IPPattern"
            />            
        </DxColumn>

        <DxColumn
            data-field="description"
            caption="Επιπλέον Πληροφορίες"
            :hiding-priority="15"
            >
            
        </DxColumn>

        <DxColumn type="buttons">
                <DxButton 
                    text="Επεξεργασία"
                    icon="edit"
                    hint="Επεξεργασία"
                    :on-click="onCustomRowEdit"
                />
                <DxButton name="delete" hint="Διαγραφή"/>
            >
        </DxColumn>


        <DxExport
            :enabled="true"
            :formats="['pdf', 'xlsx']"
            :texts= "{
                    exportAll: 'Εξαγωγή σε {0}',
                }"
        />


        <DxMasterDetail
          :enabled="true"
          template="masterDetailTemplate"
        />
        <template #masterDetailTemplate="{ data: network_id }">
          <DetailTemplate
            :template-data="network_id"
          />
        </template>
    </DxDataGrid>
  </div>
</template>

<script>
import DxDataGrid, {
    DxColumn,
    DxEditing, 
    DxPaging,
    DxPager,
    DxGroupPanel,
    DxSorting,
    DxFilterRow,
    DxHeaderFilter,
    DxExport,
    DxRequiredRule,
    //DxEmailRule,
    //DxPopup,
    DxLookup,
    DxTexts,
    DxPatternRule,
    DxMasterDetail,
    DxButton,
    DxToolbar,
    DxItem

} from 'devextreme-vue/data-grid';

import DxScrollView from 'devextreme-vue/scroll-view';
import DxPopup from 'devextreme-vue/popup';

import 'whatwg-fetch';

import { DxPopover } from 'devextreme-vue/popover';

import CustomStore from 'devextreme/data/custom_store';

import NetworkFormPage from './sims-networks-form-page.vue';

import DetailTemplate from './sims-network-ips-component.vue';

import OParameters from '../sims-globals';

const DATAGRIDREFKEY = 'my-data-grid';

const URL_SERVICE = OParameters.Host.URI + 'sims_networks';
const URL_SERVICE_LOOKUP_sims_buildings = OParameters.Host.URI + 'sims_buildings';

export default {
        components: {
            DxDataGrid,
            DxColumn,
            DxEditing, 
            DxPaging,
            DxPager,
            DxGroupPanel,
            DxSorting,
            DxFilterRow, 
            DxHeaderFilter,
            DxExport,
            DxRequiredRule,
            //DxEmailRule,
            DxPopup,
            DxLookup,
            DxTexts,
            DxPatternRule,
            DxMasterDetail,
            DetailTemplate,
            NetworkFormPage,
            DxScrollView,
            DxButton,
            DxToolbar,
            DxItem,
            DxPopover
    },
    data() {
        return {
            IPPattern: "^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$",
            CustomStoreMain: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE),
                insert: (values) => this.sendRequest(URL_SERVICE, 'POST', values),
                update: (key, values) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
            }),

            LookupCustomDS_sims_buildings:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_buildings+ '?order=name'),
            }),

            isPopupVisible: false,
            isPopoverVisible: false,
            //selectedTelephonyID: 21,
            title: 'Δίκτυα LAN & WLAN',
            pageSizes: [5, 10, 20],
            dataGridRefKey: DATAGRIDREFKEY,
            toolbarOptions: {
                items: [
                    {
                        widget: 'dxButton',
                        options: {
                            icon: 'add',
                            text: 'Προσθηκη',
                            onClick: () => {
                                this.onCustomInitNewRow()
                            }
                        }
                    }
                ]
            }
        };
    }, 
    
    methods: {
        // 
        //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
        //
        sendRequest(url, method = 'GET', values = {}) {
            // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then((response) => response.json()
                .then((json) => {
                    if (response.ok) return json.records;
                    throw json.Message;
                }));
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {                    
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
        },
        onSelectionChanged(e) {
            this.selectedNetworkID = e.currentSelectedRowKeys[0];
        },
        onClosePopupEmit() {
            this.isPopupVisible = false;
            this.dataGrid.refresh();
        },
        onOpenForm(e) {
          this.$refs.form.open(e.data);
        },
        onCustomInitNewRow() {
            this.isPopupVisible = true;
            this.selectedNetworkID = null;
        },
        onCustomRowEdit(e) {
            this.selectedNetworkID = e.row.key;
            this.isPopupVisible = true;
        },
        onExporting (e){
            OParameters.methods.exportDataGridToPdfExcel(e, this.title, [])
        },

    },
    computed: {
        dataGrid: function() {
            return this.$refs[DATAGRIDREFKEY].instance;
        }
    },

};
</script>

<style lang="scss">
</style>
