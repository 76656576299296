<template>
    <div class="grid">

        <div class="content-block dx-card responsive-paddings">
            <h3>Αποθέματα υλικού</h3>
        <!--
            <DxFunnel
                id="funnel"
                :data-source="CustomDS_sims_dashboard_stats_devices_VIEW"
                palette="Soft Pastel"
                argument-field="Υλικό"
                value-field="Αριθμός"
                inverted="true"
            >

                <DxExport :enabled="true"/>

                <DxTooltip
                    :enabled="true"
                    format="fixedPoint"
                />

                <DxItem>
                    <DxBorder :visible="true"/>
                </DxItem>

                <DxLabel
                    :visible="true"
                    position="inside"
                    background-color="none"
                    :customize-text="formatLabel"
                />
            </DxFunnel>
        -->

        <DxChart
            id="chart"
            :data-source="CustomDS_sims_dashboard_stats_devices_VIEW"
            :rotated="true"
            title=""
            
            palette="soft"
            palette-extension-mode="blend"
            
        >
            <DxCommonSeriesSettings
                argument-field="Υλικό"
                type="stackedbar"
            />

            <DxValueAxis position="right">
                <DxTitle text="Ποσότητες"/>
            </DxValueAxis>

            <DxSeries
                value-field="Συνολικά Αποθέματα"
                name="Συνολικά Αποθέματα"                
            />
            <DxSeries
                value-field="Συνολικά προς Διάθεση"
                name="Συνολικά προς Διάθεση"                
            />
            <DxSeries
                value-field="Προς Απόσυρση-Μη λειτουργικά"
                name="Προς Απόσυρση-Μη λειτουργικά"
            />
            <DxLegend
                vertical-alignment="bottom"
                horizontal-alignment="center"
                item-text-position="top"
            />
            <DxExport :enabled="true"/>
            <DxTooltip
                :enabled="true"
                :customize-tooltip="customizeTooltip"
                location="edge"
            />
        </DxChart>
        </div>

        <div class="content-block dx-card responsive-paddings">
            <h3>Εκκρεμότητες Γραφείου</h3>

            <DxPieChart
                id="pie"
                :data-source="CustomDS_sims_dashboard_stats_requests_VIEW"
                palette="carmine"
                palette-extension-mode="blend"
            >
                <DxSeries
                    argument-field="requests_status"
                    value-field="count"
                >
                    <DxLabel
                        :visible="true"
                        :customize-text="formatLabel"
                    >
                        <DxConnector
                            :visible="true"
                            :width="0.5"
                        />
                        <DxFont :size="16"/>
                    </DxLabel>
                </DxSeries>
                <DxLegendPie
                    :column-count="4"
                    orientation="horizontal"
                    item-text-position="right"
                    horizontal-alignment="center"
                    vertical-alignment="bottom"
                />
                <DxExport :enabled="true"/>
            </DxPieChart>
        </div> 

        <div class="content-block dx-card responsive-paddings">
            <h3>Εκκρεμείς Εργασίες</h3>
            <DxDataGrid
                :key-expr="id"
                :data-source="CustomDS_sims_dashboard_stats_requests_incomplete_VIEW"
                :toolbar="toolbarOptions"
                :columns="columns"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :row-alternation-enabled="true"
                :word-wrap-enabled="true"
            >
                <DxExport :enabled="true"/>

                <DxTitle text="Εκκρεμείς Εργασίες">
                    <DxMargin :bottom="30"/>
                </DxTitle>

                <DxColumn
                    data-field="entry_date"
                    caption="Ημερομηνία Εισαγωγής"
                    data-type="date"
                    format="dd/MM/yyyy"
                    sort-order="asc"
                >
                </DxColumn>

                <DxColumn
                    data-field="staff_name"
                    caption="Όνομα Υπαλλήλου"
                >
                </DxColumn>

                <DxColumn
                    data-field="staff_surname"
                    caption="Επίθετο Υπαλλήλου"
                >
                </DxColumn>

                <DxColumn
                    data-field="name"
                    caption="Περιγραφή Αιτήματος"
                >
                </DxColumn>

            </DxDataGrid>
        </div> 

        <div class="content-block dx-card responsive-paddings">
            <h3>Λήξη Προγραμμάτων Τηλεφωνίας</h3>
            <DxDataGrid
                :key-expr="id"
                :data-source="CustomDS_sims_dashboard_stats_telephony_VIEW"
                :toolbar="toolbarOptions"
                :columns="columns"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :row-alternation-enabled="true"
                :word-wrap-enabled="true"
            >
                <DxExport :enabled="true"/>

                <DxTitle text="Λήξη συμβάσεων υπαλλήλων">
                    <DxMargin :bottom="30"/>
                </DxTitle>

                <DxColumn
                    data-field="name"
                    caption="Όνομα"
                >
                </DxColumn>

                <DxColumn
                    data-field="provider_name"
                    caption="Πάροχος"
                >
                </DxColumn>

                <DxColumn
                    data-field="contract_date_end"
                    caption="Ημερομηνία Λήξης"
                    data-type="date"
                    format="dd/MM/yyyy"
                    sort-order="asc"
                >
                </DxColumn>

            </DxDataGrid>
        </div> 

        <div class="content-block dx-card responsive-paddings">
            <h3>Λήξη συμβάσεων υπαλλήλων</h3>
            <DxDataGrid
                :key-expr="id"
                :data-source="CustomDS_sims_dashboard_stats_employees_VIEW"
                :toolbar="toolbarOptions"
                :columns="columns"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :row-alternation-enabled="true"
                :word-wrap-enabled="true"
            >
                <DxExport :enabled="true"/>

                <DxTitle text="Λήξη συμβάσεων υπαλλήλων">
                    <DxMargin :bottom="30"/>
                </DxTitle>

                <DxColumn
                    data-field="name"
                    caption="Όνομα"
                >
                </DxColumn>

                <DxColumn
                    data-field="surname"
                    caption="Επίθετο"
                >
                </DxColumn>

                <DxColumn
                    data-field="enddate"
                    caption="Ημερομηνία Λήξης"
                    data-type="date"
                    format="dd/MM/yyyy"
                    sort-order="asc"
                >
                </DxColumn>

            </DxDataGrid>
        </div>

        <div class="content-block dx-card responsive-paddings">
            <h3>Αναλωσίμα χωρίς απόθεμα</h3>
            <DxDataGrid
                :key-expr="id"
                :data-source="CustomDS_sims_warehouse_hw_printer_expendables_stocks"
                :columns="columns"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :show-borders="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :row-alternation-enabled="true"
                :focused-row-enabled="true"
                :selection-mode="'single'"
                :ref="dataGridRefKey"
                :word-wrap-enabled="true"

                @exporting="onExporting"
                @selectionChanged="onSelectionChanged"
                @init-new-row="onCustomInitNewRow"
            >
                <DxSelection mode="single" />
                <DxPaging 
                    :enabled="true"
                    :page-size="5"
                />

                <DxFilterRow :visible="true"/>
                <DxHeaderFilter :visible="true" :word-wrap-enabled="true"/>
                
                <DxPager
                    :visible="true"
                    :allowed-page-sizes="pageSizes"
                    :display-mode="displayMode"
                    :show-page-size-selector="true"
                    :show-info="true"
                    :show-navigation-buttons="true"
                />

                <DxGroupPanel :visible="false"/>

                <DxSorting mode="multiple"/>

                <DxColumn
                    data-field="hw_printer_expendable_id"
                    caption="Τύπος Αναλωσίμου"
                    :hiding-priority="110"
                >
                    <DxLookup
                        :data-source="LookupCustomDS_sims_hw_printer_expendables" 
                        value-expr="id"
                        display-expr="name"
                    />
                </DxColumn>

                <!--
                <DxColumn
                    data-field="quantity"
                    caption="Αριθμός αναλωσίμων"
                    :hiding-priority="130"
                >
                </DxColumn>
                -->

                <DxExport
                    :enabled="true"
                />
            </DxDataGrid>
        </div> 

        <div class="content-block dx-card responsive-paddings">
            <h3>Εφαρμογές χωρίς άδεια χρήσης</h3>
            <DxDataGrid
                :key-expr="id"
                :data-source="CustomDS_sims_sw_software_VIEW"
                :columns="columns"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :show-borders="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :row-alternation-enabled="true"
                :focused-row-enabled="true"
                :selection-mode="'single'"
                :ref="dataGridRefKey"          
                :word-wrap-enabled="true"

                @exporting="onExporting"
                @selectionChanged="onSelectionChanged"
                @init-new-row="onCustomInitNewRow"
            >
            <DxSelection mode="single" />
    
            <DxPaging 
                :enabled="true"
                :page-size="5"
            />

            <DxFilterRow :visible="true"/>
            <DxHeaderFilter :visible="true" :word-wrap-enabled="true"/>
            
            <DxPager
                :visible="true"
                :allowed-page-sizes="pageSizes"
                :display-mode="displayMode"
                :show-page-size-selector="true"
                :show-info="true"
                :show-navigation-buttons="true"
            />

            <DxGroupPanel :visible="false"/>

            <DxSorting mode="multiple"/>

            <DxColumn
                data-field="application_name"
                caption="Εφαρμογή"
                :hiding-priority="200"
            >
            </DxColumn>

            <DxColumn
                data-field="serial_no"
                caption="Σειριακός Αριθμός"
                :hiding-priority="190"
            >
            </DxColumn>
            
            <!--
            <DxColumn
                data-field="licenses_quantity"
                caption="Πλήθος αδειών Χρήσης"
                :hiding-priority="180"
            >
            </DxColumn>
            
            <DxColumn
                data-field="commited_licenses_count"
                caption="Δεσμευμένες"
                :hiding-priority="170"
            >
            </DxColumn>
            
            <DxColumn
                data-field="remaining_licenses_count"
                caption="Διαθέσιμες προς εγκατάσταση"
                :hiding-priority="160"
            >
            </DxColumn>
            -->

            <DxExport
                :enabled="true"
            />
        </DxDataGrid>        
        </div>
 
    </div>
</template>

<script>

    const URL_SERVICE_sims_dashboard_stats_devices_VIEW = OParameters.Host.URI + 'sims_dashboard_stats_devices_VIEW';
    const URL_SERVICE_sims_dashboard_stats_employees_VIEW = OParameters.Host.URI + 'sims_dashboard_stats_employees_VIEW';
    const URL_SERVICE_sims_dashboard_stats_telephony_VIEW = OParameters.Host.URI + 'sims_dashboard_stats_telephony_VIEW';
    const URL_SERVICE_sims_dashboard_stats_requests_VIEW = OParameters.Host.URI + 'sims_dashboard_stats_requests_VIEW';
    const URL_SERVICE_sims_dashboard_stats_requests_incomplete_VIEW = OParameters.Host.URI + 'sims_dashboard_stats_requests_incomplete_VIEW';

    const URL_SERVICE_LOOKUP_sims_sw_software_VIEW = OParameters.Host.URI + 'sims_sw_software_VIEW';

    const URL_SERVICE_sims_warehouse_hw_printer_expendables_stocks = OParameters.Host.URI + 'sims_warehouse_hw_printer_expendables_stocks';
    const URL_SERVICE_LOOKUP_sims_hw_printer_expendables = OParameters.Host.URI + 'sims_hw_printer_expendables';

    import CustomStore from 'devextreme/data/custom_store'
    import 'whatwg-fetch'

    import DxPieChart, {
        //DxSeries,
        DxLabel,
        DxMargin,
        //DxExport,
        DxLegend as DxLegendPie,
        //DxAnimation,
        DxConnector,
        DxFont,
    } from 'devextreme-vue/pie-chart';
    /*
    import DxFunnel, {
        DxTitle,
        DxMargin,
        DxExport,
        DxTooltip,
        DxItem,
        DxBorder,
        DxLabel
    } from 'devextreme-vue/funnel';
    */
    import {
        DxChart,
        DxSeries,
        DxCommonSeriesSettings,
        DxValueAxis,
        DxTitle,
        DxLegend,
        DxExport,
        DxTooltip,
    } from 'devextreme-vue/chart'

    import {DxDataGrid, DxColumn, DxLookup,} from 'devextreme-vue/data-grid'

    import OParameters from '../sims-globals'

    export default {
          name: 'SimsDashboardComponent',
          components: {
            DxDataGrid,
            DxColumn,
            DxFont,
            /*
            DxFunnel, 
            DxTitle,
            DxMargin,
            DxExport,
            DxTooltip,
            DxItem,
            DxBorder,
            */
            DxChart,
            DxSeries,
            DxCommonSeriesSettings,
            DxValueAxis,
            DxTitle,
            DxLegend,
            DxExport,
            DxTooltip,
            DxLookup,
            DxPieChart, 
            DxLabel,
            DxMargin,
            //DxAnimation,
            DxConnector,
            DxLegendPie
            //DxLabel
      },
      data() {
          return {
            CustomDS_sims_dashboard_stats_devices_VIEW: new CustomStore({
                  key: 'id',
                  //loadMode: 'raw',
                  load: () => this.sendRequest(URL_SERVICE_sims_dashboard_stats_devices_VIEW + "?order=id,desc"),
                  byKey: (key) => this.sendRequest(URL_SERVICE_sims_dashboard_stats_devices_VIEW + "/" + encodeURIComponent(key)),
              }),

              CustomDS_sims_dashboard_stats_employees_VIEW: new CustomStore({
                  key: 'id',
                  //loadMode: 'raw',
                  load: () => this.sendRequest(URL_SERVICE_sims_dashboard_stats_employees_VIEW),
                  byKey: (key) => this.sendRequest(URL_SERVICE_sims_dashboard_stats_employees_VIEW + "/" + encodeURIComponent(key)),
              }),

              CustomDS_sims_dashboard_stats_telephony_VIEW: new CustomStore({
                  key: 'id',
                  //loadMode: 'raw',
                  load: () => this.sendRequest(URL_SERVICE_sims_dashboard_stats_telephony_VIEW),
                  byKey: (key) => this.sendRequest(URL_SERVICE_sims_dashboard_stats_telephony_VIEW + "/" + encodeURIComponent(key)),
              }),

              CustomDS_sims_dashboard_stats_requests_VIEW: new CustomStore({
                  key: 'id',
                  //loadMode: 'raw',
                  load: () => this.sendRequest(URL_SERVICE_sims_dashboard_stats_requests_VIEW),
                  //byKey: (key) => this.sendRequest(URL_SERVICE_sims_dashboard_stats_requests_VIEW + "/" + encodeURIComponent(key)),
              }),

              CustomDS_sims_dashboard_stats_requests_incomplete_VIEW: new CustomStore({
                  key: 'id',
                  //loadMode: 'raw',
                  load: () => this.sendRequest(URL_SERVICE_sims_dashboard_stats_requests_incomplete_VIEW),
                  //byKey: (key) => this.sendRequest(URL_SERVICE_sims_dashboard_stats_requests_VIEW + "/" + encodeURIComponent(key)),
              }),

              CustomDS_sims_sw_software_VIEW: new CustomStore({
                    key: 'id',
                    //loadMode: 'raw',
                    load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_sw_software_VIEW + '?filter=remaining_licenses_count,eq,0'),
                    byKey: (key) => this.sendRequest(URL_SERVICE_LOOKUP_sims_sw_software_VIEW + "/" + encodeURIComponent(key)),
              }),

              CustomDS_sims_warehouse_hw_printer_expendables_stocks: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_sims_warehouse_hw_printer_expendables_stocks + '?filter=quantity,eq,0'),
                byKey: (key) => this.sendRequest(URL_SERVICE_sims_warehouse_hw_printer_expendables_stocks + "/" + encodeURIComponent(key)),
            }),

            LookupCustomDS_sims_hw_printer_expendables:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_hw_printer_expendables+ '?order=name'),
            }),

            title: 'Laptop Υπολογιστές',

          };
      }, 
      
      methods: {
          // 
          //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
          //
          sendRequest(url, method = 'GET', values = {}) {
              // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
              if (method === 'GET') {
                  return fetch(url, {
                      method,
                      credentials: 'include',
                  })
                  .then((response) => response.json()
                  .then((json) => {
                      if (response.ok) return json.records;
                      throw json.Message;
                  }));
              }
              return fetch(url, {
                  method,
                  body: JSON.stringify(values),
                  headers: {
                      'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                  },
                  credentials: 'include',
              }).then((result) => {
                  if (result.ok) {
                      return result.text().then((text) => text && JSON.parse(text));
                  }
                  return result.json().then((json) => {
                      throw json.Message;
                  });
              });
          },
          formatLabel(pointInfo) {
            //return `${pointInfo.valueText} (${pointInfo.percentText})`;
            return `${pointInfo.valueText}`;
          },
      },
    };
</script>

<style lang="scss">

#funnel .label {
  font-size: 11px;
}

#funnel .value {
  font-size: 11px;
  border: 1px solid white
}

#chart {
  height: 640px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* Default column layout */
}

@media (min-width: 480px) {
  .grid {
    grid-template-columns: repeat(1, 1fr); /* 2 columns for screens wider than 480px */
  }
}

@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr); /* 3 columns for screens wider than 768px */
  }
}

@media (min-width: 1024px) {
  .grid {
    grid-template-columns: repeat(2, 1fr); /* 4 columns for screens wider than 1024px */
  }
}

.dx-card {
    border-radius: 10px !important;
}

</style>
