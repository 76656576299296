<template>
  <div>    
    <div class="widget-container">
     
      <DxForm
            id="form"
            :form-data="state.formData"
            label-mode='outside'
            :read-only='false'
            :show-colon-after-label='true'
            label-location='top'
            :min-col-width='300'
            :col-count='2'        
            onInitialized="onInitialized"
            ref="formMaster"
      >

        <DxColCountByScreen :xs="1" :sm="1" :md="1" :lg="1"/>

        <DxGroupItem caption="Βασικά Στοιχεία" :col-span="3" :col-count="3">

            <DxSimpleItem
                data-field="entry_date"
                editor-type="dxDateBox"
                :editor-options="dateBoxEditorOptions"
            >
                <DxLabel text="Ημερομηνία Εισαγωγής"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="hw_printer_expendable_id"
                editor-type="dxSelectBox"
                searchEnabled = true
                :is-required="true"
                :editor-options="{
                    dataSource: state.LookupCustomDS_sims_hw_printer_expendables,
                    searchEnabled: true,
                    displayExpr: 'name',
                    valueExpr:'id',
                    onValueChanged: this.onHWPrinterExpendablesChange
                }"
            >
                <DxLabel text="Τύπος Αναλωσίμου"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="pages_autonomy_count"
                :editor-options="{
                    readOnly: true
                }"
            >
                 <DxLabel text="Αυτονομία Σελίδων"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="quantity"
                editor-type="dxNumberBox"
                :is-required="true"
            >
                <DxLabel text="Αριθμός αναλωσίμων προς εισαγωγή"/>
            </DxSimpleItem>

            <DxSimpleItem
                data-field="supplier_document_no"
                :is-required="true"
            >
                <DxLabel text="Προμηθευτής / Παραστατικό"/>
            </DxSimpleItem>
        </DxGroupItem>
            
        <DxGroupItem caption="Επιπλέον Πληροφορίες" :col-span="3" :col-count="3">
            <DxSimpleItem
                data-field="description"
                editor-type="dxTextArea"
                :editor-options="{placeholder: 'Προσθήκη σχόλιου...', height: 75, rows: 10}"
            >
                <DxLabel :visible="false" text="Επιπλέον Πληροφορίες"/>
            </DxSimpleItem>
            
        </DxGroupItem>

      </DxForm>

      <div id="buttons">
          <DxToolbar>
              <DxItem 
                  location="after"
                  widget="dxButton"
                  @click="onClickSave"
                  :options="{
                      icon: 'save',
                      text: 'Αποθηκευση'
                  }"
              >
              </DxItem>
              <DxItem 
                  location="after" 
                  widget="dxButton"
                  @click="onClickClose"
                  :options="{
                      icon: 'close',
                      text: 'Κλεισιμο'
                  }"
                  >
              </DxItem>
          </DxToolbar>
    </div>
    </div>
</div>
</template>

<script>

  //Form
  const URL_SERVICE = OParameters.Host.URI + 'sims_warehouse_hw_printer_expendables_entries';
  
  const URL_SERVICE_LOOKUP_sims_hw_printer_expendables = OParameters.Host.URI + 'sims_hw_printer_expendables';

  import CustomStore from 'devextreme/data/custom_store';

  import { reactive, onMounted, computed} from 'vue';  

  import 'whatwg-fetch';

  import OParameters from '../sims-globals';

  import {
      DxForm, 
      DxLabel,
      DxSimpleItem, 
      DxGroupItem,
      DxColCountByScreen,
  } from 'devextreme-vue/form'; 

  import {DxItem} from 'devextreme-vue/tab-panel';

  import DxToolbar from 'devextreme-vue/toolbar';

  export default {
      name: 'WarehouseHWPrinterExpendablesEntriesFormPage',
      components: {
          DxForm,
          DxLabel,
          DxSimpleItem,
          DxToolbar,
          DxItem,
          DxColCountByScreen,
          DxGroupItem,
      },
    data() {
        return {
            title: "Εισαγωγή αναλωσίμου",
            calendarOptions: { value: new Date() },
            textAreaOptions: { placeholder: 'Προσθήκη σχολίου..'},
            pageSizes: [5, 10, 20],
            //dataGridRefKey: DATAGRIDREFKEY,
            dateBoxEditorOptions: {
                //pickerType: 'rollers',
                //value: new Date(),
                type: 'date',
                displayFormat: 'dd/MM/yyyy',
                dateSerializationFormat: 'yyyy-MM-dd',
                invalidDateMessage: 'Λάθος Ημερομηνία',
                showClearButton: true,
                //min: new Date(),
                max: new Date(2030, 11, 31)
            }
        }
    },
    props: {
        warehouse_hw_printer_expendables_entries_id: {
            type: Number,
            required: true
        }
    },
    watch: {
        warehouse_hw_printer_expendables_entries_id(newValue, oldValue) {
            this.$emit('warehouse_hw_printer_expendables_entries_id', newValue, oldValue);
            this.loadFormData(newValue);
        }
    },
    setup(props) {
        const gridWidth = computed(() => '100%'); // set the width to 100%
        const maxWidth = computed(() => '800px'); // set the max-width to 800px
        const state = reactive({
          formData: {},

          LookupCustomDS_sims_hw_printer_expendables:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE_LOOKUP_sims_hw_printer_expendables + '?order=name'),
              byKey: (key) => sendRequestByKey(URL_SERVICE_LOOKUP_sims_hw_printer_expendables + "/" + encodeURIComponent(key)),
          }),


          CustomStoreMain: new CustomStore({
              key: 'id',
              //loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE),
              insert: (values) => sendRequest(URL_SERVICE, 'POST', values),
              update: (key, values) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
              remove: (key) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
              byKey: (key) => sendRequestByKey(URL_SERVICE + "/" + encodeURIComponent(key)),
          })
      });

      // 
      //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
      //
      const sendRequest = (url, method = 'GET', values = {}) => {
            //console.log('sendRequest START LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then(response => response.json())
                .then(json => {
                    return json.records;
                })
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
      };

      const sendRequestByKey = (url, method = 'GET') => {
          //console.log(' sendRequestByKey LOG: ' + url + ' METHOD: ' + method);
          return fetch(url, {
                    method,
                    headers: {
                        'Accept': 'application/json',
                    },
                    credentials: 'include',
                })
                .then(response => response.json())
                .then(json => {
                    return json;
                })
      };

      const updateData = async () => {
            await state.CustomStoreMain.update(state.formData.id, state.formData);
      };

      const insertData = async () => {
            await state.CustomStoreMain.insert(state.formData);
      };

      const clearForm = () => {
          state.formData = {};
      };

      var isGridValidated = true;
  
      onMounted(async () => {
          if (props.warehouse_hw_printer_expendables_entries_id != null) state.formData = await state.CustomStoreMain.byKey(props.warehouse_hw_printer_expendables_entries_id);
      });

      return {
          updateData,
          insertData,
          clearForm,
          gridWidth,
          maxWidth,
          state,
          isGridValidated,
      };
    },
    methods: {
        
        async loadFormData(id) {
            if (id != undefined) {
                this.state.formData = await this.state.CustomStoreMain.byKey(id);
            } else {
                this.state.formData = {};
                this.state.formData.json_2_connection_types = []
            }
        },
              
        async onHWPrinterExpendablesChange (e) {
            if (e.event && (e.event.type === 'dxclick' || e.event.type === 'keydown')) {
                // Handle the value change
                const modelrow = await this.state.LookupCustomDS_sims_hw_printer_expendables.byKey(e.value)

                this.$refs.formMaster.instance.updateData('pages_autonomy_count', modelrow.pages_autonomy_count)
                //this.$refs.formMaster.instance.repaint()
            }
        },

        onClickSave: async function() {

            const formValidator = this.dataForm.validate();
            this.isGridValidated = true

            if (formValidator.isValid) {
                if (this.state.formData.id != undefined) {
                    await this.updateData()
                } else {
                    await this.insertData()
                }
                if (this.isGridValidated) {
                    this.$emit('closePopupEvent', true);
                }
            }

        },
        onClickClose: function() {
            this.$emit('closePopupEvent', true);
        },        
        onInitialized(e) {
            e.component.option('selectedItemKeys', []) //this.state.formData.json_2_connection_types);
        },
        onRowValidating(e) {
            this.isGridValidated = this.isGridValidated && e.isValid;
        },
    },
    computed: {
        dataForm: function() {
            return this.$refs.formMaster.instance;
        },
    }
};
</script>

<style lang="scss">

</style>

<style scoped>
    #form {
    margin-top: 25px;
    }
    .caption {
        font-size: 18px;
        font-weight: 500;
    }
    .option {
        margin-top: 10px;
    }
    .dx-fieldset-header {
        font-size: 16px;
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 10px;
        padding-top: 10px;
        margin-top: 4px;
    }
</style>
