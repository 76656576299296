<template>
  <div id="container">
      <h3 class="content-block openware-form-title">{{ title }}</h3>
 
      <DxPopup
          :drag-enabled="false"
          :hide-on-outside-click="true"
          :show-close-button="true"
          :show-title="true"

          :columns="columns"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          title="Διαχείριση Χρήστη"
          :width="'90%'" 
          :height="'90%'"
          v-model:visible="isPopupVisible"
          titleTemplate="title"
          deferRendering="true"
          :animation="{
                        show: {
                            type: 'pop',
                            duration: 800,
                            from: {
                                scale: 0.90
                            }
                        }, 
                      }"
      >
          <DxScrollView height="100%" width="100%">
              <SystemUsersFormPage
                  ref="systemUsersFormPage"
                  :system_user_id="selectedSystemUserID" 
                  @closePopupEvent="onClosePopupEmit">
              </SystemUsersFormPage>
          </DxScrollView>

      </DxPopup>
          
      <DxDataGrid
          :key-expr="id"
          :data-source="CustomStoreMain"
          :columns="columns"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          :selection-mode="'single'"
          :ref="dataGridRefKey"
          :word-wrap-enabled="true"

          @exporting="onExporting"
          @selectionChanged="onSelectionChanged"
          @init-new-row="onCustomInitNewRow"
      >
          <DxSelection mode="single" />
          <DxEditing
                :allow-updating="true"
                :allow-adding="allowAdding"
                :allow-deleting="allowDeleting"
                mode="row"
          />
            <DxToolbar>
                <DxItem location="after" 
                        widget="dxButton" 
                        :options="{
                            icon: 'add',
                            text: '',
                            hint: 'Προσθήκη',
                            visible: allowAdding,
                            onClick: () => {
                                this.onCustomInitNewRow()
                            }
                        }"
                />
                <DxItem name="exportButton" 
                    :options="{
                            hint: 'Εξαγωγή δεδομένων'

                        }"
                  />
            </DxToolbar>

          <DxPaging 
              :enabled="true"
              :page-size="5"
          />

          <DxFilterRow :visible="true"/>
          <DxHeaderFilter :visible="true" :word-wrap-enabled="true"/>
          
          <DxPager
              :visible="true"
              :allowed-page-sizes="pageSizes"
              :display-mode="displayMode"
              :show-page-size-selector="true"
              :show-info="true"
              :show-navigation-buttons="true"
          />

          <DxGroupPanel :visible="false"/>

          <DxSorting mode="multiple"/>

          <!--
          <DxColumn
              data-field="entry_date"
              caption="Ημερομηνία Εισαγωγής"
              :hiding-priority="140"
          >
          </DxColumn>
          -->

          <DxColumn
              data-field="name"
              caption="Όνομα"
          >
          </DxColumn>

          <DxColumn
              data-field="surname"
              caption="Επίθετο"
          >
          </DxColumn>

          <DxColumn
              data-field="email"
              caption="Email"
          >
          </DxColumn>

          <!--

          <DxColumn
              data-field="description"
              caption="Περιγραφή"
              :hiding-priority="120"
          >
          </DxColumn>

          <DxColumn
                :width="150"
                :allow-sorting="false"
                :calculate-filter-expression="calculateFilterExpression"
                data-field="json_2_connection_types"
                caption="Δυνατότητες Σύνδεσης"
                :cell-template="cellTemplate"
                edit-cell-template="tagBoxEditor"
                :hiding-priority="110"
          >   
                <DxLookup
                    :data-source="LookupCustomDS_sims_hw_connection_types"
                    value-expr="id"
                    display-expr="name"
                />
          </DxColumn>
          -->

          <DxColumn
              data-field="assigned_employment_staff_id"
              caption="Χρήση από Υπάλληλο"
              :hiding-priority="100"
          >
            <DxLookup
                :data-source="LookupCustomDS_sims_employment_staff" 
                value-expr="id"
                display-expr="name"
              />
          </DxColumn>

          <DxColumn
              data-field="system_user_group_id"
              caption="Ομάδα Χρηστών"
              :hiding-priority="100"
          >
            <DxLookup
                :data-source="LookupCustomDS_sims_system_user_groups" 
                value-expr="id"
                display-expr="name"
              />
          </DxColumn>

          <DxColumn
              data-field="active_ind"
              caption="Ενεργός"
              :hiding-priority="90"
          >
          </DxColumn>

          <DxColumn type="buttons">
              <DxButton 
                  text="Επεξεργασία"
                  icon="edit"
                  hint="Επεξεργασία"
                  :on-click="onCustomRowEdit"
              />
              <DxButton name="delete" hint="Διαγραφή"/>
          >
          </DxColumn>

          <DxExport
            :enabled="true"
            :formats="['pdf', 'xlsx']"
            :texts= "{
                    exportAll: 'Εξαγωγή σε {0}',
                }"
          />

          <template #tagBoxEditor="{ data: cellInfo }">
            <ConnectionsΤagBoxComponent
                :value="cellInfo.value"
                :on-value-changed="(value) => onValueChanged(value, cellInfo)"
                :data-source="LookupCustomDS_sims_hw_connection_types"
                :data-grid-component="cellInfo.component"
            />
          </template>

      </DxDataGrid>
  </div>
</template>

<script>

const DATAGRIDREFKEY = 'my-data-grid';

const URL_SERVICE = OParameters.Host.URI + 'sims_system_users_noadmins_VIEW';
const URL_SERVICE_sims_system_users = OParameters.Host.URI + 'sims_system_users';
const URL_SERVICE_LOOKUP_sims_employment_staff = OParameters.Host.URI + 'sims_employment_staff_VIEW';
const URL_SERVICE_LOOKUP_sims_system_user_groups = OParameters.Host.URI + 'sims_system_user_groups';
//const URL_SERVICE_LOOKUP_sims_hw_connection_types = OParameters.Host.URI + 'sims_hw_connection_types';

import DxDataGrid, {
            DxColumn,
            DxButton,
            DxEditing, 
            DxPaging,
            DxPager,
            DxGroupPanel,
            DxSorting,
            DxFilterRow,
            DxHeaderFilter,
            DxExport,
            DxSelection,
            DxLookup,
            DxToolbar, 
            DxItem
} from 'devextreme-vue/data-grid';

import DxScrollView from 'devextreme-vue/scroll-view';
import DxPopup from 'devextreme-vue/popup';
//import { DxPopover } from 'devextreme-vue/popover';

import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';

import SystemUsersFormPage from './sims-system-users-form-page.vue';

import OParameters from '../sims-globals';

export default {
      components: {
          DxDataGrid,
          DxColumn,
          DxButton,
          DxEditing, 
          DxPaging,
          DxPager,
          DxGroupPanel,
          DxSorting,
          DxFilterRow, 
          DxHeaderFilter,
          DxExport,
          DxPopup,
          DxLookup,
          SystemUsersFormPage,
          DxScrollView,
          DxSelection,
          DxToolbar, 
          DxItem,
          //DxPopover

  },
  data() {
      return {
          IPPattern: "^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$",
          CustomStoreMain: new CustomStore({
              key: 'id',
              //loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE + '?filter=system_user_group_id,gt,' + OParameters.loggedUser.system_user_group_id),
              remove: (key) => this.sendRequest(URL_SERVICE_sims_system_users + '/' + encodeURIComponent(key), 'DELETE'),
              /*
              insert: (values) => this.sendRequest(URL_SERVICE, 'POST', values),
              update: (key, values) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
              
              byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
              */
          }),

          LookupCustomDS_sims_system_user_groups: new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_system_user_groups+ '?order=name'),
          }),

          LookupCustomDS_sims_employment_staff:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_employment_staff+ '?order=name'),
          }),

          isPopupVisible: false,
          //isPopoverVisible: false,
          title: 'Χρήστες',
          pageSizes: [5, 10, 20],
          dataGridRefKey: DATAGRIDREFKEY
      };
  }, 
  
  methods: {
        // 
        //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
        //
        sendRequest(url, method = 'GET', values = {}) {
            //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then((response) => response.json()
                .then((json) => {
                    if (response.ok) return json.records;
                    throw json.Message;
                }));
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
        },
        cellTemplate(container, options) {
            /*
                if (options !== undefined) {
                    console.log('Grid cell template');
                    console.log(options);
                }
                */
            const noBreakSpace = '\u00A0';

                var text;
                try {
                    text = (JSON.parse(options.value) || []).map((element) => options.column.lookup.calculateCellValue(element)).join(', ');
                } catch {
                    text = '[]';//(options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(', ');
                }
                //const text = (options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(', ');
                container.textContent = (text || noBreakSpace);
                container.title = text;
        },
        onValueChanged(value, cellInfo) {
                /*
                console.log('Grid value changed');
                console.log(value);
                */

                cellInfo.setValue(JSON.stringify(value));
                //cellInfo.setValue(value);
                cellInfo.component.updateDimensions();
        },
        onSelectionChanged(e) {
                this.selectedSystemUserID = e.currentSelectedRowKeys[0];
        },
        onClosePopupEmit() {
            this.isPopupVisible = false;
            this.dataGrid.refresh();
        },
        onCustomInitNewRow() {            
                this.selectedSystemUserID = null;
                this.$refs.systemUsersFormPage.loadFormData(this.selectedSystemUserID)
                this.isPopupVisible = true;
        },
        onCustomRowEdit(e) {
            this.selectedSystemUserID = e.row.key
            this.$refs.systemUsersFormPage.loadFormData(this.selectedSystemUserID)
            this.isPopupVisible = true          
        },
        onExporting (e){
            OParameters.methods.exportDataGridToPdfExcel(e, this.title)
        },
  },
  computed: {
        dataGrid: function() {
            return this.$refs[DATAGRIDREFKEY].instance;
        },
        allowDeleting() {
            return OParameters.loggedUser.system_user_group_id === 1;
        },
        allowAdding() {
            return OParameters.loggedUser.system_user_group_id === 1;
        },
        allowEditing() {
            return OParameters.loggedUser.system_user_group_id === 1;
        },
  },
};
</script>

<style lang="scss">
</style>
