<template>
  <div id="container">
      <h3 class="content-block openware-form-title">{{ title }}</h3>
        <DxPopover
            :shading="true"
            width="70%"
            target="#help-button"
            position="top"
            shading-color="rgba(0, 0, 0, 0.5)"
            v-model:visible="isPopoverVisible"
            :show-title="true"
            title="Βοήθεια: Φόρμα «Διαχείριση Προσωπικού»"
            :toolbar-items="[{
                                widget: 'dxButton',
                                location: 'after',
                                options: {
                                    icon: 'close',
                                    onClick: () => {
                                        this.isPopoverVisible = false;
                                    }
                                }
                            }
                            ]"
         
        >
            <DxScrollView>
                <div class="application-help">
                    <div class="application-help-intro">
                        Στην Ενότητα Διαχείρισης Προσωπικού, ο χρήστης καλείται να εισάγει απαραίτητα Βασικά στοιχεία για καθένα υπάλληλο ξεχωριστά καθώς και στοιχεία επικοινωνίας. Σε αυτή την ενότητα αποθηκεύει τα «ευαίσθητα» στοιχεία πρόσβασης κάθε υπαλλήλου σε εφαρμογές, Web Application και συσκευές. Τέλος από εδώ ο χρήστης μπορεί να ενημερωθεί για τον Εξοπλισμό που χρησιμοποιεί ο κάθε υπάλληλος ξεχωριστά
                    </div>
                    <br />
                    <div class="application-help-content">
                        • Αρχικά ο χρήστης εισάγει τα Βασικά Στοιχεία του κάθε υπαλλήλου, που είναι τοποθετημένος καθώς και τα στοιχεία επικοινωνίας του.<br />
                        • Αν ο υπάλληλος είναι συμβασιούχος, Ορισμένου χρόνου ή Ειδικός Συνεργάτης συμπληρώνεται και η ημερομηνία λήξης της υπηρεσίας του. Η εφαρμογή ενημερώνει στο Dashboard για την λήξη της σχέσης εργασίας-υπηρεσίας υπαλλήλων.<br />
                        • Στην Ενότητα «Στοιχεία Πρόσβασης Υπαλλήλου» ο χρήστης έχει δυνατότητα να εισάγει διαπιστευτήρια από εφαρμογές και συσκευές στα οποία έχει πρόσβαση ο υπάλληλος.<br />
                        • Η ενότητα «Εξοπλισμός Υπαλλήλου» είναι μόνο για ανάγνωση. Εδώ ο χρήστης μπορεί να ελέγξει, τις συσκευές που χρησιμοποιεί την δεδομένη χρονική στιγμή ο υπάλληλος. {Τα στοιχεία σε αυτό τον πίνακα αντλούνται αυτόματα από το μενού «Εξοπλισμός/Hardware»}. Αν ο πίνακας αυτός είναι κενός σημαίνει ότι στο μενού «Εξοπλισμός/Hardware» δεν έχει καθοριστεί ποιες συσκευές θα χρησιμοποιεί ο υπάλληλος.
                    </div>
                </div>
            </DxScrollView>
        </DxPopover>

        <DxPopup
            :drag-enabled="false"
            :hide-on-outside-click="true"
            :show-close-button="true"
            :show-title="true"
            v-model:visible="isPopupVisible"
            title="Διαχείριση Προσωπικού"
            :width="'90%'" 
            :height="'90%'"
            :toolbar-items="[
                            {
                                widget: 'dxButton',
                                location: 'after',
                                options: {
                                    icon: 'help', 
                                    onClick: () => {
                                        this.isPopoverVisible = !this.isPopoverVisible;
                                    },
                                    elementAttr: {
                                        id: 'help-button'
                                    }
                                }
                            }
                        ]"
        >
          <DxScrollView height="100%" width="100%">
              <EmploymentStaffFormPage
                  ref="employmentStaffFormPage"
                  :employment_staff_id="selectedΕmploymentStaffID"
                  @closePopupEvent="onClosePopupEmit">
              </EmploymentStaffFormPage>
          </DxScrollView>
        </DxPopup>

      <DxDataGrid
          :key-expr="id"
          :data-source="CustomStoreMain"
          :columns="columns"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          :selection-mode="'single'"
          :ref="dataGridRefKey"
          :allow-export-selected-data="true"
          :word-wrap-enabled="true"

          @exporting="onExporting"
          @selectionChanged="onSelectionChanged"
          @init-new-row="onCustomInitNewRow"
      >
          <DxSelection mode="single" />
          <DxEditing
              :allow-deleting="true"
              mode="row"
          />

          <DxPaging 
              :enabled="true"
              :page-size="5"
          />
          
            <DxToolbar>
                <DxItem location="after" 
                        widget="dxButton" 
                        :options="{
                            icon: 'add',
                            text: '',
                            hint: 'Προσθήκη',
                            onClick: () => {
                                this.onCustomInitNewRow()
                            }
                        }"
                />
                <DxItem name="exportButton" 
                    :options="{
                            hint: 'Εξαγωγή δεδομένων'

                        }"
                  />
            </DxToolbar>

          <DxFilterRow :visible="true"/>
          <DxHeaderFilter :visible="true"/>
          
          <DxPager
              :visible="true"
              :allowed-page-sizes="pageSizes"
              :display-mode="displayMode"
              :show-page-size-selector="true"
              :show-info="true"
              :show-navigation-buttons="true"
          />

          <DxGroupPanel :visible="false"/>

          <DxSorting mode="multiple"/>

          <DxColumn
              data-field="surname"
              caption="Επίθετο"
            sort-order="asc"
          >
          </DxColumn>

          <DxColumn
              data-field="name"
              caption="Όνομα"
          >
          </DxColumn>

          <DxColumn
              data-field="employment_capacity_id"
              caption="Ιδιότητα"
              :hiding-priority="9"
          >
            <DxLookup
                :data-source="LookupCustomDS_sims_employment_capacities" 
                value-expr="id"
                display-expr="name"
              />
          </DxColumn>

          <DxColumn
              data-field="employment_relationship_id"
              caption="Σχέση εργασίας "
              :hiding-priority="10"
          >
            <DxLookup
                :data-source="LookupCustomDS_sims_employment_relationships" 
                value-expr="id"
                display-expr="name"
              />
          </DxColumn>

          <DxColumn
              data-field="enddate"
              caption="Ημερομηνία Λήξης Υπηρεσίας"
              dataType="date"
              format="shortDate" 
              :hiding-priority="11"
          >
          </DxColumn>

          <DxColumn
              data-field="building_id"
              caption="Κτίριο"
              :hiding-priority="19"
          >
              <DxLookup
                  :data-source="LookupCustomDS_sims_buildings" 
                  value-expr="id"
                  display-expr="name"
              />
          </DxColumn>

          <DxColumn
              data-field="directorate_service_id"
              caption="Διεύθυνση"
              :hiding-priority="18"
          >
            <DxLookup
              :data-source="LookupCustomDS_sims_directorate_services" 
              value-expr="id"
              display-expr="name"
              />
          </DxColumn>

          <DxColumn
              data-field="directorate_service_department_id"
              caption="Τμήμα"
              :hiding-priority="17"
          >
            <DxLookup
              :data-source="LookupCustomDS_sims_directorate_service_departments" 
              value-expr="id"
              display-expr="name"
              />
          </DxColumn>

          <DxColumn
              data-field="directorate_service_department_office_id"
              caption="Γραφείο"
              :hiding-priority="16"
          >
            <DxLookup
              :data-source="LookupCustomDS_sims_directorate_service_department_offices" 
              value-expr="id"
              display-expr="name"
              />
          </DxColumn>

          <DxColumn
              data-field="phone_telephony_numbers_id"
              caption="Σταθερό Τηλέφωνο"
              :hiding-priority="15"
          >
            <DxLookup
                :data-source="LookupCustomDS_sims_telephony_numbers" 
                value-expr="id"
                display-expr="phone_no"
              />
          </DxColumn>

          <DxColumn
              data-field="mobile_telephony_numbers_id"
              caption="Υπηρεσιακό Κινητό Тηλέφωνο"
              :hiding-priority="14"
          >
            <DxLookup
                :data-source="LookupCustomDS_sims_telephony_numbers" 
                value-expr="id"
                display-expr="phone_no"
              />
          </DxColumn>

          <DxColumn
              data-field="phone_no_1"
              caption="Προσωπικό Κινητό Τηλέφωνο"
              :hiding-priority="13"
          >
          </DxColumn>

          <DxColumn
              data-field="email_1"
              caption="Υπηρεσιακό Еmail"
              :hiding-priority="12"
          >
          </DxColumn>

          <!--
            <DxColumn
              data-field="phone_no_2"
              caption="Προσωπικό Κινητό Τηλέφωνο 2"
              :hiding-priority="2"
          >
          </DxColumn>

          <DxColumn
              data-field="email_2"
              caption="Προσωπικό Email"
              :hiding-priority="3"
          >
          </DxColumn>

          <DxColumn
              data-field="email_3"
              caption="Προσωπικό Email 2"
              :hiding-priority="4"
          >
          </DxColumn>

          <DxColumn
              data-field="description"
              caption="Περιγραφή Προγράμματος Συμβολαίου"
              :hiding-priority="5"
          >
          </DxColumn>
          -->

          <DxColumn type="buttons">
              <DxButton 
                  text="Επεξεργασία"
                  icon="edit"
                  hint="Επεξεργασία"
                  :on-click="onCustomRowEdit"
              />
              <DxButton name="delete" hint="Διαγραφή"/>
          >
          </DxColumn>


          <DxExport
            :enabled="true"
            :formats="['pdf', 'xlsx']"
            :texts= "{
                    exportAll: 'Εξαγωγή σε {0}',
                }"
          />

     </DxDataGrid>

  </div>
</template>

<script>

const DATAGRIDREFKEY = 'my-data-grid';

const URL_SERVICE = OParameters.Host.URI + 'sims_employment_staff';
const URL_SERVICE_LOOKUP_sims_employment_relationships = OParameters.Host.URI + 'sims_employment_relationships';

const URL_SERVICE_LOOKUP_sims_directorate_service_department_offices = OParameters.Host.URI + 'sims_directorate_service_department_offices';

const URL_SERVICE_LOOKUP_sims_directorate_services = OParameters.Host.URI + 'sims_directorate_services';
const URL_SERVICE_LOOKUP_sims_directorate_service_departments = OParameters.Host.URI + 'sims_directorate_service_departments';

const URL_SERVICE_LOOKUP_sims_employment_capacities = OParameters.Host.URI + 'sims_employment_capacities';
const URL_SERVICE_LOOKUP_sims_buildings = OParameters.Host.URI + 'sims_buildings';
const URL_SERVICE_LOOKUP_sims_telephony_numbers = OParameters.Host.URI + 'sims_telephony_numbers';

import DxDataGrid, {
        DxColumn,
        DxButton,
        DxEditing, 
        DxPaging,
        DxPager,
        DxGroupPanel,
        DxSorting,
        DxFilterRow,
        DxHeaderFilter,
        DxExport,
        DxSelection,
        DxLookup,
        DxToolbar,
        DxItem
} from 'devextreme-vue/data-grid';

import DxScrollView from 'devextreme-vue/scroll-view';
import DxPopup from 'devextreme-vue/popup';
import { DxPopover } from 'devextreme-vue/popover';

import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';

import EmploymentStaffFormPage from './sims-employment-staff-form-page.vue';

import OParameters from '../sims-globals';

export default {
      components: {
          DxDataGrid,
          DxColumn,
          DxButton,
          DxEditing, 
          DxPaging,
          DxPager,
          DxGroupPanel,
          DxSorting,
          DxFilterRow, 
          DxHeaderFilter,
          DxExport,
          DxPopup,
          DxLookup,
          EmploymentStaffFormPage,
          DxScrollView,
          DxSelection,
          DxToolbar,
          DxItem,
          DxPopover,
  },
  data() {
      return {
          IPPattern: "^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$",
          CustomStoreMain: new CustomStore({
              key: 'id',
              //loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE),
              insert: (values) => this.sendRequest(URL_SERVICE, 'POST', values),
              update: (key, values) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
              remove: (key) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
              byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
          }),

          LookupCustomDS_sims_buildings:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_buildings+ '?order=name'),
          }),

          LookupCustomDS_sims_employment_relationships:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_employment_relationships+ '?order=name'),
          }),

          LookupCustomDS_sims_directorate_service_department_offices:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_directorate_service_department_offices+ '?order=name'),
          }),

          LookupCustomDS_sims_directorate_services:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_directorate_services+ '?order=name'),
          }),

          LookupCustomDS_sims_directorate_service_departments:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_directorate_service_departments+ '?order=name'),
          }),

          LookupCustomDS_sims_employment_capacities:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_employment_capacities+ '?order=name'),
          }),

          LookupCustomDS_sims_telephony_numbers:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_telephony_numbers+ '?order=name'),
          }),

          isPopupVisible: false,
          isPopoverVisible: false,
          selectedΕmploymentStaffID: null,
          title: 'Προσωπικό',
          pageSizes: [5, 10, 20],
          dataGridRefKey: DATAGRIDREFKEY,
          toolbarOptions: {
              items: [
                  {
                      widget: 'dxButton',
                      options: {
                          icon: 'add',
                          text: 'Προσθηκη',
                          onClick: () => {
                              this.onCustomInitNewRow()
                          }
                      }
                  }
              ]
          }
      };
  }, 
  
  methods: {
        // 
        //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
        //
        sendRequest(url, method = 'GET', values = {}) {
            // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then((response) => response.json()
                .then((json) => {
                    if (response.ok) return json.records;
                    throw json.Message;
                }));
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
        },
        onSelectionChanged(e) {
            this.selectedΕmploymentStaffID = e.currentSelectedRowKeys[0];
        },
        onClosePopupEmit() {
            this.isPopupVisible = false;
            this.dataGrid.refresh();
        },
        onCustomInitNewRow() {
                this.isPopupVisible = true;
                this.selectedΕmploymentStaffID = null;
                this.$refs.employmentStaffFormPage.loadFormData(this.selectedΕmploymentStaffID);
        },
        onCustomRowEdit(e) {
            this.selectedΕmploymentStaffID = e.row.key
            this.isPopupVisible = true

        },
        onExporting (e){
            OParameters.methods.exportDataGridToPdfExcel(e, this.title, [])
        },
  },
  computed: {
      dataGrid: function() {
          return this.$refs[DATAGRIDREFKEY].instance;
      }
  },
};
</script>

<style lang="scss">
</style>
