<template>
    <div id="container">
        <h3 class="content-block openware-form-title">{{ title }}</h3>
  
        <DxDataGrid
            :key-expr="id"
            :data-source="CustomStoreMain"
            :columns="columns"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :show-borders="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :row-alternation-enabled="true"
            :focused-row-enabled="true"
            :selection-mode="'single'"
            :ref="dataGridRefKey"
            :allow-export-selected-data="true"
            :word-wrap-enabled="true"
  
            @exporting="onExporting"
        >
            <DxSelection mode="single" />
  
            <DxPaging 
                :enabled="true"
                :page-size="5"
            />
              <DxToolbar>
                  <DxItem name="exportButton" 
                      :options="{
                            hint: 'Εξαγωγή δεδομένων'
                      }"
                    />
              </DxToolbar>
  
            <DxFilterRow :visible="true"/>
            <DxHeaderFilter :visible="true"/>
            
            <DxPager
                :visible="true"
                :allowed-page-sizes="pageSizes"
                :display-mode="displayMode"
                :show-page-size-selector="true"
                :show-info="true"
                :show-navigation-buttons="true"
            />

            <DxGroupPanel :visible="false"/>

            <DxSorting mode="multiple"/>

            <DxColumn
                data-field="surname"
                caption="Επίθετο"
              sort-order="asc"
            >
            </DxColumn>

            <DxColumn
                data-field="name"
                caption="Όνομα"
            >
            </DxColumn>

            <DxColumn
                data-field="phone_telephony_numbers_id"
                caption="Σταθερό Τηλέφωνο"
                :hiding-priority="15"
            >
              <DxLookup
                  :data-source="LookupCustomDS_sims_telephony_numbers" 
                  value-expr="id"
                  display-expr="phone_no"
                />
            </DxColumn>

            <DxColumn
                data-field="mobile_telephony_numbers_id"
                caption="Υπηρεσιακό Κινητό Тηλέφωνο"
                :hiding-priority="14"
            >
              <DxLookup
                  :data-source="LookupCustomDS_sims_telephony_numbers" 
                  value-expr="id"
                  display-expr="phone_no"
                />
            </DxColumn>

            <DxColumn
                data-field="email_1"
                caption="Υπηρεσιακό Еmail"
                :hiding-priority="13"
            >
            </DxColumn>

            <DxExport
              :enabled="true"
              :formats="['pdf', 'xlsx']"
              :texts= "{
                      exportAll: 'Εξαγωγή σε {0}',
                  }"
            />
       </DxDataGrid>
    </div>
  </template>
  
  <script>
  
  const DATAGRIDREFKEY = 'my-data-grid';
  
  const URL_SERVICE = OParameters.Host.URI + 'sims_employment_staff';
  const URL_SERVICE_LOOKUP_sims_employment_relationships = OParameters.Host.URI + 'sims_employment_relationships';
  
  const URL_SERVICE_LOOKUP_sims_directorate_service_department_offices = OParameters.Host.URI + 'sims_directorate_service_department_offices';
  
  const URL_SERVICE_LOOKUP_sims_directorate_services = OParameters.Host.URI + 'sims_directorate_services';
  const URL_SERVICE_LOOKUP_sims_directorate_service_departments = OParameters.Host.URI + 'sims_directorate_service_departments';
  
  const URL_SERVICE_LOOKUP_sims_employment_capacities = OParameters.Host.URI + 'sims_employment_capacities';
  const URL_SERVICE_LOOKUP_sims_buildings = OParameters.Host.URI + 'sims_buildings';
  const URL_SERVICE_LOOKUP_sims_telephony_numbers = OParameters.Host.URI + 'sims_telephony_numbers';
  
  import DxDataGrid, {
          DxColumn,
          DxPaging,
          DxPager,
          DxGroupPanel,
          DxSorting,
          DxFilterRow,
          DxHeaderFilter,
          DxExport,
          DxSelection,
          DxLookup,
          DxToolbar,
          DxItem
  } from 'devextreme-vue/data-grid';

  import CustomStore from 'devextreme/data/custom_store';
  import 'whatwg-fetch';

  import OParameters from '../sims-globals';

  export default {
        components: {
            DxDataGrid,
            DxColumn,
            DxPaging,
            DxPager,
            DxGroupPanel,
            DxSorting,
            DxFilterRow, 
            DxHeaderFilter,
            DxExport,
            DxLookup,
            DxSelection,
            DxToolbar,
            DxItem,
    },
    data() {
        return {
            IPPattern: "^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$",
            CustomStoreMain: new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE),
                insert: (values) => this.sendRequest(URL_SERVICE, 'POST', values),
                update: (key, values) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
                remove: (key) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
                byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
            }),
  
            LookupCustomDS_sims_buildings:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_buildings+ '?order=name'),
            }),
  
            LookupCustomDS_sims_employment_relationships:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_employment_relationships+ '?order=name'),
            }),
  
            LookupCustomDS_sims_directorate_service_department_offices:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_directorate_service_department_offices+ '?order=name'),
            }),
  
            LookupCustomDS_sims_directorate_services:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_directorate_services+ '?order=name'),
            }),
  
            LookupCustomDS_sims_directorate_service_departments:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_directorate_service_departments+ '?order=name'),
            }),
  
            LookupCustomDS_sims_employment_capacities:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_employment_capacities+ '?order=name'),
            }),
  
            LookupCustomDS_sims_telephony_numbers:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                load: () => this.sendRequest(URL_SERVICE_LOOKUP_sims_telephony_numbers+ '?order=name'),
            }),
  
            isPopupVisible: false,
            isPopoverVisible: false,
            selectedΕmploymentStaffID: null,
            title: 'Προβολή Στοιχείων Επικοινωνίας Υπαλλήλων',
            pageSizes: [5, 10, 20],
            dataGridRefKey: DATAGRIDREFKEY,
        };
    }, 
    
    methods: {
          // 
          //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
          //
          sendRequest(url, method = 'GET', values = {}) {
              // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
              if (method === 'GET') {
                  return fetch(url, {
                      method,
                      credentials: 'include',
                  })
                  .then((response) => response.json()
                  .then((json) => {
                      if (response.ok) return json.records;
                      throw json.Message;
                  }));
              }
              return fetch(url, {
                  method,
                  body: JSON.stringify(values),
                  headers: {
                      'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                  },
                  credentials: 'include',
              }).then((result) => {
                  if (result.ok) {
                      return result.text().then((text) => text && JSON.parse(text));
                  }
                  return result.json().then((json) => {
                      throw json.Message;
                  });
              });
          },
          onExporting (e){
              OParameters.methods.exportDataGridToPdfExcel(e, this.title, [])
          },
    },
    computed: {
        dataGrid: function() {
            return this.$refs[DATAGRIDREFKEY].instance;
        }
    },
  };
  </script>
  
  <style lang="scss">
  </style>
  