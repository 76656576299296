<template>
  <div>    
    <div class="widget-container">
     
      <DxForm
        id="form"
        :form-data="state.formData"
        label-mode='outside'
        :read-only='false'
        :show-colon-after-label='true'
        label-location='top'
        :min-col-width='300'
        :col-count='1'
        ref="formMaster"
      >

        <DxGroupItem caption="Βασικά Στοιχεία" :col-span="2" :col-count="2">
        <DxSimpleItem 
          data-field="name"
          :is-required="true"
        >
            <DxLabel text="Όνομα Τηλεφωνίας"/>
        </DxSimpleItem>

        <DxSimpleItem
          data-field="building_id"
          editor-type="dxSelectBox"
          :editor-options="{
            dataSource: state.LookupCustomDS_sims_buildings,
            displayExpr: 'name',
            valueExpr:'id'
          }"
        >
          <DxLabel text="Κτιριακή Υποδομή"/>
        </DxSimpleItem>

        <DxSimpleItem
            data-field="sr_telecom_provider_id"
            editor-type="dxSelectBox"
            :editor-options="{
                dataSource: state.LookupCustomDS_sims_sr_telecom_providers,
                displayExpr: 'name',
                valueExpr:'id'
            }"
        >
            <DxLabel text="Πάροχος"/>
        </DxSimpleItem>

        <DxSimpleItem
            data-field="hw_phone_line_type_id"
            editor-type="dxSelectBox"
            :editor-options="{
                dataSource: state.LookupCustomDS_sims_hw_phone_line_types,
                displayExpr: 'name',
                valueExpr:'id'
            }"
        >
            <DxLabel text="Τύπος Γραμμής Τηλεφωνίας"/>
        </DxSimpleItem>

      <!--
        <DxSimpleItem
            data-field="phone_no"
            help-text="Παράδειγμα: +30 2310 111 111"
        >
          <DxLabel text="Αριθμός Τηλεφώνου"/>
          <DxRequiredRule 
                  message="Υποχρεωτικό πεδίο"
          />
        </DxSimpleItem>

        <DxSimpleItem
            :editor-options="phoneEditorOptions"
            data-field="phone_no"          
            help-text="Παράδειγμα: +30 2310 111 111"
        >
            <DxLabel text="Αριθμός Τηλεφώνου - MSN"/>
        </DxSimpleItem>
      -->

        <DxSimpleItem
              data-field="contract_description"
          >
            <DxLabel text="Περιγραφή Προγράμματος Συμβολαίου"/>
        </DxSimpleItem>

        <DxSimpleItem
            data-field="contract_date_end"
            editor-type="dxDateBox"
            :editor-options="dateBoxEditorOptions"
        >
            <DxLabel text="Ημερομηνία Λήξης Προγράμματος"/>
            <!-- <DxRequiredRule message="Υποχρεωτικό πεδίο" /> -->
        </DxSimpleItem>
    </DxGroupItem>


            <DxGroupItem caption="Επιπλέον Πληροφορίες">
                <DxSimpleItem
                    data-field="description"
                    editor-type="dxTextArea"
                    :editor-options="{placeholder: 'Προσθήκη σχόλιου...', height: 75, rows: 10}"
                >
                    <DxLabel :visible="false" text="Επιπλέον Πληροφορίες"/>
                </DxSimpleItem>
            </DxGroupItem>
      </DxForm>

      <div class="dx-fieldset-header">Τηλεφωνικοί Αριθμοί</div>
      <div class="dx-fieldset">
                <DxDataGrid
                    :key-expr="id"
                    :data-source="state.CustomStoreDetail"
                    :columns="columns"
                    :allow-column-reordering="true"
                    :allow-column-resizing="true"
                    :column-auto-width="true"
                    :show-borders="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :row-alternation-enabled="true"
                    :focused-row-enabled="true"
                    :width="gridWidth"
                    :style="{ 'max-width': maxWidth }"
                    ref="gridDetail"
                    :word-wrap-enabled="true"

                    @exporting="onExporting"
                    @rowClick="openForm"
                    @init-new-row="onInitNewRow"
                    @row-validating="onRowValidating">

                >
                <DxPaging 
                        :enabled="true"
                        :page-size="5"
                    />

                    <DxFilterRow :visible="true"/>
                    <DxHeaderFilter :visible="true"/>
        
                    <DxPager
                        :visible="true"
                        :allowed-page-sizes="pageSizes"
                        :display-mode="displayMode"
                        :show-page-size-selector="true"
                        :show-info="true"
                        :show-navigation-buttons="true"
                    />

                    <DxGroupPanel :visible="false"/>
                    <DxSorting mode="multiple"/>

                    <DxEditing
                        :allow-updating="true"
                        :allow-adding="true"
                        :allow-deleting="true"
                        mode="batch"
                    />        

                    <DxColumn
                        data-field="phone_no"
                        caption="Αριθμός Τηλεφώνου"
                    >
                        <DxRequiredRule 
                            message="Υποχρεωτικό πεδίο"
                        />
                    </DxColumn>

                    <DxColumn
                        data-field="phone_number_type_id"
                        caption="Τύπος Αριθμού"
                        :hiding-priority="6"
                    >
                        <DxLookup
                        :data-source="state.LookupCustomDS_sims_telephony_number_types" 
                        value-expr="id"
                        display-expr="name"
                        />
                    </DxColumn>
                </DxDataGrid>

                <div class="dx-fieldset-header">Στοιχεία Router</div>
                <DxDataGrid
                    :key-expr="id"
                    :data-source="state.CustomStoreDetail_sims_hw_router_devices_VIEW"
                    :columns="columns"
                    :allow-column-reordering="true"
                    :allow-column-resizing="true"
                    :column-auto-width="true"
                    :show-borders="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :row-alternation-enabled="true"
                    :focused-row-enabled="true"
                    :width="gridWidth"
                    :style="{ 'max-width': maxWidth }"
                    ref="gridDetail_sims_hw_router_devices_VIEW"
                    
                    :word-wrap-enabled="true"
                    @exporting="onExporting"
                    @rowClick="openForm"
                    @init-new-row="onInitNewRow"
                    @row-validating="onRowValidating">

                >
                    <DxSorting mode="multiple"/>

                    <!--
                    <DxEditing
                        :allow-updating="false"
                        :allow-adding="false"
                        :allow-deleting="false"
                        mode="batch"
                    />
                    -->

                    <DxColumn data-field="code" caption="Κωδικός"/>
                    <DxColumn data-field="name" caption="Μοντέλο"/>
                    <DxColumn data-field="ip" caption="IP"/>
                    <DxColumn data-field="username" caption="Όνομα Χρήστη"/>
                    <DxColumn data-field="password" caption="Κωδικός Πρόσβασης" :cell-template="(cellElement) => cellElement.textContent = '••••••••'"/>
                    <DxColumn data-field="ssid" caption="SSID"/>
                    <DxColumn data-field="wpa" caption="WPA"/>
                    <DxColumn data-field="wpa_passphrase" caption="WPA Passphrase"/>

                    <!--
                    <DxColumn
                        data-field="phone_number_type_id"
                        caption="Τύπος Αριθμού"
                        :hiding-priority="6"
                    >
                        <DxLookup
                        :data-source="state.LookupCustomDS_sims_telephony_number_types" 
                        value-expr="id"
                        display-expr="name"
                        />
                    </DxColumn>
                    -->

                </DxDataGrid>

            </div>
        
          <div id="buttons">
              <DxToolbar>
                  <dxItem 
                      location="after"
                      widget="dxButton"
                      @click="onClickSave"
                      :options="{
                          icon: 'save',
                          text: 'Αποθηκευση'
                      }"
                  >
                  </dxItem>
                  <dxItem 
                      location="after" 
                      widget="dxButton"
                      @click="onClickClose"
                      :options="{
                          icon: 'close',
                          text: 'Κλείσιμο'
                      }"
                      >
                  </dxItem>
              </DxToolbar>
        </div>
    </div>
</div>
</template>

<script>

  const URL_SERVICE = OParameters.Host.URI + 'sims_telephony';  
  const URL_SERVICE_sims_hw_router_devices_VIEW = OParameters.Host.URI + 'sims_hw_router_devices_VIEW';

  const URL_SERVICE_LOOKUP_sims_buildings = OParameters.Host.URI + 'sims_buildings';
  const URL_SERVICE_LOOKUP_sims_sr_telecom_providers = OParameters.Host.URI + 'sims_sr_telecom_providers';
  const URL_SERVICE_LOOKUP_sims_hw_phone_line_types = OParameters.Host.URI + 'sims_hw_phone_line_types';
  const URL_SERVICE_LOOKUP_sims_telephony_number_types = OParameters.Host.URI + 'sims_telephony_number_types';

  //Grids
  const URL_SERVICE_sims_telephony_numbers = OParameters.Host.URI + 'sims_telephony_numbers';

  //SEQUENCE
  const URL_SERVICE_seq_hw_nextval_VIEW = OParameters.Host.URI + 'seq_hw_nextval_VIEW';

  //const DATAGRIDREFKEY = 'my-data-grid';

  import CustomStore from 'devextreme/data/custom_store';

  import { reactive, onMounted, computed } from 'vue';  
  import 'whatwg-fetch';

  import OParameters from '../sims-globals';

  import {
      DxForm, 
      DxLabel,
      DxSimpleItem, 
      DxRequiredRule,
      DxGroupItem
  } from 'devextreme-vue/form';

  import DxDataGrid, {
      DxColumn,
      DxEditing, 
      DxPaging,
      DxPager,
      DxGroupPanel,
      DxSorting,
      DxFilterRow,
      DxHeaderFilter,
      DxLookup,    
  } from 'devextreme-vue/data-grid';

  import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';

  export default {
      name: 'TelephonyFormPage',
      components: {
      DxForm,
      DxLabel,
      DxSimpleItem,
      DxRequiredRule,
      DxDataGrid,
      DxColumn,
      DxEditing,
      DxPaging,
      DxPager,
      DxGroupPanel,
      DxSorting,
      DxFilterRow,
      DxHeaderFilter,
      DxLookup,
      DxToolbar,
      DxItem,
      DxGroupItem
    },
    data() {
        return {
            title: "Διαχείριση Τηλεφωνίας",
            calendarOptions: { value: new Date() },
            textAreaOptions: { placeholder: 'Προσθήκη σχολίου..'},
            pageSizes: [5, 10, 20],
            //dataGridRefKey: DATAGRIDREFKEY,
            dateBoxEditorOptions: {
              //pickerType: 'rollers',
              type: 'date',
              displayFormat: 'dd/MM/yyyy',
              dateSerializationFormat: 'yyyy-MM-dd',
              invalidDateMessage: 'Λάθος Ημερομηνία',
              showClearButton: true,
              min: new Date(),
              max: new Date(2030, 11, 31)
            },            
        }
    },
    props: {
      telephony_id: {
          type: Number,
          required: true
      }
    },
    watch: {
      telephony_id(newValue, oldValue) {          
          this.$emit('telephony_id-value-changed', newValue, oldValue);
          this.loadFormData(newValue);
      }
    },
    setup(props) {
      const gridWidth = computed(() => '100%'); // set the width to 100%
      const maxWidth = computed(() => '1000px'); // set the max-width to 800px
      const state = reactive({
        formData: {},

        LookupCustomDS_sims_buildings:  new CustomStore({
            key: 'id',
            //loadMode: 'raw',
            load: () => sendRequest(URL_SERVICE_LOOKUP_sims_buildings+ '?order=name'),
            byKey: (key) => sendRequestByKey(URL_SERVICE_LOOKUP_sims_buildings + "/" + encodeURIComponent(key)),
        }),

        LookupCustomDS_sims_sr_telecom_providers:  new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: () => sendRequest(URL_SERVICE_LOOKUP_sims_sr_telecom_providers + '?order=name'),
        }),

        LookupCustomDS_sims_hw_phone_line_types:  new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: () => sendRequest(URL_SERVICE_LOOKUP_sims_hw_phone_line_types + '?order=name'),
        }),

        LookupCustomDS_sims_telephony_number_types: new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: () => sendRequest(URL_SERVICE_LOOKUP_sims_telephony_number_types),
        }),

        CustomStoreDetail_sims_hw_router_devices_VIEW: new CustomStore({
            key: 'id',
            //loadMode: 'raw',
            load: () => sendRequest(URL_SERVICE_sims_hw_router_devices_VIEW + '?filter=telephony_id,eq,' + props.telephony_id),
            insert: (values) => sendRequest(URL_SERVICE_sims_hw_router_devices_VIEW, 'POST', values),
            update: (key, values) => sendRequest(URL_SERVICE_sims_hw_router_devices_VIEW + '/' + encodeURIComponent(key), 'PUT', values),
            remove: (key) => sendRequest(URL_SERVICE_sims_hw_router_devices_VIEW + '/' + encodeURIComponent(key), 'DELETE'),
            byKey: (key) => sendRequest(URL_SERVICE_sims_hw_router_devices_VIEW + "/" + encodeURIComponent(key)),
        }),

        CustomStoreDetail: new CustomStore({
            key: 'id',
            //loadMode: 'raw',
            load: () => sendRequest(URL_SERVICE_sims_telephony_numbers + '?filter=telephony_id,eq,' + props.telephony_id),
            insert: (values) => sendRequest(URL_SERVICE_sims_telephony_numbers, 'POST', values),
            update: (key, values) => sendRequest(URL_SERVICE_sims_telephony_numbers + '/' + encodeURIComponent(key), 'PUT', values),
            remove: (key) => sendRequest(URL_SERVICE_sims_telephony_numbers + '/' + encodeURIComponent(key), 'DELETE'),
            byKey: (key) => sendRequest(URL_SERVICE_sims_telephony_numbers + "/" + encodeURIComponent(key)),
        }),

        CustomStore_seq_hw_nextval_VIEW:  new CustomStore({
                key: 'id',
                loadMode: 'raw',
                cacheRawData: 'false',
                load: () => sendRequest(URL_SERVICE_seq_hw_nextval_VIEW),
        }),

        CustomStoreMain: new CustomStore({
            key: 'id',
            //loadMode: 'raw',
            load: () => sendRequest(URL_SERVICE),
            insert: (values) => sendRequest(URL_SERVICE, 'POST', values),
            update: (key, values) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
            remove: (key) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
            byKey: (key) => sendRequestByKey(URL_SERVICE + "/" + encodeURIComponent(key)),
        }),
      });

      // 
      //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
      //
      const sendRequest = (url, method = 'GET', values = {}) => {
          //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
          if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then(response => response.json())
                .then(json => {
                  return json.records;
                })
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
      };

      const sendRequestByKey = (url, method = 'GET') => {
          //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
          return fetch(url, {
                    method,
                    headers: {
                        'Accept': 'application/json',
                    },
                    credentials: 'include',
                })
                .then(response => response.json())
      };

      const saveData = async () => {
          // console.log('Save data');
          await state.CustomStoreMain.update(state.formData.id, state.formData);
      };

      const insertData = async () => {
          // console.log('Insert data');
          await state.CustomStoreMain.insert(state.formData);
      };

      const clearForm = () => {
          state.formData = {};
      };

      var isGridValidated = true;
  
      onMounted(async () => {
          //state.formData = await state.CustomStoreMain.byKey(props.telephony_id);
          if (props.telephony_id != null) state.formData = await state.CustomStoreMain.byKey(props.telephony_id);

      });

      return {
          saveData,
          insertData,
          clearForm,
          gridWidth,
          maxWidth,
          state,
          isGridValidated
      };
    },
    methods: {
        async loadFormData(id) {

            if (id != null) {
                this.isInsertMode = false
                this.state.formData = await this.state.CustomStoreMain.byKey(id)
                
                // cascade ips support
                /*
                const cascaderows = await this.state.LookupCascadeCustomDS_sims_network_ips_VIEW.load()
                if (cascaderows) {
                    this.$refs.formMaster.instance.getEditor('network_ip_id').option('dataSource', cascaderows)
                }
                */

            } else {
                this.getNextVal().then(value => {
                    this.isInsertMode = true
                    this.state.formData = {entry_date: new Date().toISOString().split('T')[0]}
                    this.state.formData.id = value
                });
            }

            this.initialCodeValue = this.state.formData.code

            //this.dataGrid.refresh();
            //this.dataGrid_sims_hw_router_devices_VIEW.refresh()

            await this.$refs.gridDetail.instance.refresh();
            await this.$refs.gridDetail_sims_hw_router_devices_VIEW.instance.refresh();

        },

        async getNextVal() {
            //console.log('getNextVal')
            this.state.CustomStore_seq_hw_nextval_VIEW.clearRawDataCache()
            let row = await this.state.CustomStore_seq_hw_nextval_VIEW.load()
            return row[0].nextval
        },

        onClickSave: async function() {

            const formValidator = this.dataForm.validate();
            this.isGridValidated = true
            let formSaved = false

            if (formValidator.isValid) {
                if (!this.isInsertMode) {
                    await this.saveData()
                    formSaved = true
                } else {
                    await this.insertData()
                    formSaved = true
                }
            }

            await this.$refs.gridDetail.instance.saveEditData()
            //await this.$refs.gridDetail_sims_leasing_hw_printer_device_measurements.instance.saveEditData()

            if (this.isGridValidated && formSaved) this.$emit('closePopupEvent', true)

        },
        onClickClose: function() {
            this.$emit('closePopupEvent', true);
        },
        onInitNewRow(row) {
            row.data.telephony_id = this.state.formData.id;
        },        
        onRowValidating(e) {
          this.isGridValidated = this.isGridValidated && e.isValid;
          console.log('onRowValidating')
          console.log(e.isValid)
        }
    },
    computed: {
        dataGrid: function() {
            return this.$refs.gridDetail.instance;
        },
        dataGrid_sims_hw_router_devices_VIEW: function() {
            return this.$refs.gridDetail_sims_hw_router_devices_VIEW.instance;
        },
        dataForm: function() {
            return this.$refs.formMaster.instance;
        },
    }
};
</script>

<style lang="scss">

</style>

<style scoped>

#form {
   margin-top: 25px;
}
.caption {
    font-size: 18px;
    font-weight: 500;
}
.option {
    margin-top: 10px;
}
</style>
